// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// @import '@angular/material/core/theming'; // TODO: remove this depricated link
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-component-typographies();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$angular-pxo-primary: mat.m2-define-palette(mat.$m2-blue-palette);
$angular-pxo-accent: mat.m2-define-palette(mat.$m2-blue-palette);
$angular-pxo-typography: mat.m2-define-typography-config(
  $font-family: 'Trebuchet MS',
);

// The warn palette is optional (defaults to red).
$angular-pxo-warn: mat.m2-define-palette(mat.$m2-red-palette, 400);

// Create the theme object (a Sass map containing all of the palettes).
$angular-pxo-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $angular-pxo-primary,
      accent: $angular-pxo-accent,
      warn: $angular-pxo-warn,
    ),
    typography: $angular-pxo-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($angular-pxo-theme);
@include mat.all-component-themes($angular-pxo-theme);
@include mat.typography-hierarchy($angular-pxo-typography);
@include mat.radio-density(-3);
@include mat.checkbox-density(-3);
@include mat.form-field-density(-5);

@import 'scss/components/modal.scss';
@import 'scss/components/form.scss';
@import 'scss/components/input.scss';
@import 'scss/components/select.scss';
@import 'scss/components/button.scss';
@import 'scss/components/alert.scss';
@import 'scss/components/scroll.scss';
@import 'scss/components/colors.scss';
@import 'scss/components/tooltip.scss';
@import 'scss/components/table.scss';
@import 'scss/components/components-example.scss';
@import 'scss/components/chart.scss';
@import 'scss/components/print.scss';
@import 'scss/components/range-slider.scss';
@import 'scss/components/ngx-editor.scss';
@import 'scss/components/scanner-filters.scss';

body {
  font-family: 'Trebuchet MS', Arial, sans-serif;
}
