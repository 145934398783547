@import "../../scss/values";
@import "../../scss/components/scroll";

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;

  &:has(.mat-mdc-select-panel.select-overlay, .mat-mdc-select-panel.multiselect-overlay) {
    min-width: fit-content;
  }
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: #00000052;
}

.cdk-overlay-transparent-backdrop {
  transition:
    visibility 1ms linear,
    opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.modals {
  .mat-mdc-dialog-title.mdc-dialog__title {
    padding: 16px 20px 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28px;
    color: var(--font-dark-color);
    border-bottom: 1px solid #e0e3eb;
    border-bottom: 1px solid var(--dark-border-color);

    &::before {
      display: none;
    }
  }

  .mat-mdc-dialog-actions {
    padding: 24px;
  }
}

.mat-mdc-dialog-title.mdc-dialog__title::before {
  display: none;
}

.modal-style {
  height: calc(100% - 110px);
  display: flex;
  flex-direction: column;

  &-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -40px;
    padding-right: 40px;
    padding-bottom: 40px;

    > .from-group:last-child {
      margin-bottom: 0;
    }

    > .from-group-column:last-child {
      margin-bottom: -40px;
    }
  }

  &-footer {
    padding: 20px 0;
  }

  .black-theme & {
    color: #fff !important;
  }

  .border-btn {
    .black-theme & {
      color: #fff !important;
    }
  }

  .per-input .input-box input {
    .black-theme & {
      color: #fff !important;
    }
  }

  .mat-typography {
    font-size: 12px;
    padding: 0 0 40px !important;

    p {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      display: block;
      margin: auto;
      max-width: 100%;
    }
  }

  .mat-mdc-dialog-content {
    .black-theme & {
      color: #afafc0 !important;
    }
  }

  .text-light {
    color: #787b86;

    .black-theme & {
      color: #afafc0 !important;
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .select-overlay .scrollable-options {
      min-width: 255px !important;
    }
  }
}

.block-with-button {
  background: rgba(29, 137, 228, 0.1);
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .mat-button {
    padding-left: 17px !important;
    padding-right: 17px !important;
    min-width: 100px;
    margin-bottom: 0;
  }

  > div {
    padding: 0 10px;

    &:first-child {
      width: 100%;
    }
  }
}

.modal-btns {
  &.mat-mdc-dialog-actions {
    text-align: left;
    display: flex;
    padding: 0 !important;
    margin: 0 -10px;
    min-height: 36px !important;
  }

  > div {
    padding: 0 10px;
    flex: 1 0 0;
  }

  button {
    min-width: 100px;
    margin-bottom: 20px;
  }
}

.modal-step {
  margin-bottom: 30px;

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  &-title {
    font-size: 14px;
    font-weight: 700;
    padding-left: 40px;
    position: relative;
    margin: 0 0 15px;

    .black-theme & {
      color: #fff;
    }
  }

  &-number {
    height: 24px;
    width: 24px;
    background: rgba(29, 137, 228, 0.1);
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    color: #131722;
    text-align: center;

    .black-theme & {
      color: #fff !important;
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.right-modal .mat-mdc-dialog-container {
  position: absolute;
  top: 0;
  left: initial;
  right: 0;
  display: flex;
  width: 450px;
  height: auto;
  min-height: 100%;
  max-width: 100%;
  margin: 0;
  border-radius: 0;
  padding: 0 40px;

  > * {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mat-mdc-dialog-content {
    padding: 0;
    margin: 0;
  }

  .mat-mdc-dialog-title {
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    padding: 38px 0 40px;
    position: relative;

    .popup-close-btn {
      position: absolute;
      top: 41px;
      right: -2px;

      img {
        width: 14px;
      }
    }
  }
}

.position-sizing .mat-mdc-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 368px;
  margin: 50px auto;
}

.right-modal.no-padding .mat-mdc-dialog-container {
  padding: 0;
}

.right-modal.full-height .mat-mdc-dialog-container {
  @extend .scroll-style;
  height: 100%;
}

.right-modal.wide-modal .mat-mdc-dialog-container {
  width: 550px;
}

@media (max-width: 767px) {
  .modal-style {
    height: calc(100% - 70px);
  }

  .right-modal .mat-mdc-dialog-container .mat-mdc-dialog-title {
    padding: 18px 0 20px;
  }

  .right-modal .mat-mdc-dialog-container .mat-mdc-dialog-title .popup-close-btn {
    top: 21px;
  }

  .right-modal .mat-mdc-dialog-container {
    width: 100%;
  }

  .right-modal .mat-mdc-dialog-container {
    width: 100%;
  }

  .modal-style-content {
    margin-right: -20px;
    padding-right: 20px;
  }

  .right-modal .mat-mdc-dialog-container {
    padding: 0 20px;
  }

  .modal-style-footer {
    padding-bottom: 0;
  }

  .modal-style .mat-typography {
    padding: 0 0 20px !important;
  }
}

.modal-close-btn {
  height: 16px;
  width: 16px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border: 0;
  background-color: transparent;
  color: var(--modal-close-btn-color);
  cursor: pointer;
}

.symbol-search {
  .mat-mdc-dialog-container {
    @media screen and (max-width: $mobile-min-575px-width-trigger) {
      width: 100vw;
      height: 100vh;
    }
  }

  .symbol-search-modal-content {
    height: 503px;

    @media screen and (max-width: $tablet-min-width-trigger) {
      height: auto;
      min-height: 350px;
    }
  }
}

.trading-log-quotes-pane {
  width: 300px;
  height: fit-content;
  max-height: 360px;
}

.trading-log-quotes-modal {
  width: 100vw;
  height: 100vh;
}
