@mixin iphone() {
  @media only screen and (min-device-width: 375px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

// iPhone 13 Pro Max and iPhone 12 Pro Max
@mixin iphone-13pro-max-and-portrait() {
  @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    @content;
  }
}

@mixin iphone-13pro-max-and-landscape() {
  @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    @content;
  }
}

// iPhone 13, 13 Pro,  iPhone 12 and iPhone 12 Pro
@mixin iphone-12-12-pro-13-13pro-and-portrait() {
  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    @content;
  }
}

@mixin iphone-12-12-pro-13-13pro-and-landscape() {
  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    @content;
  }
}

//Phone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X
@mixin iphone-13-12-mini-XS-x() {
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin iphone-13mini-and-portrait() {
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    @content;
  }
}

@mixin iphone-13mini-and-landscape() {
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    @content;
  }
}

@mixin iphone-X-XS-XR-11-and-portrait() {
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    @content;
  }
}

@mixin iphone-X-XS-XR-11-and-landscape() {
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    @content;
  }
}

@mixin iphone-11() {
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

//XR
@mixin iphone-XR--portrait() {
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
    @content;
  }
}

@mixin iphone-XR--portrait() {
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }
}

@mixin iphone-11-proMax() {
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

// Todo: check this mixin/ prb it doesn't work
@mixin iphone-11-proMax-portrait() {
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    @content;
  }
}

// Todo: check this mixin/ prb it doesn't work
@mixin iphone-11-proMax-landscape() {
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    @content;
  }
}

@mixin iphone-6-6s-7-8-portrait() {
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    @content;
  }
}

@mixin iphone-6-6s-7-8-landscape() {
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }
}

@mixin all-retina() {
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1),
    ( max-device-pixel-ratio: 1) and
    ( -webkit-max-device-pixel-ratio: 1) and
    ( -webkit-max-device-pixel-ratio: 1),
    ( max-device-pixel-ratio: 1) and
    ( max-resolution: 96dpi) and
    ( max-resolution: 1dppx) {
      @content;
  }
}

// tablets
@mixin ipad() {
  @media only screen and (min-device-width: 744px) and (max-device-width: 1133px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-mini() {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-9th() {
  @media only screen and (min-device-width: 810px) and (max-device-width: 1010px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-mini-landscape() {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin ipad-mini-2021() {
  @media only screen and (min-device-width: 744px) and (max-device-width: 1133px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-mini-2021-landscape() {
  @media only screen and (min-device-width: 744px) and (width: 1133px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-Pro-11() {
  @media only screen and (min-device-width: 834px) and (max-device-width: 1075px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-Pro-12-9() {
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-Pro-11() {
  @media only screen and (min-device-width : 834px) and (max-device-width : 1075px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin ipad-Pro-12-9-portrait() {
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    @content;
  }
}

@mixin ipad-Pro-12-9-landscape() {
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    @content;
  }
}

// TAB 7S
@mixin tab7s-landscape() {
  @media only screen and (max-width: 926px) and (orientation: landscape) {
    @content;
  }
}

// wide and low monitor
@mixin desktop-width-and-to800px-hight-monitor() {
  @media screen and (min-height: 550px) and (max-height: 800px) and (min-width: $desktop-min-width-trigger) {
    @content;
  }
}