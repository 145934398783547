@import '../mixins/fonts';

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.default-btn {
  color: var(--main-font-color);
}

.primary {
  color: var(--primary-color);
}

.danger {
  color: var(--danger-color);
}

.succsesfull {
  color: var(--success-color);
}

.secondary {
  color: var(--border-btn-color);
}

.status-open {
  color: var(--status-open-color);
}

.status-ok {
  color: var(--status-open-color);
}

.status-filled {
  color: var(--status-filled-color);
}

.status-partially-filled {
  color: var(--status-partially-filled-color);
}

.status-canceled {
  color: var(--status-canceled-color);
}

.status-pending {
  color: var(--status-pending-color);
}

.status-rejected {
  color: var(--status-rejected-color);
}

.status-error {
  color: var(--status-error-color);
}

.status-expired {
  color: var(--status-expired-color);
}


li {
  list-style-type: none;
}

.decorated-link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  list-style-type: none;

  &:first-child {
    padding-left: 5px;

    &::after {
      content: none;
    }
  }

  a {
    @include apply-top-menu-font();
    color: var(--main-font-color);
    padding: 5px 10px;
    height: 22px;
    transition: 0.1s;

    &.active {
      color: var(--active-link-color);
    }

    &:hover {
      color: var(--active-link-color);
      transition: 0.1s;

      .mat-icon.drop-down-list-icon {

        path {
          stroke: var(--active-link-color);
        }
      }
    }
  }
}

.full-link {
  height: 22px;
  list-style-type: none;
  display: flex;
  align-items: center;

  a {
    @include apply-top-menu-font();
    display: flex;
    align-items: center;
    color: var(--main-font-color);
    padding: 2px 10px;
    height: 22px;
    height: 22px;
    transition: 0.1s;

    &.active {
      color: var(--active-link-color);
    }

    &:hover {
      color: var(--active-link-color);
      transition: 0.1s;

      .mat-icon.drop-down-list-icon {

        path {
          stroke: var(--active-link-color);
        }
      }
    }
  }
}

.table-title {
  @include apply-table-title-font();
}

.table-small-symbol {
  @include apply-lable-default-font();
}

.table-description {
  @include apply-table-td-cell-font();
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ngx-content-content,
.section-content .ngx-editor-content .NgxEditor__Content {
  color: var(--trading-plan-section-content-color);

  h1 {
    @include ngx-header-content-font();
    font-size: 20px;
    line-height: 24px;
    color: var(--trading-plan-section-content-color);
  }

  h2 {
    @include ngx-header-content-font();
    font-size: 16px;
    line-height: 20px;
    color: var(--trading-plan-section-content-color);
  }

  h3 {
    @include ngx-header-content-font();
    font-size: 14px;
    line-height: 18px;
    color: var(--trading-plan-section-content-color);
  }

  p {
    // empty line: "<p><br class="ProseMirror-trailingBreak"/></p>" - take into account when change styles
    min-height: 20px;
    margin: 0 !important;
  }

  strong {
    @include ngx-strong-font();
  }

  ul,
  ol {
    padding-left: 15px;
  }

  ol li {
    list-style-type: unset;

    p {
      @include ngx-li-content-font();
    }
  }

  ul li {
    list-style-type: disc;

    p {
      @include ngx-li-content-font();
    }
  }
}
