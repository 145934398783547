@import './scroll.scss';
@import './../values';

.trading-log-quotes-wrapper  {
  width: 100%;
  height: fit-content;
  max-height: 100%;
  background: var(--drop-down-bg);
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
  border-radius: 4px;
  @extend .scroll-style;

  @media screen and (max-width: $mobile-max-width-trigger) {
    height: 100%;
    overflow: hidden;

    .data-window-tradier-flag {
      height: 100%;

      .data-table {
        overflow: hidden;
        display: grid;
        grid: auto auto 1fr / auto;

        .table-container {
          height: calc(100% - 1px) !important;
          max-height: unset !important;
        }
      }
    }
  }
}
