.video-hub .youtube-player iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  .video-hub {
    .youtube-player-placeholder {
      width: 100% !important;
      height: 100% !important;
    }

    .youtube-player iframe {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }

  .youtube-player iframe {
    width: 100%;
  }
}

.background-video-pane {
  position: absolute !important;

  .youtube-player-placeholder {
    width: 100% !important;
    height: 100% !important;
    min-height: 100%;
  }

  .youtube-player iframe {
    width: 100%;
    height: inherit;
    min-height: 100%;
    aspect-ratio: 16 / 9;
  }
}

.vimeo-player div {
  position: unset !important;
  padding: 0 !important;
}

.video-player-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  z-index: 10;
}

.video-player-skeleton {
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  inset: 0;
}
