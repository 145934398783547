/*
  This file should keep and link all styles for material theme customization.
*/


/*
  All base material style fixes should be added bellow:
*/

.pxo-app {
  /*
    Please use .pxo-app class as parent class for all custom base styles
  */


  /*
    All custom material components styles (modals, buttons, etc.) should be linked below:
  */
  @import 'custom-material/modals';
  @import 'custom-material/forms';
  @import 'custom-material/chips';
  @import 'custom-material/icons';
  @import 'custom-material/buttons';
  @import 'custom-material/badge';
  @import 'custom-material/slide-toggle';
  @import 'custom-material/select';
  @import 'custom-material/check-box';
  @import 'custom-material/expansion-panel';
  @import 'custom-material/table.scss';
  @import 'custom-material/tab.scss';
  @import 'custom-material/checkbox';
  @import 'custom-material/radio';
  @import 'custom-material/mat-menu';
  @import 'custom-material/textarea';
  @import 'custom-material/mat-calendar';
  @import 'custom-material/mat-carousel';
  @import 'custom-material/tooltip';
  @import 'custom-material/snackbar';
}
