.components-example-container {

    &.template-container {
        .mat-list-item-content {
            padding: 0 8px;
            margin: 5px 0;
        }
    }

    .components-examples {
        color: var(--main-font-color);

        .title {
            font-size: 32px;
        }

        .header {
            font-size: 22px;
            margin-top: 20px;
        }

        .example {
            margin-top: 10px;
            padding: 10px;
            border: 1px solid var(--main-border-color);
            border-radius: 8px;
            display: flex;
            flex-wrap: wrap;

            &.column {
                flex-direction: column;
            }
        }

        .inline-description {
            margin: 5px 0;
        }

        .description {

            p {
                margin-top: 10px;
            }
        }

        .attantion {
            margin: 10px 0;
            padding: 10px 20px;
            border-radius: 4px;
        }

        .class-view {
            display: inline-block;
            padding: 0 5px;
            margin: 0 5px;
            border-radius: 4px;
            background-color: rgb(247, 218, 121);
            color: var(--main-font-color);
        }

        .code {
            border: 1px solid var(--main-border-color);
            border-radius: 8px;
            padding: 10px;
            margin-top: 10px;
            color: var(--table-cell-placeholder);
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            user-select: text;
        }
    }

}