@mixin apply-single-bacground-image($image-url, $size, $position: center center) {
  background-position: $position;
  background-repeat: no-repeat;
  background-size: $size;
  background-image: $image-url;
}

@mixin apply-icon-size($size) {
  width: $size;
  height: $size;
  font-size: $size;
  line-height: $size;
}

@mixin weekly-options-icon-style() {
  padding: 1px 2px 0;
  @include font-regular(12px, 12px, 0px, var(--blue-pressed));
  background-color: var(--light-gray-bg-0);
  border-radius: 2px;
}
