@import '../values';
@import '../mixins/fonts';
@import '../mixins/images';

@mixin lock-icon() {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: inline-flex;
  width: 10px;
  height: 12px;
  background-image: url('/assets/img/Icons/editions-lock-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 13px;
  filter: var(--demo-page-lock-icon-color);
}

body.pxo-app {
  .locked-for-current-edition {
    position: relative;

    &:after {
      @include lock-icon();
    }
  }

  // dropdown menu-item in top-menu
  .mat-mdc-menu-content .mat-mdc-button.locked-for-current-edition {
    &:after {
      display: none;
      content: none;
    }

    .mdc-button__label .menu-item-text {
      position: relative;

      &:after {
        @include lock-icon();
        top: 2px;
        right: -12px;
      }
    }
  }

  // trading-hub top-menu button
  .toolbar-right-section .locked-for-current-edition:after {
    top: 4px;
    right: 5px;
  }

  // left menu items
  .profile-menu-block {
    .locked-for-current-edition::after {
      display: none;
      content: none;
    }

    .locked-for-current-edition {
      .menu-title {
        position: relative;
        display: inline-block;

        &:after {
          @include lock-icon();
          right: -12px;
        }
      }
    }
  }

  .mat-button-toggle.locked-for-current-edition::after {
    display: none;
    content: none;
  }

  .mat-button-toggle.locked-for-current-edition .mat-button-toggle-label-content .cap {
    position: relative;
    display: inline-block;

    &::after {
      @include lock-icon();
      top: unset;
      bottom: 0;
      right: 0;
      translate: 12px 0;

      @media screen and (max-width: $mobile-max-width-trigger) {
        translate: 10px 0;
      }
    }
  }

  // expected-move placeholder (if feature is unavailable for user)
  .expected-move-demo-button {
    @include apply-top-menu-font();
    letter-spacing: normal;
    color: var(--main-font-color);
    padding: 5px 12px 5px 10px;
    height: 22px;
    transition: 0.1s;

    &.active {
      color: var(--active-link-color);
    }

    &:hover {
      color: var(--active-link-color);
      transition: 0.1s;

      .mat-icon.drop-down-list-icon {
        path {
          stroke: var(--active-link-color);
        }
      }
    }
  }

  .calendar-tabs-container {
    .calendar-tab.locked-for-current-edition::after {
      top: 8px;
      bottom: unset;
      right: 8px;
    }
  }

  // for mobile drop-down menu
  .calendar-tab.locked-for-current-edition::after {
    top: 8px;
    bottom: unset;
    right: 8px;
  }

  .sub-menu-container {
    .sub-menu-profile-menu-block-item.locked-for-current-edition::after {
      top: 12px;
      right: -12px;
      @media screen and (max-width: $mobile-max-width-trigger) {
        top: 10px;
      }
    }
  }

  .header-v2-navbar-menu {
    .mat-mdc-menu-content .mat-mdc-button.locked-for-current-edition .mdc-button__label span {
      position: relative;
    }
  }

  .stock-screener-mobile-wrapper,
  .stock-screeners-chart-layout {
    .chart-legend-container {
      .mat-button-link.locked-for-current-edition::after {
        top: 10px;
        right: -4px;
      }
    }
  }
}

body.pxo-app:not(.black-theme) {
  .dark-theme-only {
    display: none;
  }
}

body.pxo-app.black-theme {
  .light-theme-only {
    display: none;
  }
}
