// for symbol-scanner-list-v2 customisation in case parent component is without "encapsulation: none"

.wtf-scanner-watchlist-panel.root {
  .wtf-table-wrapper {
    .table-sort-header {
      th {
        &.changePercent {
          width: 80px !important;

          .mat-sort-header-container {
            justify-content: flex-end !important;
          }
        }
      }
    }

    th {
      &.changePercent {
        width: 80px !important;

        .mat-sort-header-container {
          justify-content: flex-end !important;
        }
      }
    }

    .scanner-symbols-list-virtual-scroll {
      table {
        td {
          &.changePercent {
            width: 80px !important;

            .borderSelection.cell-content {
              justify-content: flex-end !important;
            }

            &.negative {
              color: var(--red-color);
            }

            &.positive {
              color: var(--green-color);
            }
          }
        }
      }
    }
  }
}
