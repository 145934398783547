.danger-bg{
     background-color: var(--btn-danger-icon-color);
}

.primary-bg{
    background-color: var(--primary-alpha);
}

.successful-bg{
    background-color: var(--btn-success-icon-color);
}
