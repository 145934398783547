@import '../values';
@import '../mixins/fonts';
@import '../mixins/images';
@import '../mixins/line-clamp';
@import '../components/scroll';

.pxo-app {
  div.mat-mdc-select-panel {
    padding-block: 0;
    --mat-select-panel-background-color: var(--drop-down-bg);

    .mat-mdc-option {
      --mat-option-label-text-color: var(--main-font-color);
      --mat-option-hover-state-layer-color: var(--select-hover-color);
      --mat-option-selected-state-layer-color: var(--selected-bg-color);
      --mat-option-label-text-line-height: normal;
      --mat-option-label-text-size: 14px;
      --mat-option-label-text-tracking: normal;
      --mat-option-label-text-weight: 400;
      min-height: 42px;

      .mat-pseudo-checkbox {
        display: none;
      }

      .mat-mdc-option-pseudo-checkbox {
        --mat-full-pseudo-checkbox-unselected-icon-color: var(--light-color-2);
      }

      .mdc-list-item__primary-text {
        --mdc-list-list-item-label-text-color: var(--main-font-color);
        --mdc-list-list-item-hover-label-text-color: var(--main-font-color);
      }
    }

    &.transaction-types-overlay {
      max-height: fit-content;
      padding-block: 0 !important;

      .mat-mdc-option {
        --mat-option-selected-state-label-text-color: var(--main-font-color);
        min-height: 32px;

        .mdc-list-item__primary-text {
          font-size: 12px;
          line-height: 36px;
          letter-spacing: normal;
        }
      }
    }
  }

  .mat-mdc-selection-list {
    .mat-mdc-list-item {
      height: 36px;

      &:focus {
        .mdc-list-item__primary-text {
          --mdc-list-list-item-focus-label-text-color: var(--main-font-color);
        }
      }

      .mdc-list-item__start {
        margin-right: 10px;

        .mdc-checkbox {
          --mdc-checkbox-unselected-icon-color: var(--light-color-2);
          --mdc-checkbox-selected-indicator-color: var(--selected-bg-color);
          --mdc-checkbox-state-layer-size: 18px;

          .mdc-checkbox__background {
            border-radius: 2px;
            border-width: 1px;
          }
        }
      }

      .mdc-list-item__primary-text {
        --mdc-list-list-item-label-text-weight: 400;
        --mdc-list-list-item-label-text-size: 14px;
        --mdc-list-list-item-label-text-line-height: normal;
        --mdc-list-list-item-label-text-tracking: normal;
        --mdc-list-list-item-label-text-color: var(--main-font-color);
        --mdc-list-list-item-hover-label-text-color: var(--main-font-color);
        --mdc-list-list-item-selected-state-layer-color: var(--selected-bg-color);
      }
    }
  }
}

.mat-mdc-select {
  .mat-mdc-select-value-text {
    color: var(--main-font-color);

    span,
    .mat-mdc-select-min-line {
      margin-left: 0;
      font-size: 13px;
      letter-spacing: 0.4px;
      line-height: 20px;
      color: var(--font-dark-color);
    }
  }

  .mat-mdc-select-arrow {
    color: var(--font-dark-color);
    width: 8px;
    height: 8px;
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    margin: 4px;
    rotate: 45deg;
    translate: 2px -1px;

    & > svg {
      display: none !important;
      visibility: hidden !important;
      width: 0;
      height: 0;
      opacity: 0;
      z-index: -1;
    }
  }

  &.ma200-select.item-select {
    display: none;
  }

  .mat-mdc-select-placeholder {
    color: var(--font-dark-color) !important;
    font-size: 12px;
  }
}

.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open.add-tl-trade-select-panel {
  .mat-mdc-option.mdc-list-item.ellipsis {
    .mdc-list-item__primary-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--main-font-color);
    }
  }
}

.from-group {
  .mat-mdc-select {
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.no-bg {
      background: transparent;
    }

    &.ellipsis .mat-mdc-select-value {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--main-font-color);
    }

    &.p-r-1 .mat-mdc-select-value {
      padding-right: 1px;
    }

    &.p-l-6 .mat-mdc-select-value {
      padding-left: 6px;
    }
  }

  &.no-border {
    .mat-mdc-select {
      border: none;
    }
  }

  &.full-width {
    width: 100%;
  }

  .mat-mdc-select .mat-mdc-select-value {
    padding-left: 6px;
    width: 100%;
  }

  .mat-mdc-select-trigger {
    width: 100%;
  }

  .mat-mdc-select-arrow {
    margin-right: 12px;
  }

  &.simple-imput-select {
    .option {
      margin: 0;
      padding: 10px 0 !important;
      height: 32px !important;

      &.mat-mdc-option.mat-mdc-option-active {
        background: none;
      }
    }
  }
}

.mat-mdc-select-panel {
  min-width: fit-content;

  &.date-mdc-select-options {
    @extend .scroll-style;

    .mat-mdc-option.mdc-list-item {
      min-height: 30px;
      font-size: 13px;
      line-height: 30px;

      &.mat-mdc-option-active {
        color: var(--primary-color);
        background-color: var(--selected-bg-color);
      }
    }
  }
}

.mat-mdc-select-panel:not([class*='mat-elevation-z']) {
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
}

@supports (-webkit-appearance: none) {
  .mat-mdc-select-panel {
    &.ng-animating {
      visibility: hidden;
    }
  }
}

.mat-mdc-select,
.mat-mdc-select-panel {
  .mat-mdc-option:focus.mdc-list-item,
  .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
    background-color: var(--selected-bg-color);

    &:hover {
      background-color: var(--selected-bg-color);
    }
  }
}

.mat-mdc-option.flex-option {
  .traider-option-content {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 32px;
  }
}

.mat-mdc-select-panel .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
  background-color: var(--selected-bg-color);
}

// custom styles for add-tl-trade-select-panel (based on "middle-size")
.mat-mdc-select-panel.middle-size.add-tl-trade-select-panel {
  .mat-mdc-option.mdc-list-item {
    height: auto !important;
    padding: 4px 8px;
    overflow: hidden;
    word-break: break-word;
    font-size: 12px;
    line-height: 16px;
  }

  .mat-mdc-option.mdc-list-item.ellipsis {
    .mdc-list-item__primary-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--main-font-color);
    }
  }
}

.mat-mdc-select-panel.middle-size {
  max-height: 80vh;
  max-width: 290px;
  padding: 10px 0;
  overflow-x: hidden;

  .mat-mdc-option {
    max-width: 290px;
    width: 100%;
    height: 32px;
    min-height: 32px !important;
    padding: 0 8px;
    overflow: hidden;
    font-size: 12px;
    line-height: 24px;

    //max-width: 290px;
    //width: 100%;
    ////height: 32px;
    //min-height: 32px !important;
    //padding: 2px 8px;
    //overflow: hidden;
    //font-size: 12px;
    //line-height: 16px;
  }

  .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
    min-width: fit-content;
  }
}

.mat-mdc-select-panel.fixed-width-290-for-mobile {
  @media screen and (max-width: $mobile-min-575px-width-trigger) {
    width: 290px;
  }
}

.mat-mdc-select-panel.max-height-170 {
  max-height: 170px !important;
}

.mat-mdc-option:hover:not(.mat-mdc-option-disabled) {
  background-color: var(--select-hover-color);
}

.mat-active:hover {
  background-color: var(--selected-bg-color) !important;
}

.form-group {
  .mat-mdc-select {
    height: 32px;
    width: 100%;

    &.custom-size {
      height: 100%;
      width: 100%;
    }

    .mat-mdc-select-value-text {
      color: var(--main-font-color);

      span,
      .mat-mdc-select-min-line {
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 20px;
        color: var(--font-dark-color);
      }
    }

    .mat-mdc-select-trigger {
      padding: 2px 5px;
      display: inline-block;
      width: 100%;

      .mat-mdc-select-value {
        width: 100%;
        max-width: 100%;
        display: inline-block;
        color: var(--main-font-color);
        margin-right: 12px;
      }
    }

    &.custom-arrow {
      .mat-mdc-select-trigger {
        display: flex;
        align-items: center;

        .mat-mdc-select-value {
          margin-right: 6px;
        }
      }

      .mat-mdc-select-arrow-wrapper {
        display: inline-flex;

        .mat-mdc-select-arrow {
          color: var(--custom-action-btn-font-color);
          margin-right: 12px;

          @media screen and (max-width: $mobile-min-425px-width-trigger) {
            margin-right: 6px;
          }
        }
      }
    }
  }

  .mat-mdc-select-arrow-wrapper {
    display: none;
  }

  .mat-form-field-appearance-fill {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-radius: 4px;
        padding: 0;
        background-color: var(--trading-log-table-bg);
        font-weight: 400;
        font-size: 12px;

        .mat-icon:not(.hint_filled_icon) {
          svg {
            path {
              stroke: var(--main-font-color);
              fill: transparent;
            }
          }
        }
      }
    }
  }

  .mat-form-field-flex {
    width: fit-content;
    min-width: 140px;
    max-width: 218px;
  }

  .mat-form-field-infix {
    width: fit-content;
  }

  &.contain {
    width: 100%;

    .mat-form-field-flex {
      width: 100%;
      min-width: unset;
      max-width: unset;

      .mat-form-field-infix {
        width: 100%;
      }
    }
  }
}

// for input (select) inside hint of another form-field
.inner-hint-form {
  display: flex;
  flex-direction: row;
  gap: 2px;

  .top-label.label-icon-container {
    flex: 0 0 auto;
  }

  .form-group .mat-form-field {
    flex: 1 1 auto;
    min-width: unset;
  }

  .mat-form-field {
    flex: 1 1 auto !important;
    max-width: min-content;

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        min-width: unset !important;
        max-width: unset !important;
        border: none;

        .mat-select.item-select {
          width: auto;
          height: auto;
          padding: 0 4px;

          .mat-mdc-select-value-text span {
            @include set-up-font-size-line-height-weight(11px, 130%, 400);
          }
        }
      }
    }
  }
}

.dashboard-header .form-group .mat-form-field-flex {
  width: 100%;
  max-width: initial;
}

.primary-active {
  .mat-active {
    color: var(--primary-color) !important;
  }
}

.week-selector {
  .mdc-list-item__primary-text {
    flex: 1;
  }

  .title-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .title-section.week-name {
      flex: 0 1 auto;
      min-width: 28%;
      font-size: 12px;
      line-height: 14px;
    }

    .title-section.week-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;
      flex: 0 0 auto;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      letter-spacing: 0.04em;
    }

    .title-section.week-value {
      flex: 0 1 auto;
      min-width: 41%;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
    }
  }
}

.mat-mdc-select-panel.week-selector {
  @extend .scroll-style;
  padding-block: 0 !important;
  max-height: 336px;
  max-width: 380px;

  .mat-mdc-option {
    min-height: 42px;
  }
}

.wheel-summary-panel {
  .mat-mdc-text-field-wrapper {
    width: 100%;
    padding: 0;

    &:hover {
      .mdc-notched-outline__notch {
        border-top: 1px solid var(--mdc-outlined-text-field-hover-outline-color) !important;
      }
    }
  }

  .mat-mdc-form-field-flex {
    padding: 0 8px;
    display: flex;

    .wheel-summary-panel-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      color: var(--font-dark-color);
      font-size: 13px;
      line-height: 20px;
      position: relative;
    }

    .mat-mdc-form-field-infix {
      padding: 0;
      width: auto;
      min-width: 180px;
      border: none;

      .mat-mdc-select {
        height: 36px;
        display: flex;
        align-items: center;

        .mat-mdc-select-trigger {
          .mat-mdc-select-value {
            overflow: visible;
          }

          .mat-mdc-select-arrow-wrapper {
            display: none;
          }
        }

        .mat-mdc-form-field-label-wrapper {
          padding: 0;
          top: 0;
        }
      }

      .mat-mdc-form-field-label {
        width: calc(100% - 6px);
        overflow: visible;
        color: var(--trading-log-group-summary-info-header-font-color);
      }
    }
  }

  .mdc-notched-outline__notch {
    width: auto !important;
    max-width: initial !important;
    flex-shrink: 0 !important;
    border-top: 1px solid var(--mdc-outlined-text-field-outline-color) !important;

    .mdc-floating-label {
      transform: none !important;
      max-width: initial !important;
      top: 6px !important;
      overflow: visible !important;
    }
  }

  .mdc-notched-outline--notched .mdc-notched-outline__notch,
  .mdc-notched-outline__notch:focus,
  .mdc-notched-outline__notch:active {
    border-top: 1px solid var(--mdc-outlined-text-field-focus-outline-color) !important;
  }

  .mdc-notched-outline--notched .mdc-notched-outline__notch {
    display: none !important;
  }
}

.wheel-summary-panel-overlay {
  min-width: 430px !important;

  @media screen and (max-width: $large-tablet-min-width-trigger) {
    min-width: 340px !important;
  }

  @media screen and (max-width: $mobile-min-575px-width-trigger) {
    min-width: fit-content !important;
  }

  @media screen and (max-width: $mobile-min-375px-width-trigger) {
    margin-left: 8px;
    min-width: 300px !important;
  }

  &.mat-mdc-select-panel {
    translate: 0 -38px;

    @media screen and (min-width: $tablet-min-width-trigger) {
      max-height: 596px !important;
    }

    @media screen and (max-width: $tablet-max-width-trigger) {
      max-height: 484px !important;
    }

    @media screen and (max-width: $mobile-max-width-trigger) {
      max-height: 400px !important;
      margin: 0 8px;
    }
  }

  .mat-mdc-option {
    height: fit-content !important;

    &.Active,
    &.Archived {
      border-top: 2px solid var(--table-border-color);

      & ~ .mat-mdc-option:not(.Active, .Archived) {
        .option-container {
          margin-top: 0;
          border-top: 1px solid var(--wheel-trading-log-summary-panel-option-border-color);
        }
      }
    }

    .option-container {
      height: fit-content;
      padding: 9px 0;

      .info {
        .info-row {
          height: fit-content;
          overflow: visible;
          gap: 8px;
          margin-top: 0;

          .info-column {
            min-width: 80px;

            &.shares {
              flex-basis: auto !important;
            }
          }

          .type {
            display: flex !important;
            width: 100% !important;
            flex-basis: 100%;

            @media screen and (min-width: $tablet-992px-width-trigger) {
              flex-basis: unset;
            }
          }

          .account {
            gap: 4px;
            flex-basis: 73%;
            max-width: 73%;

            @media screen and (max-width: $mobile-max-width-trigger) {
              display: flex !important;
              flex-basis: 65%;
            }

            .info-header {
              max-width: 100% !important;
              font-weight: 700 !important;

              @media screen and (max-width: $large-tablet-min-width-trigger) {
                max-width: 70% !important;
              }

              @media screen and (max-width: $tablet-992px-width-trigger) {
                max-width: 62% !important;
              }

              @media screen and (max-width: $tablet-min-width-trigger) {
                max-width: 77% !important;
              }

              @media screen and (max-width: $mobile-min-575px-width-trigger) {
                max-width: 110% !important;
              }

              @media screen and (max-width: $mobile-min-375px-width-trigger) {
                max-width: 83% !important;
              }
            }
          }

          .date {
            min-height: 32px;
          }

          .checkbox {
            display: block !important;
            flex-basis: 100%;

            mat-checkbox {
              height: 15px;
              top: 0;
              position: absolute;

              .mat-checkbox-layout {
                position: absolute;
                top: 0;

                .mat-checkbox-ripple {
                  height: 20px;
                  width: 20px;
                  left: calc(50% - 10px);
                  top: calc(50% - 10px);
                }
              }
            }
          }
        }
      }
    }

    &.mat-active {
      background: none;
    }
  }
}

.trading-panel-container {
  .mat-mdc-select-value,
  .mat-mdc-select-value-text span {
    color: var(--trading-panel-content-color);
  }
}

.oco-order-content {
  .mat-mdc-select {
    .mat-mdc-select-value {
      padding-left: 0;

      .mat-mdc-select-value-text {
        color: var(--trading-panel-content-color);

        .mat-mdc-select-min-line {
          color: var(--trading-panel-content-color);
        }
      }
    }
  }

  .mat-mdc-select-value-text span {
    font-size: 14px;
    color: var(--trading-panel-content-color);
  }

  .mat-select.mat-select-disabled {
    background-color: var(--oco-order-modal-price-ticker-btn-bg-color);
    border-radius: 4px;
    border-color: transparent;

    .mat-mdc-select-trigger {
      user-select: none;
      cursor: default;

      .mat-mdc-select-value-text span {
        color: var(--oco-order-modal-option-details-locked-input-text-color);
        -webkit-text-fill-color: var(--oco-order-modal-option-details-locked-input-text-color);
        opacity: 1;
      }
    }
  }
}

.wheel-filter {
  .filters .input-item .item-select {
    border: none !important;
    background-color: transparent !important;
  }

  .filters-group {
    .form-group.select-form {
      display: flex;
      align-items: center;

      .top-label {
        margin-bottom: 0;
        align-items: flex-start;
        white-space: nowrap;
      }
    }

    .form-group.form-label .mat-mdc-form-field-infix {
      display: flex;
      align-items: center;
      background-color: transparent !important;
    }

    .form-group .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
      background-color: transparent !important;
    }

    .mat-mdc-select.item-select {
      background-image: none !important;
    }
  }
}

.button-toggle-control,
.portfolio-control {
  .from-group .mat-mdc-select .mat-mdc-select-value {
    padding: 0 0 0 12px;
  }

  @media screen and (max-width: $mobile-min-500px-width-trigger) {
    .from-group.no-border .mat-mdc-select .mat-mdc-select-value {
      padding: 0;
      width: auto;
    }
  }
}

.tradier-activity {
  .form-group .mat-form-field-flex {
    width: 100%;
    max-width: initial;
  }

  .mat-form-field-appearance-fill {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        background-color: inherit;
      }
    }
  }

  .from-group {
    margin: 0;
  }
}

.importing-rules-select {
  .multiSelect {
    .mat-mdc-select-value {
      color: inherit;

      .mat-mdc-select-value-text {
        font-size: 12px;
      }
    }
  }
}

.mat-mdc-select-panel.tradier-import-panel-overlay {
  padding-block: 5px;
  width: 400px;
  max-width: 400px;
  max-height: initial;
  border-radius: 5px;
  box-shadow: 0px 20px 60px 0px rgba(1, 41, 84, 0.15);

  .mat-divider {
    margin-block: 0;
    border-color: var(--divider-color);
  }

  .mat-mdc-option {
    height: initial;

    .mat-radio-button {
      display: block;

      .option-title {
        @include font-bold(14px, 20px, -0.14px, inherit);
      }

      .option-text {
        @include font-regular(14px, 150%, 0.1px, inherit);
      }
    }

    .mat-radio-label {
      align-items: flex-start;
      white-space: initial;

      .mat-radio-container {
        margin-top: 14px;
      }

      .mat-radio-label-content {
        padding-block: 12px 8px;
      }
    }
  }

  .mat-mdc-select-arrow-wrapper {
    margin-left: auto;
  }

  .nested-options {
    .nested-option {
      padding-left: 45px;

      .mat-mdc-option-text {
        overflow: visible;
      }
    }

    .mat-divider {
      margin-left: 45px;
    }
  }
}

.importing-rule .multiSelect .mat-mdc-select-trigger {
  height: auto !important;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  white-space: normal;
  text-align: left;

  .selected-import-group {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;

    .group-symbol {
      border-radius: 2px;
      background-color: var(--tradier-import-selected-import-group-symbol-bg-color);
    }

    .group-button {
      display: none;
    }
  }
}

.custom-date-range-modal.modals {
  .datepicker-form-field {
    .mat-form-field-flex {
      background-color: transparent;
    }
  }
}

.tradier-imports {
  .mat-mdc-select-arrow-wrapper {
    margin-left: auto;
  }
}

.trades-group-modal.modals {
  .form-group .mat-form-field-flex {
    width: 100%;
    max-width: initial;
  }
}

.mat-mdc-select-panel.select-overlay,
.mat-mdc-select-panel.multiselect-overlay {
  --multiselect-scrollable-container-height: 385px;
  --multiselect-actions-container-height: 45px;
  padding-block: 2px 8px !important;
  width: min(100%, 260px);
  max-height: calc(var(--multiselect-scrollable-container-height) + var(--multiselect-actions-container-height));

  .scrollable-options {
    max-height: calc(var(--multiselect-scrollable-container-height) - var(--multiselect-actions-container-height));
    min-height: initial;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 298px;

    .mat-mdc-option {
      min-width: 264px;
      cursor: pointer;

      .mat-pseudo-checkbox {
        display: inline-block;

        &.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
          width: 8px;
          height: 3px;
          bottom: 4px;
        }
      }

      .mdc-list-item__primary-text {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &::before {
          content: '';
          display: block;
        }
      }
    }
  }
}

.mat-mdc-select-panel.select-overlay,
.mat-mdc-select-panel.multiselect-overlay,
.mat-mdc-selection-list {
  .select-btn-wrapper,
  .multi-select-btn-wrapper {
    margin-top: 2px;
    padding-top: 8px;
    padding-inline: 16px;
    width: 100%;
    max-height: var(--multiselect-actions-container-height);
    overflow: visible;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-top: 1px solid var(--divider-color);
  }

  &.select-account-editing {
    padding-block: 8px !important;
    translate: 0 -32px;

    &.select-overlay {
      margin-right: 10px;
      width: 100%;
      max-width: 300px;
      max-height: 432px;
      overflow-x: auto;

      .scrollable-options {
        position: relative;
        min-height: fit-content;
        max-height: 315px;
        min-width: 330px;
        overflow: auto;

        .mdc-list-item__primary-text {
          width: 100%;
        }

        &.editing-option {
          height: fit-content;
          max-height: 367px;

          .input-option {
            padding: 10px;
          }
        }

        .mat-mdc-option {
          width: 100%;
        }
      }

      .mat-mdc-option:hover:not(.mat-mdc-option-disabled) {
        background-color: var(--select-hover-color);
      }
    }

    .input-option {
      background: none !important;
    }

    .option-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &.editing-option-content {
        display: block;
      }

      &:hover {
        background: transparent;
      }

      .option-title {
        color: var(--trading-log-table-content-font-color);
        top: 2px;
        position: relative;
        background: transparent;
        display: inline-block;
        overflow: hidden;
        width: 100%;
        max-width: 300px;
        text-overflow: ellipsis;
      }

      button {
        border: none;
        background: none;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        cursor: pointer;
        z-index: 10;

        mat-icon {
          width: 20px;
          height: 20px;
          margin: 0;
        }
      }
    }

    .item-search-action {
      height: fit-content !important;
      margin: 10px 0 10px 0;
    }

    .input-option {
      opacity: 0;
    }

    &.select-overlay.edit-mode {
      .input-option {
        position: relative;
        bottom: unset;
        opacity: 1;
        transition: opacity 0.4s;
      }
    }

    &.select-overlay:not(.edit-mode) .input-option {
      height: fit-content !important;
      width: 100%;
      opacity: 1;
      transition: opacity 0.4s;
    }

    .item-searcher-wrapper {
      height: 32px;

      .input-searcher-wrap {
        display: flex;
        align-items: center;
        margin: 0 0 20px 0;

        .new-input-style {
          height: 32px;
          display: flex;
          flex-grow: 1;

          .account-edit-input {
            & ~ span label {
              color: var(--table-cell-placeholder);
            }

            &::placeholder {
              text-transform: initial;
            }
          }

          .account-number {
            padding-left: 5px;
            display: inline-block;
            height: 32px;
            line-height: 32px;
            color: var(--main-font-color);
          }
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 5px;
          width: 32px;
          height: 32px;
          margin-left: 5px;
          box-shadow: none;
          border: none;

          &.cancel {
            background: var(--btn-danger-icon-color);

            &:hover {
              background: var(--btn-danger-icon-hover-color);
              transition: 0.3s;
            }

            mat-icon {
              transform: rotate(45deg);
            }

            path {
              stroke: var(--btn-danger-icon-font-color);
            }
          }

          &.add {
            background: var(--btn-success-icon-color);

            &:hover {
              background: var(--btn-success-icon-hover-color);
              transition: 0.3s;
            }
          }

          mat-icon {
            margin: 0;
          }

          .mat-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        :host .from-group {
          .input.mat-input-element {
            height: 100%;
            color: var(--trading-log-table-content-font-color);
          }

          label {
            color: var(--table-cell-placeholder);
          }
        }
      }
    }
  }
}

.mat-mdc-select-panel.filter-select-panel {
  max-width: 150px;
  min-width: 150px !important;

  .mat-mdc-option.filters-option {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mdc-list-item__primary-text {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 7px;
      font-size: 14px;
      line-height: 36px;
      letter-spacing: normal;
      color: var(--font-dark-color);
    }

    .mat-pseudo-checkbox {
      display: block;
      height: 18px !important;
      width: 18px !important;
      order: 1;
      margin-inline: 10px 0;
      border-radius: 4px;

      &.mat-pseudo-checkbox-checked::after {
        width: 11px;
        height: 5px;
        bottom: 6px;
      }
    }
  }
}

.mat-mdc-form-field.navigation-form-field {
  .mat-mdc-text-field-wrapper {
    padding-inline: 0;
    min-height: 32px;
    background-color: var(--white-bg);

    .mat-mdc-form-field-infix {
      padding-block: 3px;
      min-height: 32px;
    }

    .mat-mdc-select-min-line {
      font-size: 12px;
    }
  }
}

.ac-correlation-length .ac-trading-days .mat-mdc-select.select-style .mat-mdc-select-value {
  padding-left: 0;
}

// added for combined-calendars
.mat-mdc-select-panel {
  padding-block: 12px !important;
  @extend .scroll-style;

  .custom-template-option {
    &.mat-mdc-option {
      padding: 12px;
      height: auto;
      line-height: initial;
    }

    &:has(.title-wrapper) {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 12px;
        right: 12px;
        bottom: 0;
        border-bottom: 1px solid var(--divider-color);
      }
    }

    &:last-child {
      &:after {
        border-bottom: 0;
      }
    }
  }
}

.mat-mdc-option.country-option {
  .mdc-list-item__primary-text {
    white-space: nowrap;
    max-width: 350px;
    text-overflow: ellipsis;
  }
}
