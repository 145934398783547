@import '../values';
@import '../mixins/fonts';
@import '../components/scroll';

.mat-mdc-tab-group {
  --mat-tab-header-active-ripple-color: transparent;
  --mat-tab-header-inactive-ripple-color: transparent;
}

.mat-mdc-tab {
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.1px;
  --mat-tab-header-label-text-line-height: 48px;
  position: relative;
}

.mat-mdc-tab:hover .mdc-tab__text-label,
.mat-mdc-tab .mdc-tab-indicator__content--underline,
.mat-mdc-tab:hover .mdc-tab-indicator__content--underline {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-color: var(--primary-color);
  --mat-tab-header-active-hover-indicator-color: var(--primary-color);
  --mat-tab-header-active-focus-indicator-color: var(--primary-color);
  --mat-tab-header-inactive-hover-label-text-color: var(--font-dark-color);
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label {
  --mat-tab-header-active-label-text-color: var(--primary-color);
  --mat-tab-header-inactive-hover-label-text-color: var(--primary-color);
  --mat-tab-header-active-focus-label-text-color: var(--primary-color);
}

.mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab:focus .mdc-tab__text-label {
  --mat-tab-header-inactive-focus-label-text-color: var(--font-dark-color);
  --mat-tab-header-inactive-label-text-color: var(--font-dark-color);
}

.mat-mdc-tab.mat-mdc-tab-disabled,
.mat-mdc-tab.mat-mdc-tab-disabled:hover {
  .mdc-tab__ripple::before,
  .mat-ripple-element {
    opacity: 0;
  }
}

.oco-order-tabs-wrapper {
  .mat-mdc-tab-body-content {
    padding: 10px;
    overflow: hidden;
  }
}

.mat-mdc-tab-group.pxo-tabs {
  overflow: visible;

  &.secondary-tabs {
    mat-mdc-tab-header {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          .mdc-tab.mat-mdc-tab {
            .mdc-tab__content {
              color: var(--trading-management-tabs-color);
            }
          }
        }
      }
    }
  }

  &.secondary-tabs.trading-panel-tabs {
    .mat-mdc-tab-header {
      &.mat-mdc-tab-header-pagination-controls-enabled {
        gap: 5px;
      }

      .mat-mdc-tab-header-pagination {
        align-self: center;
      }

      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          flex-wrap: nowrap;

          .mdc-tab.mat-mdc-tab {
            min-width: min-content;

            &.mdc-tab-indicator--active {
              .mdc-tab__content {
                color: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }

  .mat-mdc-tab-header {
    border: none !important;
    width: 100%;

    .mat-mdc-tab-list {
      width: 100%;

      .mat-mdc-tab-labels {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .mdc-tab.mat-mdc-tab {
          height: 48px;
          min-height: auto;
          min-width: 50px;
          display: flex;
          justify-content: flex-start;
          padding: 0 10px;
          overflow: hidden;
          opacity: 1;
          color: var(--trading-management-tabs-color);
          @include apply-tab-label-font();

          &.mdc-tab--active {
            position: relative;
            color: var(--trading-management-active-tabs-color);
          }

          .mdc-tab__content {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            text-align: center;
            letter-spacing: 0.1px;
            color: var(--trading-management-tabs-color);
          }
        }
      }
    }
  }

  .mat-mdc-tab-disabled {
    flex-grow: 1;
  }

  .mat-mdc-tab-body-content {
    overflow: visible;
  }

  .mat-mdc-tab-body {
    overflow: visible;
  }

  &.s-tabs {
    .mat-mdc-tab-header {
      border-bottom: 1px solid var(--tabs-border-color) !important;
      padding: 0 20px;

      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          .mdc-tab.mat-mdc-tab {
            @include apply-tab-label-font();

            &::after {
              content: none;
            }

            &.mdc-tab--active {
              &::after {
                content: '';
              }
            }
          }
        }
      }
    }

    .mat-mdc-tab-body-wrapper {
      flex-grow: 1;

      .mat-mdc-tab-body {
        width: 100%;
        overflow: hidden;

        .mat-mdc-tab-body-content {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &.default-counter .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab-labels {
    .mdc-tab.mat-mdc-tab {
      .mdc-tab__content .mdc-tab__text-label {
        display: flex;
        gap: 6px;
        color: var(--main-font-color);
        @include apply-s-tab-label-font();

        .count-wrapper {
          background: var(--tab-counter-background-color);
          border-radius: 4px;
          padding: 0 6px;
        }
      }

      &.mdc-tab--active {
        .mdc-tab__content .mdc-tab__text-label {
          color: var(--active-link);

          .count-wrapper {
            background: var(--tab-active-counter-background-color);
          }
        }
      }
    }
  }

  &.extra-small-tabs {
    .mat-mdc-tab-body {
      overflow: hidden;
    }

    .mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab.mat-mdc-tab {
      height: 38px;
      padding: 9px 10px;

      .mdc-tab__content {
        @include extra-small-tab-lable();
        color: var(--main-font-color);
      }

      &:after {
        height: 1px;
      }

      &.mat-mdc-tab-disabled::after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--trading-management-tabs-border-color);
        bottom: 0;
        left: 0;
      }
    }
  }
}

.dashboard-content
  mat-mdc-tab-group.pxo-tabs
  mat-mdc-tab-header
  .mat-mdc-tab-list
  .mat-mdc-tab-labels
  .mat-mdc-tab-label,
.dashboard-content
  mat-mdc-tab-group.pxo-tabs
  mat-mdc-tab-header
  .mat-mdc-tab-list
  .mat-mdc-tab-labels
  .cdk-drag-preview.mdc-tab--active {
  @media screen and (max-width: $mobile-min-375px-width-trigger) {
    padding: 0 9px;
  }
}

.navbar {
  .mat-button-toggle-group {
    border: 0;
    border-radius: 0;
    margin-top: 5px;

    .mat-button-toggle {
      border: 0 !important;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;

      @media screen and (max-width: $mobile-min-500px-width-trigger) {
        flex: 1;
        display: flex;
        justify-content: center;
      }

      &.active {
        background: var(--primary-color);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    .mat-button-toggle-button {
      padding: 3.5px 0px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      text-align: center;

      @media screen and (min-width: $tablet-991px-width-trigger) {
        min-width: 78px;
      }

      .s1,
      .cap {
        color: var(--nav-tab-font-color);
      }

      .cap {
        display: block;
        margin-top: -4px;
      }
    }

    .wtf-tab-btn {
      .mat-button-toggle-button {
        padding: 3.5px 2px;
      }
    }

    .mat-button-toggle-checked:not(.locked-for-current-edition) .mat-button-toggle-button .s1,
    .active .s1 {
      color: var(--white-color);
    }

    .mat-button-toggle-checked:not(.locked-for-current-edition) .mat-button-toggle-button .cap,
    .active .cap {
      color: var(--white-color);
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 0px;
    padding: 0 12px !important;

    @media screen and (max-width: $mobile-min-500px-width-trigger) {
      padding: 0 5px !important;
    }
  }

  .mat-button-toggle-appearance-standard {
    background: transparent;
  }
}

.pxo-tab-group-default {
  .mdc-tab.mat-mdc-tab {
    min-width: 100px !important;
    color: var(--tab-font-color);
    opacity: 1;

    &.mdc-tab--active {
      color: var(--active-tab-font-color);
    }
  }

  .mat-mdc-tab-group.mat-primary .mat-ink-bar {
    display: block;
  }

  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid var(--trading-management-tabs-border-color);
    }
  }
}

.trading-plan-tabs {
  padding: 10px 12px 0;
  background: transparent;

  .mat-mdc-tab-header {
    padding: 0 !important;
    width: 90%;
    align-items: center;
  }

  .plan-tab-title {
    color: var(--main-font-color);
  }

  .mat-mdc-tab-labels {
    flex-wrap: nowrap !important;

    .mdc-tab.mat-mdc-tab {
      height: 42px;
      min-width: -webkit-fit-content !important;
      min-width: -moz-fit-content !important;
      min-width: fit-content !important;
      border-width: 1px 1px 0px 1px;
      border-style: solid;
      border-color: transparent;
      border-radius: 4px 4px 0px 0px;

      .mdc-tab__content {
        gap: 2px;
      }

      .mat-mdc-icon-button {
        visibility: hidden;
      }

      &.mdc-tab--active {
        border-color: var(--table-border-color);

        .plan-tab-title {
          color: var(--trading-management-active-tabs-color);
        }

        .mdc-tab-indicator {
          display: none;
        }

        &:after {
          content: none !important;
        }

        .mat-mdc-icon-button {
          visibility: visible;
          display: block;
        }
      }
    }
  }
}

// for draggable tabs
.cdk-drag-preview.mat-mdc-tab-label,
.cdk-drag-preview.mdc-tab--active {
  @extend .mat-mdc-tab-label;
  opacity: 0;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating.mdc-tab.mat-mdc-tab {
  transition: transform 50ms cubic-bezier(0, 0, 0.2, 1);
}

.trading-plan-tabs.cdk-drop-list-dragging {
  cursor: move !important;
}

.mat-mdc-tab-label-container .cdk-drop-list-dragging .mat-mdc-tab-label:not(.cdk-drag-placeholder) {
  transition: transform 50ms cubic-bezier(0, 0, 0.2, 1);
}

.trading-plan-tabs-content {
  .mat-mdc-tab {
    height: 38px;
    min-height: 38px;
  }

  .mat-mdc-tab-label-container {
    border-bottom: 1px solid var(--table-border-color);
  }

  .mat-mdc-tab-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--white-bg);

    .mat-mdc-tab-list {
      .mat-mdc-tab-labels {
        .mdc-tab.mat-mdc-tab {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.trading-hub-component {
  .tabs-wrapper {
    .mdc-tab.mat-mdc-tab.magic-ball-tab-label {
      margin-left: auto;
    }

    .mdc-tab.mat-mdc-tab {
      overflow: visible !important;
    }

    &.trading-hub-tabs {
      .mat-mdc-tab .mdc-tab__ripple::before,
      .mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before,
      .mat-mdc-tab.mdc-tab--active .mat-ripple-element {
        --mat-tab-header-inactive-ripple-color: transparent;
        --mat-tab-header-active-ripple-color: transparent;
      }

      @media screen and (max-width: $mobile-min-425px-width-trigger) {
        .mat-mdc-tab-header {
          padding-inline: 6px;

          .mat-mdc-tab-labels {
            flex-wrap: nowrap;
          }
        }
      }
    }

    .mdc-tab--active {
      .magic-ball-inner-text {
        color: currentColor !important;
        background: none !important;
        -webkit-background-clip: unset !important;
        -webkit-text-fill-color: unset !important;
        -moz-background-clip: unset !important;
        -moz-text-fill-color: unset !important;
      }
    }
  }
}

.oco-order-tabs-wrapper {
  .mat-mdc-tab-header {
    margin-bottom: 16px;
    border-bottom: 1px solid var(--divider-color);
  }

  .mdc-tab.mat-mdc-tab {
    padding: 0 10px;
    min-width: 113px;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.1px;
    color: var(--trading-management-tabs-color);
    opacity: 1;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 2px solid transparent;
    }

    &.mdc-tab--active {
      color: var(--trading-management-active-tabs-color);

      &::after {
        border-color: var(--trading-management-active-tabs-color);
      }
    }
  }

  .mat-mdc-tab-body-content {
    .from-group {
      margin-bottom: 10px;

      .mat-form-field {
        margin-top: 0;
        color: var(--main-font-color);

        input.mat-input-element::placeholder {
          color: var(--input-color);
        }

        input.mat-input-element:disabled {
          color: var(--oco-order-modal-option-details-locked-input-text-color);
          -webkit-text-fill-color: var(--oco-order-modal-option-details-locked-input-text-color);
          opacity: 1;
        }
      }

      .mat-form-field-infix {
        width: 100%;
      }
    }
  }
}

.mat-mdc-tab-group.ws-tabbing,
.mat-mdc-tab-group.scanner-watchlist-tab-group {
  .mat-mdc-tab-header {
    --mdc-secondary-navigation-tab-container-height: 32px;
    --mat-tab-header-label-text-tracking: 0;
    --mat-tab-header-label-text-line-height: 32px;
    --mat-tab-header-label-text-weight: 400;

    .mat-mdc-tab-list {
      display: flex;

      .mat-mdc-tab-labels {
        border-bottom: 1px solid var(--wheel-table-border-color);
      }
    }

    .mdc-tab.mat-mdc-tab {
      padding: 0 10px 0 16px;
      min-width: 113px;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.1px;
      color: var(--font-dark-color);
      border: 1px solid transparent;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      position: relative;

      &.mdc-tab--active {
        border-color: var(--wheel-table-border-color);
        border-bottom: 1px solid var(--white-bg);

        .mdc-tab-indicator {
          top: 2px;
        }
      }

      .mat-ripple.mat-mdc-tab-ripple {
        display: none;
      }

      .mdc-tab__ripple::before,
      .mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before,
      .mat-mdc-tab.mdc-tab--active .mat-ripple-element {
        --mat-tab-header-inactive-ripple-color: transparent;
        --mat-tab-header-active-ripple-color: transparent;
      }

      &.mat-mdc-tab:hover .mdc-tab__text-label,
      &.mat-mdc-tab .mdc-tab-indicator__content--underline,
      &.mat-mdc-tab:hover .mdc-tab-indicator__content--underline {
        --mdc-tab-indicator-active-indicator-color: var(--white-bg);
        --mat-tab-header-active-hover-indicator-color: var(--white-bg);
        --mat-tab-header-active-focus-indicator-color: var(--white-bg);
      }

      .mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base img {
        --mdc-icon-button-icon-size: 16px;
      }
    }
  }

  .mat-mdc-tab-body-content {
    @extend .scroll-style;
  }

  .mat-mdc-tab-header-pagination-before {
    margin: 4px 2px 0 0;
  }

  .mat-mdc-tab-header-pagination-after {
    margin: 4px 2px 0 2px;
  }

  &.wtf-scanner-tabs {
    .mdc-tab.mat-mdc-tab {
      padding-left: 5px !important;
      padding-right: 6px !important;
      min-width: 60px !important;
      flex-shrink: 0;
    }
  }

  &.symbol-screeners-scanner-tabs {
    .mat-tab-label {
      padding-left: 5px !important;
      padding-right: 6px !important;
      min-width: 60px !important;
      flex-shrink: 0;
    }
  }

  @media (max-width: $mobile-min-575px-width-trigger) {
    &.symbol-screeners-scanner-tabs {
      .mat-tab-label {
        width: auto;
      }
    }
  }
}

.trading-plan-tabs-content {
  .table-container {
    max-height: calc(100dvh - 250px);
    overflow-y: auto !important;
    @extend .scroll-style;

    @media screen and (max-width: $tablet-992px-width-trigger) {
      max-height: calc(100dvh - 225px);
    }

    @media screen and (max-width: $tablet-min-width-trigger) {
      max-height: calc(100dvh - 200px);
    }

    @media screen and (max-width: $tablet-992px-width-trigger) and (orientation: landscape) {
      max-height: 100%;
    }
  }

  .trading-panel-order-wrapper {
    .oco-order-modal-heading {
      cursor: default;
    }

    &.pinned-panel::after {
      right: initial;
      left: 0;
      border: none;
      border-left: 1px solid var(--table-border-color);
    }

    .mat-ink-bar {
      display: block;
    }
  }
}

.earnings {
  .pxo-tab-group .mat-mdc-tab-header {
    margin-bottom: 15px;
  }

  .pxo-tab-group .mat-mdc-tab-body-wrapper {
    min-height: 60dvh;
  }
}

.income-statement-tabs-container {
  .mat-mdc-tab-header .mat-mdc-tab {
    --mdc-secondary-navigation-tab-container-height: 30px;
  }
}

app-scanner-watchlist-panel {
  .scanner-watchlist-tab-group {
    height: 100%;
    overflow: hidden;
  }

  .mat-mdc-tab-body-wrapper {
    height: calc(100% - 31px);
  }
}

.spreadsheet-container {
  .mdc-tab.mat-mdc-tab {
    height: 38px !important;
    padding-block: 8px;
    padding-inline: 12px;

    .mdc-tab__content {
      height: initial !important;
    }
  }
}

.oco-order-content {
  .mat-mdc-tab .mdc-tab__text-label,
  .mat-mdc-tab:hover .mdc-tab__text-label,
  .mat-mdc-tab:focus .mdc-tab__text-label {
    --mat-tab-header-inactive-focus-label-text-color: var(--main-font-color);
    --mat-tab-header-inactive-hover-label-text-color: var(--main-font-color);
    --mat-tab-header-inactive-label-text-color: var(--main-font-color);
  }
}

.trading-log-container {
  .mat-mdc-tab .mdc-tab__text-label:not(.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label) {
    color: var(--trading-management-tabs-color);
  }
}

.app-trading-plan-container {
  .mat-mdc-tab-header {
    width: 92% !important;
    @media screen and (max-width: $mobile-max-width-trigger) {
      width: 90% !important;
    }
  }
}

.sub-menu-container {
  .mdc-tab {
    min-width: fit-content;
    padding-left: 0;
    padding-right: 0;
  }

  .mat-mdc-tab-link-container {
    width: 100%;
    overflow-x: auto;
    @extend .scroll-style;
    @extend .show-scroll-on-hover;

    .mat-mdc-tab-list {
      transform: none !important;
    }
  }

  .mat-mdc-tab-links {
    gap: 28px;
  }
}
