@import '../../scss/mixins/fonts';

.pxo-app {
  .custom-large-tooltip {
    margin-right: 10px;
  }

  .error-message-tooltip {
    line-height: 16px !important;
    background-color: var(--red-point) !important;
    white-space: pre-line !important;
    font-size: 12px !important;
    font-family: Trebuchet MS, roboto, ubuntu, sans-serif !important;
    text-align: left !important;
    border-radius: 3px !important;
    padding: 8.5px !important;
    pointer-events: all !important;
    font-style: normal;
    font-weight: 400;

    &.tooltip-top::after {
      border-color: var(--red-point) transparent transparent transparent !important;
    }

    &.tooltip-bottom::after {
      border-color: transparent transparent var(--red-point) transparent !important;
    }

    &.tooltip-left::after {
      border-color: transparent transparent transparent var(--red-point) !important;
    }

    &.tooltip-right::after {
      border-color: transparent var(--red-point) transparent transparent !important;
    }

  }

  .tooltip.tooltip-show {
    word-break: break-word;

    a, b {
      word-break: break-all;
    }
  }

  .roi-legend {
    margin-top: 8px;

    .roi-legend-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .light-green-color,
    .light-pink-color,
    .light-yellow-color {
      margin-right: 4px;
      border-radius: 100%;
      height: 8px;
      width: 8px;
    }

    .light-green-color {
      background: var(--legend-marker-green-color);
    }

    .light-pink-color {
      background: var(--legend-marker-red-color);
    }

    .light-yellow-color {
      background: var(--legend-marker-orange-color);
    }

    .roi-legend-item span {
      color: var(--white-color);
      @include apply-roi-legend-tooltips-item-font();
      display: block;
      white-space: nowrap;
    }

    .roi-legend-item > span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .tooltip.hidden {
    display: none;
  }
}
