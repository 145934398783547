.mat-mdc-tooltip {

  max-width: var(--mdc-tooltip-max-width, 410px);
  white-space: pre-line;

  .mdc-tooltip__surface {
    max-width: unset;
    padding: 10px;
  }
}

// fix for elements with mat-tooltip - allow scrolling for touchable devices
.mat-mdc-tooltip-trigger,
.mat-tooltip-trigger {
  touch-action: auto !important;
}
