@import '../../scss/mixins/fonts';

.feedback-content,
.chat-controls {
  .mat-mdc-form-field {
    .mat-mdc-form-field-subscript-wrapper,
    .mat-mdc-form-field-bottom-align::before {
      display: none;
    }

    .mat-mdc-form-field-subscript-wrapper {
      padding-inline: 0;
    }

    .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }

    .mat-mdc-form-field-infix {
      padding: 0;
      min-height: auto;
    }
  }

  textarea.pxo-style {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
    box-sizing: border-box;
    resize: none;

    &:active,
    &:focus-visible {
      outline: none;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0;
    display: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding: 0;
    width: 100%;
  }

  .mat-form-field-infix {
    border: none;
  }

  .mat-form-field-label-wrapper {
    left: 0;
  }

  .mat-form-field-label {
    top: 70%;
    transform: translateY(-50%);
    @include apply-chatbot-tab-question-input-label-font();
    color: var(--chat-bot-send-icon-default-color);
  }
}

.feedback-content {
  .mat-form-field-label {
    top: 0;
    transform: none;
    padding-top: 11px;
    @include apply-chatbot-tab-question-input-label-font();
    color: var(--chat-bot-send-icon-default-color);
    font-size: 14px;
    white-space: normal;
  }
}
