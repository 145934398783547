@import '../collors';
@import '../mixins/fonts';

table.mat-mdc-table {
  --mat-table-header-container-height: auto;
  --mat-table-footer-container-height: auto;
  --mat-table-row-item-container-height: auto;
  --mat-table-background-color: var(--white-bg);
}

.table-styles {
  th {
    @include apply-table-th-cell-font();
    text-align: center;
    color: var(--table-header-font-color);
    border-color: var(--table-border-color) !important;
    border-bottom-color: var(--table-border-color) !important;

    &:first-child {
      text-align: left;
    }
  }

  td {
    @include apply-table-td-cell-font();
    color: var(--main-font-color);
    background: var(--table-td-bg);
    border-bottom-color: var(--table-border-color) !important;
  }

  tbody {
    tr:not(.expanded-details-row):hover td {
      background: var(--tr-hover-color);
    }

    tr > td.mat-cell {
      &.positive {
        background-image: linear-gradient(to right, var(--success-bg-color) var(--elementStatistic), transparent var(--elementStatistic));
        background-repeat: no-repeat;
        background-position: center;
        background-size: 95% 16px;
      }

      &.negative {
        background-image: linear-gradient(to right, var(--alert-bg-color) var(--elementStatistic), transparent var(--elementStatistic));
        background-repeat: no-repeat;
        background-position: center;
        background-size: 95% 16px;
      }
    }
  }
}

.table-main-style {
  &.no-shadow {
    box-shadow: none;
  }

  .text-left {
    .th-conteiner {
      justify-content: flex-start;
    }
  }

  .text-right {
    .th-conteiner {
      justify-content: flex-end;
    }

    &.mat-header-cell.mat-sort-header {
      .mat-sort-header-container {
        justify-content: flex-end;
      }
    }
  }

  .text-center {
    .th-conteiner {
      justify-content: center;
    }
  }

  thead tr {
    top: 0;
    left: 0;
    z-index: 4;
  }

  th {
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 5px 10px;
    color: var(--trading-log-table-header-font-color);

    &:first-child {
      text-align: left;
      padding-left: 10px;
    }
  }

  tr {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    &.expanded-row + .expanded-details-row .expanded-details {
      overflow: inherit;
    }

    td {
      color: var(--trading-log-table-content-font-color);
      padding: 5px 10px;
      white-space: pre;
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &.mat-column-expandedDetails {
        padding: 0;

        .expanded-details {
          overflow: hidden;

          table {
            border-spacing: 0;
          }
        }

        th {
          padding-block: 0;

          & > * {
            height: 0;
            display: block;
            overflow: hidden;
          }
        }

        .expanded-tr:not(:first-child) .expanded-td {
          border-top: 1px solid var(--table-border-color);
        }

        .expanded-td.sticky {
          position: sticky;
          left: 0;
          z-index: 1;
        }
      }
    }
  }

  tr th,
  tr td {
    &.text-left {
      text-align: left;
    }

    &.text-center {
      text-align: center;
    }

    &.text-right {
      text-align: right;
    }
  }

  tr th.mat-sort-header:not(:first-child).text-center {
    .mat-sort-header-container {
      padding-left: 18px;
      align-items: flex-start;
      justify-content: center;
    }
  }
}

.table-scroll-view {
  tr th:first-child,
  tr td:first-child:not(.mat-column-expandedDetails) {
    box-shadow: var(--table-sticky-tr-box-shadow);
    border-right: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: var(--table-secondary-border-color);
      width: 1px;
      height: calc(100% + 2px);
      right: -1px;
      top: -1px;
    }
  }

  .expanded-td.sticky {
    box-shadow: var(--table-sticky-tr-box-shadow);
  }
}
