@import '../values';
@import '../mixins/fonts';

.extra-small-badge {
  .mat-badge-content {
    min-width: $extra-small-badge-size;
    height: $extra-small-badge-size;
    top: -7px;
    right: -7px !important;
    @include extra-small-badge-font();
    color: var(--tooltip-font-color);
  }

  &.wheel-summary-panel-badge {
    .mat-badge-content {
      display: inline-block;
      padding: 0 3px;
      width: fit-content;
      text-align: center;
      border-radius: 22px;
      background-color: var(--mat-badge-background-color);
      color: var(--mat-badge-text-color);
      font-weight: var(--mat-badge-text-weight);
      font-size: 9px;
    }
  }
}

.mat-badge-small.mat-badge-overlap .mat-badge-content {
  display: inline-block;
  text-align: center;
  border-radius: 22px;
  background-color: var(--mat-badge-background-color);
  font-weight: 400;
  font-size: 9px;
  line-height: 16px;
  color: var(--tooltip-font-color);
}
