@import '../mixins/fonts';

.full-width {
  min-width: 100%;
}

button {
  cursor: pointer;

  &.hide {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }

  &.extra-small-icon-btn {
    width: 20px;
    height: 20px;
    border-radius: 3px;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:focus-visible {
    outline: none;
  }

  &.content-center {
    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.add-item-button {
    .mat-button-wrapper {
      display: flex;
      align-items: center;
      gap: 3px;
      color: var(--active-link-color);
    }

  }

  &:disabled {
    cursor: var(--disabled-button-icon) 5 5, auto !important;
  }
}

.icon-button:hover {
  background: var(--custom-btn-hover-color);
}

.icon-button:disabled {
  background: unset;
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.custom-action-btn {
  background: var(--custom-action-btn-bg);
  border: none;
  border-radius: 5px;
  padding: 5px 10px !important;
  margin-right: 0;
  color: var(--custom-action-btn-font-color);
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content !important;
  overflow: hidden;
  align-items: center;
  display: flex;
  height: 26px;

  &.risk-button {
    .mat-button-wrapper {
      line-height: 100%;
    }
  }

  &:hover {
    background: var(--custom-btn-hover-color);
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
  }

  .custom-btn-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    max-width: 58px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    letter-spacing: .1px;

    &.left-side-title {
      margin-right: 10px;
    }

    &.right-side-title {
      margin-left: 7px;
    }
  }

  .custom-btn-title,
  .arrow-wrapper {
    display: block;
    line-height: normal;
  }
}

.mat-icon-button.square-small-btn {
  width: 24px;
  height: 24px;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
  }
}

// icon-btn
.custom-icon-btn {
  border: transparent;
  background-color: transparent;
  background: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 5px;
}

.arrow-wrapper {
  background-color: var(--custom-action-btn-bg);
  border-radius: 50%;
}

button.action-button,
button.removed-btn {
  border: 1px solid rgba(33, 150, 243, 0.2);
  display: flex;
  height: 20px;

  .mat-button-wrapper {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
  }
}

button.removed-btn {
  background-color: var(--custom-removed-btn-bg-color);
  border: 1px solid var(--custom-removed-btn-icon-border-color);

  .mat-button-wrapper {
    color: var(--custom-removed-btn-font-color);
    display: flex;
    align-items: center;
    height: 10px;
    line-height: 10px;
  }
}

button.action-button {
  border: 1px solid transparent;
}

.close-open-item-btn {
  border: 1px solid var(--close-open-btn);
  background: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 0px 0 7px;

}

.close-open-arrow {
  width: 100%;
  height: 100%;
  position: relative;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 1px;
    background-color: var(--mat-chips-close-button-color);
    transition: 0.3s;
  }

  &::after {
    top: 9px;
    left: 4px;
    transform: rotate(45deg);
  }

  &::before {
    top: 9px;
    left: 8px;
    transform: rotate(-45deg);
  }
}

.arrow-container {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  .left-part,
  .right-part {
    display: block;
    position: absolute;
    width: 6px;
    height: 1px;
    background: var(--trading-log-table-header-font-color);
    top: 7px;
    transition: .2s;
  }

  .left-part {
    left: 3px;
    transform: rotate(45deg);
  }

  .right-part {
    right: 3px;
    transform: rotate(-45deg);
  }

  &.active {
    .left-part {
      transform: rotate(-45deg);
    }

    .right-part {
      transform: rotate(45deg);
    }
  }
}

.toggle-square-shape,
.toggle-button {
  border: 1px solid var(--close-open-btn) !important;
  border-radius: 50% !important;

  .close-open-arrow {

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 6px;
      height: 1px;
      background-color: var(--mat-chips-close-button-color);
      transition: 0.3s;
    }

    &::after {
      top: 0px;
      left: -5px;
      transform: rotate(45deg);
    }

    &::before {
      top: 0px;
      left: -1px;
      transform: rotate(-45deg);
    }
  }

  .arrow-wrapper.close-item {
    .close-open-arrow {
      &::after {
        top: 0px;
        transform: rotate(-45deg);
        transition: 0.3s;
      }

      &::before {
        top: 0px;
        transform: rotate(45deg);
        transition: 0.3s;
      }
    }
  }
}

.toggle-square-shape{
  border-radius: 5px !important;
}

.close-open-item-btn.colorfull {
  border: 1px solid var(--custom-action-btn-icon-border-color);

  .close-open-arrow {

    &::after,
    &::before {
      background-color: var(--custom-action-btn-font-color);
    }
  }
}

.arrow-wrapper.close-item {
  .close-open-arrow {
    &::after {
      top: 8px;
      transform: rotate(-45deg);
      transition: 0.3s;
    }

    &::before {
      top: 8px;
      transform: rotate(45deg);
      transition: 0.3s;
    }
  }
}

.mat-mdc-icon-button.icon-button,
.icon-button {
  --mdc-icon-button-state-layer-size: 32px;
  --mdc-icon-button-icon-size: 20px;
  background-color: var(--btn-bg-color);
  border-radius: 4px;
  padding: 0 !important;
  overflow: hidden;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.risk-btn {
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  line-height: 100% !important
}

.pxo-link {
  @include apply-link-font();
}

.button-link {
  padding: 2px 5px 2px 10px !important;
  line-height: 130% !important;
  border-radius: 5px;
  transition: .15s;

  &.button-link-prymary:hover {
    background-color: var(--prymary-link-bg-color);
  }
}

.primary-link {
  color: var(--prymary-link-color);

  mat-icon path {
    stroke: var(--prymary-link-color)
  }

  &:hover {
    color: var(--prymary-link-hover-color);

    mat-icon path {
      stroke: var(--prymary-link-hover-color);
    }
  }

  &:visited {
    color: var(--prymary-link-visited-color);

    mat-icon path {
      stroke: var(--prymary-link-visited-color);
    }
  }
}

.week-control-btn {
  flex-shrink: 0;
  background: transparent;
  width: 31px;
  height: 31px;

  svg {
    path {
      stroke: var(--primary-lg-color);
    }
  }
}

.refresh-btn:hover {
  .refresh-icon {
    svg g {
      path {
        fill: var(--refresh-icon-hover-color);

        &:last-child {
          fill: none;
          stroke: var(--refresh-icon-hover-color);
        }
      }
    }
  }
}


.large-padding {
  padding: 0 28px !important;
}

.action-icon-btn {
  height: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 5px !important;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
  }
}

.add-watchlist {
  @media screen and (max-width: $mobile-min-425px-width-trigger) {
    right: 0 !important;
  }
}
