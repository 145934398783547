@import '../values';
@import '../mixins/fonts';
@import '../mixins/images';
@import '../mixins/divider';
@import '../mixins/mobile-devices';

.cdk-overlay-connected-position-bounding-box {
  container-type: inline-size;
}

.mat-mdc-menu-panel {
  transform-origin: top center !important;
  --mat-menu-container-color: var(--drop-down-bg);
  min-height: fit-content;
  border-radius: 1px;
  box-shadow: 0 3px 6px 0 var(--modal-box-shadow);
  border: 1px solid var(--modal-border);
  position: relative;
  overflow: unset;

  &.mat-menu-default,
  &.mat-mdc-menu-default {
    .mat-mdc-menu-item,
    .mat-mdc-menu-item:visited,
    .mat-mdc-menu-item:link {
      @include mat-menu-button-font();
      color: var(--font-dark-color);
    }

    .low-type-button {
      &:hover {
        --mat-mdc-menu-item-hover-color: var(--mat-menu-item-hover-color);
        background: var(--mat-mdc-menu-item-hover-color);
      }

      .mat-mdc-menu-item-text {
        height: 31px;
        @include mat-menu-button-font();
        color: var(--main-font-color);
        display: flex;
        align-items: center;

        .mat-icon {
          margin-right: 10px;
          color: var(--main-font-color);
        }
      }

      &.mat-mdc-menu-item {
        padding: 5px 12px;
        height: 31px;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1px;
        color: var(--main-font-color);
        display: flex;
        align-items: center;

        .mat-icon {
          margin-right: 10px;
        }
      }
    }

    .empty-symbol-option-menu-item {
      height: 0;
      min-height: 0;
    }

    background: var(--mat-menu-background-color);
    box-shadow: var(--mat-menu-shadow-color);
    border-radius: 5px;
  }

  .mat-mdc-menu-item {
    --mat-menu-item-label-text-weight: 500;
    --mat-menu-item-label-text-size: 13px;
    --mat-menu-item-label-text-line-height: 15px;
    --mat-menu-item-label-text-tracking: 0.4px;
    --mat-menu-item-label-text-color: var(--main-font-color);
    min-height: 32px;

    &:hover {
      --mat-menu-item-label-text-color: var(--active-link-color);
      --mat-menu-item-hover-state-layer-color: var(--select-hover-color);

      a {
        color: var(--active-link-color);
      }
    }

    .mat-mdc-button-ripple {
      * {
        background-color: var(--top-menu-mat-button-ripple);
      }
    }
  }

  &.trading-plan-menu {
    width: 141px;
    margin-top: 6px;

    .add-plan-sub-title {
      font-weight: 500;
      font-size: 10px;
      line-height: 13px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      padding: 0 12px 2px;
      color: var(--static-header-font-color);
    }
  }

  .mat-mdc-menu-header {
    @include mat-menu-headers-font();
    padding: 0 12px 2px 12px;
    color: var(--static-header-font-color);
  }

  .separate-line {
    width: calc(100% - 24px);
    height: 2px;
    display: block;
    background: var(--top-menu-decorator);
    margin: 12px auto;
  }

  &.smiley-menu.mat-mdc-menu-panel {
    box-shadow: 0 2px 8px 0 var(--box-shadow-2);
    min-height: initial;
    overflow: visible;
  }

  &.smiley-menu.mat-mdc-menu-panel::before {
    position: absolute;
    content: '';
    background: url(/assets/img/White-left-arrow.svg);
    width: 16px;
    background-position: center;
    background-size: cover;
    height: 30px;
    top: 6px;
    left: -16px;
  }

  &.smiley-menu .mat-mdc-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.smiley-menu .mat-mdc-menu-content {
    display: flex;
  }

  &.smiley-menu .mat-mdc-menu-item {
    padding: 5px 16px 5px 12px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  &.smiley-menu.symbol-flag-menu .mat-mdc-menu-item {
    padding-top: 0;
  }

  &.smiley-menu .mat-mdc-menu-item {
    color: var(--font-dark-color);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    background-color: transparent !important;

    .mat-mdc-menu-ripple {
      display: none;
    }

    &.mat-button-focus-overlay,
    &.mat-mdc-menu-item-highlighted {
      background-color: transparent !important;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.04) !important;
    }
  }

  &.smiley-menu .mat-mdc-menu-item:last-child {
    border-bottom: 0;
  }

  &.smiley-menu .mat-mdc-menu-item img {
    height: 15px;
    width: 15px;
    margin-top: 2px;
  }

  &.smiley-menu .mat-mdc-menu-item .icon {
    height: 15px;
    width: 15px;
    margin-top: 2px;
    margin-right: 0;
  }

  &.smiley-menu {
    position: absolute;
    left: 28px;
    top: -38px;
    padding: 1px 8px;
    width: min(380px, 90%);
    max-width: min(380px, 90%);
    max-height: 60px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid var(--smiley-menu-border-color);
    border-radius: 8px;
    filter: var(--smiley-menu-shadow);

    @media screen and (max-width: $mobile-min-500px-width-trigger) {
      padding: 1px 4px;
      width: auto;
      min-width: min(90%, 332px);
      left: 26px;
    }

    @media screen and (max-width: $tablet-max-width-trigger) {
      filter: none;
    }

    .mat-mdc-menu-content:not(:empty),
    .smiley-menu-inner {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      gap: 4px;
      z-index: 1;

      @media screen and (max-width: $mobile-min-500px-width-trigger) {
        gap: 2px;
      }
    }

    .smiley-menu-item {
      width: 70px;
      height: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: var(--secondary-btn-bg-hover);

        .smiley-menu-percentage .smiley-progressbar {
          background-color: var(--smiley-menu-item-hover-progressbar-bg-color);
        }
      }

      @media screen and (max-width: $mobile-min-500px-width-trigger) {
        width: 63px;
        height: 50px;
      }

      @media screen and (max-width: $mobile-min-375px-width-trigger) {
        width: 60px;
      }

      .smiley-img-with-text {
        padding-right: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        border-radius: 4px;
        transition: all 0.35s ease-in-out;
        transition-delay: 0.15s;

        @media screen and (max-width: $mobile-min-500px-width-trigger) {
          flex-direction: row;
        }

        .smiley-menu-item-btn.mat-mdc-menu-item {
          padding: 0 2px;
          width: auto;
          height: initial;

          &:hover {
            background: none !important;
          }

          .mat-icon {
            margin: 0;
            @include apply-icon-size(24px);
            flex-shrink: 0;
          }
        }

        .smiley-menu-title {
          padding-right: 4px;
          min-width: 30px;
          @include apply-smiley-menu-item-title-font();
          text-align: center;
          color: var(--smiley-menu-item-title-color);

          @media screen and (max-width: $mobile-min-500px-width-trigger) {
            order: 1;
          }

          @media screen and (max-width: $mobile-min-375px-width-trigger) {
            font-size: 11px;
          }
        }
      }

      .smiley-menu-percentage {
        padding: 0 2px 0 4px;
        display: flex;
        align-items: center;
        gap: 4px;
        opacity: 1;
        transform: translateY(-3px);
        transition: all 0.35s ease-in-out;
        transition-delay: 0.15s;

        .smiley-progressbar {
          flex: 1 1 100%;
          height: 3px;
          background: var(--smiley-menu-item-progressbar-bg-color);
          border-radius: 256px;
          overflow: hidden;
          position: relative;

          &::after {
            content: '';
            display: inline-block;
            width: var(--smiley-progressbar-width);
            height: 3px;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 8px;
            background: var(--smiley-menu-item-progressbar-percentage-bg-color);
          }
        }

        .smiley-counter {
          @include apply-smiley-menu-item-counter-font();
          text-align: right;
          color: var(--smiley-menu-item-progressbar-percentage-bg-color);
        }

        &.hidden-percentage {
          opacity: 0;
          appearance: none;
          transform: translateY(-100%);
        }
      }

      &.active {
        .smiley-menu-percentage {
          .smiley-progressbar {
            background: var(--smiley-menu-item-progressbar-active-bg-color);

            &::after {
              background: var(--smiley-menu-item-progressbar-active-percentage-bg-color);
            }
          }

          .smiley-counter {
            color: var(--smiley-menu-item-progressbar-active-percentage-bg-color);
          }
        }

        &:hover {
          .smiley-menu-percentage {
            .smiley-progressbar {
              background: var(--smiley-menu-item-progressbar-active-bg-hover-color);
            }
          }
        }
      }

      &.hidden-percentage {
        .smiley-img-with-text {
        }

        .smiley-menu-percentage {
          flex-shrink: 0;
          opacity: 0;
          appearance: none;
          transform: translateY(-10px);
        }
      }
    }

    .close-button-icon {
      @include apply-icon-size(10px);
      align-self: center;
      cursor: pointer;
      color: var(--smiley-menu-close-btn-color);
    }

    .smiley-menu-inner {
      width: min(380px, 100%);
      max-width: min(380px, 100%);

      &.smiley-items-wrapped {
        width: min(380px, 100%);
        max-width: min(380px, 100%);
        justify-content: space-between;
      }
    }

    .smiley-menu-item {
      width: max(70px, 20%);

      .smiley-img-with-text {
        flex-direction: column;
        align-items: center;

        .smiley-menu-title {
          padding: 0;
          order: -1;
        }
      }
    }

    &.mat-mdc-menu-panel::before {
      width: 12px;
      height: 12px;
      position: absolute;
      left: -6px;
      top: 50%;
      background: var(--drop-down-bg);
      border-left: 1px solid var(--smiley-menu-border-color);
      border-top: 1px solid var(--smiley-menu-border-color);
      filter: var(--smiley-menu-shadow);
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

.smiley-menu-item .mat-icon {
  --symbol-flag-outer-stroke-color: var(--none-smile-icon-color);
  --symbol-flag-stroke-color: var(--none-smile-icon-color);
}

.smiley-menu-item:hover .mat-icon,
.smiley-menu-item.active .mat-icon {
  &.none-icon {
    --symbol-flag-outer-stroke-color: var(--none-smile-icon-color);
    --symbol-flag-stroke-color: var(--none-smile-icon-color);
  }

  &.no-icon {
    --symbol-flag-bg-color: var(--symbol-flag-danger-bg-color);
    --symbol-flag-outer-stroke-color: var(--symbol-flag-danger-bg-color);
    --symbol-flag-stroke-color: var(--white-color);
  }

  &.yes-icon {
    --symbol-flag-bg-color: var(--symbol-flag-success-bg-color);
    --symbol-flag-outer-stroke-color: var(--symbol-flag-success-bg-color);
    --symbol-flag-stroke-color: var(--white-color);
  }

  &.maybe-icon {
    --symbol-flag-bg-color: var(--symbol-flag-warning-bg-color);
    --symbol-flag-outer-stroke-color: var(--symbol-flag-warning-bg-color);
    --symbol-flag-stroke-color: var(--white-color);
  }

  &.never-icon {
    --symbol-flag-bg-color: var(--symbol-flag-never-bg-color);
    --symbol-flag-stroke-color: var(--symbol-flag-never-stroke-color);
  }
}

.mat-mdc-menu-trigger {
  .mat-icon {
    &.none-icon {
      --symbol-flag-outer-stroke-color: var(--none-smile-icon-color);
      --symbol-flag-stroke-color: var(--none-smile-icon-color);
    }

    &.no-icon {
      --symbol-flag-bg-color: var(--symbol-flag-danger-bg-color);
      --symbol-flag-outer-stroke-color: var(--symbol-flag-danger-bg-color);
      --symbol-flag-stroke-color: var(--white-color);
    }

    &.yes-icon {
      --symbol-flag-bg-color: var(--symbol-flag-success-bg-color);
      --symbol-flag-outer-stroke-color: var(--symbol-flag-success-bg-color);
      --symbol-flag-stroke-color: var(--white-color);
    }

    &.maybe-icon {
      --symbol-flag-bg-color: var(--symbol-flag-warning-bg-color);
      --symbol-flag-outer-stroke-color: var(--symbol-flag-warning-bg-color);
      --symbol-flag-stroke-color: var(--white-color);
    }

    &.never-icon {
      --symbol-flag-bg-color: var(--symbol-flag-never-bg-color);
      --symbol-flag-stroke-color: var(--symbol-flag-never-stroke-color);
    }
  }
}

.profile-menu-item app-checklist-progress .donat .mat-icon {
  margin: 0;
}

// styles for ""portfolio-symbols-list component, (cannot be placed into component styles file)
.portfolio-symbols-list-menu {
  & + .cdk-overlay-connected-position-bounding-box {
    .mat-mdc-menu-panel {
      --mat-menu-container-color: var(--drop-down-bg);
      max-width: 450px;
      width: calc(100% - 20px);

      // fix for position, without it - menu is placed without space from left border
      // important: width breakpoint and max-width should have the same values
      @media screen and (max-width: $mobile-min-450px-width-trigger) {
        margin-left: 8px;
      }

      .mat-mdc-menu-content:not(:empty) {
        padding: 0;
      }

      .groups-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;

        padding: 16px;
        min-width: 320px;
        width: 450px;
        max-width: 100%;
        max-height: 450px;
        overflow: auto;

        .symbols-group {
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          align-items: flex-start;
          gap: 8px;
          width: 100%;

          .group-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            width: 100%;

            font-weight: 700;
            color: var(--main-font-color);
          }

          .divider {
            width: 100%;
            height: 1px;
            border-top: 1px solid var(--divider-color);

            &.outer {
              position: relative;
              left: -14px; // 28px = (16px * 2) - 2px
              width: calc(100% + 28px);
            }

            &.hidden {
              display: none;
            }
          }

          .symbols-list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 4px 8px;

            .symbol-container {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 2px;

              .symbol {
                font-weight: 400;
                color: var(--light-color);
              }

              &.active {
                &:hover {
                  cursor: pointer;
                }

                .symbol {
                  color: var(--recent-comparison-font-color);
                }
              }

              &::after {
                content: ',';
                display: inline-block;
                color: var(--main-font-color);
              }

              &:last-of-type::after {
                content: '';
                display: inline-block;
                color: var(--main-font-color);
              }
            }
          }
        }
      }
    }
  }
}

.wheel-chart-legend-menu {
  & + .cdk-overlay-connected-position-bounding-box {
    .mat-mdc-menu-panel {
      --mat-menu-container-color: var(--drop-down-bg);
      background: var(--wheel-chart-legend-bg-color);
      border: 1px solid var(--wheel-chart-legend-border-color);
      box-shadow: 0 30px 100px var(--wheel-chart-legend-box-shadow-color);
      border-radius: 4px;

      .mat-mdc-menu-content {
        padding: 6px 8px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        @media screen and (max-width: $mobile-max-width-trigger) {
          gap: 3px;
        }

        .legend-menu-item {
          display: flex;
          align-items: center;
          justify-items: start;

          .legend-item-marker {
            margin-right: 5px;
            width: 12px;
            height: 12px;
            border-radius: 2px;

            &.blue-marker {
              background-color: var(--legend-marker-blue-color);
            }

            &.orange-marker {
              background-color: var(--legend-marker-orange-color);
            }

            &.purple-marker {
              background-color: var(--legend-marker-purple-color);
            }
          }

          .legend-item-marker-title {
            @include apply-wheel-chart-lh-legend-marker-title-font();
            color: var(--wheel-chart-legend-text-color);

            @media screen and (max-width: $mobile-max-width-trigger) {
              font-size: 9px;
              line-height: 14px;
              letter-spacing: 0.2px;
            }
          }

          .legend-menu-title {
            margin-bottom: 1px;
            font-weight: 700;
            font-size: 10px;
            line-height: 120%;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: var(--wheel-chart-legend-text-color);
            white-space: nowrap;

            @media screen and (max-width: $mobile-max-width-trigger) {
              font-size: 9px;
              line-height: 115%;
            }
          }
        }
      }
    }
  }
}

.wheel-scanner-mat-menu {
  min-width: 130px;
  min-height: auto;
  box-shadow: 0 3px 6px 0 var(--modal-box-shadow);
  border: 1px solid var(--modal-border);
  border-radius: 8px;
  overflow: hidden;

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;

    .menu-content {
      padding: 8px 12px;
      background: var(--white-bg);

      .fields-wrapper {
        display: flex;
        align-items: flex-start;

        .mat-mdc-form-field-wrapper {
          padding: 0;

          .mat-mdc-form-field-subscript-wrapper {
            top: 24px !important;
            left: 2px;
            position: absolute;
          }
        }

        .mat-mdc-form-field {
          flex-grow: 1;
          width: 100%;
          height: 100%;

          .mat-mdc-text-field-wrapper {
            padding-inline: 0;
          }

          .mat-mdc-form-field-hint {
            @include apply-wheel-scanner-mat-hint-font();
            color: var(--filter-hint-color);
            display: block;
            width: 100%;
          }

          .mat-mdc-floating-label {
            --mat-form-field-outlined-label-text-populated-size: 14px;
            --mat-form-field-subscript-text-weight: 400;
            --mat-form-field-subscript-text-size: 14px;
            --mat-form-field-subscript-text-line-height: 16px;
            --mat-form-field-subscript-text-tracking: 0;
            color: var(--input-color);
          }

          &.mat-focused {
            .mat-mdc-floating-label {
              color: var(--primary-color);
            }
          }
        }

        .form-group .mat-mdc-form-field-flex {
          padding: 0;
          display: flex;
          align-items: center;
          background: transparent;
          border: none;
          border-radius: 4px;
          height: 100%;
        }

        .mat-mdc-form-field-underline {
          display: none;
          opacity: 0;
        }

        .mat-mdc-form-field-infix {
          margin: 0;
          padding: 0;
          min-height: 28px;
          display: flex;
          align-items: center;
          background-color: transparent !important;
          border: none;
          width: 100%;

          .mat-mdc-input-element.wheel-input {
            margin: 0;
            padding: 5px 7px;
            height: 28px;
            font-size: 12px;
            color: var(--input-color);
            background: var(--white-bg);
            border-radius: 4px;
            border: 1px solid var(--light-border-color);

            &.invalid-input {
              border-color: var(--wheel-filter-input-invalid-value-color);
              color: var(--wheel-filter-input-invalid-value-color);
            }

            &:active,
            &:focus {
              border: 1px solid var(--primary-color);
            }
          }

          .mat-form-field-label {
            left: 4px;
            top: 24px !important;
            padding-inline: 4px;
            display: inline-block;
            width: min-content;
            background: var(--white-bg);
            border-block: 1px solid var(--white-bg);
          }
        }

        .fields-separator {
          margin: 14px 3px 0;
          align-self: flex-start;
          display: block;
          width: 10px;
          height: 1px;
          background-color: var(--close-open-btn-font);
        }
      }
    }
  }
}

.mat-mdc-menu-panel.chat-menu {
  margin-bottom: 10px;
  border: 1px solid var(--divider-color);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;

  .mat-mdc-menu-content:not(:empty) {
    padding: 12px 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .limitations {
      .mat-icon {
        color: var(--chat-bot-limitations-icon-color);
      }

      .limitations-title {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        @include apply-chatbot-tab-limitations-title-font();
        color: var(--chat-bot-limitations-title-color);
      }

      .limitations-list {
        padding-left: 20px;

        .limitations-list-item {
          margin-bottom: 4px;
          @include apply-chatbot-tab-limitations-item-font();
          color: var(--chat-bot-limitations-list-item-color);
          list-style-type: disc;
        }
      }
    }
  }

  .mat-divider {
    width: 100%;
    border-color: var(--divider-color);
  }

  .chat-menu-btn {
    padding: 8px;
    height: 32px;
    @include apply-chatbot-tab-chat-button-font();
    color: var(--main-headers-font-color);
  }
}

.mat-mdc-menu-panel.mat-controlMenu {
  --mat-menu-container-color: var(--drop-down-bg);
  min-height: initial;
  background: var(--drop-down-bg);

  .mat-mdc-menu-item:hover {
    background: var(--select-hover-color);
  }

  .mat-icon {
    @include apply-icon-size(20px);
  }
}

.tradier-accounts-menu {
  min-width: 153px;
  min-height: fit-content;
  border-radius: 1px;
  box-shadow: 0 3px 6px 0 var(--modal-box-shadow);
  border: 1px solid var(--modal-border);
  background-color: var(--drop-down-bg);

  .mat-button {
    color: var(--main-font-color);
  }

  .mat-button:hover {
    color: var(--active-link-color);
    background-color: var(--select-hover-color);
  }
}

.tl-sorting-filters-trigger.custom-action-btn,
.tl-additional-columns-trigger.custom-action-btn {
  margin-left: 0;
  width: auto;

  .mdc-button__label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 60px;
  }
}

.tl-additional-columns-trigger.custom-action-btn {
  padding-inline: 5px !important;
}

.tl-sorting-filters-menu,
.tl-additional-columns-menu {
  margin-top: 4px;
  padding: 0;
  max-width: initial;
  min-height: initial;
  box-shadow:
    0 3px 5px rgba(0, 0, 0, 0.1),
    0 1px 18px rgba(0, 0, 0, 0.06),
    0 6px 10px rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 8px;

  .sorting-filters {
    margin-bottom: 16px;
    max-height: 230px;
    min-width: 272px;
    display: flex;
    flex-flow: column wrap;
    column-gap: 32px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      @include divider(vertical);
      overflow: hidden;
    }
  }

  .mat-mdc-menu-content {
    padding: 16px;

    .mat-mdc-menu-item {
      --mat-menu-item-label-text-color: var(--tl-sorting-filter-menu-item-color);
      width: 120px;
      padding-inline: 8px;
      max-height: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      overflow: hidden;

      &:hover .mat-icon {
        display: block;
      }

      .mat-mdc-menu-item-text {
        width: 100%;
        flex: 1;
        --mat-menu-item-label-text-weight: 400;
        --mat-menu-item-label-text-size: 12px;
        --mat-menu-item-label-text-line-height: 16px;
        --mat-menu-item-label-text-tracking: -0.01em;
        color: var(--tl-sorting-filter-menu-item-color);

        .menu-item-custom-label-with-icon {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 4px;
        }
      }

      .mat-icon {
        margin: 0;
        display: none;

        &.up,
        &.down {
          display: block;
        }
      }
    }

    .mat-mdc-menu-item.tl-export-btn {
      display: flex;
      justify-content: flex-start;

      .mat-icon {
        display: block;
        color: var(--tl-sorting-filter-icon-color);
      }
    }

    .mat-mdc-menu-item:hover:not([disabled]),
    .mat-mdc-menu-item-highlighted:not([disabled]) {
      background-color: var(--select-hover-color);
    }
  }
}

.tl-additional-columns-menu {
  .right-side-title {
    margin-left: 8px;
    margin-right: auto;
  }

  .mat-menu-submenu-icon {
    display: none;
  }

  .mat-mdc-menu-content {
    padding: 8px 0;

    .mat-mdc-menu-item {
      padding-inline: 16px;
      min-width: 190px;
      max-height: 38px;
      height: 38px;

      .mat-icon {
        display: block;
        color: var(--tl-sorting-filter-icon-color);

        &.submenu-trigger-icon {
          rotate: -90deg;
        }
      }

      .mat-mdc-menu-item-text {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          translate: -50% -50%;
          rotate: -45deg;
          width: 8px;
          height: 8px;
          color: var(--tl-sorting-filter-icon-color);
          border-bottom: 1px solid currentColor;
          border-right: 1px solid currentColor;
        }
      }

      .mat-mdc-menu-submenu-icon {
        display: none !important;
        visibility: hidden !important;
        width: 0;
        height: 0;
        opacity: 0;
        z-index: -1;
      }
    }
  }
}

.no-arrow > .mat-mdc-menu-item-text:after {
  content: none !important;
}

.tl-additional-columns-submenu {
  border: none;

  .mat-mdc-selection-list.mat-mdc-list-base {
    padding: 0;
  }

  .mat-mdc-list-item-content:has(.custom-action-btn) {
    justify-content: flex-end;
  }

  .mat-mdc-list-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--tl-sorting-filter-menu-item-color);

    &:focus::before {
      opacity: var(--mdc-list-list-item-hover-state-layer-opacity);
    }
  }

  .mat-mdc-list-option:hover:not(.mat-mdc-list-option-disabled) {
    background-color: var(--select-hover-color);
  }
}

.mat-mdc-menu-panel {
  border: none;

  .mat-mdc-menu-content {
    .management-control {
      .custom-icon-btn {
        height: 44px;
        margin: 0;
        justify-content: flex-start !important;

        &:hover {
          background: var(--select-hover-color);
        }

        .monochrome-color-icon {
          path {
            fill: var(--monochrome-icon-color);
          }
        }

        .mat-mdc-menu-item-text {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: var(--main-font-color);
        }
      }
    }
  }
}

.heatmap-market-data-menu,
.filters-presets-settings-menu {
  min-width: 230px;
  box-shadow: 0 30px 100px 0 rgba(1, 41, 84, 0.15);
  border: 1px solid var(--modal-border);

  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      height: 38px;

      &:hover {
        color: var(--active-link-color);
        background-color: var(--select-hover-color);
      }

      .mat-mdc-menu-item-text {
        @include font-regular(14px, 21px, 0.1px, var(--main-font-color));
        text-align: left;
      }
    }
  }
}

.multiple-options-select-filter-menu,
.wtf-filter-menu {
  min-width: 150px;
  box-shadow: 0 30px 100px 0 rgba(1, 41, 84, 0.15);

  .mat-mdc-menu-content {
    padding: 0;

    .multiple-options-select-filter-options-container,
    .wtf-filter-options-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px;

      .reset-btn {
        margin: 4px 2px;
        padding: 2px 4px;
        border-radius: 4px;
        color: var(--btn-primary-color);
        background: var(--light-bg-17);
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        height: 32px;

        .mat-button-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 6px;
        }

        svg {
          vertical-align: top;
        }

        &:hover {
          background: var(--light-hover-color-5);
        }
      }
    }
  }
}

.bcs-drop-percent-menu {
  min-width: 100px;
  max-width: 100px;
  box-shadow: 0 30px 100px 0 rgba(1, 41, 84, 0.15);

  .mat-mdc-menu-content {
    padding: 0;

    .drop-percent-options-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 6px 8px;

      .mat-mdc-checkbox {
        margin-left: 4px;
      }

      .reset-btn {
        margin: 4px 2px;
        padding: 2px 4px;
        border-radius: 4px;
        color: var(--btn-primary-color);
        background: var(--light-bg-17);
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        height: 28px;

        .mat-button-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 6px;
        }

        svg {
          vertical-align: top;
        }

        &:hover {
          background: var(--light-hover-color-5);
        }
      }
    }
  }
}

.mat-menu-datepicker {
  min-width: 298px;
  max-width: 298px;
  min-height: min-content !important;
  border-radius: 1px !important;
  box-shadow: 0 3px 6px 0 var(--modal-box-shadow);
  border: 1px solid var(--modal-border);
  overflow-x: hidden;
  max-height: 100dvh;
  overflow-y: auto;

  @media screen and (max-width: $mobile-max-width-trigger) {
    min-height: initial !important;
  }

  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      --mat-menu-item-label-text-line-height: 34px;
      --mat-menu-item-label-text-size: 14px;
      --mat-menu-item-label-text-tracking: 0.1px;
      --mat-menu-item-label-text-weight: 400;
      color: var(--main-font-color);

      &:hover {
        color: var(--active-link-color);
        background-color: var(--select-hover-color);
      }
    }
  }

  &.fix-empty-space-clicks {
    .mat-menu-content {
      padding: 0px;
    }
  }
}

.cdk-overlay-pane:has(.stocks-dropdown-menu) {
  @media screen and (max-width: $mobile-max-width-trigger) and (orientation: portrait) {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }
}

.stocks-dropdown-menu {
  --stocks-dropdown-menu-width: 350px;
  --mat-menu-container-color: var(--drop-down-bg);
  margin: 4px;
  padding-inline: 8px;
  width: var(--stocks-dropdown-menu-width);
  height: fit-content;
  max-height: calc(100dvh - 24px);
  min-width: var(--stocks-dropdown-menu-width);
  color: var(--main-font-color);
  background-color: var(--drop-down-bg);
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 var(--modal-box-shadow);
  border: 1px solid var(--modal-border);
  overflow-y: auto;
  overflow-x: hidden;

  &.trades-menu {
    overflow-y: hidden;
  }

  @media screen and (max-width: $tablet-992px-width-trigger) and (orientation: landscape) {
    max-height: calc(100dvh - var(--new-header-height));
  }

  @media screen and (max-width: $mobile-max-width-trigger) and (orientation: portrait) {
    margin: 0;
    max-height: initial;
    height: 100dvh;
    width: 100vw;
    max-width: initial;
  }

  @media screen and (max-width: $mobile-max-width-trigger) and (orientation: landscape) {
    margin-block: 5dvh;
    height: fit-content;
    max-height: calc(90dvh - 24px);
  }

  .mat-mdc-menu-content {
    @media screen and (max-width: $mobile-max-width-trigger) {
      height: 100%;
    }
  }
}

.filters-presets-settings-menu {
  margin-top: 4px;
  min-width: 300px;
  max-width: 300px;

  @media screen and (max-width: $mobile-max-width-trigger) {
    min-width: 300px;
    max-width: calc(100% - 30px);
  }

  .mat-mdc-menu-content {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .divider {
    margin-block: 10px;
    width: 100%;
    height: 1px;
  }

  .filters-drop-list {
    max-width: 100%;
    width: 100%;
    max-height: 315px;
    overflow-y: auto;
    transition: 0.3s all;

    @include ipad {
      padding-right: 10px;
    }

    @include iphone {
      padding-right: 10px;
    }
  }

  .filters-drop-list.drop-disabled {
    .mat-mdc-menu-item.filters-list-item {
      &:hover {
        background-color: var(--select-hover-color);
        border-radius: 6px;
        transition: 0.3s all;
        .mat-icon.drag-handler {
          opacity: 0;
        }
      }

      .mat-icon.drag-handler {
        cursor: unset;
        opacity: 0;

        &:active {
          cursor: unset;
        }
      }
    }
  }

  .filters-list-title {
    margin: 0;
    padding: 0;
    @include font-medium(10px, normal, 0.1px, var(--sub-header));
    text-transform: uppercase;
  }

  .filters-list-default .mat-mdc-menu-item.filters-list-item,
  .filters-list-default .mat-mdc-menu-item.filters-list-item:hover {
    .drop-list-actions {
      opacity: 0;
      pointer-events: none;
    }
  }

  .mat-mdc-menu-item.filters-list-item {
    --mat-menu-item-label-text-weight: 400;
    --mat-menu-item-label-text-size: 14px;
    --mat-menu-item-label-text-line-height: 21px;
    --mat-menu-item-label-text-tracking: 0.1px;
    padding: 5px 0;
    height: 31px;
    min-height: 31px;
    color: var(--main-font-color);
    position: relative;
    overflow: hidden;

    &:hover {
      color: var(--primary-color);

      .mat-icon.drag-handler {
        opacity: 1;
      }

      .drop-list-actions {
        opacity: 1;
        pointer-events: initial;
      }
    }

    .mat-icon.drag-handler {
      margin-right: 0;
      margin-left: -6px;
      min-width: 24px;
      min-height: 28px;
      cursor: grab;
      opacity: 0;

      &:active {
        cursor: grabbing;
      }
    }

    @media (hover: none) {
      .mat-icon.drag-handler {
        opacity: 1;
      }
    }

    .drop-list-actions {
      padding-left: 30px;
      position: absolute;
      top: 50%;
      right: 0;
      translate: 0 -50%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      opacity: 0;
      pointer-events: none;

      @media screen and (max-width: $tablet-992px-width-trigger) {
        opacity: 1;
        pointer-events: initial;
      }

      .filters-presets-action-btn {
        border: none;
        background: none;
        width: 24px;
        height: 24px;
        cursor: pointer;

        .mat-icon {
          @include apply-icon-size(24px);

          @media screen and (max-width: $mobile-max-width-trigger) {
            @include apply-icon-size(20px);
          }
        }
      }
    }
  }

  .add-filter-btn {
    margin-top: 8px;
    color: var(--btn-primary-color);
    background: var(--light-bg-17);

    &:hover {
      background: var(--light-hover-color-5);
    }
  }

  .new-filter-wrapper {
    padding-block: 8px;
    margin-bottom: 1px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    .new-filter-input {
      flex: 1 1 100%;
      overflow: hidden;

      .form-group .mat-mdc-text-field-wrapper {
        padding-inline: 7px;
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    .new-filter-actions {
      display: flex;
      align-items: center;
      gap: 5px;

      .mat-icon {
        @include apply-icon-size(16px);

        svg {
          --mdc-icon-button-icon-size: 16px;
        }
      }

      .icon-cancel {
        rotate: 45deg;
      }
    }
  }
}

@-moz-document url-prefix() {
  .filters-drop-list {
    padding-right: 10px !important;
  }
}

.ws-menu {
  .mat-mdc-menu-content {
    padding: 0;

    .mat-mdc-menu-item {
      --mat-menu-item-focus-state-layer-color: transparent;
      width: 100%;
      padding-left: 8px;
      padding-right: 16px;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      color: var(--font-dark-color);

      &:hover {
        background-color: var(--mat-menu-item-hover-state-layer-color);
      }

      & + .mat-mdc-menu-item {
        border-top: 1px solid var(--dark-border-color);
      }

      .mat-icon {
        margin-right: 8px;
        color: var(--font-dark-color);
      }

      .mat-mdc-menu-item-text {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
      }
    }
  }
}

.position-dropdown {
  &.mat-mdc-menu-panel {
    box-shadow: 0 2px 8px 0 var(--box-shadow-2);
    border-radius: 0;
    min-width: 180px;
    max-width: 180px;

    .mat-mdc-menu-content:not(:empty) {
      padding: 0;

      .mat-mdc-menu-item {
        padding-inline: 9px;
        height: 32px;
        border-bottom: 0;

        .mat-mdc-menu-item-text {
          --mat-menu-item-label-text-weight: 400;
          --mat-menu-item-label-text-size: 12px;
          --mat-menu-item-label-text-line-height: 16px;
          --mat-menu-item-label-text-tracking: 0.3px;
          color: var(--font-dark-color);
        }
      }
    }
  }
}

.calendar-legend-menu {
  margin-top: 4px;
  transform-origin: center top !important;
  border-radius: 4px;
  border: 1px solid var(--modal-border);
  background: var(--drop-down-bg);
  box-shadow: 0 3px 6px 0 var(--modal-box-shadow);

  .mat-menu-content {
    padding-block: 4px;
  }

  .legend-menu-wrapper {
    padding-inline: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    .legend-menu-item {
      display: flex;
      align-items: center;
      gap: 5px;

      .legend-menu-item-marker {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;

        &.high {
          background: var(--red-point);
        }

        &.med {
          background: var(--orange-point);
        }

        &.low {
          background: var(--yellow-point);
        }

        &.non {
          background: var(--grey-point);
        }
      }

      .legend-menu-item-text {
        @include font-regular(12px, 120%, 0.4px);
      }
    }
  }
}

.expandable-content-toggle-menu {
  background: var(--drop-down-bg);
  border: 1px solid var(--modal-border);
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 var(--modal-box-shadow);

  .mat-mdc-menu-content {
    padding-block: 4px;

    .mat-mdc-menu-item {
      --mat-menu-item-label-text-size: 14px;
      --mat-menu-item-label-text-tracking: 0.1px;
      --mat-menu-item-label-text-line-height: 21px;
      --mat-menu-item-label-text-weight: 400;
      --mat-menu-item-label-text-color: var(--main-font-color);
      line-height: 16px;
      height: 32px;
      color: var(--main-font-color);

      &:hover {
        color: var(--active-link-color);
        background: var(--select-hover-color);
      }
    }
  }
}

.expandable-tabs-toggle-menu {
  margin-top: 4px;
  width: calc(100vw - 16px);
  max-width: 100%;
  background: var(--drop-down-bg);
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 var(--modal-box-shadow);
  z-index: 999999;

  @media screen and (max-width: $tablet-991px-width-trigger) {
    width: 350px;
  }

  @media screen and (max-width: $mobile-max-width-trigger) {
    width: calc(100vw - 16px);
  }

  .mat-mdc-menu-content {
    padding: 0;

    .mat-mdc-menu-item.calendar-tab {
      border: 1px solid var(--table-border-color);

      &:hover {
        border-color: var(--combined-calendars-page-tab-border-color);
        background-color: var(--combined-calendars-page-tab-bg-color);
        cursor: pointer;
      }

      &.active {
        border-color: var(--combined-calendars-page-tab-border-color);
        background: var(--combined-calendars-page-tab-bg-color);
      }

      .mat-mdc-menu-item-text {
        padding: 8px 16px;
        min-height: 70px;
        display: flex;
        align-items: center;
        justify-items: flex-start;
        gap: 12px;

        &:first-child {
          border-radius: 4px 4px 0 0;
        }

        &:last-child {
          border-radius: 0 0 4px 4px;
        }

        .calendar-tab-icon {
          .mat-icon {
            margin: 0;
            @include apply-icon-size(24px);
            color: var(--primary-color);
          }
        }

        .calendar-tab-content {
          .calendar-tab-title {
            margin-bottom: 2px;
            @include font-bold(16px, 20px, -0.01em, var(--main-font-color));
          }

          .calendar-tab-description {
            @include font-regular(12px, 16px, -0.01em);
          }
        }

        .calendar-tab-arrow {
          margin-left: auto;
          flex-shrink: 0;

          .mat-icon {
            @include apply-icon-size(22px);
            color: var(--primary-color);
            transform: rotateX(0deg);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }
  }
}

.header-v2-navbar {
  .mat-mdc-menu-item:hover {
    background-color: transparent;
  }
  .pxo-app .mat-mdc-menu-panel {
    border-radius: 8px !important;
  }

  .mdc-button.mat-mdc-button-base:not(:disabled).mat-unthemed {
    @media screen and (max-width: $mobile-min-575px-width-trigger) {
      padding-right: 2px;
      padding-left: 2px;
      font-size: 11px;
    }
  }
}

.mat-mdc-menu-panel.header-v2-navbar-menu {
  border-radius: 8px;
}

.header-v2-navbar-menu {
  .mat-mdc-menu-content {
    width: 250px;
    min-height: -webkit-fit-content !important;
    min-height: -moz-fit-content !important;
    min-height: fit-content !important;
    border-radius: 8px !important;
    box-shadow: var(--mat-menu-shadow-color);
    border: 1px solid var(--modal-border);
    position: relative;
    overflow: unset !important;
    color: var(--main-font-color);
    background-color: var(--white-bg);
    padding: 16px;

    div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      button,
      a {
        box-sizing: border-box;
        text-align: left;
        color: var(--main-font-color);
        transition: 0.1s;
        border-radius: 0;
        justify-content: flex-start;
        font-size: 16px;

        .mat-button-wrapper {
          justify-content: flex-start;
        }
      }
    }

    .mat-mdc-button:has(.material-icons, mat-icon, [matButtonIcon]) {
      padding: 0;
    }

    .menu-item {
      width: 100%;
      padding: 12px 8px;
      height: 45px;
      display: flex;
      align-items: center;
      position: relative;
      transition: 0.3s all;
      .strategy-icon {
        color: var(--white-bg);
      }
      &:hover {
        transition: 0.3s all;
        border-radius: 4px;
        background: var(--profile-menu-block-item-bg-color);
        .menu-icon {
          transition: 0.3s all;
          color: var(--menu-icon-hover-color);
        }
        .strategy-icon {
          color: var(--profile-menu-block-item-bg-color);
        }
      }

      .menu-item-text {
        color: var(--main-font-color);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        @media screen and (max-width: $mobile-min-575px-width-trigger) {
          font-size: 14px;
        }
      }

      mat-icon {
        color: var(--profile-menu-block-item-icon-color);
        margin-right: 16px;
        @media screen and (max-width: $mobile-min-575px-width-trigger) {
          margin-right: 10px;
        }
      }
    }

    .mat-mdc-button .mdc-button__label {
      width: 100%;
      display: block;
    }
  }

  .lock-icon {
    mat-icon {
      color: var(--lock-icon-top-menu-color) !important;
    }
  }

  .mdc-button.mat-mdc-button-base:not(:disabled).mat-unthemed {
    @media screen and (max-width: $mobile-min-575px-width-trigger) {
      padding-right: 2px;
      padding-left: 2px;
      font-size: 11px;
    }
  }
}
