@import '../values';

@mixin font($weight: 400, $size: 14px, $l-height: normal, $l-spacing: normal, $color: var(--font-dark-color)) {
  font-family: $trebuchet-font;
  font-weight: $weight;
  font-size: $size;
  line-height: $l-height;
  letter-spacing: $l-spacing;
  color: $color;
}

@mixin font-regular($size: 14px, $l-height: normal, $l-spacing: normal, $color: var(--font-dark-color)) {
  @include font(400, $size, $l-height, $l-spacing, $color);
}

@mixin font-medium($size: 14px, $l-height: normal, $l-spacing: normal, $color: var(--font-dark-color)) {
  @include font(500, $size, $l-height, $l-spacing, $color);
}

@mixin font-bold($size: 14px, $l-height: normal, $l-spacing: normal, $color: var(--font-dark-color)) {
  @include font(700, $size, $l-height, $l-spacing, $color);
}

@mixin apply-modal-header-font() {
  font-family: $trebuchet-font;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;

  @media screen and (max-width: $mobile-max-width-trigger) {
    font-size: 16px;
  }
}

@mixin apply-modal-body-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 17px;
}

@mixin apply-modal-action-button-font() {
  font-family: $trebuchet-font;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 16px;
}

@mixin apply-admin-panel-header-font() {
  font-family: $trebuchet-font;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
}

@mixin apply-admin-panel-semi-header-font() {
  font-family: $trebuchet-font;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
}

@mixin apply-admin-panel-semi-header-font() {
  font-family: $trebuchet-font;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
}

@mixin apply-admin-panel-table-row-font() {
  font-family: $trebuchet-font;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
}

@mixin apply-admin-panel-table-header-font() {
  font-family: $trebuchet-font;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
}

@mixin apply-mat-select-selected-font() {
  font-family: $trebuchet-font;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

@mixin apply-admin-panel-warning-font() {
  font-family: $trebuchet-font;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}

@mixin apply-warning-font() {
  font-family: $trebuchet-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}

@mixin apply-alert-font() {
  font-family: $trebuchet-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0px;
}

@mixin apply-default-alert-font() {
  font-family: $trebuchet-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.1px;
}

@mixin set-up-font-size-line-height-weight($size, $line-height, $weight) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  @content;
}

@mixin apply-top-menu-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
}

@mixin apply-top-menu-mat-panel-font() {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 15px;
}

@mixin apply-section-title-font() {
  font-family: $trebuchet-font;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}

@mixin apply-link-font() {
  font-size: 11px;
  line-height: 100%;
  font-weight: 400;
}

@mixin apply-tl-dashboard-section-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

@mixin apply-tl-dashboard-no-data-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

@mixin apply-tl-dashboard-chart-tick-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
}

@mixin apply-wheel-chart-lh-legend-marker-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.4px;
}

@mixin apply-small-default--font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
}

@mixin apply-license-agreement-text-font() {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

@mixin apply-license-page-header-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

@mixin apply-license-page-subheader-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

@mixin apply-secondary-subheader-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin apply-table-th-cell-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin apply-table-td-cell-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

@mixin apply-input-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

@mixin apply-table-title-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

@mixin apply-disclaimer-title-font() {
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
}

@mixin apply-disclaimer-description-font() {
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
}

@mixin apply-tl-dashboard-chart-title-font() {
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 1px;
}

@mixin apply-tl-dashboard-chart-header-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

@mixin apply-date-picker-title-font() {
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin apply-date-picker-value-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
}

@mixin apply-date-picker-current-period-value-font() {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}

@mixin apply-trades-group-header-title-font() {
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
}

@mixin apply-tab-label-font() {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin apply-s-tab-label-font() {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin apply-trades-summary-panel-label-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
}

@mixin apply-trades-summary-panel-values-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

@mixin apply-add-trades-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

@mixin apply-lable-small-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.4px;
}

@mixin apply-lable-default-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

@mixin apply-expected-move-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.1px;
}

@mixin apply-secondary-header-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

@mixin apply-tbd-todo-title-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px; // use only integer numbers for textarea with cdkTextareaAutosize
  letter-spacing: 0.1px;
}

@mixin apply-tbd-alert-subheader-title-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
}

@mixin apply-no-data-header() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
}

@mixin apply-no-data-subheader() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin apply-tbd-progress-center-label-font-style() {
  font-family: $yantramanav-font;
  font-weight: 700;
  letter-spacing: 0.1px;
  font-size: 8px;
  line-height: 130%;
}

@mixin oco-order-confirm-font() {
  font-family: $trebuchet-font;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 700;
}

@mixin mat-menu-button-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.1px;
}

@mixin mat-menu-headers-font() {
  font-family: $trebuchet-font;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

@mixin trading-plan-list() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin trading-plan-plan-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin trading-plan-list-title-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

@mixin ngx-strong-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
}

@mixin ngx-li-content-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

@mixin ngx-header-content-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
}

@mixin title-description-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: 1px;
}

@mixin extra-small-badge-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
}

@mixin apply-whell-group-summary-title-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 1px;
}

@mixin extra-small-tab-lable() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin flat-button-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin trading-panel-page-title() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin trading-panel-filter-text() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin trading-panel-connection-subheader() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin apply-impersonate-panel-logout-button-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@mixin apply-impersonate-panel-main-label-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin apply-impersonate-panel-email-label-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

@mixin apply-feelings-modal-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

@mixin apply-feelings-modal-table-header-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

@mixin apply-my-settings-sound-bar-text-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-my-settings-sound-bar-timer-text-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-feelings-modal-options-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-feelings-modal-options-description-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-feelings-panel-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
}

@mixin apply-my-settings-sound-bar-info-container-text-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-magic-ball-tab-title-font {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
}

@mixin apply-magic-ball-tab-description-font {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin apply-magic-ball-tab-image-inner-text-font {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

@mixin apply-magic-ball-tab-result-text-font {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin apply-smiley-menu-item-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
}

@mixin apply-smiley-menu-item-counter-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.01em;
}

@mixin apply-header-menu-profile-icon-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

@mixin apply-header-menu-profile-name-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
}

@mixin apply-header-menu-profile-block-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

@mixin apply-trading-log-dashboard-section-item-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

@mixin apply-header-menu-profile-block-item-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.14px;
}

@mixin apply-header-menu-profile-help-block-item-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-trading-log-dashboard-section-content-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-header-menu-profile-help-footer-block-license-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.01em;
}

@mixin apply-wheel-calculator-min-premium-table-header-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
}

@mixin apply-wheel-calculator-min-premium-table-body-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

@mixin apply-feature-page-title() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

@mixin apply-feature-page-description() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-feature-page-link-more() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-feature-page-availability-block-title() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

@mixin apply-feature-page-availability-block-level-title() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: clamp(8px, calc(1vw + 1vh * 0.7), 13px); /* adaptive font-size based on viewport-width */
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.01em;
}

@mixin apply-wheel-scanner-filter-modal-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-wheel-scanner-mat-hint-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
}

@mixin apply-trading-panel-connection-item-btn-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin apply-trading-panel-connection-provider-name-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

@mixin apply-trading-log-panel-error-container-text-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

@mixin apply-chatbot-tab-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

@mixin apply-chatbot-tab-description-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-chatbot-tab-question-example-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

@mixin apply-chatbot-tab-example-question-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-chatbot-tab-limitations-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-chatbot-tab-limitations-item-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-chatbot-tab-chat-button-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-chatbot-tab-pagination-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

@mixin apply-chatbot-tab-question-input-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-chatbot-tab-question-input-label-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-oco-order-modal-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-oco-order-modal-label-subtitle-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
}

@mixin apply-oco-order-modal-footer-button-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
}

@mixin apply-oco-order-modal-price-change-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

@mixin apply-oco-order-modal-price-ticker-btn-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
}

@mixin apply-oco-order-modal-price-ticker-btn-value-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

@mixin apply-heatmap-page-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

@mixin apply-heatmap-page-subtitle-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-heatmap-page-info-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-portfolio-header-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-portfolio-additional-panel-header-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-portfolio-additional-panel-item-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

@mixin apply-portfolio-symbol-panel-text-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-trading-log-dashboard-section-content-title-sm-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.01em;
}

@mixin apply-roi-legend-tooltips-item-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

@mixin apply-heatmap-tooltip-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

@mixin apply-heatmap-tooltip-symbol-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.01em;
}

@mixin apply-heatmap-tooltip-first-symbol-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20.8px;
}

@mixin apply-heatmap-tooltip-symbol-subtitle-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10.8px;
  line-height: 14px;
  letter-spacing: -0.01em;
}

@mixin apply-heatmap-tooltip-symbol-nums-font() {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-heatmap-tooltip-first-symbol-nums-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20.8px;
}

@mixin apply-oco-order-select-inner-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@mixin apply-oco-order-error-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
}

@mixin apply-error-message-box-text-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

@mixin apply-tl-dashboard-days-block-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

@mixin apply-tl-dashboard-days-block-assignment-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-tl-dashboard-view-switcher-btn-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.01em;
}

@mixin apply-quick-links-section-link-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-no-links-section-description-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

@mixin apply-income-statement-no-data-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

@mixin apply-earnings-calendar-page-title-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

@mixin apply-earnings-calendar-current-day-label-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

@mixin apply-earnings-calendar-days-block-title-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

@mixin apply-earnings-calendar-days-block-date-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-earnings-calendar-market-data-type-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
}

@mixin apply-earnings-calendar-market-data-stocks-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

@mixin apply-earnings-calendar-day-earning-title-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

@mixin apply-tl-dashboard-metric-details-header-static-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin apply-tl-dashboard-metric-details-header-particular-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

@mixin apply-tl-dashboard-metric-details-trade-groups-label-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 1px;
}

@mixin apply-tl-dashboard-metric-details-date-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 1px;
}

@mixin apply-tl-dashboard-metric-details-info-item-font() {
  font-family: $trebuchet-font;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

@mixin apply-tl-dashboard-metric-details-info-item-font() {
  font-family: $trebuchet-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
}

@mixin apply-datepicker-display-date-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.12px;
}

@mixin apply-datepicker-display-lifetime-no-data-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
}

@mixin apply-print-trading-plan-doc-title-font() {
  font-family: $trebuchet-font;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
}

@mixin apply-print-trading-plan-section-title-font() {
  font-family: $trebuchet-font;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
}

@mixin apply-print-trading-plan-section-content-font() {
  font-family: $trebuchet-font;
  font-size: 16px;
}

@mixin apply-quote-of-day-block-title() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

@mixin circular-progress-modal-header-font() {
  font-family: $trebuchet-font;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

@mixin circular-progress-modal-text-font() {
  font-family: $trebuchet-font;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: 0.1px;
}

@mixin apply-stock-screener-page-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

@mixin apply-stock-screener-mobile-page-title-font() {
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
}

@mixin sector-value-font() {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  color: var(--light-color);
}

@mixin top-menu-strategy-name-font() {
  color: var(--stock-screener-header-color);
  font-family: $trebuchet-font;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
