/*
  This file should keep and link all global styles for application.
*/

/*
  Import exist custom styles first:
*/
@use '@angular/material' as mat;
@import './syncfusion';
@import './values';
@import './collors';
@import './images';

@import './mixins/fonts';
@import './mixins/images';
@import './mixins/placeholders';

@import './custom-material-styles';

@import './components/trading-log-quotes-modal';
@import './components/font.scss';
@import './components/tooltip.scss';
@import './components/header.scss';
@import './components/demo-pages.scss';
@import './components/symbol-details-panel.scss';
@import './components/textarea-autoresize.scss';
@import './components/symbol-scanner-list-v2';
@import './components/video-hub-page.scss';
@import './components/combined-calendar';
@import './charts';
@import './components/skeleton.scss';
@import './components/links-organiser.scss';

:root {
  --close-button-cross-icon-url: url('/assets/img/newImg/Close-p.svg');
  --news-icon-url: url('/assets/img/Icons/news.png');
  --no-search-result-tl-trades-img-url: url('/assets/img/no-search-results/tl-trades-light.svg');
  --no-search-results-tl-dashboard-img-url: url('/assets/img/no-search-results/tl-dashboard-light.svg');
  --watchlist-icon-active-url: url('/assets/img/Icons/star-active.svg');
  --watchlist-icon-disabled-url: url('/assets/img/Icons/star-disabled.svg');
  --earnings-calendar-nodata-img: url('/assets/img/earnings-nodata-img.svg ');
  --global-font-family: #{$trebuchet-font};
  --no-links-bg-image: url('/assets/img/no-links-added/no-links-img-light.svg');
  --company-logo-placeholder: url('/assets/img/earnings-analysis/company-placeholder-light.svg');
  --no-data-stocks-dropdown: url('/assets/img/wheel/calculator/no-data-stocks-dropdown/no-data-light.svg');
  --wheel-scanner-no-stocks: url('/assets/img/wheel/scanner/no-stocks.svg');
  --no-import-data-bg-image: url('/assets/img/trading-panel/no-import-data-img-light.svg');

  --global-mat-btn-border-radius: 4px;
  --mat-ripple-btn-opacity: 0.02;
  --mm-trades-transparency-icon: url('/assets/img/mm-trades/transparency-icon-light.png');
  --mm-trades-yellow-icon: url('/assets/img/mm-trades/yellow-icon-light.png');
  --trade-zero-logo: url('/assets/img/trading-panel/trade-zero.png');
  --trade-zero-small-logo: url('/assets/img/trading-panel/trade-zero-small-logo-light.png');
}

body.black-theme {
  --close-button-cross-icon-url: url('/assets/img/newImg/Close-p-white.svg');
  --news-icon-url: url('/assets/img/Icons/news-dark.png');
  --no-search-result-tl-trades-img-url: url('/assets/img/no-search-results/tl-trades-dark.svg');
  --no-search-results-tl-dashboard-img-url: url('/assets/img/no-search-results/tl-dashboard-dark.svg');
  --earnings-calendar-nodata-img: url('/assets/img/earnings-nodata-img-dark-mode.svg ');
  --no-links-bg-image: url('/assets/img/no-links-added/no-links-img-dark.svg');
  --company-logo-placeholder: url('/assets/img/earnings-analysis/company-placeholder-dark.svg');
  --no-data-stocks-dropdown: url('/assets/img/wheel/calculator/no-data-stocks-dropdown/no-data-dark.svg');
  --wheel-scanner-no-stocks: url('/assets/img/wheel/scanner/no-stocks-dark.svg');
  --no-import-data-bg-image: url('/assets/img/trading-panel/no-import-data-img-dark.svg');

  --mm-trades-transparency-icon: url('/assets/img/mm-trades/transparency-icon-dark.png');
  --mm-trades-yellow-icon: url('/assets/img/mm-trades/yellow-icon-dark.png');
  --trade-zero-logo: url('/assets/img/trading-panel/trade-zero-dark.png');
  --trade-zero-small-logo: url('/assets/img/trading-panel/trade-zero-small-logo-dark.png');
}

/*
  Then add additional global styles if necessary:
*/
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Angular-Material.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.pxo-app {
  /*
    Please use .pxo-app class as parent class for all custom global styles
  */
  &.mat-typography {
    :is(.admin-panel, .admin-config) :is(h1, h2, h3, h4, h5, h6, p, .header) {
      font-family: 'Trebuchet MS', roboto, ubuntu, sans-serif;
    }
  }

  input {
    box-sizing: border-box;
  }

  .pxo-main-div {
    height: 100%;
    width: 100%;
  }

  .page-title {
    @include font-bold(18px, 24px, normal, var(--main-font-color));
  }

  .news-button {
    cursor: pointer;

    .news-icon {
      background: no-repeat center var(--news-icon-url);
      background-size: contain;
      width: 32px;
      height: 32px;
    }
  }

  .watchlist-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid var(--asset-correlation-table-border-color);
    cursor: pointer;

    .watchlist-icon {
      background: no-repeat center var(--watchlist-icon-active-url);
      background-size: contain;
      width: 26px;
      height: 26px;
    }

    .watchlist-icon.disabled {
      background: no-repeat center var(--watchlist-icon-disabled-url);
    }
  }

  .data-for-print {
    visibility: hidden;
    overflow: visible;

    &.trading-plan {
      p {
        min-height: 12px;
      }

      ul {
        list-style-position: inside;
        padding-inline-start: 16px;

        li {
          list-style-type: disc;

          p {
            display: inline;
          }
        }
      }

      ol {
        list-style-position: inside;
        padding-inline-start: 16px;

        li {
          list-style-type: decimal;

          p {
            display: inline;
          }
        }
      }
    }

    #print-chart {
      &,
      canvas {
        max-width: 890px;
        min-height: 550px;
        max-height: 999px;
      }
    }
  }

  .navbar {
    .menu-modal-outer {
      .version-text {
        color: var(--light-color);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        padding: 8px 16px;
      }
    }
  }

  .toggle-arrow-icon {
    transition: transform 0.35s ease-in-out;

    &.open {
      transform: rotateX(180deg);
    }
  }

  .print-section-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    margin: 15px 0 15px 0 !important;
  }

  .page-break {
    &,
    & .report-con-tbl {
      -moz-column-break-after: page;
      break-after: page;
    }
  }

  .change-password-field-text {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: var(--settings-section-title-color);
    margin-bottom: 20px;
  }

  .side-menu-close {
    display: block;
    height: 16px;
    margin-left: auto;
    cursor: pointer;

    mat-icon {
      display: block;
      width: 16px;
      height: 16px;
      pointer-events: none;
      color: var(--economic-calendar-close-icon-color);
      font-size: 16px;
      line-height: 16px;
    }
  }

  .profiles:hover {
    background: transparent;
  }

  .profiles .s2 {
    font-weight: normal;
  }

  .profiles .s2 span:first-child {
    margin-right: 6px;
  }

  .backBlack {
    background-color: var(--menu-background-shadow);
  }

  .menu-modals {
    width: 288px;
    display: flex !important;
    align-items: flex-start;
    background: var(--white-bg);
    border-radius: 0;
    border: 0;
    position: fixed;
    top: 0 !important;
    left: 0;
    bottom: 0;
    z-index: 999;
    height: 100dvh;
    box-shadow: 0 100px 0 0 var(--white-bg) !important;

    @media screen and (min-width: $mobile-max-width-trigger) {
      width: 690px;
    }

    @media screen and (max-width: $mobile-max-width-trigger) {
      padding: 0;
      min-height: 100dvh;
      height: 100dvh;
      max-height: 100dvh;
      overflow: hidden;
    }
  }

  .menu-modals::before {
    display: none;
  }

  .dark-theme-toogle h4,
  .profile-menu-item a,
  .help-menu-item a {
    font-weight: normal;
  }

  .profile-menu-item a mat-icon {
    margin-right: 8px;
  }

  .help-footer ul li:last-child {
    margin-bottom: 180px;
  }

  .e-tab .e-tab-header {
    .e-toolbar-item.e-active .e-tab-text {
      color: var(--spreadsheet-main-color) !important;
    }

    .e-indicator {
      background: var(--spreadsheet-main-color) !important;
    }
  }

  @-webkit-keyframes slowShow {
    0% {
      opacity: 0;
      display: none;
    }

    100% {
      opacity: 1;
      display: block;
    }
  }

  @keyframes slowShow {
    0% {
      opacity: 0;
      display: none;
    }

    100% {
      opacity: 1;
      display: block;
    }
  }

  @-webkit-keyframes leftToRight {
    0% {
      opacity: 0;
      left: -100%;
      display: none;
    }

    100% {
      opacity: 1;
      left: 0px;
      display: block;
    }
  }

  @-webkit-keyframes leftToRight {
    0% {
      opacity: 0;
      left: -100%;
      display: none;
    }

    100% {
      opacity: 1;
      left: 0px;
      display: block;
    }
  }

  @keyframes leftToRight {
    0% {
      opacity: 0;
      left: -100%;
      display: none;
    }

    100% {
      opacity: 1;
      left: 0px;
      display: block;
    }
  }

  @-webkit-keyframes rightToLeft {
    100% {
      opacity: 0;
      left: -100%;
      display: none;
    }
  }

  @-webkit-keyframes rightToLeft {
    100% {
      opacity: 0;
      left: -100%;
      display: none;
    }
  }

  @keyframes rightToLeft {
    100% {
      opacity: 0;
      left: -100%;
      display: none;
    }
  }

  /*
    change view depends on screen width helpers
  */

  .hide-always {
    display: none;
  }

  @media screen and (max-width: $mobile-max-width-trigger) {
    .hide-on-max-767 {
      display: none;
    }
  }

  @media screen and (min-width: $tablet-min-width-trigger) {
    .hide-on-min-767 {
      display: none;
    }
  }

  @media screen and (max-width: $tablet-991px-width-trigger) {
    .hide-on-max-991 {
      display: none !important;
    }
  }

  @media screen and (min-width: $tablet-992px-width-trigger) {
    .hide-on-min-991 {
      display: none;
    }
  }

  @media screen and (max-width: $tablet-max-width-trigger) and (min-width: $tablet-992px-width-trigger) {
    .hide-on-mid-to-max-tablet {
      display: none;
    }
  }

  @media screen and (max-width: $mobile-max-width-trigger) {
    .hide-on-mobile {
      display: none;
    }
  }

  @media screen and (max-width: $mobile-min-575px-width-trigger) {
    .hide-on-horizontal-mobile {
      display: block;
    }
  }

  @media screen and (min-width: $tablet-min-width-trigger) {
    .show-on-mobile {
      display: none;
    }
  }

  @media screen and (max-width: $tablet-max-width-trigger) {
    .hide-on-tablet-and-less {
      display: none;
    }
  }

  @media screen and (min-width: $desktop-min-width-trigger) {
    .show-on-tablet-and-less {
      display: none;
    }
  }

  @media screen and (max-width: $tablet-max-width-trigger) and (min-width: $tablet-min-width-trigger) {
    .hide-on-tablet {
      display: none;
    }
  }

  /*
     ==========================================
     */

  .limit-text {
    max-width: 250px;
    text-overflow: ellipsis;
  }

  div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
  }

  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  /*
    overwrite styles for none-icon (smiley-menu component) in case of usage in scanner and watchlist
  */

  .scanner-table,
  .wheel-conservative-aggressive-table {
    tbody {
      tr {
        // normal state - transparent
        .scanner-flag:not(.symbol-flag-menu) {
          .none-icon {
            --symbol-flag-outer-stroke-color: transparent;
            --symbol-flag-stroke-color: transparent;
            color: transparent !important;
          }
        }

        &:hover {
          // visible only on hover
          .scanner-flag:not(.symbol-flag-menu) {
            .none-icon {
              --symbol-flag-outer-stroke-color: var(--none-smile-icon-color) !important;
              --symbol-flag-stroke-color: var(--none-smile-icon-color) !important;
              color: var(--none-smile-icon-color) !important;
            }
          }
        }
      }
    }
  }

  .grouped-watchlist-root {
    .list {
      .item {
        // normal state - transparent
        .scanner-flag:not(.symbol-flag-menu) {
          .none-icon {
            --symbol-flag-outer-stroke-color: transparent;
            --symbol-flag-stroke-color: transparent;
            color: transparent !important;
          }
        }

        &:hover {
          // visible only on hover
          .scanner-flag:not(.symbol-flag-menu) {
            .none-icon {
              --symbol-flag-outer-stroke-color: var(--none-smile-icon-color) !important;
              --symbol-flag-stroke-color: var(--none-smile-icon-color) !important;
              color: var(--none-smile-icon-color) !important;
            }
          }
        }
      }
    }
  }

  .votes-hint-container {
    .title {
      margin-bottom: 7px;
      font-size: 14px;
      line-height: 16px;
      color: var(--white-color);
    }
  }

  .conservative-table tr:hover td .symbol-flag-statistic-widget.root .flag-item .count-badge:not(.selected),
  .aggresive-table tr:hover td .symbol-flag-statistic-widget.root .flag-item .count-badge:not(.selected) {
    background-color: var(--light-bg-1);
  }

  .data-window-content.shadow-top,
  .data-window-container.shadow-top,
  .data-window.shadow-top {
    .extended-data-window .header-section {
      box-shadow: var(--table-sticky-th-box-shadow);

      &::after {
        @media screen and (min-width: $tablet-992px-width-trigger) {
          right: 2px;
        }
      }
    }
  }

  .data-window.shadow-bottom .extended-data-window .header-section::after,
  .data-window-content.shadow-bottom .extended-data-window .header-section::after {
    @media screen and (min-width: $tablet-992px-width-trigger) {
      right: 2px;
    }
  }
}

@-moz-document url-prefix() {
  .data-window-content.shadow-top .extended-data-window .header-section::after,
  .data-window-content.shadow-bottom .extended-data-window .header-section::after {
    right: -10px !important;
  }

  .portfolio {
    .multi-select {
      .multi-select-wrap {
        margin: 0;
      }
    }
  }
}

.text-success {
  color: var(--success-color);
}

.text-error {
  color: var(--danger-color);
}

.mat-mdc-form-field-error {
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
  color: var(--danger-color);
}

.trading-panel-order-wrapper {
  .error {
    @include apply-oco-order-error-font();
    @extend .text-error;
    margin-top: 8px;
    display: block;
  }
}

.metric-details-modal-trigger {
  @extend %metric-details-modal-trigger-style;
}

.cdk-drag-preview.user-link-wrapper {
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: var(--white-bg);
  box-shadow: 0 0 20px 0 rgba(black, 0.04);

  @media screen and (max-width: $mobile-max-width-trigger) {
    background-color: var(--modal-bg);
  }

  .drag-handler {
    flex-shrink: 0;
  }

  .user-link:visited,
  .user-link:hover,
  .user-link {
    color: var(--primary-color);
    background-color: var(--white-bg);

    @media screen and (max-width: $mobile-max-width-trigger) {
      background-color: var(--modal-bg);
    }
  }

  .user-link-actions {
    flex-shrink: 0;
    opacity: 0;
  }
}

.trading-panel .trading-panel-order-wrapper.pinned-panel::after {
  border-width: 1px;
}

.mat-divider {
  --mat-divider-color: var(--divider-color);
}

// indicators to display shadows in scroll-containers
.top-indicator,
.bottom-indicator {
  min-height: 1px;
}

.dividends-search-symbol .search-section {
  border: none;
}

.cons-agg {
  .split-wrapper {
    .as-split-gutter:first-of-type {
      pointer-events: none;
    }
  }
}

// import trades styles
.uploading-page {
  .tradier-activity {
    .tradier-import {
      display: flex !important;

      @media screen and (max-width: 660px) {
        flex-wrap: wrap;
      }

      .tradier-import-settings {
        margin-right: auto;
      }

      .tradier-import-actions {
        margin: 0 !important;

        @media screen and (max-width: 660px) {
          flex: 1 1 100%;
        }
      }
    }
  }

  .import-list {
    border: 1px solid var(--table-border-color);

    .virtual-list-wrapper {
      height: calc(100dvh - var(--new-header-height) - 155px);

      @media screen and (max-width: $tablet-992px-width-trigger) {
        height: calc(100dvh - var(--new-header-height) - 155px);
      }

      // custom breakpoint
      @media screen and (max-width: 660px) {
        height: calc(100dvh - var(--new-header-height) - 215px);
      }

      @media screen and (max-width: $mobile-min-500px-width-trigger) {
        height: calc(100dvh - var(--new-header-height) - 255px);
      }
    }
  }
}

.right-side-content {
  flex: 1;
}

.rocky-icon-wrapper {
  width: 20px;
  height: 100%;
  position: absolute;
  right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .mat-icon.rocky-icon {
    @include apply-icon-size(20px);

    @media screen and (hover: hover) {
      opacity: 0;
      pointer-events: none;
    }
  }

  @media screen and (max-width: $tablet-991px-width-trigger) {
    display: none;
  }
}

.mat-icon.rocky-icon.always-visible {
  opacity: 1 !important;
  pointer-events: all !important;
}

.terms-conditions-detail::-webkit-scrollbar-track,
.scanner-table::-webkit-scrollbar-track,
.watchlist-table::-webkit-scrollbar-track,
.scroll-data::-webkit-scrollbar-track,
.conservative-table::-webkit-scrollbar-track,
.aggresive-table::-webkit-scrollbar-track,
.mat-dialog-content::-webkit-scrollbar-track,
.trading-report::-webkit-scrollbar-track,
.report-con-tbl::-webkit-scrollbar-track,
.modals::-webkit-scrollbar-track,
.powerx-main .right-side-content::-webkit-scrollbar-track,
#tradReportHtml::-webkit-scrollbar-track,
.mat-autocomplete-panel::-webkit-scrollbar-track,
.search-first-tbl::-webkit-scrollbar-track,
.search-second-tbl::-webkit-scrollbar-track {
  background: transparent;
}

.terms-conditions-detail::-webkit-scrollbar-thumb,
.scanner-table::-webkit-scrollbar-thumb,
.watchlist-table::-webkit-scrollbar-thumb,
.scroll-data::-webkit-scrollbar-thumb,
.conservative-table::-webkit-scrollbar-thumb,
.aggresive-table::-webkit-scrollbar-thumb,
.mat-dialog-content::-webkit-scrollbar-thumb,
.trading-report::-webkit-scrollbar-thumb,
.report-con-tbl::-webkit-scrollbar-thumb,
.modals::-webkit-scrollbar-thumb,
.powerx-main .right-side-content::-webkit-scrollbar-thumb,
#tradReportHtml::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar-thumb,
.search-first-tbl::-webkit-scrollbar-thumb,
.search-second-tbl::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 5px var(--box-shadow-6) !important;
}

@-moz-document url-prefix() {
  .terms-conditions-detail,
  .terms-conditions-detail:hover,
  .scanner-table,
  .scanner-table:hover,
  .watchlist-table,
  .watchlist-table:hover,
  .scroll-data,
  .scroll-data:hover,
  .conservative-table,
  .conservative-table:hover,
  .aggresive-table,
  .aggresive-table:hover,
  .mat-dialog-content,
  .mat-dialog-content:hover,
  .trading-report,
  .trading-report:hover,
  .report-con-tbl,
  .report-con-tbl:hover,
  .modals,
  .modals:hover,
  .powerx-main .right-side-content,
  .powerx-main .right-side-content:hover,
  #tradReportHtml,
  #tradReportHtml:hover,
  .mat-autocomplete-panel,
  .mat-autocomplete-panel:hover,
  .search-first-tbl,
  .search-first-tbl:hover,
  .search-second-tbl,
  .search-second-tbl:hover {
    scrollbar-color: var(--box-shadow-6) transparent !important;
    scrollbar-width: thin !important;
  }
}

.short-selling-stocks-layout .short-selling-stocks-chart .app-chart-container {
  @media screen and (min-width: $tablet-992px-width-trigger) {
    height: 100%;
  }
}

.cons-agg .mat-mdc-tab-body-wrapper {
  height: calc(100% - 31px);
}

.wtf-scanner-virtual-scroll {
  .wheel-conservative-aggressive-table.wtf-scanner-table,
  .scanner-symbol-table {
    thead {
      opacity: 0;
      height: 0 !important;
      border: 0 none;
    }

    thead * {
      margin: 0;
      padding: 0 !important;
      border: 0 none;
      height: 0 !important;
    }
  }
}

.agreement-action-btns {
  .mdc-button.i-agree-btn {
    margin-right: 24px;
    width: 96px;

    .mdc-button__label {
      white-space: nowrap;
    }

    &:disabled {
      background: #77c3ff;
      cursor: not-allowed;
    }
  }

  .mdc-button.mat-mdc-outlined-button.cancel-btn {
    --mdc-outlined-button-label-text-color: var(--light-border-color-2) !important;
    width: 96px;
    font-size: 14px;
    line-height: 36px;
  }
}

.mm-trades {
  table.mm-trades-table {
    td:has(.cell .cell-textarea) {
      border: none;
      outline: 1px solid var(--primary-color);
      outline-offset: -1px;
    }
  }

  .sheet-option-item {
    .mdc-button__label {
      @media screen and (max-width: $mobile-min-sm-width-trigger) {
        display: none;
      }
    }
  }
}

.short-selling-stocks-layout.system-1 {
  .scanner-symbols-list-virtual-scroll.cdk-virtual-scrollable {
    min-height: calc(230px - 28px);
    height: calc(100% - 28px);
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .short-selling-stocks-scanner-watchlist-panel {
    .flag-statistics-widget {
      @media screen and (max-width: $mobile-min-500px-width-trigger) {
        display: none;
      }
    }
  }
}

.cdk-virtual-scrollable {
  outline: none !important;
}

.expected-wrapper {
  &:hover .rocky-icon-wrapper .mat-icon.rocky-icon {
    opacity: 1 !important;
    pointer-events: all;
  }
}

.extended-data-window {
  .second-row {
    &:hover .rocky-icon-wrapper .mat-icon.rocky-icon {
      opacity: 1 !important;
      pointer-events: all;
    }
  }
}
