@import '../../scss/values';
@import '../../scss/mixins/fonts';

.alert {
    padding: 18px 20px 12px 54px;
    font-size: 12px;
    margin-bottom: 40px;
    border-radius: 5px;
    position: relative;

    p {
        margin-bottom: 6px !important;
    }

    // TODO: put colors and urls to the --variables
    &-success {
        background: var(--alert-box-success-bg-color);
        background-image: url(/assets/img/alert-success.svg);
        background-repeat: no-repeat;
        background-position: 17px 18px;
    }

    &-danger {
        background: var(--alert-box-danger-bg-color);
        background-image: url(/assets/img/alert-danger.svg);
        background-repeat: no-repeat;
        background-position: 17px 18px;
    }

    &-info {
        color: var(--main-font-color);
        background: var(--alert-box-info-bg-color);
        border-radius: 5px;
        border: 1px solid var(--alert-box-info-bg-color);

        .mat-icon {
            position: absolute;
            top: 17px;
            left: 18px;
            color: var(--primary-color);
        }

        a {
            text-decoration: underline;
            color: var(--primary-color);
        }
    }

    &.tradier-custom-info-box {
        width: min(630px, 100%);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.14px;
    }
}

.warning-alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 11px;

    &.label-alert,
    &.lable-alert {
        padding: 13px;
        border-radius: 5px;
        border: 1px solid var(--table-border-color);
        background-color: transparent;

        &.warn-bg {
            border-color: rgba(247, 104, 1, 0.1);
            background: rgba(247, 140, 1, 0.05);
        }

        &.content {
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content
        }
    }

    .message {
        @include apply-alert-font();
        color: var(--light-color);

        &.primary {
            color: var(--primary-color);
        }

        &.danger {
            color: var(--danger-color);
        }

        &.succsesfull {
            color: var(--success-color);
        }
    }

    &.default-alert {
        gap: 7px;

        .message {
            @include apply-default-alert-font();
            color: var(--main-font-color);
        }
    }
}
