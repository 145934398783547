.pxo-form {

  .mat-form-field[appearance='pxo-form-field'] {
    width: 100%;

    &.mat-mdc-form-field-has-label {
      padding-top: calc(1em + #{$form-label-indent});
    }

    .mat-mdc-form-field-flex {
      border: $dark-border;
      align-items: center;
      min-height: calc(2em + 2px);

      @media screen and (max-width: $mobile-max-width-trigger) {
        min-height: calc(2.5em + 2px);
      }

      .mat-mdc-form-field-infix {
        padding-left: 0.5em;
        border-top: 0;

        input {
          line-height: normal;
          color: var(--input-color);

          &.mat-input-element {
            margin-top: 0;
          }

          &::placeholder {
            color: var(--input-color);
            opacity: $placeholder-opacity;
          }
        }
      }

      .mat-mdc-form-field-suffix {
        border-left: $dark-border;
      }

      .mat-mdc-form-field-prefix {
        border-right: $dark-border;
      }

      .mat-mdc-form-field-suffix,
      .mat-mdc-form-field-prefix {
        .mat-icon-button {
          width: 2em;
          height: 2em;
          color: var(--input-color);

          @media screen and (max-width: $mobile-max-width-trigger) {
            width: 2.5em;
            height: 2.5em;
          }
        }
      }
    }

    .mat-mdc-select-trigger {
      display: flex;
      height: 14px !important; // !important - is only to overvrite value in styles.scss. !important can be removed once style.css is cleared.
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      .mat-mdc-select-value {
        max-width: unset;

        .mat-mdc-select-value-text span {
          color: var(--input-color);
          @include apply-mat-select-selected-font();
        }

        .mat-mdc-select-placeholder {
          color: var(--light-color) !important; // !important - is only to overvrite value in styles.scss. !important can be removed once style.css is cleared.
          opacity: $placeholder-opacity;
          -webkit-text-fill-color: inherit;
        }
      }

      .mat-mdc-select-arrow-wrapper {
        min-width: 2em;
        @media screen and (max-width: $mobile-max-width-trigger) {
          min-width: 2.5em;
        }

        .mat-mdc-select-arrow {
          margin: 0 auto;
          color: inherit;
        }
      }
    }

    .mat-mdc-form-field-label-wrapper {
      top: calc(-1em - #{$form-label-indent});
      padding-top: calc(1em + #{$form-label-indent});

      // put font here

      .mat-mdc-form-field-label {
        width: unset;
        transform: none;
        top: 0;
        color: var(--light-color);
      }
    }

    .mat-mdc-form-field-hide-placeholder .mat-mdc-select-placeholder {
      -webkit-text-fill-color: inherit;
      color: var(--light-color);

    }

    .mat-checkbox {
      .mat-checkbox-layout {
        margin-bottom: 0;
      }

      .mat-checkbox-label {
        opacity: 0.5;
      }
    }

    .mat-checkbox.inline {
      .mat-checkbox-layout {
        margin-bottom: 0;
      }
    }

    .mat-checkbox.mat-checkbox-checked .mat-checkbox-label {
      opacity: 1;
    }

    .mat-form-field-subscript-wrapper {
      font-size: 11px;

      .mat-error {
        margin-top: 0;
        font-size: unset;
        line-height: unset;
      }
    }
  }

  .mat-mdc-form-field.ac-form-filed {
    --mat-form-field-container-height: 48px;
    display: flex;
    border: 1px solid var(--chip-container-field-border-color);
    border-radius: $control-border-radius;

    .mat-mdc-text-field-wrapper {
      padding: 5px 15px;

      .mat-mdc-form-field-focus-overlay {
        --mat-form-field-hover-state-layer-opacity: 0;
        opacity: 0;
      }

      .mdc-text-field--outlined {
        --mdc-outlined-text-field-outline-width: 1px;
        --mdc-outlined-text-field-focus-outline-width: 1px;
        --mdc-outlined-text-field-container-shape: 5px;
      }
    }

    .mat-mdc-form-field-infix {
      padding-block: 5px;
      display: flex;
      align-items: center;
    }

    input.mat-input-element {

      &::placeholder {
        color: var(--mat-form-field-placeholder-color);
        -webkit-text-fill-color: var(--mat-form-field-placeholder-color);
        transition: none;
        opacity: 1;
      }
    }

    input.mat-chip-input {
      height: $standard-chip-height;

      &.no-input {
        height: 0;
      }
    }

    .mat-mdc-form-field-label-wrapper {
      display: none;
    }

    .mat-mdc-form-field-underline {
      display: none;
    }

    .mat-mdc-form-field-wrapper {
      padding: 0;
    }

    .mat-mdc-form-field-infix {
      padding: 0;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
