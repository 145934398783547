.mat-mdc-chip-listbox {
  flex-grow: 1;
}

.mat-mdc-chip-set {
  flex-grow: 1;

  .mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-container-height: 40px;
    --mdc-chip-container-shape-radius: 5px;
    --mdc-chip-label-text-line-height: 20px;
    --mdc-chip-label-text-size: 14px;
    --mdc-chip-label-text-tracking: normal;
    --mdc-chip-label-text-weight: 700;
    --mdc-chip-label-text-color: var(--font-dark-color);
    --mdc-chip-elevated-container-color: var(--chip-bg-color);

    .mat-mdc-chip-action-label {
      display: flex;
      align-items: center;
    }
  }
}

.ac-filter {
  .mat-mdc-chip-set + .btn-clear {
    --mdc-icon-button-state-layer-size: 40px;
    --mdc-icon-button-icon-size: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: var(--chip-container-field-clear-button-bg-color);
    border: none;
    border-radius: $control-border-radius;
    cursor: pointer;

    &:hover {
      background-color: var(--chip-container-field-clear-button-hover-bg-color);
    }
  }
}

.ac-form-filed {
  .mat-mdc-chip.cdk-focused .mat-mdc-chip-focus-overlay {
    --mdc-chip-focus-state-layer-opacity: 0;
    opacity: var(--mdc-chip-focus-state-layer-opacity);
  }

  .mat-mdc-chip.cdk-focused:hover .mat-mdc-chip-focus-overlay {
    --mdc-chip-focus-state-layer-opacity: 0;
    --mdc-chip-hover-state-layer-opacity: .12;
    opacity: var(--mdc-chip-hover-state-layer-opacity);
  }

  .mat-mdc-chip:hover .mat-mdc-chip-focus-overlay {
    --mdc-chip-hover-state-layer-opacity: .12;
  }

  .mat-mdc-chip-remove {
    opacity: 1;
  }
}
