@import '../src/scss/values';

@font-face {
  font-family: 'Yantramanav';
  src:
    url('./assets/fonts/Yantramanav-Regular.ttf') format('truetype'),
    url('./assets/fonts/Yantramanav-Bold.ttf') format('truetype');
}

:root {
  --white-bg: #ffffff;
  --yellow-bg: #ff9500;
  --grey-bg: #a5a5a5;
  --red-bg: #ef534f;
  --green-bg: #33a69a;
  --red-light-bg: #fadcdc;
  --green-light-bg: #cceae7;
  --drop-down-bg: #ffffff;
  --modal-bg: #ffffff;
  --light-bg-1: #f0f3fa;
  --light-bg-2: #d5d5d5; //terms condition detail
  --light-bg-3: #f9fafb;
  --light-bg-4: #f7f7f7;
  --light-bg-5: #f2f2f2;
  --light-bg-6: #d8d8d8;
  --light-bg-7: #f7f9fc;
  --light-bg-8: rgba(0, 122, 255, 0.1);
  --light-bg-9: rgba(27, 30, 41, 0.06);
  --light-bg-10: #dededf; //removed wheel data-window
  --light-bg-11: #fafafa;
  --light-bg-12: #dfe3e8; //cron chip
  --light-bg-13: #e0e3eb;
  --light-bg-14: #787b86;
  --light-bg-15: #757575; //checkbox frame
  --light-bg-16: #e6e6e6;
  --light-bg-17: rgba(29, 137, 228, 0.1);
  --dark-bg-1: #131722;
  --dark-bg-2: #767171;
  --dark-bg-3: #d9d9d9;
  --dark-bg-4: #2a2e39;
  --dark-bg-5: #d1d4dc;
  --dark-bg-6: rgba(232, 234, 240, 1);
  --input-bg-1: rgba(27, 30, 41, 0.06);
  --selected-item-bg: #dbdbdb;
  --green-dark-bg: #00a97f;
  --light-green-bg: #d6edeb;
  --light-red-bg: #fff3f3;
  --light-yellow-bg: #fff7ee;
  --light-blue-bg: rgba(33, 150, 243, 0.1);
  --dark-yellow-bg: rgba(250, 192, 2, 0.25);
  --toogle-bg: #e0e3eb;
  --login-bg: #e0e3eb;

  --btn-primary-color: #2196f3;
  --primary-color: #2196f3;
  --all-theme-white: #ffffff;

  --dark-svg: #131722;
  --white-svg: #ffffff;
  --light-svg: #787b86;
  --red-svg: #ef534f;

  --light-color: #787b86;
  --light-color-2: #757575;
  --light-color-3: #a2a6af;
  --light-color-4: #7c7f8a;
  --light-color-5: #787b86;
  --border-btn-color: #7c7f8a;
  --red-color: #ef534f;
  --light-red-color: rgba(239, 83, 80, 0.25);
  --green-color: #33a69a;
  --light-green-color: rgba(38, 166, 154, 0.25);
  --font-dark-color: #131722;
  --font-dark-color-2: #4a4a4a;
  --font-dark-color-3: #1b1e29;
  --font-dark-color-4: #535353;
  --font-dark-color-5: #767171;
  --font-dark-color-6: #2a2e39;
  --black-color: #000000;
  --white-color: #ffffff;
  --long-yellow-color: #f2a200;
  --input-color: #535353;
  --no-data-msg: #131722;
  --no-data-msg-2: #787b86;
  --blur-color: #787b86;

  --border-color-1: #131722;
  --border-white: #ffffff;
  --red-border-color: #ef534f;
  --light-border-color: #e5e5e5;
  --light-border-color-1: #787b86;
  --light-border-color-2: #757575;
  --light-border-color-3: #f0f3fa;
  --light-border-color-4: #d5d5d5;
  --light-border-color-5: #f2f2f2;
  --light-border-color-6: #c4cdd5;
  --light-border-color-7: #e0e3eb;
  --dark-border-color: #e0e3eb;
  --blue-border-color: #2196f3;

  --red-hover-bg: #e6514d;
  --primary-hover-color: #2090ea;
  --light-hover-color: #f5f5f5;
  --light-hover-color-2: #eeeeee;
  --light-hover-color-3: #f9fbfd;
  --light-hover-color-4: #bfbfbf;
  --light-hover-color-5: rgba(29, 137, 228, 0.2);

  --box-shadow-1: #ffffff;
  --box-shadow-2: rgba(0, 0, 0, 0.12);
  --box-shadow-3: rgba(107, 121, 136, 0.4);
  --box-shadow-4: rgba(0, 0, 0, 0.08);
  --box-shadow-5: rgba(22, 29, 37, 0.05);
  --box-shadow-6: #d1d4dc;
  --box-shadow-7: rgba(27, 30, 41, 0.06);

  --warning-bg-color: rgba(247, 140, 1, 0.05);
  --warning-border-color: rgba(247, 140, 1, 0.1);

  --info-bg-color: #f5f8fd;
  --info-text-color: #787b86;
  --info-border-color: #f5f8fd;

  --info-orange-bg-color: rgba(247, 104, 1, 0.1);
  --light-violet-bg: rgba(161, 0, 237, 0.1);

  --filter-hint-color: #afafc0;

  --light-img: block;
  --dark-img: none;
}

body.black-theme {
  --white-bg: #131722;
  --yellow-bg: #ff9500;
  --grey-bg: rgba(255, 255, 255, 0.21);
  --red-bg: #ef534f;
  --green-bg: #33a69a;
  --red-light-bg: rgb(239, 83, 79, 0.2);
  --green-light-bg: rgba(51, 166, 154, 0.25);
  --drop-down-bg: #1e222d;
  --modal-bg: #1e222d;
  --light-bg-1: #2f3241;
  --light-bg-3: #1e222d;
  --light-bg-4: #252833;
  --light-bg-5: #262931;
  --light-bg-6: rgba(255, 255, 255, 0.2);
  --light-bg-7: rgba(255, 255, 255, 0.05);
  --light-bg-8: rgba(255, 255, 255, 0.1);
  --light-bg-9: #262b3e;
  --light-bg-11: #1f232c;
  --light-bg-13: rgba(255, 255, 255, 0.15);
  --light-bg-14: #474a54;
  --light-bg-16: rgba(255, 255, 255, 0.17);
  --light-bg-17: rgba(33, 150, 243, 0.3);
  --dark-bg-1: rgba(255, 255, 255, 0.19);
  --dark-bg-2: #767171;
  --dark-bg-3: rgba(255, 255, 255, 0.14);
  --dark-bg-4: #50535e;
  --dark-bg-5: #50535e;
  --dark-bg-6: rgba(255, 255, 255, 0.1);
  --input-bg-1: #2a2e39;
  --selected-item-bg: rgba(255, 255, 255, 0.15);
  --green-dark-bg: #00a97f;
  --light-red-color: rgba(239, 83, 80, 0.6);
  --light-green-color: rgba(38, 166, 154, 0.6);
  --light-green-bg: rgb(51, 166, 154, 0.2);
  --light-red-bg: rgb(239, 83, 79, 0.2);
  --light-yellow-bg: rgb(255, 149, 0, 0.2);
  --dark-yellow-bg: rgba(250, 192, 2, 0.25);
  --toogle-bg: rgba(255, 255, 255, 0.3);
  --login-bg: #2a2e39;

  --btn-primary-color: #2196f3;
  --primary-color: #2196f3;
  --all-theme-white: #ffffff;

  --dark-svg: #c5cbce;
  --light-svg: #787b86;
  --red-svg: #ef534f;

  --light-color: #b2b5be;
  --light-color-2: rgba(255, 255, 255, 0.6);
  --light-color-3: #a2a6af;
  --light-color-4: rgba(255, 255, 255, 0.5);
  --light-color-5: #afafc0;
  --border-btn-color: #c5cbce;
  --red-color: #ef534f;
  --green-color: #33a69a;
  --font-dark-color: #c5cbce;
  --font-dark-color-2: rgba(255, 255, 255, 0.75);
  --font-dark-color-3: rgba(255, 255, 255, 0.8);
  --font-dark-color-4: rgba(255, 255, 255, 0.7);
  --black-color: #ffffff;
  --long-yellow-color: #f2a200;
  --input-color: rgba(255, 255, 255, 0.7);
  --no-data-msg: #8a8a8a;
  --no-data-msg-2: #787b86;
  --blur-color: rgba(255, 255, 255, 0.5);

  --border-color-1: #ffffff;
  --border-white: #131722;
  --red-border-color: #ef534f;
  --light-border-color: #434651;
  --light-border-color-3: #2a2e39;
  --light-border-color-4: #434651;
  --light-border-color-5: #262931;
  --light-border-color-7: #2f323c;
  --dark-border-color: #434651;

  --red-hover-bg: #e6514d;
  --primary-hover-color: #2090ea;
  --light-hover-color: #2a2e39;
  --light-hover-color-2: #2a2e39;
  --light-hover-color-3: rgba(255, 255, 255, 0.05);
  --light-hover-color-4: rgba(255, 255, 255, 0.22);
  --light-hover-color-5: rgba(33, 150, 243, 0.4);

  --box-shadow-1: #131722;
  --box-shadow-2: rgba(0, 0, 0, 0.4);
  --box-shadow-4: rgba(255, 255, 255, 0.02);
  --box-shadow-6: #50535e;

  --warning-bg-color: rgba(247, 140, 1, 0.2);
  --warning-border-color: rgba(247, 140, 1, 0.3);

  --info-bg-color: #1d2d49;
  --info-text-color: #b4c8e7;
  --info-border-color: #3a5686;

  --info-orange-bg-color: rgba(247, 104, 1, 0.1);
  --light-violet-bg: rgba(161, 0, 237, 0.4);

  --light-img: none;
  --dark-img: block;
}

body {
  background: var(--white-bg);
  overflow: hidden;
}

body,
html,
input,
textarea,
table,
tr,
th,
td {
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
}

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scroll-behavior: smooth;
}

.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5,
.mat-typography p {
  margin-top: 0;
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
.s1,
.s2 {
  color: var(--font-dark-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
}

div,
span,
p,
h5,
h6,
select,
option,
input,
textarea,
a,
label,
ul,
li {
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
}

h1 {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 56px;
}

h2 {
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}

h4 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 24px;
}

.s1 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.s2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.cap {
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

a {
  text-decoration: none;
}

.links {
  color: var(--primary-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.links:hover {
  text-decoration: underline;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.mat-table {
  background: var(--white-bg);
}

.mat-button-toggle-appearance-standard {
  background: transparent;
}

.mat-menu-panel {
  background: var(--drop-down-bg);
}

.mat-select-panel {
  background: var(--drop-down-bg);
}

.mat-expansion-panel {
  background: var(--white-bg);
}

.mat-autocomplete-panel {
  background: var(--white-bg);
}

.v-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.v-center label {
  width: auto !important;
}

.v-center span {
  display: block;
  margin-right: 4px;
}

.light-theme-img {
  display: var(--light-img) !important;
}

.black-theme-img {
  display: var(--dark-img) !important;
}

.height100 {
  height: 100%;
}

.block-level {
  display: block !important;
}

.remove-scroll {
  height: 100vh !important;
  overflow: hidden !important;
}

.search-box-with-add {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/*****************************
    select and input start
*****************************/
.select {
  box-sizing: border-box;
  height: 32px;
  width: 100%;
  outline: none;
  padding: 6px 12px;
  padding-right: 22px;
  color: var(--input-color);
  font-size: 14px;
  letter-spacing: 0px;
  border: 1px solid var(--light-border-color);
  border-radius: 4px;
  background-color: transparent;
}

.select {
  background-image: url(assets/img/newImg/drop-down-blc.svg);
  background-position: calc(100% - 12px), calc(100% - 16px);
  background-size: 12px;
  background-repeat: no-repeat;
}

.input {
  box-sizing: border-box;
  height: 32px;
  border: 0;
  border-radius: 4px;
  background-color: var(--input-bg-1);
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  font-size: 14px;
  color: var(--font-dark-color);
  display: inline-block;
}

.input-group label {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 8px;
  margin-top: 0;
  display: block;
}

.input-group input {
  box-sizing: border-box;
  height: 33px;
  border: 1px solid var(--dark-border-color);
  border-radius: 0px;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0;
  display: block;
}

.select-group label {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 8px;
  margin-top: 0;
  display: block;
}

.select-group select {
  box-sizing: border-box;
  height: 33px;
  border: 1px solid var(--dark-border-color);
  border-radius: 0px;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 32px;
  outline: none;
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0;
  display: block;
  background-position: calc(100% - 13px), calc(100% - 13px);
  background-size: 8px;
  background-repeat: no-repeat;
}

/*****************************
    select and input end
*****************************/

/**************************
      Button css start
**************************/
.fill-btn {
  box-sizing: border-box;
  height: 36px;
  background-color: var(--btn-primary-color);
  color: var(--all-theme-white);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 16px !important;
  text-align: center !important;
  padding: 0 !important;
}

.fill-btn-red {
  box-sizing: border-box;
  height: 36px;
  background-color: var(--red-bg);
  color: var(--all-theme-white);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 16px !important;
  text-align: center !important;
  padding: 0 !important;
}

.fill-btn-red span {
  margin: auto;
}

.fill-btn-red:hover {
  background-color: var(--red-hover-bg);
}

.fill-btn:hover {
  background-color: var(--primary-hover-color);
}

.border-btn {
  box-sizing: border-box;
  height: 36px;
  color: var(--border-btn-color);
  border: 1px solid #bdbfc1 !important;
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px !important;
  text-align: center !important;
  padding: 0 !important;
  background: transparent !important;
}

.border-btn:hover {
  background-color: var(--light-bg-5) !important;
}

.trade-btn {
  border-radius: 4px;
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  margin-top: 8px;
  display: block;
  cursor: pointer;

  @media (max-width: 425px) {
    font-size: 12px;
  }

  a {
    color: inherit;
  }

  &.trade-btn-options {
    @media (max-width: 375px) {
      font-size: 10px;
    }
  }
}

/**************************
      Button css end
**************************/

/****************************
     Checkbox Css Start
****************************/
.mat-checkbox-frame {
  border-width: 1px !important;
  background-color: var(--white-bg) !important;
  border-color: var(--light-bg-15) !important;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 2px !important;
}

.mat-checkbox-inner-container {
  height: 14px !important;
  width: 14px !important;
  margin-left: 2px !important;
  margin-right: 9px !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--primary-color) !important;
}

.mat-checkbox-frame {
  background: var(--white-bg);
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  border: 1px solid var(--primary-color) !important;
  background: var(--primary-color) !important;
}

.mat-checkbox-checkmark-path {
  stroke: var(--white-svg) !important;
  stroke-width: 3px !important;
}

.mat-checkbox-label {
  color: var(--font-dark-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}

/****************************
      Checkbox Css End
****************************/

/******************************
     Radio Button Css Start
******************************/
.mat-radio-outer-circle {
  background: var(--white-bg);
}

.mat-radio-container {
  height: 16px !important;
  width: 16px !important;
  margin: 3px;
}

.mat-radio-label {
  width: auto !important;
}

.mat-radio-outer-circle {
  border-color: var(--light-border-color-2) !important;
  border-width: 1px !important;
  height: 16px !important;
  width: 16px !important;
}

.mat-radio-inner-circle {
  height: 16px !important;
  width: 16px !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--primary-color) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-color) !important;
}

.mat-radio-label-content {
  color: var(--font-dark-color);
  font-size: 12px;
  line-height: 24px !important;
}

/* tab index transparent css */
.mat-radio-group .mat-ripple-element {
  background-color: transparent !important;
}

/* tab index transparent css */
/******************************
     Radio Button Css End
******************************/

/**************************
      Flex style start
**************************/
.flex {
  display: flex;
}

.justify-start {
  justify-content: flex-start;
}

.align-center {
  align-items: center;
}

/**************************
      Flex style end
**************************/

/**************************
    Header css start
**************************/

.navbar .logo {
  height: 24px;
  width: auto;
  margin-right: 24px;
  display: flex;
}

.header-menu-icon {
  height: 24px;
  width: 24px;
}

.navbar .mat-button-toggle-group {
  border: 0;
  border-radius: 0;
  margin-top: 5px;
}

.navbar .mat-button-toggle-group .mat-button-toggle {
  border: 0 !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.navbar .mat-button-toggle-group .active {
  background: var(--primary-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* .navbar .mat-button-toggle-group .mat-button-toggle-checked::before,
.navbar .mat-button-toggle-group .active::before{
  position: absolute;
  content: '';
  height: 2px;
  width: 100%;
  background-color: var(--primary-color);
} */
.navbar .mat-button-toggle-group .mat-button-toggle-button {
  padding: 3.5px 0px;
  width: 100px;
  text-align: center;
}

.navbar .mat-button-toggle-group .mat-button-toggle-button .s1,
.navbar .mat-button-toggle-group .mat-button-toggle-button .cap {
  color: var(--font-dark-color);
}

.navbar .mat-button-toggle-group .mat-button-toggle-button .cap {
  display: block;
  margin-top: -4px;
}

.navbar .mat-button-toggle-group .mat-button-toggle-checked .mat-button-toggle-button .s1,
.navbar .mat-button-toggle-group .active .s1 {
  color: var(--white-color);
}

.navbar .mat-button-toggle-group .mat-button-toggle-checked .mat-button-toggle-button .cap,
.navbar .mat-button-toggle-group .active .cap {
  color: var(--white-color);
}

.navbar .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 0px;
  padding: 0 10px !important;
}

.navbar .mat-button-toggle-appearance-standard {
  background: transparent;
}

.time-machine {
  padding: 2px 2px;
  margin-left: 40px;
}

.time-header,
.time-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.time-header {
  margin-bottom: 2px;
}

.time-header span {
  color: var(--light-color);
  line-height: 14px;
  font-size: 12px;
  display: inline-block;
  padding-left: 8px;
}

.time-header svg {
  height: 14px;
  width: 14px;
  cursor: pointer;
  margin-left: 6px;
}

.time-header svg {
  fill: var(--btn-primary-color);
}

.time-section {
  cursor: pointer;
}

.time-section p {
  background: var(--red-light-bg);
  padding: 2px 8px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 10px;
  color: var(--font-dark-color);
}

.time-section img {
  height: 14px;
  width: 14px;
  display: block;
  margin-left: 4px;
}

.date-popup {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: auto;
}

.date-popup .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 320px;
  margin: 50px auto;
}

.date-popup .mat-dialog-content {
  margin: 0 !important;
  padding: 16px 24px 8px 24px !important;
  max-height: unset;
}

.date-popup .mat-calendar-header,
.date-popup .mat-calendar-content {
  padding: 0;
}

.date-popup .mat-calendar-controls {
  justify-content: space-between;
  margin: 0 0 16px 0;
}

.date-popup .mat-calendar-previous-button {
  order: 1;
}

.date-popup .mat-calendar-period-button {
  order: 2;
}

.date-popup .mat-calendar-next-button {
  order: 3;
}

.date-popup .mat-calendar-spacer {
  display: none;
}

.date-popup .mat-calendar-body tr:first-child td:first-child {
  visibility: hidden;
  padding: 0 !important;
}

.date-popup .mat-calendar-table-header-divider::after {
  display: none;
}

.date-popup .mat-calendar-body {
  font-size: 12px;
}

.date-popup .mat-calendar-body .mat-calendar-body-cell-content {
  color: var(--font-dark-color);
}

.date-popup .mat-calendar-table-header tr:first-child th {
  text-align: center;
  padding: 4px 0 4px 0;
  background: rgba(33, 150, 243, 0.1);
  color: var(--light-color);
}

.date-popup .mat-calendar-table-header tr:first-child th:first-child {
  padding: 0 !important;
}

.date-popup .mat-calendar-table-header th:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.date-popup .mat-calendar-table-header th:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.date-popup .mat-calendar-table-header tr:last-child {
  // display: none;
  padding-bottom: 12px;
}

.date-popup .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: var(--primary-color);
  background: transparent;
  color: var(--font-dark-color);
  // border-radius: 4px;
}

.date-popup .mat-calendar-body-cell-content {
  height: 80%;
  width: 80%;
}

.date-popup
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.date-popup
  .cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.date-popup
  .cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background: var(--primary-color);
  color: var(--all-theme-white);
}

.date-popup .mat-calendar-previous-button::after,
.date-popup .mat-calendar-next-button::after {
  border-top-width: 1px;
  border-color: var(--border-color-1);
}

.date-popup .mat-calendar-previous-button::after {
  border-left-width: 1px;
}

.date-popup .mat-calendar-next-button::after {
  border-right-width: 1px;
}

.date-popup .mat-calendar-controls button {
  color: var(--font-dark-color);
  padding-left: 8px;
  padding-right: 4px;
}

.date-popup .mat-calendar-controls button .mat-calendar-arrow {
  border: 0;
  width: 16px;
  height: 16px;
  margin: 0 4px;
  border: 0;
  background-image: url(assets/img/newImg/drop-down-blc.svg);
  background-position: center;
  background-size: 12px;
  background-repeat: no-repeat;
}

.account-size {
  padding: 3px 12px;
  border: 1px solid var(--light-border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s;
}

.account-size:hover {
  background: var(--light-hover-color);
}

.account-size .size {
  margin-right: 8px;
  text-align: center;
}

.account-size .risk {
  // margin-right: 8px;
  text-align: center;
}

.account-size .integration-header {
  margin-right: 8px;
  text-align: center;
}

.account-size span {
  display: block;
  color: var(--light-color);
  font-size: 12px;
  line-height: 16px;
}

.account-size p {
  color: var(--font-dark-color);
  font-size: 12px;
  line-height: 15px;
  display: block;
  margin-right: 8px;
  margin-top: 3px;
}

.account-size p:last-child {
  margin-right: 0;
}

.account-size label {
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 15px;
}

.integration-inner-div label {
  display: block;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 2px;
}

.risk-inner-div,
.integration-inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-size img {
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.icon-btn {
  padding: 0px !important;
  min-width: 10px !important;
  border-radius: 100% !important;
}

.run-scanner {
  width: 135px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.run-scanner .run-drop {
  height: 28px;
  width: 28px;
  line-height: 28px;
}

.run-scanner .run-drop img {
  height: 16px;
  width: 16px;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  transition: all 0.3s;
}

.run-scanner .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.run-scanner-only-btn {
  padding-left: 15px;
  padding-right: 4px;
  height: 36px;
  display: inline-flex;
  align-items: center;
}

.a-known-issue {
  margin-right: 18px;
}

.known-issues-only-btn {
  padding-left: 15px;
  padding-right: 15px;
  height: 36px;
  display: inline-flex;
  align-items: center;
}

.run-scanner {
  position: relative;
}

.run-scanner-menu {
  position: absolute;
  top: 100%;
  color: var(--font-dark-color);
  font-family: RR;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 8px 14px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 var(--box-shadow-2);
  background: var(--white-bg);
  z-index: 999;
  left: 0;
  width: 100%;
  white-space: pre-line;
  text-align: left;
  display: none;
  transition: all 0.3s;
}

.run-scanner .cdk-focused + .run-scanner-menu {
  display: block;
}

.run-scanner .mat-button-focus-overlay {
  background: transparent !important;
}

.known-issues {
  width: 135px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.scanner-dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background: var(--drop-down-bg);
  z-index: 888;
  box-shadow: 0 2px 8px 0 var(--box-shadow-2);
  text-align: left;
  display: none;
}

.scanner-dropdown span {
  color: var(--font-dark-color);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: pre-wrap;
  display: block;
  padding: 8px 15px;
  text-align: left;
}

.scanner-dropdown span:hover {
  background: var(--light-hover-color);
}

.showMenu {
  display: block;
}

.hideMenu {
  display: none;
}

.square-btn {
  height: 24px;
  min-width: 24px !important;
  padding: 0 !important;
  text-align: center;
  border-radius: 100% !important;
  margin: 4px !important;
}

.square-btn svg {
  display: block;
  height: 20px;
  width: 20px;
  margin: auto;
}

.square-btn svg {
  fill: var(--light-svg);
}

.square-btn svg:hover path {
  fill: var(--dark-svg);
}

.round-btn {
  margin: 4px !important;
  height: 24px;
  position: relative;
  min-width: 24px !important;
  border-radius: 100% !important;
}

.round-btn svg path {
  fill: var(--light-svg);
}

.round-btn svg:hover path {
  fill: var(--dark-svg);
}

.profiles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 4px 10px;
  padding-right: 5px;
}

.profiles:hover {
  background-color: var(--light-bg-1);
}

.profiles .s2 {
  font-family: RR;
}

.profile-icon {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  overflow: hidden;
  margin-left: 8px;
  background: var(--primary-color);
  display: inline-flex;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  color: var(--all-theme-white);
}

.profiles .profiles-drop {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.profiles .profiles-drop img {
  height: 16px;
  width: 16px;
}

.backBlack {
  height: calc(100vh);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 887;
}

.backTransparent {
  background-color: transparent;
  height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 887;
}

.menu-modal-outer {
  position: relative;
}

.menu-modals {
  border-radius: 1px !important;
  box-shadow: 0 3px 6px 0 var(--box-shadow-2) !important;
  border: 1px solid var(--dark-border-color);
  width: 192px;
  min-height: auto;
  max-height: calc(100vh - 40px);
  background: var(--drop-down-bg);
  padding-top: 8px;
  padding-bottom: 8px;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 888;
}

.menu-modals::before {
  position: absolute;
  content: '';
  background: url(assets/img/newImg/Path.svg);
  background-size: cover;
  background-position: center;
  height: 7px;
  width: 12px;
  top: -7px;
  right: 0;
}

.modalShow {
  -webkit-animation-name: slowShow;
  animation-name: slowShow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.modalHide {
  display: none;
}

.leftToRight {
  -webkit-animation-name: leftToRight;
  animation-name: leftToRight;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.rightToLeft {
  -webkit-animation-name: rightToLeft;
  animation-name: rightToLeft;
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes slowShow {
  0% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes slowShow {
  0% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

@-webkit-keyframes leftToRight {
  0% {
    opacity: 0;
    right: -160%;
    display: none;
  }

  100% {
    opacity: 1;
    right: 10px;
    display: block;
  }
}

@keyframes leftToRight {
  0% {
    opacity: 0;
    right: -160%;
    display: none;
  }

  100% {
    opacity: 1;
    right: 10px;
    display: block;
  }
}

@-webkit-keyframes rightToLeft {
  100% {
    opacity: 0;
    right: -160%;
    display: none;
  }
}

@keyframes rightToLeft {
  100% {
    opacity: 0;
    right: -160%;
    display: none;
  }
}

@media (max-width: 767px) {
  .menu-modals {
    min-height: unset;
    height: 100vh;
    overflow-y: auto;
    top: 0;
    right: 0;
    width: 300px;
  }

  .modalClose {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    padding-top: 16px;
  }
}

.modalClose {
  display: none;
}

.dark-theme-toogle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.dark-theme-toogle h4 {
  color: var(--font-dark-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 0;
}

.mat-slide-toggle-bar {
  width: 24px !important;
  height: 14px !important;
}

.mat-slide-toggle-thumb-container {
  top: 2px !important;
  left: 2px !important;
}

.mat-slide-toggle-thumb-container,
.mat-slide-toggle-thumb {
  height: 10px !important;
  width: 10px !important;
}

.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: var(--toogle-bg) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--primary-color) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--white-bg) !important;
}

.mat-slide-toggle-thumb {
  box-shadow: none !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(10px, 0, 0) !important;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background: var(--primary-color) !important;
}

.mat-slide-toggle-content {
  color: var(--font-dark-color);
}

.profile-menu-item a {
  padding: 8px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: var(--font-dark-color);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 16px;
}

.profile-menu-item a:hover {
  background-color: var(--light-bg-1);
}

.profile-menu-item a img {
  height: 24px;
  width: 24px;
  display: block;
  margin-right: 8px;
}

.profile-menu-item a.tradier-integration-new {
  color: #fff;
  margin: 0 16px;
  justify-content: center;
}

.menu-modals .divider {
  width: calc(100% - 32px);
  margin: 8px auto;
  background: var(--light-bg-13);
  height: 1px;
}

.risk-based-position-sizing .w-100 {
  width: 100%;
}

.risk-based-position-sizing .per-input,
.risk-based-position-sizing .icon-input {
  margin-top: 0px;
  display: inline-block;
  width: 100%;
}

.risk-based-position-sizing .per-input label {
  padding-right: 5px;
}

.risk-based-position-sizing .per-input .help-image {
  vertical-align: -4px;
}

.risk-based-position-sizing .per-input .input-box,
.risk-based-position-sizing .icon-input .input-box {
  margin-top: 8px;
}

.risk-based-position-sizing .dollar-input {
  margin-top: 0px;
  display: inline-block;
  width: 100%;
}

.risk-based-position-sizing .dollar-input label {
  padding-right: 5px;
}

.risk-based-position-sizing .dollar-input .help-image {
  vertical-align: -4px;
}

.risk-based-position-sizing .dollar-input .input-box {
  margin-top: 8px;
}

.risk-based-position-sizing .amount-of-accountrisk {
  margin-top: 12px;
}

.risk-based-position-sizing .amount-of-accountrisk .title {
  color: var(--font-dark-color-2);
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 14px;
}

.risk-based-position-sizing .amount-of-accountrisk .title.light {
  width: 100%;
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 11px;
  padding-top: 5px;
  padding-bottom: 6px;
}

.risk-based-position-sizing .amount-of-accountrisk .help-image {
  vertical-align: -4px;
  padding-left: 5px;
}

.risk-based-position-sizing .amount-of-accountrisk .row {
  display: flex;
  flex-wrap: flex;
}

.risk-based-position-sizing .amount-of-accountrisk .per-input {
  width: 100%;
}

.risk-based-position-sizing .amount-of-accountrisk .col:nth-child(2) {
  padding-left: 8px;
}

.risk-based-position-sizing .mt4 {
  margin-top: 4px;
  display: inline-flex;
  align-items: center;
}

.risk-based-position-sizing .mt4 label {
  color: var(--font-dark-color);
  font-family: RR;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.risk-based-position-sizing .info-container {
  position: relative;
  margin-top: 30px;
  padding: 10px 20px 10px 40px;
  background-color: var(--info-orange-bg-color);
  border-radius: 5px;
}

.risk-based-position-sizing .info-container .icon {
  position: absolute;
  left: 11px;
  right: 11px;
  top: 11px;
  bottom: 11px;
}

.risk-based-position-sizing .info-container .text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.risk-based-position-sizing .info-container .text p {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: var(--font-dark-color);
}

.scanner-dropdown .mat-menu-item {
  height: 40px;
  line-height: 40px;
}

.scanner-dropdown .mat-menu-content:not(:empty) {
  padding: 0px;
}

.scanner-dropdown .mat-menu-item {
  color: var(--font-dark-color);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  border-bottom: 1px solid var(--dark-border-color);
}

.scanner-dropdown.mat-menu-panel {
  box-shadow: 0 2px 8px 0 var(--box-shadow-2);
  min-height: 40px;
}

.print-help-group {
  display: flex;
  align-items: center;
  position: relative;
}

.help-modals {
  border-radius: 1px !important;
  box-shadow: 0 3px 6px 0 var(--box-shadow-2) !important;
  border: 1px solid var(--dark-border-color);
  width: 192px;
  min-height: 130px;
  max-height: calc(100vh - 40px);
  background: var(--drop-down-bg);
  padding-top: 8px;
  padding-bottom: 8px;
  position: fixed;
  opacity: 0;
  top: 200%;
  right: 5px;
  z-index: 888;
}

.help-modals::before {
  position: absolute;
  content: '';
  background: url(assets/img/newImg/Path.svg);
  background-size: cover;
  background-position: center;
  height: 7px;
  width: 12px;
  top: -7px;
  right: 0;
}

.bottomToTop {
  -webkit-animation-name: bottomToTop;
  animation-name: bottomToTop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes bottomToTop {
  0% {
    opacity: 0;
    top: 200%;
    display: none;
    position: absolute;
  }

  100% {
    opacity: 1;
    top: calc(100% + 8px);
    display: block;
    position: absolute;
  }
}

@keyframes bottomToTop {
  0% {
    opacity: 0;
    top: 200%;
    display: none;
    position: absolute;
  }

  100% {
    opacity: 1;
    top: calc(100% + 8px);
    display: block;
    position: absolute;
  }
}

.help-menu-item a {
  padding: 8px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: var(--font-dark-color-3);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 15px;
}

.help-menu-item a:hover {
  background-color: var(--light-bg-1);
}

.help-menu-item a img {
  height: 24px;
  width: 24px;
  display: block;
  margin-right: 8px;
}

.help-menu-item .divider {
  width: calc(100% - 32px);
  margin: 8px auto;
  background: var(--light-bg-13);
  height: 1px;
}

.help-ul {
  padding: 0 16px;
  text-align: left;
  margin-top: 16px;
}

.help-ul ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.help-ul p {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 14px;
  margin-bottom: 8px;
}

.help-ul ul li {
  display: block;
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 14px;
  margin-bottom: 4px;
  position: relative;
  padding-left: 12px;
}

.help-ul ul li::before {
  position: absolute;
  content: '';
  height: 4px;
  width: 4px;
  border-radius: 100%;
  background: var(--light-bg-14);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.help-ul ul li:last-child {
  margin-bottom: 16px;
}

.help-footer {
  padding: 0 16px;
  text-align: left;
  margin-top: 16px;
}

.help-footer ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.help-footer ul li {
  display: block;
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 4px;
}

.help-footer ul li:last-child {
  margin-bottom: 6px;
}

.help-menu-items {
  height: 0px;
  transition: all 1s;
  overflow: hidden;
}

.help-menu-items ul {
  overflow: hidden;
}

.dd-menu {
  position: relative;
}

.dd-menu::after {
  position: absolute;
  content: '';
  background: url(assets/img/arrow-down.svg);
  background-position: center;
  background-size: cover;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  height: 22px;
  width: 22px;
  transition: all 0.3s;
}

.dd-menu.open::after {
  transform: translateY(-50%) rotate(-180deg);
}

.help-menu-items.open {
  height: auto;
  transition: all 1s;
}

/**************************
    Header css end
**************************/

/**************************
    Powerx css start
**************************/

/* Powerx Main Layout start */

.powerx-main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100vh - 64px);
}

.powerx-main .left-side {
  width: calc(100% - 540px);
  border-left: 3px solid var(--border-white);
  border-right: 4px solid var(--dark-border-color);
  height: 100dvh;
  display: flex;
  flex-direction: column;

  &.wide-right-border {
    border-right: 6px solid var(--dark-border-color);
  }
}

.powerx-main .right-side {
  width: 540px;
  height: 100%;
}

.as-vertical > .as-split-gutter .as-split-gutter-icon {
  /* background-position: center -2px; */
  background-image: unset !important;
  background-color: var(--dark-border-color);
}

.as-horizontal > .as-split-gutter .as-split-gutter-icon {
  background-image: unset !important;
  background-color: var(--dark-border-color);
}

.as-split-gutter {
  background-color: var(--dark-border-color) !important;
}

as-split-area {
  position: relative !important;
}

/* Powerx Main Layout end */

/* Searchbar section start */

.search-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .company-name {
    position: relative;
  }

  .company-info {
    position: absolute;
    color: var(--font-dark-color);
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    vertical-align: middle;
    border: 1px solid var(--dark-border-color);
    background: var(--white-bg);
    width: 280px;
    padding: 6px 6px;
    border-radius: 0px 4px 4px 4px;
    z-index: 199;
    top: 21px;
  }
}

.search-section .search-bar-btn {
  width: 96px;
  margin-right: 0px;
  height: 32px;
  border: 1px solid var(--dark-border-color);
  background: var(--white-bg);
  color: var(--font-dark-color);
  text-transform: uppercase;
  padding-left: 12px !important;
  letter-spacing: 0.01px;
  text-align: left !important;
  justify-content: flex-start !important;
  cursor: pointer;
  font-weight: 700;
  border-radius: 4px 0px 0px 4px !important;
}

.search-section .search-bar-btn:hover {
  background-color: var(--light-hover-color-2);
}

.search-user-bar {
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 169px;
  margin-right: 24px;
}

.search-user-bar input {
  box-sizing: border-box;
  height: 32px;
  width: calc(100% - 32px);
  border: 1px solid var(--dark-border-color);
  border-right: 0;
  border-radius: 0;
  outline: none;
  background-color: var(--white-bg);
  padding-left: 8px;
  padding-right: 8px;
  color: var(--black-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.search-user-bar a {
  width: 36px;
  box-sizing: border-box;
  border: 1px solid var(--dark-border-color);
  border-left: 0;
  padding: 0;
  height: 32px;
  background: var(--white-bg);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-user-bar input::-webkit-input-placeholder {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
}

.search-user-bar input::-moz-placeholder {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
}

.search-user-bar input:-ms-input-placeholder {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
}

.search-user-bar input::-ms-input-placeholder {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
}

.search-user-bar input::placeholder {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
}

.search-section .company-name .name-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.company-name .cap {
  color: var(--light-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.updateNumber {
  border: 1px solid var(--light-border-color-1);
  color: var(--light-border-color-1);
  margin-left: 18px;
  font-size: 10px;
  min-width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 10px;
  border-radius: 3px;
  text-align: center;
  vertical-align: middle;
  line-height: 9px;
  padding: 3px 0px;
}

.updateNumber.red {
  border-color: var(--red-border-color);
  color: var(--red-color);
}

.sector-value,
.industry-value {
  color: var(--light-color);
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.search-section .update-time {
  margin-left: 16px;
  color: var(--light-color);
  font-family: RR;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

.long-position .mat-button {
  background-color: var(--long-yellow-color);
  border-radius: 4px;
  background-image: url('assets/img/down-white.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 9px);
  background-position-y: center;
  padding-right: 48px;
  background-size: 16px;
  padding-left: 9px;
  line-height: 30px;
  width: 180px;
  color: var(--all-theme-white);
  font-size: 12px;
  letter-spacing: 0.33px;
  text-align: left;
  font-weight: 400;
}

.long-position .mat-button.grey {
  background-color: var(--grey-bg);
  border-radius: 4px;
  background-image: url('assets/img/down-white.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 9px);
  background-position-y: center;
  padding-right: 30px;
  background-size: 16px;
  padding-left: 9px;
  line-height: 30px;
  width: auto;
  color: var(--white-color);
  font-size: 12px;
  letter-spacing: 0.33px;
  text-align: left;
  font-weight: 400;
}

.long-position .mat-menu-panel {
  max-width: 180px;
}

.position-dropdown .selected-item {
  background: var(--selected-item-bg);
}

.position-dropdown .mat-menu-item {
  height: 32px;
  line-height: 24px;
}

.position-dropdown .mat-menu-content:not(:empty) {
  padding: 0px;
}

.position-dropdown .mat-menu-item {
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  padding-left: 9px;
  padding-right: 9px;
  border-bottom: 0;
  font-weight: 400;
}

.position-dropdown.mat-menu-panel {
  box-shadow: 0 2px 8px 0 var(--box-shadow-2);
  border-radius: 0;
  min-height: 40px;
  min-width: 180px;
}

.search-section .add-to-watchlist-btn {
  display: flex;
  border: 1px solid var(--dark-border-color);
  border-radius: 0px 4px 4px 0px;
  border-left: 0px;
  cursor: pointer;
  height: 32px;
  align-items: center;
  justify-content: center;
}

.search-section .add-to-watchlist-btn:hover {
  background-color: var(--light-hover-color-2);
}

/* Searchbar section end */

/* cart trade otopn tabbing start */
.tabbing {
  height: 100%;
  overflow: hidden;
}

.tabbing .mat-tab-label {
  padding: 0 8px !important;
  height: 40px;
  min-width: 60px;
  opacity: 1;
  margin-right: 16px;
}

.tabbing .mat-tab-label-content {
  color: var(--light-color);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.tabbing .mat-tab-label-active .mat-tab-label-content {
  color: var(--primary-color);
}

.tabbing .mat-tab-body,
.mat-tab-body-content {
  height: 100%;
  overflow: hidden;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  display: none;
}

/* cart trade otopn tabbing end */

/**************************
    Powerx css End
**************************/

.ws-menu.mat-menu-panel {
  box-shadow: 0 2px 8px 0 var(--box-shadow-2);
  min-height: 40px;
}

.ws-menu .mat-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}

.ws-menu .mat-menu-item {
  padding-left: 8px;
  padding-right: 16px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ws-menu .mat-menu-item {
  color: var(--font-dark-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  border-bottom: 1px solid var(--dark-border-color);

  &:hover {
    background-color: var(--select-hover-color);
  }
}

.ws-menu .mat-menu-item .mat-icon {
  color: inherit;
  margin-right: 8px;
}

.ws-menu .mat-menu-item.no-border-bottom,
.ws-menu .mat-menu-item:last-child {
  border-bottom: 0;
}

.ws-menu .mat-menu-item img {
  height: 24px;
  width: 24px;
  margin-right: 4px;
}

/****************************************
    Scanner watchlist tabbing css start
****************************************/

.ws-tabbing {
  height: 100%;
  overflow: hidden;
}

.ws-tabbing .mat-tab-header {
  border-bottom: 0;
}

.ws-tabbing .mat-tab-labels {
  border-bottom: 1px solid var(--dark-border-color);
  height: 32px;
}

.ws-tabbing .mat-tab-label {
  padding-left: 16px !important;
  padding-right: 10px !important;
  height: 32px;
  min-width: 85px;
  opacity: 1;
  background: var(--white-bg);
  border-bottom: 1px solid var(--dark-border-color);
}

.scanner-watchlist .mat-tab-label,
.cons-aggress .mat-tab-label {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.scanner-watchlist.ws-tabbing .mat-tab-label,
.cons-aggress.ws-tabbing .mat-tab-label {
  @media (max-width: 575px) {
    width: 50%;
  }
}

.ws-tabbing .mat-tab-label-content {
  color: var(--font-dark-color);
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 16px;
  font-weight: 400;
}

.ws-tabbing .mat-tab-label-active .mat-tab-label-content {
  color: var(--primary-color);
  font-weight: 500;
}

.ws-tabbing .mat-tab-body,
.mat-tab-body-content {
  height: 100%;
  overflow: hidden;
}

.ws-tabbing .mat-tab-label:last-of-type {
  border-right: 0 !important;
}

.mat-tab-group.ws-tabbing .mat-tab-label-active {
  background: var(--white-bg);
  border-left: 1px solid var(--dark-border-color) !important;
  border-bottom: 1px solid var(--border-white);
  border-right: 1px solid var(--dark-border-color) !important;
  border-top: 1px solid var(--dark-border-color) !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mat-tab-group.ws-tabbing .mat-tab-label:first-of-type {
  border-left: 0 !important;
}

.ws-tabbing .drop {
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin-left: 4px;
}

.ws-tabbing .drop img {
  height: 16px;
  width: 16px;
  margin-inline: auto;
  transition: all 0.3s;
}

.ws-tabbing [aria-expanded='true'].drop img {
  transform: rotate(-180deg);
  transition: all 0.3s;
}

.ws-tabbing .mat-tab-nav-bar {
  border-color: var(--dark-border-color);
}

.top-border .mat-tab-header {
  margin-top: -1px;
}

.mat-sort-header-pointer-left,
.mat-sort-header-pointer-right {
  height: 1px !important;
}

.mat-sort-header-stem {
  display: none !important;
}

.mat-sort-header-arrow {
  color: var(--light-color) !important;
}

.mat-sort-header-pointer-middle {
  display: none !important;
}

/***************************************
    Scanner watchlist tabbing css end
***************************************/

/****************************************
    Scanner Table css start
*****************************************/
app-scanner {
  display: block;
  height: 100%;
}

.scanner-table .no-data-msg {
  margin-top: 10px;
  width: 100%;
  text-align: left;
  color: var(--no-data-msg);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  padding: 0px 17px;

  a {
    text-decoration: none;
    color: var(--primary-color);
  }
}

.scanner-table {
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  overflow: overlay;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 30px;

  table {
    tbody {
      tr {
        .emoji.Grey {
          display: none;
        }

        &:hover {
          .emoji.Grey {
            display: block;
          }
        }
      }
    }
  }
}

.scanner-table table {
  width: 100%;
  margin: auto;
  table-layout: fixed;
}

.scanner-table th,
.scanner-table td {
  background: var(--white-bg);
  vertical-align: top;
  white-space: nowrap;
  border-color: var(--dark-border-color);
}

.scanner-table .mat-header-row {
  min-height: auto;
  height: auto;
}

.scanner-table th {
  color: var(--light-color);
  font-size: 12px;
  padding: 6px 8px !important;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 14px;
}

.scanner-table td {
  color: var(--font-dark-color);
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  vertical-align: middle;
  border-collapse: collapse;
  border-color: var(--light-border-color-3);
}

.scanner-table tr:last-child td {
  border-bottom: 0;
}

.scanner-table tr.mat-row,
.scanner-table tr.mat-footer-row {
  min-height: auto;
  height: auto;
}

.selected td {
  background-color: var(--light-bg-1) !important;
}

.scanner-table tbody tr:first-child td,
.watchlist-table tbody tr:first-child td {
  border-top: 1px solid transparent;
}

.scanner-table td .borderSelection,
.watchlist-table td .borderSelection {
  padding: 5px 7px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;

  &.active {
    .emoji.Grey {
      display: block;
    }
  }
}

.scanner-table .selected td .borderSelection::after,
.watchlist-table .selected td .borderSelection::after {
  background: var(--primary-color);
  position: absolute;
  content: '';
  width: calc(100% + 4px);
  height: 2px;
  top: -1px;
  left: -2px;
}

.scanner-table .selected td .borderSelection::before,
.watchlist-table .selected td .borderSelection::before {
  background: var(--primary-color);
  position: absolute;
  content: '';
  width: calc(100% + 4px);
  height: 2px;
  bottom: -1px;
  left: -2px;
}

.scanner-table .selected td:first-child .borderSelection,
.watchlist-table .selected td:first-child .borderSelection {
  border-left: 2px solid var(--primary-color);
  padding-left: 5px;
}

.scanner-table .selected td .borderSelection .ie-btns {
  padding-right: 6px;
}

.scanner-table .selected td:last-child .borderSelection,
.watchlist-table .selected td:last-child .borderSelection {
  border-right: 2px solid var(--primary-color);
}

.scanner-table .selected td,
.watchlist-table .selected td,
.conservative-table .selected td,
.aggresive-table .selected td {
  background-color: transparent !important;
}

.scanner-table tr:hover td,
.watchlist-table tr:hover td {
  background-color: var(--light-bg-1) !important;
}

.lightRed,
.lightGray,
.lightGreen {
  width: 48px;
}

.lightRed {
  background: var(--red-bg);
  color: var(--all-theme-white);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  padding: 2px 7.5px;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
}

.lightGray {
  background: var(--light-bg-14);
  color: var(--all-theme-white);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  padding: 2px 7.5px;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
}

.lightGreen {
  background: var(--green-bg);
  color: var(--all-theme-white);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  padding: 2px 7.5px;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
}

.emoji {
  height: 20px;
  width: 20px;
  display: block;
}

.scanner-table th:nth-child(1) {
  width: 48px;
  vertical-align: middle;
}

.scanner-table th:nth-child(2) {
  width: 65px;
  vertical-align: middle;
}

.scanner-table th:nth-child(3) {
  width: 68px;
  vertical-align: middle;
}

.scanner-table th:nth-child(4) {
  width: 82px;
  vertical-align: middle;
}

.scanner-table td .borderSelection.country-code {
  justify-content: center;
}

.relative {
  position: relative;
}

.scanner-table .company-eclips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ie-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: var(--light-bg-1);
  opacity: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.ie-btns img {
  display: block;
  margin-right: 6px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  opacity: 0.37;
}

.ie-btns img:hover {
  opacity: 1;
}

.ie-btns img:last-child {
  margin-right: 0;
}

.scanner-table tr:hover .ie-btns {
  opacity: 1;
  transition: opacity 0.3s;
}

.scanner-table[style~='auto;'],
.watchlist-table[style~='auto;'],
.conservative-table[style~='auto;'],
.aggresive-table[style~='auto;'] {
  overflow: overlay !important;
}

/****************************************
    Scanner Table css end
*****************************************/

/****************************************
     Watchlist Table css start
*****************************************/

app-scanner-watchlist-panel .mat-tab-body-wrapper {
  height: calc(100% - 31px);
}

::ng-deep .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0px) !important;
}

.watchlist-table .no-data-msg {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: var(--no-data-msg);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
}

.add-watchlist {
  height: 28px;
  width: 28px;
  line-height: 28px;
  padding: 0 !important;
  min-width: 28px !important;
  position: absolute !important;
  right: 8px;
  top: 1px;
  z-index: 3;
}

.add-watchlist img {
  height: 24px;
  width: 24px;
  display: block;
  margin: auto;
}

.help-scanner img {
  height: 12px;
  width: 12px;
}

.watchlist-table {
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  overflow: overlay;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.watchlist-table table {
  width: 100%;
  margin: auto;
  table-layout: fixed;
}

.watchlist-table th,
.watchlist-table td {
  background: var(--white-bg);
  vertical-align: top;
  white-space: nowrap;
  border-color: var(--dark-border-color);
}

.watchlist-table .mat-header-row {
  min-height: auto;
  height: auto;
}

.watchlist-table th {
  color: var(--light-color);
  font-size: 12px;
  padding: 6px 8px !important;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
}

.watchlist-table td {
  color: var(--font-dark-color);
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  vertical-align: middle;
  border-collapse: collapse;
  border-color: var(--light-border-color-3);
}

.watchlist-table tr:last-child td {
  border-bottom: 0;
}

.watchlist-table tr.mat-row,
.watchlist-table tr.mat-footer-row {
  min-height: auto;
  height: auto;
}

.watchlist-table tr:hover td {
  background-color: var(--light-bg-1);
}

.watchlist-table th:nth-child(1) {
  width: 64px;
  vertical-align: middle;
}

.watchlist-table th:nth-child(2) {
  width: 70px;
}

.watchlist-table th:nth-child(3) {
  width: 70px;
}

.watchlist-table th:nth-child(4) {
  width: 80px;
}

.watchlist-table td:nth-child(4) .borderSelection {
  justify-content: center;
}

.watchlist-table th:nth-child(5),
.watchlist-table td:nth-child(5) .borderSelection {
  padding-right: 4px;
}

.watchlist-table th:last-child,
.watchlist-table td:last-child {
  width: 85px;
}

.watchlist-table td:last-child {
  color: var(--light-color-2);
}

.watchlist-table .ie-btns {
  justify-content: space-between;
}

.watchlist-table tr:hover .ie-btns {
  opacity: 1;
  transition: opacity 0.3s;
}

.watchlist-table .company-eclips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/****************************************
     Watchlist Table css End
*****************************************/

/****************************************
     Modal Common Css Start
*****************************************/
.mat-dialog-container {
  background: var(--modal-bg);
}

.modals .mat-dialog-actions {
  margin-bottom: 0 !important;
  padding: 24px !important;
  border-top: 0;
}

.popup-close-btn {
  padding: 0 4px !important;
  line-height: 22px !important;
  // padding-bottom: 2px !important;
  min-width: 24px !important;
  height: 24px;
}

.popup-close-btn svg {
  height: 16px;
  width: 16px;
}

.modals .mat-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--font-dark-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  padding: 16px 20px 16px 24px;
  border-bottom: 1px solid var(--dark-border-color);
}

.modals .mat-dialog-title img {
  cursor: pointer;
  margin-inline: auto;
}

.modals .mat-dialog-actions .mat-button {
  margin-right: 32px !important;
  margin-left: 0 !important;
}

.modals .mat-dialog-actions .mat-button:last-child {
  margin-right: 0 !important;
}

.modals .mat-dialog-actions.mr-16 .mat-button {
  margin-right: 16px !important;
  margin-left: 0 !important;
}

.modals .mat-dialog-actions.mr-16 .mat-button:last-child {
  margin-right: 0 !important;
}

.modals .small-btn {
  width: 96px !important;
}

.modals .large-btn {
  width: 132px !important;
}

/****************************************
     Modal common css end
*****************************************/

/****************************************
     scanner setting css start
*****************************************/
.scanner-setting {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: auto;
}

.scanner-setting .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 628px;
  margin: 50px auto;
}

.scanner-setting .mat-dialog-content {
  margin: 0 !important;
  padding: 16px 24px 8px 24px !important;
  max-height: unset;
}

.signal-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.scanner-exchanges {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;

  .mat-checkbox-label {
    display: flex;
    align-items: center;
    color: var(--font-dark-color);
    font-size: 12px;
    line-height: 24px !important;
  }

  .mat-checkbox {
    margin-right: 34px;
  }

  .mat-checkbox:last-child {
    margin-right: 0;
  }

  .exchanges-checkbox {
    width: 420px;
    margin-left: auto;
  }

  .scanner-exchanges-icon {
    margin-left: 8px;
  }
}

.signal-type p,
.scanner-exchanges p {
  color: var(--font-dark-color-2);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-right: 16px;
}

.signal-type .o-radio {
  width: 420px;
  margin-left: auto;
}

.signal-type .mat-radio-label-content {
  letter-spacing: 0;
  padding-left: 4px;
}

.signal-type .mat-radio-button {
  margin-right: 24px;
}

.signal-type .mat-radio-button:last-child {
  margin-right: 0;
}

.scanner-inputs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.per-input,
.dollar-input,
.icon-input,
.only-input {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.w-186 {
  width: 186px;
}

.w-267 {
  width: 267px;
}

.per-input label,
.dollar-input label,
.icon-input label,
.only-input label {
  color: var(--font-dark-color-2);
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 14px;
  width: calc(100% - 105px);
}

.per-input .input-box,
.dollar-input .input-box,
.icon-input .input-box,
.only-input .input-box {
  box-sizing: border-box;
  height: 32px;
  width: 105px;
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--light-border-color);
}

.input-box input {
  background-color: transparent;
}

.per-input .percentage,
.dollar-input .dollar-sign {
  width: 25px;
  height: 24px;
  border-right: 1px solid var(--light-border-color-4);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--font-dark-color-4);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.per-input .input-box input {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  width: calc(100% - 25px);
  padding-left: 8px;
  padding-right: 8px;
  color: var(--input-color);
  font-size: 13px;
  letter-spacing: 0;
}

.per-input .input-box img,
.icon-input .input-box img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  cursor: pointer;
}

.dollar-input .input-box input {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  width: calc(100% - 25px);
  padding-left: 8px;
  padding-right: 8px;
  color: var(--input-color);
  font-size: 13px;
  letter-spacing: 0;
}

.icon-input .input-box input {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  width: calc(100% - 24px);
  padding-left: 8px;
  padding-right: 8px;
  color: var(--input-color);
  font-size: 13px;
  letter-spacing: 0;
}

.only-input .input-box input {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--input-color);
  font-size: 13px;
  letter-spacing: 0;
}

.re-signal-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.re-signal-type p {
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 4px;
}

.re-signal-type img {
  margin-right: 14px;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.re-signal-type .mat-radio-label-content {
  letter-spacing: 0;
  padding-left: 4px;
}

.re-signal-type .mat-radio-button {
  margin-right: 16px;
}

.re-signal-type .mat-radio-button:last-child {
  margin-right: 0;
}

.run-scanner-for {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.run-scanner-for p {
  color: var(--font-dark-color-2);
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-right: 4px;
  white-space: nowrap;
}

.run-scanner-for img {
  margin-right: 12px;
  height: 16px;
  width: 16px;
}

.run-scanner-for .select {
  max-width: 417px;
  margin-left: auto;
  font-size: 13px;
  color: var(--input-color);
}

.run-scanner-for .mat-form-field {
  width: 417px;
  margin-left: auto;
}

.run-scanner-for .mat-form-field .select {
  padding-right: 12px;
  padding: 5px 12px;
}

.run-scanner-for .mat-form-field-wrapper {
  padding-bottom: 0;
}

.run-scanner-for .select .mat-select-arrow-wrapper {
  margin: 0;
  opacity: 0;
}

.run-scanner-for .mat-form-field-infix {
  padding: 0;
  border-top: 0;
}

.run-scanner-for .mat-form-field-underline {
  display: none;
}

.run-scanner-for .mat-form-field-subscript-wrapper {
  display: none;
}

.run-scanner-for .mat-select-value-text span {
  font-size: 13px;
  color: var(--font-dark-color-4);
  letter-spacing: 0;
}

.run-scanner-option {
  box-shadow: 0 2px 4px 0 var(--box-shadow-3) !important;
  border-radius: 3px !important;
}

.run-scanner-option .mat-option-text {
  color: var(--font-dark-color-2);
}

.run-scanner-option .mat-active .mat-option-text {
  color: var(--primary-color);
}

.run-scanner-option .mat-active {
  background: var(--light-hover-color) !important;
}

.run-scanner-option .mat-option:hover,
.run-scanner-option .mat-active:hover {
  background: var(--light-hover-color) !important;
}

.run-scanner-option .mat-select-panel .mat-option {
  line-height: 35px;
  height: unset;
}

.header-help-icon {
  margin-left: auto;
  margin-right: 13px;
  cursor: pointer;
  height: 14px;
  width: 14px;
}

.header-help-icon path {
  fill: var(--primary-color);
}

.scanner-setting .per-input .input-box img,
.scanner-setting .icon-input .input-box img {
  display: none;
}

.scanner-setting .per-input .input-box input,
.scanner-setting .dollar-input .input-box input,
.scanner-setting .icon-input .input-box input,
.scanner-setting .only-input .input-box input {
  text-align: right;
}

/****************************************
     scanner setting css start
*****************************************/

/****************************************
     Compare Option Popup css start
*****************************************/
.compare-option {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: auto;
}

.compare-option .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 850px;
  margin: 50px auto;
}

.compare-option .mat-dialog-content {
  margin: 0 !important;
  padding: 0px !important;
  max-height: unset;
}

.compare-option .mat-dialog-actions {
  border-top: 0;
}

.compare-table {
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
}

.compare-table table {
  width: 100%;
  margin: auto;
  table-layout: auto;
}

.compare-table th,
.compare-table td {
  background: var(--white-bg);
  vertical-align: top;
  border-color: var(--dark-border-color);
}

.compare-table .mat-header-row {
  min-height: auto;
  height: auto;
}

.compare-table th {
  color: var(--red-color);
  font-size: 12px;
  font-weight: 500;
  padding: 6px 8px !important;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
}

.compare-table td {
  color: var(--font-dark-color);
  padding: 4px 8px !important;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
  vertical-align: middle;
}

.compare-table tr:last-child td {
  border-bottom: 0;
}

.compare-table tr.mat-row,
.compare-table tr.mat-footer-row {
  min-height: auto;
  height: auto;
}

.compare-table tr:hover td {
  background-color: var(--light-hover-color-3);
}

.compare-table th:nth-child(1) {
  width: 40px;
}

.compare-table th:nth-child(2) {
  width: 80px;
}

.compare-table th:nth-child(3) {
  width: 96px;
  text-transform: uppercase;
}

.compare-table td:nth-child(4) {
  width: 62px;
}

.compare-table td:nth-child(5),
.compare-table td:nth-child(6),
.compare-table td:nth-child(7) {
  width: 56px;
}

/****************************************
     Compare Option Popup css end
*****************************************/

/****************************************
     Position sizing css start
*****************************************/
.position-sizing {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: auto;
}

.position-sizing .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 368px;
  margin: 50px auto;
}

.position-sizing .mat-dialog-content {
  margin: 0 !important;
  padding: 16px 24px 8px 24px !important;
  max-height: unset;
}

.position-sizing .risk-based-position-sizing .risk-input .input-box {
  width: 154px;
}

.position-sizing .risk-based-position-sizing .risk-input .full-width {
  width: 100%;
}

.w-50-per {
  width: 50%;
}

.w-50-per:first-of-type {
  padding-right: 8px;
}

.w-50-per:last-of-type {
  padding-left: 8px;
}

.integration {
  margin-top: 16px;
}

.integration label {
  display: block;
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 4px;
}

.integration span {
  display: block;
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.tradier {
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.tradier .tradier-content {
  width: calc(100% - 24px);
  padding-left: 8px;
}

.mat-error.input-error-txt span {
  display: block;
}

.position-sizing.buying-power .mat-dialog-container {
  width: 363px;
}

.position-sizing.buying-power .icon-input:last-of-type {
  margin-top: 16px;
}

.buying-power .mat-error.input-error-txt span {
  color: #ff0000;
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0.3 px;
  line-height: 20px;
}

/****************************************
     Position sizing css end
*****************************************/

/****************************************
     Position sizing css start
*****************************************/
.position-compare {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: auto;
}

.position-compare .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 1048px;
  margin: 50px auto;
  box-shadow: 0 3px 5px 0 var(--box-shadow-2);
}

.position-compare .mat-dialog-content {
  margin: 0 !important;
  padding: 0px !important;
  max-height: unset;
}

.position-tbl-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 1048px;
}

.position-tbl-header > div {
  border-bottom: 1px solid var(--dark-border-color);
  text-align: center;
  padding: 6px;
  color: var(--font-dark-color);
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.position-tbl-header > div:first-child {
  border-right: 1px solid var(--dark-border-color);
  width: 533px;
}

.position-tbl-header > div:last-child {
  width: 515px;
}

.position-table {
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  overflow-y: hidden;
  height: 100%;
  box-sizing: border-box;
  min-height: 324px;
}

.position-table table {
  width: 100%;
  margin: auto;
  table-layout: auto;
  table-layout: fixed;
  background: transparent;
}

.position-table th,
.position-table td {
  background: transparent;
  vertical-align: top;
  border-color: var(--dark-border-color);
}

.position-table .mat-header-row {
  min-height: auto;
  height: auto;
}

.position-table th {
  color: var(--light-color);
  font-size: 12px;
  padding: 6px 8px !important;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 14px;
  text-align: center !important;
}

.position-table td {
  color: var(--font-dark-color);
  padding: 4px 8px !important;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 14px;
  vertical-align: middle;
}

.position-table tr.mat-row,
.position-table tr.mat-footer-row {
  min-height: auto;
  height: auto;
}

.position-table tr:hover td {
  background-color: var(--light-hover-color-3);
}

.position-table th:nth-child(1) {
  width: 60px;
  // vertical-align: middle;
}

.position-table th:first-child,
.position-table td:first-child {
  padding-left: 15px !important;
}

.position-table th:last-child,
.position-table td:last-child {
  padding-right: 15px !important;
}

.position-table th:nth-child(2) {
  width: 105px;
}

.position-table th:nth-child(3) {
  width: 96px;
}

.position-table th:nth-child(4) {
  width: 76px;
}

.position-table th:nth-child(5) {
  width: 56px;
}

.position-table th:nth-child(6),
.position-table th:nth-child(7) {
  width: 70px;
}

.position-table th:nth-child(8),
.position-table th:nth-child(9),
.position-table th:nth-child(10) {
  width: 88px;
}

.position-table th:nth-child(11) {
  width: 77px;
}

.position-table th:nth-child(12) {
  width: 64px;
}

.position-table th:last-child {
  width: 110px;
}

.position-table td:nth-child(4) {
  width: 62px;
  text-align: center;
}

.position-table td:nth-child(6),
.position-table td:nth-child(7) {
  width: 56px;
  text-align: right;
}

.position-table td:nth-child(5) {
  text-align: center;
}

.position-table th:nth-child(7),
.position-table td:nth-child(7) {
  border-right: 1px solid var(--dark-border-color);
}

.position-table td:nth-child(1) {
  padding-left: 15px !important;
}

.position-table th:nth-child(3),
.position-table td:nth-child(3) {
  text-align: center;
}

.position-table td:nth-child(2) .text-78 {
  white-space: nowrap;
}

.position-table tr:hover .ie-btns img {
  opacity: 1;
  transition: opacity 0.3s;
}

.position-table .w-48 {
  width: 48px;
}

.position-table .w-60 {
  width: 60px;
}

.pin-icon {
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: cover;
  display: block;
}

.position-table tr:hover .pin-icon {
  height: 24px;
  width: 24px;
  background: url(assets/img/newImg/Pin-l.svg);
  background-position: center;
  background-size: cover;
  display: block;
}

.pin-active .pin-icon {
  height: 24px;
  width: 24px;
  background: url(assets/img/newImg/Pin.svg);
  background-position: center;
  background-size: cover;
  display: block;
}

.position-table tr.highlighted .pin-icon {
  background: url(assets/img/newImg/Pin.svg);
  background-position: center;
  background-size: cover;
  display: block;
}

.pin-checked .mat-checkbox-inner-container {
  visibility: hidden;
  height: 24px !important;
  width: 24px !important;
}

.position-table tr .mat-checkbox.pin-checked {
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: cover;
  display: block;
}

.position-table tr:hover .pin-checked {
  height: 24px;
  width: 24px;
  background: url(assets/img/newImg/Pin-l.svg);
  background-position: center;
  background-size: cover;
  display: block;
}

.position-table tr .mat-checkbox-checked.pin-checked {
  height: 24px;
  width: 24px;
  background: url(assets/img/newImg/Pin.svg) !important;
  background-position: center;
  background-size: cover;
  display: block;
}

/* .position-table tr.highlighted .pin-icon {
  background: url(assets/img/newImg/Pin-l.svg);
  background-position: center;
  background-size: cover;
  display: block;
} */
.gemValue {
  height: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gemValue .red {
  height: 100%;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--red-light-bg);
  color: var(--font-dark-color);
  margin-right: 2px;
}

.gemValue .right-progress-gem {
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  color: var(--font-dark-color);
  margin-left: 2px;
}

.gemValue .right-progress-gem .right-p-line {
  width: 50%;
  height: 100%;
  background: var(--green-light-bg);
  padding-left: 4px;
  color: var(--font-dark-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mb4 {
  margin-bottom: 4px !important;
}

.text-78 {
  color: var(--light-color) !important;
}

.redText {
  color: var(--red-color) !important;
}

.greenText {
  color: var(--green-color) !important;
}

.lightRedBg {
  background: var(--red-light-bg);
}

.blueText {
  color: #007aff !important;
}

.position-table .none div {
  color: var(--font-dark-color-5) !important;
  padding-left: 10px;
}

.greenUp {
  color: var(--green-color) !important;
}

.greenUp div {
  padding-left: 13px;
  margin-top: 2px;
  text-transform: uppercase;
  position: relative;
}

.greenUp div::before {
  position: absolute;
  content: '';
  background: url(assets/img/greenUp.png);
  background-position: center;
  background-size: cover;
  height: 12px;
  width: 12px;
  left: 0;
}

.redDown {
  color: var(--red-color) !important;
}

.redDown div {
  padding-left: 13px;
  margin-top: 2px;
  text-transform: uppercase;
  position: relative;
}

.redDown div::before {
  position: absolute;
  content: '';
  background: url(assets/img/r-down.svg);
  background-position: center;
  background-size: cover;
  height: 12px;
  width: 12px;
  left: 0;
}

.boldText {
  font-weight: 600 !important;
}

.progress-bar-parent {
  width: 72px;
}

.progress-bar {
  height: 18px;
  width: 100%;
  background: var(--green-light-bg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
  color: var(--font-dark-color);
}

.progress-on-text {
  position: relative;
  height: 18px;
  width: 72px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--white-color);
  overflow: hidden;
}

.progress-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: var(--font-dark-color);
  padding-left: 8px;
  // border-right: 1px solid var(--border-white);
  height: 100%;
}

.light-red-progress .progress-back {
  background: var(--red-light-bg);
}

.light-green-progress .progress-back {
  background: var(--green-light-bg);
}

// .progress-front {
//   position: absolute;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   background: var(--white-bg);
//   color: var(--font-dark-color);
//   clip-path: inset(0 0 0 50%);
//   padding-left: 8px;
// }

.lose-win-bar {
  height: 18px;
  width: 72px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lose-win-bar .lose {
  width: 30%;
  background: var(--red-light-bg);
  color: var(--font-dark-color) !important;
  padding-right: 5px;
  text-align: right;
  line-height: 18px;
}

.lose-win-bar .win {
  width: 70%;
  background: var(--green-light-bg);
  color: var(--font-dark-color) !important;
  padding-left: 5px;
  line-height: 18px;
}

.grey-progress-bar-parent {
  width: 48px;
}

.grey-progress-bar {
  height: 18px;
  width: 100%;
  background: var(--light-bg-13);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
}

.date-progress {
  width: 100%;
  border-bottom: 1px solid var(--dark-border-color);
  position: relative;
  padding-bottom: 5px;
}

.date-value {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pogress-value {
  position: absolute;
  bottom: -1.5px;
  border-radius: 1.5px;
  left: 0;
  height: 3px;
  width: 50%;
  background: var(--dark-bg-2);
  z-index: 2;
}

.position-table .date-progress {
  border-bottom: 0;
}

.position-table .date-progress::after {
  position: absolute;
  content: '';
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--light-bg-13);
  bottom: -2.5px;
  z-index: 1;
  border-radius: 5px;
}

.position-table .date-value p {
  font-size: 10px;
  line-height: 10px;
}

.position-table .date-value p:last-of-type {
  position: absolute;
  top: 20px;
  right: 0;
}

.position-table .pogress-value {
  height: 5px;
  bottom: -2.5px;
  border-radius: 5px;
}

.position-table .date-progress {
  margin-bottom: 14px;
}

/****************************************
     Position sizing css end
*****************************************/

/****************************************
     Wheel split css start
*****************************************/
.wheel-section {
  /* height: calc(100vh - 49px); */
  // height: calc(100vh - 97px);
  height: 100%;
}

.wheel-section .as-horizontal > .as-split-gutter > .as-split-gutter-icon {
  background-image: unset !important;
  background-color: var(--dark-border-color);
}

.wheel-section .left-section {
  border-left: 3px solid var(--border-white);
}

/****************************************
     Wheel split css end
*****************************************/

/****************************************
     Wheel Calculator css end
*****************************************/
.wheel-section .mat-tab-body-wrapper {
  overflow: visible;
}

.wheel-section .mat-tab-body.mat-tab-body-active {
  overflow-x: visible;
  overflow-y: visible;
  z-index: 99;
  width: 100%;
}

.per-legend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lightGreenColor,
.lightPinkColor,
.lightYellowColor {
  margin-right: 4px;
  border-radius: 100%;
  height: 8px;
  width: 8px;
}

.lightGreenColor {
  background: var(--green-bg) !important;
}

.lightPinkColor {
  background: var(--red-bg) !important;
}

.lightYellowColor {
  background: var(--yellow-bg) !important;
}

.per-legend span {
  color: var(--light-color);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
  white-space: nowrap;
}

.per-legend > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wheel-section .wheel-search {
  height: 40px;
  width: 40px;
  min-width: 40px;
  padding: 0;
  background: var(--green-dark-bg);
  border-radius: 0;
  position: absolute;
  right: 0;
  top: -41px;
}

.wheel-section .wheel-search img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/****************************************
     Wheel Calculator split css end
*****************************************/

/****************************************
     Wheel Calculator angular page css Start
*****************************************/
app-wheel-calculator {
  display: block;
  height: 100%;
  overflow: auto;
}

.wheel-calculator-tabel {
  width: 100%;
  z-index: 1;
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
}

.wheel-calculator-tabel table {
  table-layout: auto;
  border-collapse: collapse;
}

.wheel-calculator-tabel .mat-expansion-panel {
  box-shadow: none !important;
}

.wheel-calculator-tabel .mat-expansion-panel-body {
  padding: 0;
}

.wheel-calculator-tabel table tr,
.wheel-calculator-tabel table td {
  transition: 0.1s;
  overflow: hidden;
}

.wheel-calculator-tabel {
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
}

.wheel-calculator-tabel th,
.wheel-calculator-tabel td {
  vertical-align: top;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.wheel-calculator-tabel .mat-header-row {
  min-height: auto;
  height: auto;
}

.wheel-calculator-tabel th {
  color: #891c19;
  font-size: 12px;
  font-weight: 500;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
}

.wheel-calculator-tabel td {
  color: var(--font-dark-color);
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
  vertical-align: middle;
}

.wheel-calculator-tabel tr.mat-row {
  min-height: auto;
  height: auto;
}

.wheel-calculator-tabel th {
  width: 225px;
  vertical-align: middle;
  background-color: var(--white-bg) !important;
  border-right: 1px solid var(--border-white) !important;
}

.wheel-calculator-tabel th:nth-child(1) {
  width: 220px;
  vertical-align: top;
  text-align: center;
}

.wheel-calculator-tabel tr td:first-child {
  background-color: rgba(0, 0, 0, 0.03);
  color: #891c19;
  font-family: RM;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.wheel-calculator-tabel tr th,
.wheel-calculator-tabel tr td {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.wheel-calculator-tabel tr td {
  border-right: 1px solid var(--dark-border-color) !important;
}

.wheel-calculator-tabel tr th:first-child,
.wheel-calculator-tabel tr td:first-child {
  padding-left: 8px !important;
  padding-right: 8px !important;
  border-right: 0 !important;
}

/****************************************
     Wheel Calculator angular page css End
*****************************************/

/*************************************************
     Wheel Calculator noraml table page css End
*************************************************/
.save-print {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.save-print .save-btn {
  width: 81px;
  border: 1px solid var(--light-border-color-2);
  border-radius: 4px;
  background: linear-gradient(180deg, var(--white-bg) 0%, var(--light-bg-3) 100%);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  color: var(--light-color);
  font-size: 11px;
  letter-spacing: 0.33px;
  line-height: 22px;
  font-weight: normal;
}

.save-print .print-btn {
  height: 24px;
  width: 24px;
  line-height: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 100% !important;
}

.save-print .print-btn img {
  height: 24px;
  width: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.save-print .collapse-btn {
  height: 24px;
  width: 24px;
  line-height: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 100% !important;
  background: var(--white-bg);
}

.save-print .collapse-btn img {
  height: 12px;
  width: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.collapse-first-column .save-print .collapse-btn img {
  transform: rotate(-180deg);
}

.wheel-tbl {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: auto;
  max-height: 100%;
}

.wheel-tbl table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
}

.wheel-tbl th,
.wheel-tbl td {
  vertical-align: top;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.wheel-tbl .mat-header-row {
  min-height: auto;
  height: auto;
}

.wheel-tbl th {
  color: var(--font-dark-color);
  font-size: 13px;
  letter-spacing: 0.09px;
  line-height: 24px;
  text-align: left;
  font-weight: normal;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.wheel-tbl td {
  color: var(--font-dark-color);
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
  vertical-align: middle;
}

.wheel-tbl thead th {
  background: transparent;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  z-index: 5;
}

.wheel-tbl th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: var(--white-bg);
}

.wheel-tbl thead th:first-child {
  z-index: 7;
}

.wheel-tbl th {
  width: 168px;
  vertical-align: middle;
  background-color: var(--white-bg) !important;
  border-right: 1px solid var(--border-white) !important;
}

.wheel-tbl thead th:first-child {
  background: var(--light-bg-4) !important;
}

.wheel-tbl th:nth-child(1) {
  min-width: 225px;
  vertical-align: top;
}

.wheel-tbl tr th,
.wheel-tbl tr td {
  padding-right: 8px !important;
  padding-left: 8px;
}

.wheel-tbl tbody tr td .td-ticker {
  display: flex;
  padding-right: 0 !important;
  margin: 0 !important;

  &::after {
    right: -10px;
  }
}

.wheel-tbl tbody tr:last-child th {
  padding-bottom: 10px !important;
}

.wheel-tbl tbody tr:last-child th,
.wheel-tbl tbody tr:last-child td {
  border-bottom: 1px solid var(--dark-border-color) !important;
}

.wheel-tbl thead tr th:last-child,
.wheel-tbl tbody tr td:last-child {
  // padding-right: 40px !important;
}

.wheel-tbl tr td {
  position: relative;
}

.wheel-tbl tr td::after {
  position: absolute;
  content: '';
  right: 0;
  height: 100%;
  width: 1px;
  background: var(--light-bg-13);
  top: 0;
}

.wheel-tbl tr td:last-child:after {
  // right: 31.5px;
}

.wheel-tbl tbody th {
  padding-left: 8px !important;
  padding-right: 8px !important;
  border-right: 0 !important;
}

.wheel-tbl tbody th:first-child {
  background-color: var(--light-bg-4) !important;
  border-right: 1px solid var(--border-white) !important;
}

.wheel-tbl thead th:first-child {
  text-align: center;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.wheel-tbl .disabled-icon .w-delete {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
  cursor: not-allowed;
}

.wheel-tbl tbody th .th-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.wheel-tbl .selling-puts .th-div,
.wheel-tbl .selling-calls .th-div {
  justify-content: flex-start;
  cursor: pointer;
}

.wheel-tbl tbody th .help {
  height: 14px;
  width: 14px;
  margin-right: 6px;
  cursor: pointer;
}

.wheel-tbl tbody th .help path {
  fill: var(--light-svg);
}

.wheel-tbl tbody span {
  display: inline-block;
  margin-left: 8px;
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

.wheel-tbl .th-div span {
  line-height: 13px;
}

.wheel-tbl tbody th ul {
  display: inline-block;
  padding-left: 4px;
}

.wheel-tbl tbody th ul li {
  display: inline;
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  padding-right: 4px;
  padding-left: 4px;
  position: relative;
  border-right: 1px solid var(--light-border-color-1);
}

.wheel-tbl tbody th ul li:last-child {
  border-right: 0;
  padding-right: 0;
}

.wheel-tbl tbody td {
  min-width: 195px;
}

.wheel-tbl .heading-text {
  color: var(--white-color);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.09px;
  font-family: RM;
  line-height: 26px;
}

.wheel-tbl .selling-puts .heading-text.p-0 {
  padding-top: 2px !important;
}

.wheel-tbl .selling-calls .heading-text.p-0 {
  padding-top: 1px !important;
}

.wheel-tbl .heading-text > div {
  position: relative;
}

.wheel-tbl .heading-text > div::after {
  height: 18px;
  width: 18px;
  position: absolute;
  content: '';
  background: url(assets/img/down-white.svg);
  background-position: center;
  background-size: cover;
  transform: rotate(-180deg);
  right: 8px;
  transition: 0.3s all;
}

.wheel-tbl table {
  .wheel-profit-link {
    a {
      font-size: 13px;
      line-height: 24px;
      color: var(--primary-color);
      background-color: #ebf6fe;
      border-radius: 4px;
      padding: 2px 11px;
      max-width: 50%;
      transition:
        color 0.3s ease,
        background-color 0.3s ease;

      &:hover {
        background-color: var(--primary-color);
        color: #fff;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;

      a {
        &:hover {
          color: var(--primary-color);
          background-color: #ebf6fe;
        }
      }
    }
  }
}

.ref-btn.mat-button {
  padding: 0 4px;
  min-width: 20px;
  width: 140px;
  height: 24px;
  color: var(--font-dark-color);
  font-family: RR;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  border-radius: 0;
  line-height: 24px;
}

.ref-btn.mat-button img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 6px;
}

.input-btn-header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-btn-header img {
  height: 23px;
  width: 23px;
  cursor: pointer;
}

.w-clear {
  height: 23px;
  width: 23px;
  cursor: pointer;
  background: url(assets/img/newImg/Clear-blur.svg) no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  flex: 1 0 auto;
  transition: all 0.3s;
}

.w-delete {
  height: 23px;
  width: 23px;
  background: url(assets/img/newImg/Delete-blur.svg);
  background-size: contain;
  background-position: center;
  display: block;
  transition: all 0.3s;
}

.w-clear:hover {
  background: url(assets/img/newImg/Clear-dark.svg);
  background-size: contain;
  background-position: center;
}

.w-delete:hover {
  height: 23px;
  width: 23px;
  cursor: pointer;
  background: url(assets/img/newImg/Delete-dark.svg);
  background-size: contain;
  background-position: center;
}

.input-btn-header img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.w-print {
  margin-right: 8px;
  margin-left: 4px;
  display: none;
}

.input-btn-header input {
  width: 107px;
  border: 0;
  outline: none;
  box-shadow: none !important;
  border-radius: 0;
  background: transparent;
  color: var(--font-dark-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  height: 16px;
  padding-left: 0px;
  text-align: left !important;
  padding-right: 8px;
  padding-top: 3px;
}

.input-btn-header input::-moz-selection {
  background: transparent;
  color: var(--font-dark-color);
}

.input-btn-header input::selection {
  background: transparent;
  color: var(--font-dark-color);
}

.input-btn-header input::-webkit-input-placeholder {
  color: var(--light-color);
  font-size: 14px;
  letter-spacing: 0.1px;
}

.input-btn-header input::-moz-placeholder {
  color: var(--light-color);
  font-size: 14px;
  letter-spacing: 0.1px;
}

.input-btn-header input:-ms-input-placeholder {
  color: var(--light-color);
  font-size: 14px;
  letter-spacing: 0.1px;
}

.input-btn-header input::-ms-input-placeholder {
  color: var(--light-color);
  font-size: 14px;
  letter-spacing: 0.1px;
}

.input-btn-header input::placeholder {
  color: var(--light-color);
  font-size: 14px;
  letter-spacing: 0.1px;
}

.w-clear {
  margin-left: 8px;
}

.w-delete {
  margin-left: 4px;
}

.w-add {
  height: 18px;
  width: 18px;
  background: var(--white-bg);
  position: absolute;
  display: flex;
  right: -18px;
  justify-content: center;
  align-items: center;
  display: none;
}

.w-add img {
  height: 18px;
  width: 18px;
}

.wheel-tbl thead th:last-child .w-add {
  display: block;
}

.wheel-tbl thead th:last-child .w-add img {
  -webkit-filter: grayscale(6000000);
  filter: grayscale(6000000);
  opacity: 0.3;
  transition: all 0.3s;
}

.wheel-tbl thead th:last-child .w-add:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
  transition: all 0.3s;
}

.pre-d-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--light-bg-5);
  height: 26px;
  border-radius: 3px;
}

.pre-sign {
  width: 25px;
  height: 16px;
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.pre-d-input input {
  width: calc(100% - 25px);
  padding: 0 8px;
  height: 16px;
  color: var(--font-dark-color);
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
}

.pre-d-input.i-100 input {
  width: 100%;
  text-align: left;
}

.selling-puts .pre-d-input.i-100 input,
.selling-calls .pre-d-input.i-100 input {
  text-align: center;
  font-weight: 600;
}

.selling-puts .pre-d-input.i-100 input::-webkit-input-placeholder,
.selling-calls .pre-d-input.i-100 input::-webkit-input-placeholder {
  font-weight: 600;
}

.selling-puts .pre-d-input.i-100 input::-moz-placeholder,
.selling-calls .pre-d-input.i-100 input::-moz-placeholder {
  font-weight: 600;
}

.selling-puts .pre-d-input.i-100 input:-ms-input-placeholder,
.selling-calls .pre-d-input.i-100 input:-ms-input-placeholder {
  font-weight: 600;
}

.selling-puts .pre-d-input.i-100 input::-ms-input-placeholder,
.selling-calls .pre-d-input.i-100 input::-ms-input-placeholder {
  font-weight: 600;
}

.selling-puts .pre-d-input.i-100 input::-webkit-input-placeholder,
.selling-calls .pre-d-input.i-100 input::-webkit-input-placeholder {
  font-weight: 600;
}

.selling-puts .pre-d-input.i-100 input::-moz-placeholder,
.selling-calls .pre-d-input.i-100 input::-moz-placeholder {
  font-weight: 600;
}

.selling-puts .pre-d-input.i-100 input:-ms-input-placeholder,
.selling-calls .pre-d-input.i-100 input:-ms-input-placeholder {
  font-weight: 600;
}

.selling-puts .pre-d-input.i-100 input::-ms-input-placeholder,
.selling-calls .pre-d-input.i-100 input::-ms-input-placeholder {
  font-weight: 600;
}

.selling-puts .pre-d-input.i-100 input::placeholder,
.selling-calls .pre-d-input.i-100 input::placeholder {
  font-weight: 600;
}

.bottom-min-max {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1px;
}

.bottom-min-max p,
.bottom-min-max p,
.bottom-min-max p span {
  color: var(--light-color);
  font-size: 11px;
  letter-spacing: 0.33px;
  line-height: 16px;
}

.bottom-min-max .red span {
  color: var(--red-color);
  margin-left: 2px;
}

.bottom-min-max .green span {
  color: var(--green-color);
  margin-left: 2px;
}

.wheel-tbl .date-select {
  height: 24px;
  width: 100%;
  text-align: right;
  background-color: var(--light-bg-5);
  outline: none;
  border: 0;
  color: var(--font-dark-color);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.09;
  border-radius: 3px;
  padding: 0 24px 0 8px;
  background-image: url(assets/img/newImg/Arrow.svg);
  background-position: calc(100% - 5px), calc(100% - 10px);
  background-size: 16px;
  background-repeat: no-repeat;
}

.date-select-outer.mat-form-field {
  width: 100%;
  margin-left: auto;
  border-radius: 3px;
  height: 26px;
  border: 1px solid var(--light-border-color-5);
  outline-color: var(--primary-color);
}

// .date-select-outer.mat-form-field.mat-focused {
//   border: 1px solid var(--primary-color);
// }
.date-select-outer.mat-form-field .date-select {
  padding-right: 8px;
  padding: 2.5px 8px;
}

.date-select-outer .mat-form-field-wrapper {
  padding-bottom: 0;
}

.date-select-outer .date-select .mat-select-arrow-wrapper {
  margin: 0;
  opacity: 0;
}

.date-select-outer .mat-form-field-infix {
  padding: 0;
  border-top: 0;
  width: unset;
  border-radius: 3px;
}

.date-select-outer .mat-form-field-underline {
  display: none;
}

.date-select-outer .mat-form-field-subscript-wrapper {
  display: none;
}

.date-select-outer .mat-select-value-text {
  display: block;
  margin-left: 0;
}

.date-select-outer .mat-select-value-text span {
  font-size: 13px;
  color: var(--font-dark-color);
  font-weight: 500;
  letter-spacing: 0;
  display: block;
  text-align: left;
  margin-left: 0;
}

.date-select-option {
  box-shadow: 0 2px 4px 0 var(--box-shadow-3) !important;
  border-radius: 3px !important;
}

.date-select-option .mat-option-text {
  color: var(--font-dark-color-2) !important;
}

.date-select-option .mat-active .mat-option-text {
  color: var(--primary-color) !important;
}

.date-select-option .mat-active {
  background: var(--light-hover-color) !important;
}

.date-select-option .mat-option:hover,
.date-select-option .mat-active:hover {
  background: var(--light-hover-color) !important;
}

.date-select-option .mat-option {
  line-height: 30px !important;
  height: unset !important;
}

.s-content {
  margin-top: 1px !important;
  color: var(--light-color);
  font-size: 11px;
  letter-spacing: 0.33px;
  line-height: 16px;
}

.p-input {
  height: 24px;
  border: 0;
  outline: none;
  box-shadow: none;
  padding: 0 8px;
  color: var(--font-dark-color);
  font-family: RM;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
}

.round-div {
  padding-left: 4px !important;
  padding-right: 0 !important;
  align-items: center;
  text-align: left !important;
}

.round-div .round-div-item {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  border: 1px solid var(--border-color-1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.left-i-right-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-i-right-c .p-input {
  width: 48px;
  text-align: right;
}

.left-i-right-c p {
  color: var(--light-color);
  font-size: 11px;
  letter-spacing: 0.33px;
  line-height: 16px;
  font-weight: normal;
}

.left-i-right-c.with-trade-btn p {
  width: 100%;
  text-align: center;
  margin-top: 4px;
}

.left-i-right-c .whiteDiv .round-div {
  padding-left: 0 !important;
  justify-content: center;
}

.full-input .p-input {
  width: 100%;
  text-align: center;
}

.two-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.two-input input {
  width: 50%;
  padding-left: 16px;
  padding-right: 16px;
}

.two-input input:last-child {
  text-align: right;
}

.blurText {
  color: var(--blur-color) !important;
}

// .yellowInput {
//   background: #FAC002 !important;
// }
// .yellowInput input {
//   color: var(--white-color);
// }
// .redInput {
//   background: #FF6464 !important;
// }
// .redInput input {
//   color: var(--white-color);
// }

.whiteDiv {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.whiteDiv > div {
  width: 50%;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--font-dark-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
}

.whiteDiv > div:last-child {
  text-align: right;
}

.value-center-dotted > div {
  text-align: center !important;
  color: var(--light-color) !important;
}

.value-center-dotted > div:last-child {
  position: relative;
}

.value-center-dotted > div:last-child::before {
  position: absolute;
  top: 50%;
  content: '';
  left: -3px;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background: var(--light-bg-6);
  transform: translateY(-50%);
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left;
}

.v-hidden {
  visibility: hidden;
}

.t-middle {
  vertical-align: middle !important;
}

.t-line {
  width: 80px;
  margin: 0 auto;
  height: 1px;
  background: var(--light-bg-13);
}

.total-tbl {
  color: var(--light-color);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.09px;
  line-height: 24px;
  text-align: center;
}

.pb-0 th,
.pb-0 td {
  padding-bottom: 0 !important;
}

.pt-0 th,
.pt-0 td {
  padding-top: 0 !important;
}

.ptb-0 th,
.ptb-0 td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pre-d-input input::-webkit-input-placeholder {
  color: var(--light-color-3);
  font-weight: normal;
}

.pre-d-input input::-moz-placeholder {
  color: var(--light-color-3);
  font-weight: normal;
}

.pre-d-input input:-ms-input-placeholder {
  color: var(--light-color-3);
  font-weight: normal;
}

.pre-d-input input::-ms-input-placeholder {
  color: var(--light-color-3);
  font-weight: normal;
}

.pre-d-input input::placeholder {
  color: var(--light-color-3);
  font-weight: normal;
}

.wheel-tbl .pre-sign {
  color: var(--light-color-3);
}

.wheel-tbl .collapse-first-column thead th:first-child,
.wheel-tbl .collapse-first-column th:nth-child(1) {
  width: 40px;
  min-width: 40px;
}

.wheel-tbl .collapse-first-column thead th:first-child .save-btn,
.wheel-tbl .collapse-first-column tbody th:nth-child(1) > * {
  display: none;
}

/*************************************************
     Wheel Calculator noraml table page css start
*************************************************/

/*************************************************
     Option Compare Popup css start
*************************************************/
.compare-tbl {
  width: 100%;
}

.compare-tbl {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
}

.compare-tbl table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.compare-tbl th,
.compare-tbl td {
  font-size: 12px;
  border-bottom: 1px solid var(--dark-border-color);
  background: var(--white-bg);
  vertical-align: top;
  text-align: left;
}

.compare-tbl thead th {
  background: var(--white-bg);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.compare-tbl thead th:first-child {
  z-index: 5;
}

.compare-tbl thead th {
  padding: 6px 8px;
  color: var(--light-color);
  font-family: RR;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
}

.compare-tbl td {
  color: var(--font-dark-color);
  padding: 4px 8px;
  font-family: RR;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  vertical-align: middle;
  text-align: center;
}

.compare-tbl th:first-child,
.compare-tbl td:first-child {
  padding-left: 16px;
  padding-right: 2px;
  text-align: left;
}

.compare-tbl th:last-child,
.compare-tbl td:last-child {
  padding-right: 16px;
}

.compare-tbl td:nth-child(2) {
  border-right: 1px solid var(--dark-border-color);
}

.compare-tbl th:nth-child(1),
.compare-tbl td:nth-child(1) {
  width: 62px;
}

.compare-tbl th:nth-child(5),
.compare-tbl td:nth-child(5) {
  width: 69px;
  text-align: center;
}

.compare-tbl tbody tr:nth-child(even) td {
  background: var(--light-bg-7);
}

.d-not {
  display: none;
}

.compare-tbl .help-icon-th {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}

.compare-tbl .help-icon-th img {
  margin-left: 4px;
  cursor: pointer;
}

.compare-tbl .variant {
  padding: 8px;
  border-radius: 4px;
  background: rgba(224, 227, 235, 0.35);
  width: 84px;
}

.compare-tbl .sort-full-name span {
  color: var(--light-color);
  font-size: 10px;
  line-height: 11px;
  white-space: nowrap;
}

.compare-tbl .rm-14 {
  font-family: RM;
  font-size: 14px;
}

.compare-tbl .contract {
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  border: 1px solid var(--border-color-1);
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.compare-tbl .bid-ask {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.compare-tbl .bid-ask img {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
}

.compare-tbl .l-yellow {
  padding: 4px;
  background: var(--dark-yellow-bg);
  min-width: 42px;
  display: inline-block;
}

.compare-tbl .stop-target {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.compare-tbl .stop-target p:first-child {
  margin-right: 4px;
}

.compare-tbl .stop-target p:last-child {
  margin-left: 4px;
}

.compare-tbl .red {
  color: var(--red-color) !important;
}

.compare-tbl .green {
  color: var(--green-color) !important;
}

.compare-tbl .blur {
  color: var(--light-color) !important;
}

.compare-tbl tbody:hover .pin-icon {
  height: 24px;
  width: 24px;
  background: url(assets/img/newImg/Pin.svg);
  background-position: center;
  background-size: cover;
  display: block;
}

.compare-tbl tbody.highlighted .pin-icon {
  background: url(assets/img/newImg/Pin-l.svg);
  background-position: center;
  background-size: cover;
  display: block;
}

/*************************************************
     Option Compare Popup css End
*************************************************/

/*************************************************
     Trading css Start
*************************************************/

app-trading-report {
  display: block;
  height: 100%;
}

.trading-report {
  height: 100%;
}

.trading-headers {
  padding-left: 8px;
}

.trading-report .result-based {
  margin-bottom: 16px;
}

.trading-report .btn-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trading-report .btn-headers .toggle-button-red {
  margin-top: 16px;
}

.trading-report .btn-headers .toggle-button-red .mat-button-toggle-appearance-standard {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 12px;
  letter-spacing: 0.21px;
  line-height: 20px;
}

.trading-report .btn-headers .toggle-button-red .mat-button-toggle-appearance-standard:not(:last-child) {
  border-right: 0px solid var(--primary-color);
}

.trading-report .btn-headers .toggle-button-red .mat-button-toggle-checked {
  background-color: var(--primary-color);
  color: var(--white-color);
  font-size: 12px;
  letter-spacing: 0.21px;
  line-height: 20px;
}

.trading-report .btn-headers .toggle-button-red .mat-button-toggle-group-appearance-standard {
  border-radius: 0px;
}

.trading-report .btn-headers .toggle-button-red .mat-button-toggle-label-content {
  line-height: 10px;
}

.trading-report .btn-headers .toggle-button-red .mat-button-toggle-label-content {
  line-height: 24px;
  width: 104px;
}

.trading-report .btn-headers .large.toggle-button-red .mat-button-toggle-label-content {
  line-height: 32px;
  min-width: 108px;
  font-size: 14px;
  letter-spacing: 0.25px;
  width: auto;
}

.trading-report .flex-border {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--dark-border-color);
}

.right-view-toggle .cap {
  color: var(--light-color);
}

.blue-line-toggle .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 40px !important;
}

.blue-line-toggle .target-toggle-button .target {
  padding-right: 12px;
}

.blue-line-toggle .target-toggle-button .target span {
  line-height: 27px;
}

.blue-line-toggle .target-toggle-button .toggle-button-red .mat-button-toggle-appearance-standard {
  color: var(--light-color);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.blue-line-toggle .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: 0;
}

.blue-line-toggle .target-toggle-button .toggle-button-red .mat-button-toggle-checked {
  position: relative;
  color: var(--font-dark-color);
  background: transparent;
  font-weight: 500;
}

.blue-line-toggle .target-toggle-button .toggle-button-red .mat-button-toggle-checked::after {
  position: absolute;
  content: '';
  width: 100%;
  bottom: 0px;
  left: 0;
  height: 2px;
  background: var(--primary-color);
  z-index: 2;
}

.blue-line-toggle .target-toggle-button .toggle-button-red .mat-button-toggle-group-appearance-standard {
  border-radius: 0px;
  border: 0;
}

.blue-line-toggle .target-toggle-button .toggle-button-red .mat-button-toggle-label-content {
  line-height: 10px;
}

.blue-line-toggle .target-toggle-button .toggle-button-red .mat-button-toggle-label-content {
  line-height: 40px;
  padding: 0 9px;
}

.right-view-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 8px;
}

.right-view-toggle .col-row-indicator {
  margin-left: 12px;
}

/* trading report conservative table css start */

.report-con-tbl {
  width: 100%;
  height: calc(100% - 41px);
}

.report-con-tbl {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
}

.report-con-tbl table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.report-con-tbl th,
.report-con-tbl td {
  border-bottom: 1px solid var(--dark-border-color);
  background: var(--white-bg);
  vertical-align: top;
  text-align: left;
}

.report-con-tbl thead th {
  background: var(--white-bg);
  position: -webkit-sticky;
  white-space: nowrap;
  position: sticky;
  z-index: 5;
  top: 0;
}

.report-con-tbl th {
  color: var(--light-color);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 14px;
  padding: 6px 8px;
}

.report-con-tbl td {
  color: var(--font-dark-color);
  font-size: 12px;
  border: 0;
  letter-spacing: 0.4px;
  line-height: 16px;
  vertical-align: middle;
  padding: 8px 8px;
}

.report-con-tbl tbody tr:nth-child(even) td {
  background: var(--light-bg-7);
}

.report-con-tbl th:last-child {
  text-align: center;
}

.report-con-tbl .start-end-date-name {
  width: 206px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-con-tbl .fonts-14px {
  color: var(--font-dark-color);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

.profit-center {
  width: 176px;
  margin-left: auto;
}

.compare-profit-th {
  text-align: unset !important;
}

.report-con-tbl .start-end-date-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 206px;
}

.report-con-tbl .start-end-date-value p {
  width: 65px;
  white-space: nowrap;
}

.report-con-tbl .date-progress-bar {
  width: 65px;
  margin-left: 8px;
  text-align: center;
  margin-right: 8px;
  position: relative;
  font-size: 10px;
  letter-spacing: 0.33px;
  color: var(--light-color);
  line-height: 16px;
}

.report-con-tbl .date-progress-bar::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  left: 0;
  background: var(--light-bg-13);
  bottom: -0.5px;
}

.report-con-tbl .date-progress-bar .progress-line {
  position: absolute;
  width: 40px;
  height: 3px;
  left: 0;
  background: var(--dark-bg-2);
  bottom: -1.5px;
  border-radius: 1.5px;
  z-index: 1;
}

.report-con-tbl .long {
  display: inline-block;
  background: var(--green-bg);
  color: var(--all-theme-white);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  padding: 2px 7.5px;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  width: 48px;
}

.report-con-tbl .short {
  display: inline-block;
  background: var(--red-bg);
  color: var(--all-theme-white);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  padding: 2px 7.5px;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  width: 48px;
}

.report-con-tbl .bb {
  display: inline-block;
  border-radius: 2px;
  background-color: var(--dark-bg-1);
  color: var(--white-color);
  font-size: 9px;
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  padding: 3px 2px;
  margin-left: 8px;
}

.report-con-tbl .sl {
  display: inline-block;
  border-radius: 2px;
  background-color: #ff90a8;
  color: var(--font-dark-color);
  font-size: 9px;
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  padding: 3px 2px;
  margin-left: 8px;
}

.report-con-tbl .pt {
  display: inline-block;
  border-radius: 2px;
  background-color: #7be4a2;
  color: var(--font-dark-color);
  font-size: 9px;
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  padding: 3px 2px;
  margin-left: 8px;
}

.profit-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.profit-bar .progress-space {
  width: 176px;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.profit-bar .profit-progress {
  height: 12px;
  width: 88px;
  display: inline-flex;
}

.profit-bar .profit-progress .profit-line {
  height: 12px;
}

.profit-bar p {
  white-space: nowrap;
}

.profit-bar.red p {
  color: var(--red-color);
}

.profit-bar.red .profit-progress {
  justify-content: flex-start;
  background: var(--red-light-bg);
  border-radius: 2px;
}

.profit-bar.red .profit-progress .profit-line {
  background: var(--red-bg);
  border-radius: 2px;
  margin-left: auto;
  width: 18px;
}

.profit-bar.green p {
  color: var(--green-color);
}

.profit-bar.green .progress-space {
  justify-content: flex-end;
}

.profit-bar.green .profit-progress {
  background: var(--green-light-bg);
  border-radius: 2px;
}

.profit-bar.green .profit-progress .profit-line {
  background: var(--green-bg);
  border-radius: 2px;
  width: 28px;
}

.stop-entry-target-name,
.stop-entry-target-value,
.set-numeric-value {
  width: 256px;
}

.stop-entry-target-name,
.set-numeric-value {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.set-numeric-value p:first-child,
.set-numeric-value p:last-child {
  font-size: 10px;
}

.ls-value {
  text-align: center;
  margin-bottom: 2px;
}

.ls-value span {
  display: inline-block;
  padding-left: 14px;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  text-transform: uppercase;
}

.ls-value span::before {
  position: absolute;
  content: '';
  left: 0;
  height: 12px;
  width: 12px;
}

.set-main-progress {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
}

.set-progress-full-line {
  width: 100%;
  height: 6px;
  background: var(--light-bg-13);
  position: relative;
}

.set-progress-full-line::after {
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  border-radius: 100%;
  z-index: 3;
  background: var(--dark-bg-1);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.set-progress-half {
  width: 50%;
  height: 100%;
}

.set-progress {
  height: 100%;
  position: relative;
  max-width: 100%;
}

.meter-value {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
}

.meter-value img {
  height: 4px;
  width: 6px;
  display: block;
}

.long-side .ls-value span {
  color: var(--green-color);
}

.long-side .ls-value span::before {
  background: url(assets/img/newImg/up-green.png);
  background-position: center;
  background-size: cover;
}

.right-bar.set-progress-half {
  margin-left: auto;
}

.right-bar .set-progress {
  background: #28cd41;
}

.right-bar .meter-value {
  right: 0;
  transform: translateX(50%);
}

.short-side .ls-value span {
  color: var(--red-color);
}

.short-side .ls-value span::before {
  background: url(assets/img/newImg/down-red.png);
  background-position: center;
  background-size: cover;
}

.left-bar .set-progress {
  margin-left: auto;
  background: #ff453a;
}

.left-bar .meter-value {
  transform: translateX(-50%);
}

.d-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.d-space-between {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.visualViewTbl .progress-space {
  width: 200px;
}

.visualViewTbl .profit-progress {
  width: 100px;
}

.report-con-tbl .progress-bar {
  position: relative;
  padding-left: 0;
}

.report-con-tbl .progress-inner-value {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.report-con-tbl .progress-bar.green {
  background: var(--green-light-bg);
  color: var(--green-color);
}

.report-con-tbl .progress-bar.red {
  background: var(--red-light-bg);
  color: var(--red-color);
}

.report-con-tbl .progress-bar-parent {
  overflow: hidden;
  width: 60px;
}

/* trading report conservative table css start */
/*************************************************
     Trading css Start
*************************************************/

/****************************************
    Conservative Table css start
*****************************************/
app-wheel-scanner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.conservative-table,
.aggresive-table {
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.conservative-table .company-eclips,
.aggresive-table .company-eclips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.conservative-table table,
.aggresive-table table {
  width: 100%;
  margin: auto;
  table-layout: fixed;
}

.conservative-table th,
.conservative-table td,
.aggresive-table th,
.aggresive-table td {
  background: var(--white-bg);
  vertical-align: top;
  white-space: nowrap;
  border-color: var(--dark-border-color);
}

.conservative-table .mat-header-row,
.aggresive-table .mat-header-row {
  min-height: auto;
  height: auto;
}

.conservative-table th,
.aggresive-table th {
  color: var(--light-color);
  font-size: 12px;
  padding: 6px 8px !important;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 14px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--dark-border-color);

  &.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.conservative-table td,
.aggresive-table td {
  color: var(--font-dark-color);
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  vertical-align: middle;
  border-collapse: collapse;
  border-color: var(--light-border-color-3);
}

.conservative-table tr:last-child td,
.aggresive-table tr:last-child td {
  border-bottom: 0;
}

.conservative-table tr.mat-row,
.aggresive-table tr.mat-row {
  min-height: auto;
  height: auto;
}

.conservative-table tr:hover td,
.aggresive-table tr:hover td {
  background-color: var(--light-bg-1);
}

.conservative-table th:nth-child(1),
.aggresive-table th:nth-child(1) {
  width: 50px;
  vertical-align: middle;
}

.conservative-table th:nth-child(2),
.aggresive-table th:nth-child(2) {
  width: 80px;
}

.conservative-table tr:hover .ie-btns,
.aggresive-table tr:hover .ie-btns {
  opacity: 1;
  transition: opacity 0.3s;
}

.conservative-table tbody tr:first-child td,
.aggresive-table tbody tr:first-child td {
  border-top: 1px solid transparent;
}

.conservative-table td .borderSelection,
.aggresive-table td .borderSelection {
  padding: 3px 7px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;

  &.active {
    .emoji.Grey {
      display: block;
    }
  }
}

.conservative-table .selected td .borderSelection::after,
.aggresive-table .selected td .borderSelection::after {
  background: var(--primary-color);
  position: absolute;
  content: '';
  width: calc(100% + 4px);
  height: 2px;
  top: 0;
  left: -2px;
}

.conservative-table .selected td .borderSelection::before,
.aggresive-table .selected td .borderSelection::before {
  background: var(--primary-color);
  position: absolute;
  content: '';
  width: calc(100% + 4px);
  height: 2px;
  bottom: 0;
  left: -2px;
}

.conservative-table .selected td:first-child .borderSelection,
.aggresive-table .selected td:first-child .borderSelection {
  border-left: 2px solid var(--primary-color);
  padding-left: 5px;
}

.conservative-table .selected td:last-child .borderSelection,
.aggresive-table .selected td:last-child .borderSelection {
  border-right: 2px solid var(--primary-color);
  padding-right: 5px;
}

.conservative-table .selected td,
.aggresive-table .selected td {
  background-color: transparent !important;
}

.conservative-table tr:hover td,
.aggresive-table tr:hover td {
  background-color: var(--light-bg-1) !important;
}

.smiley-menu.mat-menu-panel {
  box-shadow: 0 2px 8px 0 var(--box-shadow-2);
  min-height: initial;
  position: relative;
  overflow: visible;
}

.smiley-menu.symbol-flag-menu.mat-menu-panel {
  position: absolute;
  left: 28px;
  top: -28px;
}

.smiley-menu.mat-menu-panel::before {
  position: absolute;
  content: '';
  background: url(assets/img/White-left-arrow.svg);
  width: 16px;
  background-position: center;
  background-size: cover;
  height: 30px;
  top: 6px;
  left: -16px;
}

.smiley-menu .mat-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}

.smiley-menu .mat-menu-content {
  display: flex;
}

.smiley-menu .mat-menu-item {
  padding-left: 12px;
  padding-right: 16px;
  padding-bottom: 5px;
  padding-top: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}

.smiley-menu.symbol-flag-menu .mat-menu-item {
  padding-top: 0;
}

.smiley-menu .mat-menu-item {
  color: var(--font-dark-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  background-color: transparent !important;

  .mat-menu-ripple {
    display: none;
  }

  &.mat-button-focus-overlay,
  &.mat-menu-item-highlighted {
    background-color: transparent !important;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04) !important;
  }
}

.smiley-menu .mat-menu-item:last-child {
  border-bottom: 0;
}

.smiley-menu .mat-menu-item img {
  height: 15px;
  width: 15px;
  margin-top: 2px;
}

.smiley-menu .mat-menu-item .icon {
  height: 15px;
  width: 15px;
  margin-top: 2px;
  margin-right: 0;
}

.conservative-table .no-data-msg,
.aggresive-table .no-data-msg {
  margin-top: 10px;
  width: 100%;
  text-align: left;
  color: var(--light-color);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  padding: 0px 17px;
  text-align: center;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: var(--font-dark-color);
    margin-bottom: 20px;
  }

  .all-good {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
  }

  a {
    text-decoration: none;
    color: var(--primary-color);
  }
}

/****************************************
    Conservative Table css end
*****************************************/

/****************************************
    Sweet Alert CSS Start
*****************************************/
.sweet-alert {
  width: 400px !important;
  border-radius: 0;
}

.sweet-alert .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
}

.sweet-alert .mat-dialog-content {
  margin: 0 !important;
  padding: 24px 24px 24px 24px !important;
  text-align: center;
}

.sweet-alert .mat-dialog-actions {
  margin-bottom: 0 !important;
  padding: 16px !important;
  border-top: 1px solid var(--dark-border-color);
}

.sweet-alert .mat-typography img {
  margin-bottom: 15px;
}

.sweet-alert .mat-typography h4 {
  margin-bottom: 16px;
  color: var(--black-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 21px;
  text-align: center;
}

.sweet-alert .mat-typography p {
  margin-bottom: 0;
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.21px;
  line-height: 18px;
  text-align: center;
}

/****************************************
    Sweet Alert CSS End
*****************************************/

/**********************************************
     Add Symbol to Watchlist popup css start
***********************************************/
.add-symbol-to-watchlist {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: auto;
}

.add-symbol-to-watchlist .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 404px;
  margin: 50px auto;
}

.add-symbol-to-watchlist .mat-dialog-content {
  margin: 0 !important;
  padding: 16px 24px 8px 24px !important;
  max-height: unset;
}

.add-symbol-to-watchlist label {
  color: var(--font-dark-color-2) !important;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 14px;
}

.add-symbol-to-watchlist .input {
  width: 100%;
  display: block;
  margin-top: 8px;
  padding-left: 8px !important;
  border: 1px solid var(--light-border-color);
  background: transparent;
  color: var(--input-color);
  font-size: 13px;
  box-shadow: none !important;
}

.add-symbol-to-watchlist .input::-webkit-input-placeholder {
  color: var(--light-color-3);
  font-size: 13px;
}

.add-symbol-to-watchlist .input::-moz-placeholder {
  color: var(--light-color-3);
  font-size: 13px;
}

.add-symbol-to-watchlist .input:-ms-input-placeholder {
  color: var(--light-color-3);
  font-size: 13px;
}

.add-symbol-to-watchlist .input::-ms-input-placeholder {
  color: var(--light-color-3);
  font-size: 13px;
}

.add-symbol-to-watchlist .input::placeholder {
  color: var(--light-color-3);
  font-size: 13px;
}

.add-symbol-to-watchlist .full-input {
  margin-bottom: 16px;
}

.add-symbol-to-watchlist .full-input:last-of-type {
  margin-bottom: 0;
}

.MtoY {
  margin-top: 8px;
  color: var(--light-color);
  margin-bottom: 0;
  display: block;
}

.pxo-samll-autofocus.mat-autocomplete-panel {
  max-height: 155px;
  border-radius: 0;
}

.pxo-samll-autofocus .mat-option {
  line-height: 31px;
  height: 31px;
  font-size: 12px;
  padding: 0 10px;
  color: var(--input-color);
}

.pxo-samll-autofocus .mat-option:hover:not(.mat-option-disabled),
.pxo-samll-autofocus .mat-option:focus:not(.mat-option-disabled) {
  background: var(--light-hover-color-2);
}

/**********************************************
     Add Symbol to Watchlist popup css End
***********************************************/

/**********************************************
     import and export popup css start
***********************************************/
.import-export-symbol .mat-form-field-infix {
  border: 0 !important;
}

.import-export-symbol {
  width: 372px !important;
  border-radius: 0;
}

.import-export-symbol .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
}

.import-export-symbol .mat-dialog-content {
  margin: 0 !important;
  padding: 24px 24px 0px 24px !important;
}

.import-export-symbol .input {
  width: 100%;
  padding-left: 8px;
  color: var(--input-color);
}

.import-export-symbol .full-input label {
  color: var(--font-dark-color-2);
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
}

.full-input label,
.mat-error {
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
  display: block;
}

.mat-error {
  margin-top: 8px;
}

.import-export-symbol .mat-typography img {
  margin-bottom: 24px;
}

.import-export-symbol .mat-typography h4 {
  margin-bottom: 16px;
  color: var(--black-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}

.import-export-symbol .mat-typography p {
  margin-bottom: 0;
  color: var(--font-dark-color);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
}

.import-export-symbol .input {
  margin-bottom: 0;
  border: 1px solid var(--light-border-color);
  background: transparent;
  color: var(--input-color);
}

.import-export-symbol .full-input {
  margin-bottom: 16px;
}

.import-export-symbol .full-input:last-of-type {
  margin-bottom: 0;
}

.import-export-symbol .choose-file-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  background: var(--light-hover-color);
  padding: 4px;
  border-radius: 4px;
}

.import-export-symbol .choose-file-div input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 28px;
  width: 96px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.import-export-symbol .choose-file-btn {
  height: 28px;
  width: 96px;
  background-color: var(--dark-bg-3) !important;
  color: var(--font-dark-color) !important;
  border: 0 !important;
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif !important;
}

.import-export-symbol .choose-file-div p {
  margin-left: 8px;
  color: var(--font-dark-color-4);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}

.import-export-symbol .import-label {
  margin-bottom: 4px;
}

.import-export-symbol .sample-file {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.import-export-symbol .sample-file label,
.import-export-symbol .sample-file a {
  margin-bottom: 8px;
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 15px;
}

.import-export-symbol .sample-file a {
  color: var(--primary-color);
  text-decoration: underline;
}

.import-export-symbol .sample-file a:hover {
  color: var(--primary-color);
}

.import-export-symbol .mb8 {
  margin-bottom: 8px !important;
}

.import-export-symbol .input {
  font-size: 13px;
  color: var(--input-color);
}

.import-error .mat-dialog-content span {
  display: block;
  margin-bottom: 12px;
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
}

.import-error .mat-dialog-content span:last-of-type {
  margin-bottom: 8px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

/**********************************************
     import and export popup css end
***********************************************/

/************************************
     Symbol Search Popup start
************************************/
.symbol-search {
  width: 840px !important;
  border-radius: 0;
}

.symbol-search .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
}

.symbol-search .mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
  height: 69vh;
  max-height: 69vh;
}

.symbol-search .mat-dialog-title {
  margin-bottom: 0 !important;
}

@media (min-height: 700px) {
  .symbol-search .mat-dialog-content {
    max-height: 503px;
    min-height: 503px;
  }
}

@media (max-height: 699px) {
  .symbol-search .mat-dialog-content {
    height: 66vh;
    max-height: 66vh;
    min-height: 66vh;
  }
}

.symbol-search .modal-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  color: var(--font-dark-color);
  border-bottom: 1px solid var(--dark-border-color);
}

.symbol-search .mat-dialog-title .modal-right-icon {
  height: 24px;
}

.symbol-search .modal-right-icon svg {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.symbol-search .modal-right-icon svg path {
  stroke: var(--dark-svg);
}

.symbol-search .modal-right-icon img:last-child {
  margin-left: 8px !important;
}

.symbol-search label {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  display: block;
  margin-bottom: 8px;
}

.symbol-search .modal-search {
  border-bottom: 1px solid var(--dark-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.symbol-search .modal-search input {
  height: 40px;
  width: calc(100% - 40px);
  border: 0;
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
  box-shadow: none;
  color: var(--font-dark-color);
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: uppercase;
}

.symbol-search [pseudo='-webkit-input-placeholder'] {
  color: var(--font-dark-color);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: uppercase;
}

.symbol-search .modal-search input::-webkit-input-placeholder {
  color: var(--light-color-3);
  font-size: 16px;
  text-transform: capitalize;
}

.symbol-search .modal-search input::-moz-placeholder {
  color: var(--light-color-3);
  font-size: 16px;
  text-transform: capitalize;
}

.symbol-search .modal-search input:-ms-input-placeholder {
  color: var(--light-color-3);
  font-size: 16px;
  text-transform: capitalize;
}

.symbol-search .modal-search input::-ms-input-placeholder {
  color: var(--light-color-3);
  font-size: 16px;
  text-transform: capitalize;
}

.symbol-search .modal-search input::placeholder {
  color: var(--light-color-3);
  font-size: 16px;
  text-transform: capitalize;
}

.symbol-search .highlight {
  color: var(--primary-color);
}

.modal-search-btn {
  height: 36px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border: 0;
  cursor: pointer;
  outline: none;
  /* due to black outline on search icon while loading popup*/
}

.modal-search-btn svg {
  width: 18px;
  height: 18px;
}

.modal-close-btn {
  height: 16px;
  width: 16px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border: 0;
  cursor: pointer;
}

.symbol-search .mat-dialog-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.search-first-tbl h2,
.search-second-tbl h2 {
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  padding-left: 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--white-bg);
  border-bottom: 1px solid var(--light-border-color-3);
}

.search-first-tbl,
.search-second-tbl {
  height: 100%;
  overflow: auto;
}

.search-first-tbl {
  margin-bottom: 5px;
}

.search-second-tbl {
  margin-top: 5px;
}

.dv-center {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.dv-center img {
  margin-left: 4px;
  height: 20px;
  width: 20px;
}

/************************************
     Symbol Search Popup End
************************************/

/****************************************
     print popup css start
*****************************************/
.print-options {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: auto;
}

.print-options .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 302px;
  margin: 50px auto;
}

.print-options .mat-dialog-content {
  margin: 0 !important;
  padding: 16px 24px 8px 24px !important;
  max-height: unset;
  overflow: hidden;
}

.print-options .mat-checkbox-label {
  letter-spacing: 0;
  font-size: 12px;
  line-height: 14px !important;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: 0.4px;
}

.print-options .mat-checkbox-label img {
  margin-right: 4px;
  display: inline-block;
  height: 24px;
  width: 24px;
}

.print-options .print-ck {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
}

.print-options .print-ck:last-child {
  margin-bottom: 0;
}

/****************************************
     print popup css end
*****************************************/

/****************************************
     print Wheel popup css Start
*****************************************/
.wheel-print .mat-radio-button {
  display: block;
  margin-bottom: 8px;
}

.wheel-print .inner-checkbox {
  padding-left: 28px;
}

.wheel-print .print-options .print-ck {
  margin-bottom: 8px;
}

/****************************************
     print Wheel popup css End
*****************************************/

/************************************
     Trading chart css start
************************************/

/* app-trading-chart{
  height: 100%;
  display: block;
}
.app-tv-chart-container{
  height: calc(100% - 64px);
} */

.valueValue-3kA0oJs5 {
  display: none;
}

app-chart-menu {
  // height: calc(100% - 49px);
}

.date-updated {
  padding: 8px 8px 10px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--dark-border-color);
}

.chart-new-header {
  padding-top: 17px;
  padding-left: 16px;
  padding-bottom: 17px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid var(--dark-border-color);
}

.entry-and-exit,
.show-trades {
  margin-right: 32px;
}

.entry-and-exit p,
.show-trades p {
  color: var(--light-color-2);
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
  white-space: nowrap;
}

.entry-and-exit .select,
.show-trades .select {
  width: 100%;
  background-color: transparent;
  padding: 0;
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  height: 16px;
  background-position: calc(100% - 5px), calc(100% - 16px);
}

.multiSelect .mat-select-value {
  line-height: 16px;
  width: auto;
  max-width: unset;
}

.multiSelect .mat-select-arrow {
  width: 16px;
  height: 16px;
  margin: 0 4px;
  border: 0;
  background-image: url(assets/img/newImg/drop-down-blc.svg);
  background-position: center;
  background-size: 12px;
  background-repeat: no-repeat;
}

.mat-select-value-text span {
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 20px;
}

.mat-select-trigger {
  height: 19px !important;
}

.cart-select-option {
  max-width: 130px !important;
  min-width: 180px !important;
  box-shadow: 0 2px 4px 0 var(--box-shadow-3) !important;
  border-radius: 3px !important;
}

.cart-select-option .mat-option {
  padding: 0 12px;
}

.cart-select-option .mat-option-text,
.mat-select-placeholder {
  color: var(--font-dark-color) !important;
  font-size: 12px !important;
}

.cart-select-option .mat-active .mat-option-text {
  color: var(--primary-color) !important;
}

.cart-select-option .mat-option {
  line-height: 35px !important;
  height: unset !important;
}

.cart-select-option .mat-active {
  background: var(--light-hover-color) !important;
}

.cart-select-option .mat-option:hover,
.cart-select-option .mat-active:hover {
  background: var(--light-hover-color) !important;
}

.mat-pseudo-checkbox-checked::after {
  top: 2px !important;
  left: 1px !important;
  width: 8px;
  height: 3px;
  border-left: 2px solid currentColor;
}

.mat-pseudo-checkbox {
  color: var(--light-color-2) !important;
  height: 14px !important;
  width: 14px !important;
  border-width: 1px !important;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: var(--primary-color) !important;
}

.techni-indicator {
  width: 100%;
}

/************************************
     Trading chart css end
************************************/

/********************************************
    leftside and rightside tabbing css start
*******************************************/
.chart-trading-option,
.chart-wheel {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.chart-wheel .left-side-button {
  border-top: 1px solid var(--dark-border-color);
}

.left-side-button {
  width: 36px;
  height: 100%;
  border-left: 1px solid transparent;
  border-right: 1px solid var(--dark-border-color);
}

.left-side-button .mat-button-toggle-group {
  flex-direction: column;
  width: 35px;
  margin-top: -1px;
  background: var(--white-bg);
}

.chart-wheel .left-side-button .mat-button-toggle-group {
  margin-top: -2px;
}

.left-side-button .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  -webkit-writing-mode: tb-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  line-height: 34px;
  padding: 0;
  padding-top: 15px;
  padding-bottom: 17.35px;
  color: var(--font-dark-color);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
}

.left-side-button .mat-button-toggle-button {
  border-right: 1px solid var(--dark-border-color);
}

.left-side-button .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.left-side-button .mat-button-toggle-group-appearance-standard {
  border-radius: 0;
  border: 0;
}

.left-side-button .mat-button-toggle {
  border-left: 0 !important;
}

.left-side-button .mat-button-toggle {
  background: var(--white-bg);
}

.left-side-button .mat-button-toggle-checked {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid var(--dark-border-color) !important;
  border-top: 1px solid var(--dark-border-color);
  border-bottom: 1px solid var(--dark-border-color);
}

.left-side-button .mat-button-toggle-checked .mat-button-toggle-button {
  border-right: 0;
}

.left-side-button .mat-button-toggle-checked .mat-button-toggle-label-content {
  color: var(--primary-color);
}

.left-side-button .mat-button-toggle:first-child {
  border-top: 1px solid var(--dark-border-color);
}

.right-side-content {
  width: calc(100% - 36px);
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

/********************************************
    leftside and rightside tabbing css End
*******************************************/

/********************************************
    small toggle button css start
*******************************************/
.col-row-indicator {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--light-bg-8);
  padding: 2px;
}

.col-row-indicator .mat-button-toggle {
  height: 20px;
}

.col-row-indicator .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 3px;
}

.col-row-indicator .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-row-indicator .mat-button-toggle-appearance-standard .mat-button-toggle-label-content img {
  display: block;
}

.col-row-indicator .mat-button-toggle-button {
  height: 20px;
}

.col-row-indicator .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  height: 2px;
}

.col-row-indicator .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.col-row-indicator .mat-button-toggle-group-appearance-standard {
  border: 0;
  border-radius: 0;
}

.col-row-indicator .mat-button-toggle-checked {
  background: transparent;
}

.col-row-indicator .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  height: 20px;
  border: 0;
}

.col-row-indicator .mat-button-toggle:last-child {
  margin-left: 2px;
}

/********************************************
    small toggle button css end
*******************************************/

/********************************************
    Admin Config CSS Start
*******************************************/
app-admin-config {
  overflow: auto;
}

.user-list {
  padding-left: 16px;
  padding-right: 16px;
}

.trading-log-details {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.user-list .header,
.holidays-list .header,
.trading-log-details .header,
.banner-details .header {
  color: var(--font-dark-color-3);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  margin: 0;
  padding: 16px 0;
  border-bottom: 1px solid var(--dark-border-color);
}

.user-search-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0;
}

.user-search-bar p {
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 14px;
  white-space: nowrap;
  margin-right: 8px;
}

.user-search-bar .user-select {
  height: 31px;
  width: 100%;
  text-align: right;
  background-color: var(--white-bg);
  outline: none;
  border: 0;
  color: var(--font-dark-color);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.09;
  border-radius: 0px;
  padding: 0 24px 0 8px;
  background-image: url(assets/img/newImg/Arrow.svg);
  background-position: calc(100% - 5px), calc(100% - 10px);
  background-size: 16px;
  background-repeat: no-repeat;
}

.user-select-outer.mat-form-field {
  width: 181px;
  border-radius: 0px;
  height: 33px;
  border: 1px solid var(--dark-border-color);
  outline-color: var(--primary-color);
  margin-right: 24px;
}

.user-select-outer.mat-form-field.mat-focused {
  border: 1px solid var(--primary-color);
}

.user-select-outer.mat-form-field .user-select {
  padding-right: 8px;
  padding: 2.5px 12px;
}

.user-select-outer .mat-form-field-wrapper {
  padding-bottom: 0;
}

.user-select-outer .user-select .mat-select-arrow-wrapper {
  margin: 0;
  opacity: 0;
}

.user-select-outer .mat-form-field-infix {
  padding: 0;
  border-top: 0;
  width: unset;
}

.user-select-outer .mat-form-field-underline {
  display: none;
}

.user-select-outer .mat-form-field-subscript-wrapper {
  display: none;
}

.user-select-outer .mat-select-value-text {
  display: block;
  margin-left: 0;
}

.user-select-outer .mat-select-value-text span {
  font-size: 12px;
  color: var(--font-dark-color);
  letter-spacing: 0;
  display: block;
  text-align: left;
  margin-left: 0;
}

.user-select-option {
  box-shadow: 0 2px 4px 0 var(--box-shadow-3) !important;
  border-radius: 3px !important;
}

.user-select-option .mat-option-text {
  color: var(--font-dark-color-2) !important;
}

.user-select-option .mat-active .mat-option-text {
  color: var(--primary-color) !important;
}

.user-select-option .mat-active {
  background: var(--light-hover-color) !important;
}

.user-select-option .mat-option:hover,
.user-select-option .mat-active:hover {
  background: var(--light-hover-color) !important;
}

.user-select-option .mat-option {
  line-height: 30px !important;
  height: unset !important;
}

.user-search-bar .mat-select-value {
  padding-top: 3px;
}

.search-boxs {
  display: inline-flex;
  align-items: center;
  width: 376px;
}

.search-boxs input {
  box-sizing: border-box;
  height: 32px;
  width: calc(100% - 32px);
  border: 1px solid var(--dark-border-color);
  border-right: 0;
  border-radius: 0;
  outline: none;
  background-color: var(--white-bg);
  padding-left: 12px;
  padding-right: 8px;
  color: var(--font-dark-color-3);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  box-shadow: none !important;
}

.search-boxs a {
  width: 36px;
  box-sizing: border-box;
  border: 1px solid var(--dark-border-color);
  border-left: 0;
  padding: 0;
  height: 32px;
  background: var(--white-bg);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-boxs input::-webkit-input-placeholder {
  color: var(--light-color-4);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0;
}

.search-boxs input::-moz-placeholder {
  color: var(--light-color-4);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0;
}

.search-boxs input:-ms-input-placeholder {
  color: var(--light-color-4);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0;
}

.search-boxs input::-ms-input-placeholder {
  color: var(--light-color-4);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0;
}

.search-boxs input::placeholder {
  color: var(--light-color-4);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0;
}

.userListTbl {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  margin-bottom: 8px;
  max-height: 200px;
}

.userListTbl table {
  width: 100%;
  margin: auto;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
}

.userListTbl th,
.userListTbl td {
  padding: 6px 8px;
  background: var(--white-bg);
  vertical-align: middle;
}

.userListTbl thead th {
  background: var(--white-bg);
  padding: 8px 8px;
  border-top: 1px solid var(--dark-border-color);
  border-bottom: 1px solid var(--dark-border-color);
  white-space: nowrap;
}

.userListTbl thead th:first-child {
  z-index: 5;
}

.userListTbl th {
  color: var(--primary-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
}

.userListTbl td {
  color: var(--font-dark-color-3);
  border-bottom: 1px solid var(--light-border-color-3);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
}

.userListTbl thead th:nth-child(1) {
  width: 100px;
}

.userListTbl thead th:nth-child(2) {
  width: auto;
}

.userListTbl thead th:nth-child(3) {
  width: auto;
}

.userListTbl thead th:nth-child(4) {
  width: 448px;
}

.userListTbl thead th:nth-child(5) {
  width: 150px;
}

.userListTbl thead th:nth-child(6) {
  width: 150px;
}

.userListTbl thead th:first-child,
.userListTbl tbody td:first-child {
  padding-left: 0;
}

.userListTbl thead th:last-child,
.userListTbl tbody td:last-child {
  width: 100px;
  text-align: right;
  padding-right: 0;
}

.edit-delet {
  display: inline-block;
}

.e-icon,
.d-icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.c-icon {
  margin: 0px 6px;
  vertical-align: middle;
  cursor: pointer;
}

.d-icon {
  margin-left: 16px;
}

.hr-4 {
  background: var(--light-bg-13);
  height: 4px;
  border: 0;
  outline: 0;
}

.holidays-list,
.banner-details {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.holidays-list .semi-header,
.banner-details .semi-header {
  color: var(--font-dark-color-3);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.holidaysListTbl {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  margin-bottom: 8px;
  max-height: 192px;
}

.holidaysListTbl table {
  width: 100%;
  margin: auto;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
}

.holidaysListTbl th,
.holidaysListTbl td {
  padding: 6px 8px;
  background: var(--white-bg);
  vertical-align: middle;
}

.holidaysListTbl thead th {
  background: var(--white-bg);
  padding: 8px 8px;
  border-top: 1px solid var(--dark-border-color);
  border-bottom: 1px solid var(--dark-border-color);
}

.holidaysListTbl th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: var(--white-bg);
}

.holidaysListTbl thead th:first-child {
  z-index: 5;
}

.holidaysListTbl th {
  color: var(--primary-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
}

.holidaysListTbl td {
  color: var(--font-dark-color-3);
  border-bottom: 1px solid var(--light-border-color-3);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
}

.holidaysListTbl thead th:first-child,
.holidaysListTbl tbody td:first-child {
  padding-left: 0;
}

.holidaysListTbl thead th:last-child,
.holidaysListTbl tbody td:last-child {
  width: 100px;
  text-align: right;
  padding-right: 0;
}

.tradingLogDetails {
  padding-top: 16px;
}

.bannerDetailsTbl {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 25px;
}

.bannerDetailsTbl table {
  width: 100%;
  margin: auto;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
}

.bannerDetailsTbl th,
.bannerDetailsTbl td {
  padding: 6px 8px;
  background: var(--white-bg);
  vertical-align: middle;
}

.bannerDetailsTbl thead th {
  background: var(--white-bg);
  padding: 8px 8px;
  border-top: 1px solid var(--dark-border-color);
  border-bottom: 1px solid var(--dark-border-color);
}

.bannerDetailsTbl th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: var(--white-bg);
}

.bannerDetailsTbl thead th:first-child {
  z-index: 5;
}

.bannerDetailsTbl th {
  color: var(--primary-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
}

.bannerDetailsTbl td {
  color: var(--font-dark-color-3);
  border-bottom: 1px solid var(--light-border-color-3);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
}

.bannerDetailsTbl thead th:nth-child(2),
.bannerDetailsTbl tbody td:nth-child(2) {
  width: 200px;
}

.bannerDetailsTbl thead th:first-child,
.bannerDetailsTbl tbody td:first-child {
  padding-left: 0;
  width: auto;
}

.bannerDetailsTbl thead th:last-child,
.bannerDetailsTbl tbody td:last-child {
  width: 70px;
  text-align: right;
  padding-right: 0;
}

.bannerDetailsTbl .mat-radio-label-content {
  padding-left: 5px;
}

.bannerDetailsTbl .mat-radio-button:first-child {
  margin-right: 13px;
}

.textFieldRow,
.textFieldRow td {
  background: var(--light-bg-5);
}

.textFieldRow input {
  border: 0;
  width: 100%;
  outline: none;
  box-shadow: none;
  color: var(--font-dark-color-3);
  font-size: 12px;
  background: transparent;
}

.textFieldRow .updateBtn {
  padding: 0 4px;
  color: var(--white-color);
  font-size: 11px;
  letter-spacing: 0.4px;
  line-height: 20px;
  width: 60px;
  font-weight: normal;
  text-align: center;
  border: 0;
  border-radius: 50px;
  background: var(--primary-color);
  box-shadow: none;
  min-width: 60px;
}

.editField-search-bar .editField-select {
  height: 20px;
  width: 100%;
  text-align: right;
  background-color: transparent;
  outline: none;
  border: 0;
  color: var(--font-dark-color);
  font-size: 12px;
  border-radius: 0px;
  padding: 0 24px 0 8px;
  background-image: url(assets/img/newImg/calc-down.png);
  background-position: calc(100% - 5px), calc(100% - 10px);
  background-size: 16px;
  background-repeat: no-repeat;
}

.editField-select-outer.mat-form-field {
  width: 80px;
  border-radius: 0px;
  height: 20px;
  border: 0;
  outline-color: var(--primary-color);
}

.editField-select-outer.mat-form-field.mat-focused {
  border: 0;
}

.editField-select-outer.mat-form-field .editField-select {
  padding: 0;
}

.editField-select-outer .mat-form-field-wrapper {
  padding-bottom: 0;
}

.editField-select-outer .editField-select .mat-select-arrow-wrapper {
  margin: 0;
  opacity: 0;
}

.editField-select-outer .mat-form-field-infix {
  padding: 0;
  border-top: 0;
  width: unset;
}

.editField-select-outer .mat-form-field-underline {
  display: none;
}

.editField-select-outer .mat-form-field-subscript-wrapper {
  display: none;
}

.editField-select-outer .mat-select-value-text {
  display: block;
  margin-left: 0;
}

.editField-select-outer .mat-select-value-text span {
  font-size: 12px;
  color: var(--font-dark-color);
  letter-spacing: 0;
  display: block;
  text-align: left;
  margin-left: 0;
}

.editField-select-option {
  box-shadow: 0 2px 4px 0 var(--box-shadow-3) !important;
  border-radius: 3px !important;
}

.editField-select-option .mat-option-text {
  color: var(--font-dark-color-2) !important;
}

.editField-select-option .mat-active .mat-option-text {
  color: var(--primary-color) !important;
}

.editField-select-option .mat-active {
  background: var(--light-hover-color) !important;
}

.editField-select-option .mat-option:hover,
.editField-select-option .mat-active:hover {
  background: var(--light-hover-color) !important;
}

.editField-select-option .mat-option {
  line-height: 30px !important;
  height: unset !important;
}

.editField-search-bar .mat-select-value {
  padding-top: 0px;
}

.textFieldRow .date-form-field {
  width: 120px;
}

.textFieldRow .date-form-field .mat-form-field-wrapper {
  padding-bottom: 0;
}

.textFieldRow .date-form-field .mat-form-field-underline {
  display: none;
}

.textFieldRow .mat-form-field-infix {
  border-top: 0;
}

.textFieldRow .date-form-field .mat-form-field-infix {
  padding: 3px 0;
}

.textFieldRow .date-form-field .mat-form-field-prefix .mat-icon-button,
.textFieldRow .date-form-field .mat-form-field-suffix .mat-icon-button {
  height: 19px;
  width: 19px;
}

.textFieldRow .date-form-field .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.textFieldRow .date-form-field .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  height: 19px !important;
  width: 19px;
}

.update-delete-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.update-delete-block .c-icon {
  margin-left: 0;
}

/********************************************
    Admin Config CSS End
*******************************************/

/****************************************
     Edit Announcement banner css start
*****************************************/
.edit-announcement {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: auto;
}

.edit-announcement .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 424px;
  margin: 50px auto;
}

.edit-announcement .mat-dialog-content {
  margin: 0 !important;
  padding: 16px 24px 8px 24px !important;
  max-height: unset;
}

.announcement {
  color: var(--font-dark-color-3);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 16px;
  display: block;
}

.NgxEditor__Wrapper {
  border: 1px solid var(--dark-border-color) !important;
  border-radius: 0 !important;
}

.NgxEditor__Wrapper .NgxEditor__MenuBar {
  border-bottom: 0 !important;
  border-radius: 0 !important;
  background: transparent;
}

.NgxEditor__MenuBar {
  flex-wrap: wrap !important;
}

.NgxEditor__Wrapper svg {
  height: 16px !important;
  width: 16px !important;
}

.NgxEditor__MenuItem.NgxEditor__MenuItem--Icon {
  height: 27px;
  width: 27px;
}

.NgxEditor__Wrapper .NgxEditor {
  background: transparent;
  color: var(--font-dark-color); // added for trading-plan
}

.NgxEditor__Wrapper svg {
  fill: var(--dark-svg);
}

.NgxEditor__Seperator {
  border-color: var(--dark-border-color);
}

.NgxEditor__MenuItem:hover {
  background-color: var(--light-bg-1) !important;
}

/****************************************
     Edit Announcement banner css end
*****************************************/

/****************************************
      pagination css start
*****************************************/
.pxo-pagination {
  text-align: right;
  margin-top: 16px;
}

.pxo-pagination .pagination-previous,
.pxo-pagination .pagination-next {
  color: var(--font-dark-color-3);
  padding-left: 8px;
  padding-right: 0px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  position: relative;
}

.pxo-pagination .pagination-previous.disabled,
.pxo-pagination .pagination-next.disabled {
  color: var(--light-color-4);
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  position: relative;
}

.pxo-pagination .ngx-pagination a {
  color: var(--font-dark-color-3);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  padding: 5px 2px;
  min-width: 24px;
  min-height: 24px;
  text-align: center;
  outline: none !important;
}

.pxo-pagination .ngx-pagination a:hover,
.pxo-pagination .ngx-pagination button:hover {
  background: var(--light-hover-color-2);
}

.pxo-pagination .pagination-previous.disabled {
  padding-left: 20px !important;
}

.pxo-pagination .pagination-next.disabled {
  padding-right: 20px !important;
}

.pxo-pagination .pagination-previous.disabled::before {
  content: '' !important;
  position: absolute;
  height: 16px;
  width: 16px;
  background: url(assets/img/Arrowgreyleft.svg);
  background-position: center;
  background-size: cover;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.pxo-pagination .pagination-next.disabled::after {
  content: '' !important;
  position: absolute;
  height: 16px;
  width: 16px;
  background: url(assets/img/Arrowgreyright.svg);
  background-position: center;
  background-size: cover;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.pxo-pagination .pagination-previous a {
  padding-left: 20px;
  padding-right: 5px;
  position: relative;
}

.pxo-pagination .pagination-previous a::before {
  content: '' !important;
  position: absolute;
  height: 16px;
  width: 16px;
  background: url(assets/img/Arrowleft.svg);
  background-position: center;
  background-size: cover;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.pxo-pagination .pagination-next a {
  padding-right: 20px;
  padding-left: 5px;
  position: relative;
}

.pxo-pagination .pagination-next a::after {
  content: '' !important;
  position: absolute;
  height: 16px;
  width: 16px;
  background: url(assets/img/ArrowRight.svg);
  background-position: center;
  background-size: cover;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.pxo-pagination .ngx-pagination .current {
  color: var(--white-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  padding: 5px 2px;
  min-width: 24px;
  min-height: 24px;
  background-color: var(--primary-color);
  text-align: center;
}

.pxo-pagination .ngx-pagination li {
  margin: 0 2px !important;
}

/****************************************
      pagination css start
*****************************************/

/****************************************
      Top Alert css start
*****************************************/
.top-alert-text {
  display: flex;
}

.top-alert {
  position: relative;
  padding: 7px 0 7px 18px;
  background-color: #41558d;
  font-size: 14px;
  z-index: 999999;
  padding-right: 15px !important;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .alert-text {
    margin: 1px 15px 0 0;
    color: #e0e3eb;
  }

  .alert-text-bold {
    margin: 1px 14px 0 14px;
    color: white;
    font-weight: bold;
  }

  .notify-icon {
    vertical-align: bottom;
    color: white;
    display: flex;
    align-items: center;
    font-size: 22px;
    margin-left: -1px;
  }

  .ver-info-btn {
    width: 144px;
    height: 36px;
    border-radius: 5px !important;
    border: none;
    outline: none;
    margin-right: 10px;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    line-height: 18px !important;
    padding-top: 2px !important;
  }

  .refresh-btn {
    width: 125px;
    height: 36px;
    border: none;
    outline: none;
    margin-right: 35px;
    cursor: pointer;
  }

  .refresh-btn-text {
    line-height: 18px !important;
    margin-left: 1px;
  }

  .refresh-icon {
    transform: scale(-1, 1);
    font-size: 22px;
  }

  .close-alert {
    position: absolute;
    right: 14px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-top: 3px;
    color: white;
    font-weight: bold;
    font-size: 18px;
  }
}

.ver-info-btn:hover {
  background: rgba(255, 255, 255, 0.07);
}

/* .bannerAlert.mat-snack-bar-container{
  margin: 12px;
  padding: 12px 16px;
  background-color: var(--primary-color);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.05), 0px 6px 10px 0px rgba(0, 0, 0, 0.01), 0px 1px 18px 0px rgba(0, 0, 0, 0.01);
}
.bannerAlert.mat-snack-bar-container span{
  color: var(--white-color);
  font-size: 14px;
}
.bannerAlert .mat-simple-snackbar{
  align-items: flex-start;
}
.bannerAlert .mat-simple-snackbar-action {
  color: #087cdb;
}
.bannerAlert .mat-simple-snackbar-action{
  height: 18px;
  width: 18px;
  margin: 0;
  overflow: hidden;
  margin-left: 12px;
  background: url(assets/img/Close.svg);
  background-position: center;
  background-size: cover;
  margin-right: -4px;
  cursor: pointer;
}
.bannerAlert .mat-simple-snackbar-action button{
  visibility: hidden;
} */

/****************************************
      Top Alert css end
*****************************************/

app-data-window {
  height: 100%;
  display: block;
}

.cons-agg {
  // height: calc(100% - 51px);
  flex: 1;
  overflow: hidden;
}

// .SER-accordian + .cons-agg {
//   height: calc(100% - 87px);
// }
// .SER-accordian.mat-expanded + .cons-agg {
//   height: calc(100% - 131px);
// }
.cons-agg .mat-tab-body-wrapper {
  height: calc(100% - 31px);
}

.compare-btn .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-dialog-actions[align='space'] {
  justify-content: space-between !important;
}

/* on split dragging time UI fixing css */

.as-dragging .left-section {
  position: relative;
  z-index: -1;
}

.as-dragging .left-section::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

/* on split dragging time UI fixing css */

/* open modal time main scroll hidden css */

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

/* open modal time main scroll hidden css */

.loading-text p {
  font-size: 16px !important;
  letter-spacing: 0.25px;
}

.technical-indicators {
  cursor: pointer;
  line-height: 16px;
}

.w-186 {
  width: 186px;
}

.w-169 {
  width: 169px;
}

.font-12 {
  font-size: 12px !important;
}

.tooltiptext b {
  display: block;
  margin-bottom: 4px;
}

.per-input .right-bottom-tooltip .tooltiptext {
  right: 3px;
}

.stop-loss-target .center-bottom-tooltip .tooltiptext::after {
  left: 89px;
}

.space-12 {
  height: 12px;
  width: 100%;
}

.mat-sort-header-button {
  letter-spacing: 0.4px;
}

table .mat-sort-header-indicator {
  top: 3px;
  display: none;
}

[aria-sort='ascending'] .mat-sort-header-indicator {
  top: 3px;
  display: block;
}

[aria-sort='descending'] .mat-sort-header-indicator {
  top: -3px;
  display: block;
}

@media (min-width: 1366px) {
  .position-data .price-range {
    padding: 8px 9px !important;
  }

  .position-data .font-10 {
    font-size: 12px !important;
  }

  .stop-loss-target .font-10 {
    white-space: nowrap !important;
  }

  .risk-reward-small span {
    font-size: 12px !important;
  }
}

.account-size .mat-button-focus-overlay {
  background: transparent !important;
}

.mat-button-focus-overlay {
  background: transparent !important;
}

/* custome mat tooltip css */

.tooltip.custom-tooltip-trade-link {
  max-width: 300px;
}

.squere-tooltip {
  line-height: 16px !important;
  background-color: var(--dark-bg-4) !important;
  white-space: pre-line !important;
  font-size: 12px !important;
  font-family: RR !important;
  text-align: left !important;
  border-radius: 3px !important;
  padding: 8.5px !important;
  color: red !important;
}

.tooltip-top::after {
  border-color: var(--dark-bg-4) transparent transparent transparent !important;
}

.tooltip-bottom::after {
  border-color: transparent transparent var(--dark-bg-4) transparent !important;
}

.tooltip-left::after {
  border-color: transparent transparent transparent var(--dark-bg-4) !important;
}

.tooltip-right::after {
  border-color: transparent var(--dark-bg-4) transparent transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--box-shadow-7) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--box-shadow-7) inset !important;
}

input::-moz-selection {
  background-color: var(--primary-color);
  color: var(--white-color);
}

input::selection {
  background-color: var(--primary-color);
  color: var(--white-color);
}

/*****************************
    Custome scroll bar start
*****************************/
::-webkit-scrollbar-corner {
  background: var(--white-bg);
}

.scanner-table::-webkit-scrollbar-track,
.watchlist-table::-webkit-scrollbar-track,
.scroll-data::-webkit-scrollbar-track,
.conservative-table::-webkit-scrollbar-track,
.aggresive-table::-webkit-scrollbar-track,
.mat-dialog-content::-webkit-scrollbar-track,
.trading-report::-webkit-scrollbar-track,
.report-con-tbl::-webkit-scrollbar-track,
.modals::-webkit-scrollbar-track,
.powerx-main .right-side-content::-webkit-scrollbar-track,
#tradReportHtml::-webkit-scrollbar-track,
.mat-autocomplete-panel::-webkit-scrollbar-track,
.search-first-tbl::-webkit-scrollbar-track,
.search-second-tbl::-webkit-scrollbar-track {
  background: var(--white-bg);
  height: 12px;
}

.scanner-table::-webkit-scrollbar,
.scanner-table::-webkit-scrollbar-thumb,
.watchlist-table::-webkit-scrollbar,
.watchlist-table::-webkit-scrollbar-thumb,
.scroll-data::-webkit-scrollbar,
.scroll-data::-webkit-scrollbar-thumb,
.conservative-table::-webkit-scrollbar,
.conservative-table::-webkit-scrollbar-thumb,
.aggresive-table::-webkit-scrollbar,
.aggresive-table::-webkit-scrollbar-thumb,
.mat-dialog-content::-webkit-scrollbar,
.mat-dialog-content::-webkit-scrollbar-thumb,
.trading-report::-webkit-scrollbar,
.trading-report::-webkit-scrollbar-thumb,
.report-con-tbl::-webkit-scrollbar,
.report-con-tbl::-webkit-scrollbar-thumb,
.modals::-webkit-scrollbar,
.modals::-webkit-scrollbar-thumb,
.powerx-main .right-side-content::-webkit-scrollbar,
.powerx-main .right-side-content::-webkit-scrollbar-thumb,
#tradReportHtml::-webkit-scrollbar,
#tradReportHtml::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar,
.mat-autocomplete-panel::-webkit-scrollbar-thumb,
.search-first-tbl::-webkit-scrollbar,
.search-first-tbl::-webkit-scrollbar-thumb,
.search-second-tbl::-webkit-scrollbar,
.search-second-tbl::-webkit-scrollbar-thumb {
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-clip: content-box;
  border: 4px solid transparent;
}

.scroll-data.narrow-scroll-bar::-webkit-scrollbar,
.scroll-data.narrow-scroll-bar::-webkit-scrollbar-thumb {
  width: 6px;
}

.scanner-table::-webkit-scrollbar-thumb,
.watchlist-table::-webkit-scrollbar-thumb,
.scroll-data::-webkit-scrollbar-thumb,
.conservative-table::-webkit-scrollbar-thumb,
.aggresive-table::-webkit-scrollbar-thumb,
.mat-dialog-content::-webkit-scrollbar-thumb,
.trading-report::-webkit-scrollbar-thumb,
.report-con-tbl::-webkit-scrollbar-thumb,
.modals::-webkit-scrollbar-thumb,
.powerx-main .right-side-content::-webkit-scrollbar-thumb,
#tradReportHtml::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar-thumb,
.search-first-tbl::-webkit-scrollbar-thumb,
.search-second-tbl::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 5px var(--box-shadow-1);
}

.scroll-data.visible-scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 5px var(--box-shadow-6) !important;
}

.scanner-table::-webkit-scrollbar-thumb:hover,
.watchlist-table::-webkit-scrollbar-thumb:hover,
.scroll-data::-webkit-scrollbar-thumb:hover,
.conservative-table::-webkit-scrollbar-thumb:hover,
.aggresive-table::-webkit-scrollbar-thumb:hover,
.mat-dialog-content::-webkit-scrollbar-thumb:hover,
.trading-report::-webkit-scrollbar-thumb:hover,
.report-con-tbl::-webkit-scrollbar-thumb:hover,
.modals::-webkit-scrollbar-thumb:hover,
.powerx-main .right-side-content::-webkit-scrollbar-thumb:hover,
#tradReportHtml::-webkit-scrollbar-thumb:hover,
.mat-autocomplete-panel::-webkit-scrollbar-thumb:hover,
.search-first-tbl::-webkit-scrollbar-thumb:hover,
.search-second-tbl::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 0 5px var(--box-shadow-6);
}

/* On hover show scrollbar */

*:hover::-webkit-scrollbar-thumb,
body:hover::-webkit-scrollbar-thumb,
html:hover::-webkit-scrollbar-thumb,
.report-con-tbl:hover::-webkit-scrollbar-thumb,
.scanner-table:hover::-webkit-scrollbar-thumb,
.watchlist-table:hover::-webkit-scrollbar-thumb,
.scroll-data:hover::-webkit-scrollbar-thumb,
.right-side-content:hover::-webkit-scrollbar-thumb,
.mat-dialog-content:hover::-webkit-scrollbar-thumb,
.conservative-table:hover::-webkit-scrollbar-thumb,
.aggresive-table:hover::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar-thumb,
.search-first-tbl::-webkit-scrollbar-thumb,
.search-second-tbl::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 5px var(--box-shadow-6) !important;
}

/* Fire fox scrollbar with and withot hover css start*/
.scanner-table::-webkit-scrollbar-track,
.watchlist-table::-webkit-scrollbar-track,
.conservative-table::-webkit-scrollbar-track,
.aggresive-table::-webkit-scrollbar-track {
  background: transparent;
  height: 12px;
}

.scanner-table::-webkit-scrollbar-thumb,
.watchlist-table::-webkit-scrollbar-thumb,
.conservative-table::-webkit-scrollbar-thumb,
.aggresive-table::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 5px transparent;
}

.tooltip {
  /* Due to Dynamic tool tip custom class assign problem  */
  line-height: 16px !important;
  background-color: var(--dark-bg-4) !important;
  white-space: pre-line !important;
  font-size: 12px !important;
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif !important;
  text-align: left !important;
  border-radius: 3px !important;
  padding: 8.5px !important;
  pointer-events: all !important;

  &.tooltip-hidden {
    display: none;
  }
}

/******************************
    Custome scroll bar end
*******************************/

.consAndAgg,
.right-one {
  overflow-y: hidden !important;
}

/******************************
    Login CSS Start
*******************************/

.showNoTagMsg {
  float: right;
  width: 94%;
}

.showNoTagMsg a {
  text-decoration: underline;
  color: red;
}

.form-design {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.full-width {
  width: 100%;
}

.outer-login {
  background: var(--login-bg);
}

.login-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-page::before {
  position: absolute;
  content: '';
  height: 412px;
  width: 392px;
  background: url(assets/img/newImg/login-top.svg);
  background-position: center;
  background-size: cover;
  left: 0;
  top: 0;
  z-index: 2;
}

.login-page::after {
  position: absolute;
  content: '';
  height: 235px;
  width: 204px;
  background: url(assets/img/newImg/login-bottom.svg);
  background-position: center;
  background-size: cover;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.login-section {
  min-height: 528px;
  display: table;
  width: 1174px;
  overflow: hidden;
  border-radius: 16px;
  background: var(--white-bg);
  box-shadow: 0 10px 35px 0 var(--box-shadow-4);
  z-index: 3;
}

.login-section button {
  background: var(--btn-primary-color);
  color: var(--white-color);
  border-radius: 4px;
  font-weight: normal;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-column {
  width: calc(100% - 775px);
  height: 100%;
  background: var(--white-bg);
}

.second-column {
  width: 58.43%;
  min-height: 528px;
  background: url(assets/img/Blacklbg.png);
  background-position: center;
  background-size: cover;
}

.upper-logo {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.upper-logo img {
  margin-bottom: 24px;
}

.upper-logo p {
  color: var(--white-color);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-bottom: 0;
}

.v-align {
  display: table-cell;
  vertical-align: middle;
}

.login-box {
  min-height: 250px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 68px;
  box-sizing: border-box;
}

.login-box h2 {
  color: var(--font-dark-color);
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 42px;
  margin-bottom: 40px;
}

.login-box img {
  height: 56px;
  width: auto;
  margin-bottom: 32px;
}

.login-box [type='text'],
.login-box [type='email'],
.login-box [type='password'] {
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  padding: 8px 16px;
  border: 0;
  border-radius: 4px;
  outline: none;
  background-color: var(--light-bg-9);
  color: var(--font-dark-color);
  font-size: 14px;
  letter-spacing: 0;
}

.mt-16 {
  margin-top: 16px;
}

.login-box [type='text']::-webkit-input-placeholder,
.login-box [type='email']::-webkit-input-placeholder,
.login-box [type='password']::-webkit-input-placeholder {
  color: var(--light-color);
}

.login-box [type='text']::-moz-placeholder,
.login-box [type='email']::-moz-placeholder,
.login-box [type='password']::-moz-placeholder {
  color: var(--light-color);
}

.login-box [type='text']:-ms-input-placeholder,
.login-box [type='email']:-ms-input-placeholder,
.login-box [type='password']:-ms-input-placeholder {
  color: var(--light-color);
}

.login-box [type='text']::-ms-input-placeholder,
.login-box [type='email']::-ms-input-placeholder,
.login-box [type='password']::-ms-input-placeholder {
  color: var(--light-color);
}

.login-box [type='text']::-webkit-input-placeholder,
.login-box [type='email']::-webkit-input-placeholder,
.login-box [type='password']::-webkit-input-placeholder {
  color: var(--light-color);
}

.login-box [type='text']::-moz-placeholder,
.login-box [type='email']::-moz-placeholder,
.login-box [type='password']::-moz-placeholder {
  color: var(--light-color);
}

.login-box [type='text']:-ms-input-placeholder,
.login-box [type='email']:-ms-input-placeholder,
.login-box [type='password']:-ms-input-placeholder {
  color: var(--light-color);
}

.login-box [type='text']::-ms-input-placeholder,
.login-box [type='email']::-ms-input-placeholder,
.login-box [type='password']::-ms-input-placeholder {
  color: var(--light-color);
}

.login-box [type='text']::placeholder,
.login-box [type='email']::placeholder,
.login-box [type='password']::placeholder {
  color: var(--light-color);
}

.label-sections {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 23.5px;
}

.label-box {
  margin-top: 26px;
  box-sizing: border-box;
  border: 1px solid var(--light-border-color);
  border-radius: 4px;
  padding: 14.5px 8.5px 8.5px 8.5px;
  position: relative;
}

.label-box label:first-child {
  color: #212b36;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 20px;
  display: inline-block;
  background: var(--white-bg);
  position: absolute;
  top: -10px;
  left: 4.5px;
  padding-left: 4px;
  padding-right: 4px;
}

.login-error {
  margin-bottom: 8px;
  display: block;

  &.flex {
    display: flex;
    align-items: flex-start;
  }

  a {
    color: #f44336;
    text-decoration: underline;
  }
}

.login-error img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-bottom: 0 !important;
  position: relative;
  top: 0px;
}

.login-error span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.remember-me {
  margin-top: 2px;
}

.remember-me label {
  margin-top: 8px !important;
  margin-bottom: 0 !important;
}

.remember-me .mat-checkbox-label {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0;
}

.btn-section {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgot-psw {
  display: inline-block;
  margin-top: 16px;
  color: var(--light-color);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
}

.forgot-psw:hover {
  text-decoration: underline;
}

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.forgot-psw-box h2 {
  margin-bottom: 16px;
}

.forgot-psw-box p {
  color: var(--light-color);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 32px;
}

.back-to-login {
  padding-left: 20px;
  position: relative;
}

.back-to-login::before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  background: url(assets/img/newImg/lo.svg);
  background-position: center;
  background-size: cover;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

/******************************
    Login CSS End
*******************************/

/******************************
    Custome Loader Css Start
*******************************/
.loading-spinner {
  background-color: #0000001f;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: grid;
}

.loading-spinner img {
  align-self: end;
}

.loading-message {
  text-align: center;
  align-self: start;
}

/******************************
    Custome Loader Css End
*******************************/

/******************************
    Licence agreement css start
*******************************/

.licence-agreement-page .m-b-12 {
  margin-bottom: 12px !important;
}

.licence-agreement-page .m-b-20 {
  margin-bottom: 12px !important;
}

.licence-agreement-page .m-t-10 {
  margin-top: 10px !important;
}

.licence-agreement-page .m-l-r-8 {
  margin: 8px 0px;
}

.licence-agreement-page .center {
  text-align: center !important;
}

.licence-agreement-section {
  max-width: 700px;
  width: 700px;
  height: 700px;
  overflow: auto;
  border-radius: 16px;
  background: var(--white-bg);
  box-shadow: 0 10px 35px 0 rgb(0 0 0 / 8%);
  max-height: calc(100vh - 122px);
  min-height: 700px;
  position: relative;
}

.licence-agreement-page hr {
  height: 1px;
  margin: 0px -32px;
  background-color: var(--light-bg-13);
  border: 0px;
  margin-bottom: 18px;
}

.licence-agreement-page .m-b-16 {
  margin-bottom: 16px;
}

.licence-agreement-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.user-agreement {
  padding: 23px 32px 0 32px;
}

.header-powerx-logo {
  width: 146px;
  height: 72px;
  margin-top: 0px;
  margin-left: 31px;
  background-color: rgba(222, 225, 233, 0.35);
  padding: 0px 18px;
  display: block;
}

.user-agreement .main-agreement-heading {
  color: var(--font-dark-color-3);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 12px;
  margin-bottom: 16px;
}

.user-agreement .terms-conditions-detail {
  padding: 12px 12px;
  box-sizing: border-box;
  height: 358px;
  line-height: 24px;
  border: 1px solid var(--dark-border-color);
  background-color: var(--white-bg);
  overflow-y: auto;
}

.user-agreement .terms-conditions-detail .h2 {
  font-size: 14px !important;
  font-weight: bold;
  width: 100%;
  text-align: center;
  line-height: 20px !important;
}

.user-agreement .terms-conditions-detail .h2-left {
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;
  line-height: 36px;
}

.user-agreement .agreement-checkbox {
  padding: 16px 0px;
}

.agreement-action-btns {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  padding: 24px 32px;
  bottom: 0;
  border-top: 1px solid var(--dark-border-color);
}

.agreement-action-btns .i-agree-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--btn-primary-color);
  color: var(--white-color);
  border-radius: 4px;
  font-weight: normal;
  padding: 0px 26px;
  line-height: 36px;
  margin-right: 24px;
  width: 96px;
}

.agreement-action-btns .i-agree-btn:disabled {
  background: #77c3ff;
  cursor: not-allowed;
  color: var(--white-color);
}

.agreement-action-btns .cancle-btn {
  border-radius: 4px;
  font-weight: normal;
  padding: 0px 26px;
  line-height: 36px;
  width: 96px;
  background: var(--white-bg);
  color: var(--font-dark-color-3);
  border: 1px solid var(--light-border-color-2);
  color: var(--light-color-2);
  font-family: 'Trebuchet MS';
  font-size: 14px;
}

.agreement-info {
  margin-bottom: 16px !important;
  color: var(--font-dark-color-3);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.licence-agreement-page .mat-checkbox-layout .mat-checkbox-label {
  line-height: 18px;
  color: var(--font-dark-color-3);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
}

.licence-agreement-page .mat-checkbox-inner-container {
  margin-top: 1px !important;
}

.terms-conditions-detail::-webkit-scrollbar-track {
  border-left: 1px solid var(--dark-border-color);
  background-color: var(--white-bg);
}

.terms-conditions-detail::-webkit-scrollbar {
  width: 10px;
  background-color: var(--light-hover-color);
}

.terms-conditions-detail::-webkit-scrollbar-thumb {
  background-color: var(--light-bg-2);
}

.licence-agreement-page .mat-checkbox-layout {
  white-space: unset !important;
  margin-top: 10px;
}

.agreement-checkbox .mat-checkbox-layout {
  margin-top: 0;
}

/******************************
    Licence agreement css End
*******************************/

/******************************
    Trading chart CSS Start
*******************************/

.app-tv-chart-container {
  height: 100%;
  width: 100%;
}

.trading-chart .row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.trading-chart .chart-statistics-updated-time {
  justify-content: space-between;
  align-items: center;
}

.trading-chart .chart-statistics-updated-time .statistic {
  display: flex;
  margin: 4px 0px;
  padding: 0px 7px;
}

.trading-chart .chart-statistics-updated-time .statistic-main {
  display: flex;
}

// .trading-chart .chart-statistics-updated-time .statistic:not(:last-child) {
//   border-right: 1px solid grey;
// }
.trading-chart .chart-statistics-updated-time .statistic .statistic-title,
.trading-chart .chart-statistics-updated-time .statistic .statistic-data {
  color: var(--font-dark-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

.trading-chart .chart-statistics-updated-time .updated-time {
  padding-top: 4px;
  padding-right: 8px;
  color: var(--light-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: right;
}

.trading-chart .target-toggle-button {
  padding-top: 16px;
  padding-left: 7px;
}

.target-toggle-button p {
  color: var(--font-dark-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0.21px;
  line-height: 20px;
  margin-bottom: 0;
  margin-right: 18px;
}

.trading-chart .target-toggle-button .target {
  padding-right: 12px;
}

.trading-chart .target-toggle-button .target span {
  line-height: 27px;
}

.trading-chart .target-toggle-button .toggle-button-red .mat-button-toggle-appearance-standard {
  color: var(--light-color);
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.21px;
}

.trading-chart .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-color: var(--light-border-color-1);
}

.trading-chart .target-toggle-button .toggle-button-red .mat-button-toggle-checked {
  position: relative;
  color: var(--white-color);
  background: var(--light-bg-14);
}

.trading-chart .target-toggle-button .toggle-button-red .mat-button-toggle-group-appearance-standard {
  border-radius: 0px;
  border-color: var(--light-bg-14);
}

.trading-chart .target-toggle-button .toggle-button-red .mat-button-toggle-label-content {
  line-height: 10px;
}

.trading-chart .target-toggle-button .toggle-button-red .mat-button-toggle-label-content {
  line-height: 24px;
  padding: 0 9px;
  width: 108px;
}

.trading-chart .indicator-trade-button {
  padding: 0px 8px 16px 0px;
}

.trading-chart .indicator-trade-button {
  justify-content: space-between;
  align-items: center;
}

.trading-chart .indicator-trade-button .eye-radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.trading-chart .indicator-trade-button .next-erning {
  text-align: right;
  color: var(--light-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.trading-chart .indicator-trade-button .next-erning img {
  height: 14px;
  width: 14px;
  display: block;
  margin-right: 10px;
}

.trading-chart .indicator-trade-button .radio-btn:not(:first-child) {
  padding: 0px 8px;
}

.trading-chart .indicator-trade-button .radio-btn input:checked {
  background-color: red;
}

.trading-chart .indicator-trade-button .radio-btn input {
  display: none;
}

.trading-chart .indicator-trade-button .radio-btn label {
  color: var(--font-dark-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.trading-chart .indicator-trade-button .radio-btn input[type='checkbox']:not(:checked) + label {
  background-image: url('assets/img/eye off.svg');
  background-repeat: no-repeat;
  padding-right: 30px;
  background-position-x: right;
  background-position-y: center;
  cursor: pointer;
}

.trading-chart .indicator-trade-button .radio-btn input[type='checkbox']:checked + label {
  background-image: url('assets/img/eye.svg');
  background-repeat: no-repeat;
  padding-right: 30px;
  background-position-x: right;
  background-position-y: center;
  cursor: pointer;
}

.trading-chart .show-trade-toggle {
  padding-top: 8px;
}

.show-trade-toggle p {
  margin-right: 16px;
}

.earning-alert-icon {
  -webkit-filter: invert(46%) sepia(40%) saturate(7486%) hue-rotate(337deg) brightness(112%) contrast(88%);
  filter: invert(46%) sepia(40%) saturate(7486%) hue-rotate(337deg) brightness(112%) contrast(88%);
}

/******************************
    Trading chart CSS End
*******************************/

/**********************************
    Wheel calculator CSS start
**********************************/

.wheel-tbl .bg-yellow {
  background-color: var(--yellow-bg);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 14px;
  height: 26px;
  text-align: center;
  padding: 5px 0px;
  color: var(--white-color);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.wheel-tbl .bg-red {
  background-color: var(--red-bg);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 14px;
  height: 26px;
  text-align: center;
  padding: 5px 0px;
  color: var(--white-color);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.wheel-tbl .bg-green {
  background-color: var(--green-bg);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 14px;
  height: 26px;
  text-align: center;
  padding: 5px 0px;
  color: var(--white-color);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.wheel-tbl .w-100 {
  width: 100% !important;
}

.wheel-tbl .row {
  display: flex;
  flex-wrap: wrap;
}

.wheel-tbl .m-l-auto {
  margin-left: auto;
}

.wheel-tbl .m-l-0 {
  margin-left: 0;
}

.wheel-tbl .p-0 {
  padding: 0px;
}

.wheel-tbl .bg-light-pink {
  background-color: rgba(137, 28, 25, 0.1);
  height: 26px;
}

.wheel-tbl .bg-maroon {
  background-color: var(--light-bg-14);
  padding: 2px 8px;
  text-transform: uppercase;
}

.wheel-tbl .align-center {
  text-align: center;
}

.wheel-tbl .p-0 {
  padding: 0px !important;
}

.wh-100 {
  width: 100%;
  height: 26px;
}

.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-in {
  color: var(--font-dark-color);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.09px;
  line-height: 26px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.premium-per-day {
  height: 26px;
}

.light-bg-yellow {
  background: var(--light-yellow-bg) !important;
}

.light-bg-red {
  background: var(--light-red-bg) !important;
}

.light-bg-green {
  background: var(--light-green-bg) !important;
}

.wheel-tbl .down-arrow {
  background: url(assets/img/newImg/down-arro-tir.svg);
  height: 16px;
  width: 16px;
  margin-right: 4px;
  background-position: center;
  background-size: cover;
}

.wheel-tbl .up-arrow {
  background: url(assets/img/newImg/up-arrow-tir.svg);
  height: 16px;
  width: 16px;
  margin-right: 4px;
  background-position: center;
  background-size: cover;
}

.blur-rr {
  color: var(--light-color-2) !important;
  font-weight: normal !important;
}

.blur-rm {
  color: var(--light-color) !important;
  font-weight: 500 !important;
}

.dark-rm {
  color: var(--font-dark-color) !important;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.selling-calls .heading-text {
  border-top: 1px solid var(--border-white) !important;
}

.selling-puts ~ .s-puts,
.selling-calls ~ .s-calls {
  display: none;
  transition: 1s all;
}

.wheel-tbl .selling-puts.showBelow .heading-text > div::after,
.wheel-tbl .selling-calls.showBelow .heading-text > div::after {
  transform: rotate(0deg);
  transition: 0.3s all;
}

.selling-puts.showBelow ~ .s-puts,
.selling-calls.showBelow ~ .s-calls {
  transition: 1s all;
  display: table-row;
}

/**********************************
    Wheel calculator CSS End
**********************************/

/*****************************************
    Wheel calculator Max Popup CSS Start
*****************************************/

.max-popup {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: auto;
}

.max-popup .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
  width: 385px;
  margin: 50px auto;
}

.max-popup .mat-dialog-content {
  margin: 0 !important;
  padding: 16px 24px 8px 24px !important;
  max-height: unset;
}

.max-popup p {
  color: var(--font-dark-color-2);
}

.max-popup p:first-child {
  margin-bottom: 16px;
}

/******************************************
    Wheel calculator Max Popup CSS End
******************************************/

/**********************************
    Wheel Chart CSS Start
**********************************/

.tradingview-widget-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#tradingview_24e84 {
  height: 100%;
}

.chart-back-layout {
  height: 100%;
  overflow: hidden;
}

.chart-inner-layout {
  position: relative;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  margin: -1px;

  .chart-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
}

/**********************************
    Wheel Chart CSS End
**********************************/

/**********************************
    Wheel Datawindow CSS Start
**********************************/
app-wheel-datawindow {
  display: block;
  height: 100%;
}

.wheel-table-right .add-calculator {
  padding: 0px 7px;
  background: var(--light-bg-1);
  color: var(--font-dark-color);
  min-width: 100%;
  line-height: 21px;
  font-size: 11px;
  letter-spacing: 0.4px;
  font-weight: normal;
  text-align: center;
  border-radius: 50px;
}

.wheel-table-right .add-calculator:hover {
  background: var(--light-bg-10);
}

.wheel-table-right .wheel-table-right-header .header-title {
  color: var(--font-dark-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: inline-block;
  width: 100%;
  position: relative;

  .border-bottom {
    border-bottom: 1px dashed var(--btn-primary-color);
  }

  .company-info {
    position: absolute;
    color: var(--font-dark-color);
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    vertical-align: middle;
    border: 1px solid var(--dark-border-color);
    background: var(--white-bg);
    width: 280px;
    padding: 6px 6px;
    border-radius: 0px 4px 4px 4px;
    z-index: 99;
    top: 23px;
    left: 34px;
  }
}

.wheel-table-right .wheel-table-right-header {
  padding: 5px 8px;
  border-bottom: 1px solid var(--dark-border-color);

  .updateNumber {
    display: inline-flex;
  }
}

.wheel-table-right-header .header-title p {
  color: var(--light-color);
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}

.header-title .rr-font {
  font-size: 14px;
  font-weight: normal;
}

.list-table {
  // height: calc(100% - 52px);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.list-table .tableScroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0px;
  overflow: auto;
  height: 100%;
  padding-bottom: 30px;
}

.list-table .tableScroll table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table-wrap {
  position: relative;
}

.list-table .font-RB {
  font-weight: bold !important;
}

.list-table .font-blur {
  color: var(--light-color) !important;
}

.list-table .tableScroll th,
.list-table .tableScroll td {
  padding: 6px 8px;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 14px;
  border-bottom: 1px solid var(--dark-border-color);
  border-right: 1px solid var(--dark-border-color);
  vertical-align: middle;
  text-align: center;
}

.list-table .tableScroll colgroup col:nth-child(even) {
  background: var(--white-bg);
}

.list-table .tableScroll colgroup col:nth-child(odd) {
  background: var(--light-bg-11);
}

.list-table .tableScroll thead th {
  background: var(--white-bg);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.list-table .tableScroll th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 5;
  background: var(--white-bg);
}

.list-table .tableScroll thead th:first-child {
  z-index: 6;
  background: var(--light-bg-11) !important;
}

.list-table .tableScroll tbody th {
  background: var(--light-bg-11) !important;
  line-height: 14px;
  text-align: left;
  font-weight: normal;
  height: 40px;
  color: var(--light-color);
}

.list-table thead th:last-child,
.list-table tbody td:last-of-type {
  border-right: 0;
}

.list-table tbody td {
  width: 55px;
  padding: 6px 6px !important;
  color: var(--font-dark-color);
}

.list-table thead th,
.list-table tbody td {
  width: 55px;
}

.list-table thead th:nth-child(1),
.list-table tbody td:nth-child(1) {
  width: 76px;
}

.list-table .tableScroll th {
  width: 100px !important;
}

// .list-table .center-plus img{
//   color: var(--font-dark-color);
//   font-size: 12px;
//   font-weight: normal;
//   line-height: 15px;
//   vertical-align: middle;
//   padding: 3px 10px;
//   width: auto;
//   min-width: auto;
//   border-radius: 50px;
//   background: #e8e8e8;
// }

.list-table .center-plus .calculator-plus-btn {
  color: var(--font-dark-color);
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  vertical-align: middle;
  padding: 3px 8px;
  width: auto;
  min-width: auto;
  border-radius: 50px;
  background: var(--light-bg-16);
}

.list-table .center-plus .calculator-plus-btn:hover {
  background: var(--light-hover-color-4);
}

.list-table .plus-box:hover img {
  opacity: 1;
}

.list-table .center-in-tbl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.list-table .help-icon-tbl {
  margin-left: 6px;
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.list-table .help-icon-tbl path {
  fill: var(--light-svg);
}

.list-table .meet-box,
.list-table .does-not,
.list-table .warning {
  padding: 3px;
  min-width: 43px;
  height: 20px;
  display: inline-block;
  border-radius: 2px;
}

.list-table .meet-box {
  background: var(--green-bg);
  color: var(--white-color);
}

.list-table .does-not {
  background: var(--red-bg);
  color: var(--white-color);
}

.list-table .warning {
  background: #ff9850;
  color: var(--white-color);
}

.wheel-table-right .list-table .tableScroll input {
  border: 0;
  outline: none;
  box-shadow: none;
  height: 17px;
  width: 100%;
  text-align: right;
}

.wheel-table-right .indicator-trade-button .radio-btn input[type='radio']:checked + label img {
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(103%) contrast(105%);
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(103%) contrast(105%);
}

.column-table {
  // height: calc(100% - 52px);
  flex: 1;
  overflow: hidden;
}

.column-tbl {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: auto;
  height: 100%;
}

.column-tbl table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

.column-tbl th,
.column-tbl td {
  padding: 2px 8px;
  font-size: 12px;
  border-bottom: 1px solid var(--dark-border-color);
  border-right: 1px solid var(--dark-border-color);
  background: var(--white-bg);
  line-height: 14px;
  vertical-align: middle;
  text-align: left;
}

.column-tbl td {
  text-align: center;
  color: var(--font-dark-color);
}

.column-tbl thead th:last-child,
.column-tbl tbody td:last-of-type {
  border-right: 0;
}

.column-tbl thead th {
  background: var(--light-bg-11) !important;
  color: var(--light-color);
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
  padding: 6px 8px;
  vertical-align: top;
}

.column-tbl thead th:first-child {
  z-index: 5;
}

.column-tbl thead th {
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  text-align: center;
  z-index: 5;
}

.column-tbl tbody tr td:nth-child(1),
.column-tbl thead tr th:nth-child(1) {
  width: 70px;
}

.column-tbl tbody tr td:nth-child(2),
.column-tbl thead tr th:nth-child(2) {
  width: 87px;
}

.column-tbl tbody tr td:nth-child(3),
.column-tbl thead tr th:nth-child(3) {
  width: 78px;
  text-align: center;
}

.column-tbl tbody tr td:last-child,
.column-tbl thead tr th:last-child {
  width: 48px;
}

.column-tbl tbody tr td:nth-child(4),
.column-tbl thead tr th:nth-child(4) {
  width: 48px;
}

.column-tbl tbody tr td:nth-child(5),
.column-tbl thead tr th:nth-child(5) {
  width: 63px;
}

.column-tbl tbody tr td:nth-child(6),
.column-tbl thead tr th:nth-child(6) {
  width: 71px;
  text-align: center;
}

.column-tbl tbody tr td:nth-child(7),
.column-tbl thead tr th:nth-child(7) {
  width: 55px;
}

.column-tbl tbody tr td:nth-child(8),
.column-tbl thead tr th:nth-child(8) {
  width: 78px;
}

.column-tbl tbody tr td:last-child,
.column-tbl thead tr th:last-child {
  padding-left: 5.5px;
  padding-right: 5.5px;
}

.column-tbl .center-in-tbl {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.column-tbl .help-icon-tbl {
  margin-left: 6px;
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.column-tbl .help-icon-tbl path {
  fill: var(--light-svg);
}

.column-tbl .font-RB {
  font-weight: bold !important;
}

.column-tbl .font-blur {
  color: var(--light-color) !important;
}

.column-tbl .font-blur {
  color: var(--light-color) !important;
}

.column-tbl .meet-box,
.column-tbl .does-not,
.column-tbl .warning {
  padding: 3px;
  min-width: 43px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.column-tbl .meet-box {
  background: var(--green-bg);
  color: var(--white-color);
}

.column-tbl .does-not {
  background: var(--red-bg);
  color: var(--white-color);
}

.column-tbl .warning {
  background: #ff9850;
  color: var(--white-color);
}

.column-tbl .plus-box {
  background: var(--white-bg) !important;
}

.column-tbl .center-plus .calculator-plus-btn {
  color: var(--font-dark-color);
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  vertical-align: middle;
  padding: 3px 8px;
  width: auto;
  min-width: auto;
  border-radius: 50px;
  background: var(--light-bg-16);
}

.column-tbl .center-plus .calculator-plus-btn:hover {
  background: var(--light-hover-color-4);
}

.smallFont {
  font-size: 10px;
}

.center-in-tbl.f-end {
  align-items: flex-end;
}

/**********************************
    Wheel Datawindow CSS End
**********************************/

/***************************************************
    Wheel Conservative Aggressive Header css start
****************************************************/

@-webkit-keyframes flicker-2 {
  0%,
  100% {
    opacity: 1;
  }

  41.99% {
    opacity: 1;
  }

  42% {
    opacity: 0;
  }

  43% {
    opacity: 0;
  }

  43.01% {
    opacity: 1;
  }

  45.99% {
    opacity: 1;
  }

  46% {
    opacity: 0;
  }

  46.9% {
    opacity: 0;
  }

  46.91% {
    opacity: 1;
  }

  51.99% {
    opacity: 1;
  }

  52% {
    opacity: 0;
  }

  52.8% {
    opacity: 0;
  }

  52.81% {
    opacity: 1;
  }
}

@keyframes flicker-2 {
  0%,
  100% {
    opacity: 1;
  }

  41.99% {
    opacity: 1;
  }

  42% {
    opacity: 0;
  }

  43% {
    opacity: 0;
  }

  43.01% {
    opacity: 1;
  }

  45.99% {
    opacity: 1;
  }

  46% {
    opacity: 0;
  }

  46.9% {
    opacity: 0;
  }

  46.91% {
    opacity: 1;
  }

  51.99% {
    opacity: 1;
  }

  52% {
    opacity: 0;
  }

  52.8% {
    opacity: 0;
  }

  52.81% {
    opacity: 1;
  }
}

.result-as-of .flicker-2 {
  -webkit-animation: flicker-2 2s linear infinite both;
  animation: flicker-2 2s linear infinite both;
}

.result-as-of .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.result-as-of .progress-detail-txt {
  // padding-left: 12px;
  color: var(--font-dark-color);
  font-size: 11px;
  letter-spacing: 0.2px;
  line-height: 15px;
}

.result-as-of .progress {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: none;
  box-shadow: none;
  position: relative;
}

.result-as-of .progress:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #e7d2d1;
  position: absolute;
  top: 0;
  left: 0;
}

.result-as-of .progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.result-as-of .progress .progress-left {
  left: 0;
}

.result-as-of .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #891c19;
}

.result-as-of .progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-left: 0;
  transform-origin: center left;
}

.result-as-of .progress .progress-right {
  right: 0;
}

.result-as-of .progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  border-right: 0;
  transform-origin: center right;
}

.result-as-of .progress .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 36px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 300;
}

.result-as-of .progress .progress-value:hover .value-detail {
  display: none;
}

.result-as-of .component-circle-timer {
  position: relative;
  margin: -13px -3px;
  margin-top: -8px;
  margin-right: -8px;
}

.result-as-of .component-circle-timer #play-img {
  display: none;
  width: 8px;
  height: 8px;
  margin-left: 2px;
  position: absolute;
  top: 19px;
  left: 18px;
}

.result-as-of .component-circle-timer:hover #play-img {
  display: block;
}

.result-as-of .component-circle-timer #stop-img {
  display: none;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 19px;
  left: 19px;
}

.result-as-of .component-circle-timer:hover #stop-img {
  display: none;
}

.result-as-of .progress .progress-value div {
  color: var(--font-dark-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
}

.result-as-of .progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
}

.result-as-of .progress[data-percentage='10'] .progress-right .progress-bar {
  -webkit-animation: loading-1 1.5s linear forwards;
  animation: loading-1 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='10'] .progress-left .progress-bar {
  -webkit-animation: 0;
  animation: 0;
}

.result-as-of .progress[data-percentage='20'] .progress-right .progress-bar {
  -webkit-animation: loading-2 1.5s linear forwards;
  animation: loading-2 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='20'] .progress-left .progress-bar {
  -webkit-animation: 0;
  animation: 0;
}

.result-as-of .progress[data-percentage='30'] .progress-right .progress-bar {
  -webkit-animation: loading-3 1.5s linear forwards;
  animation: loading-3 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='30'] .progress-left .progress-bar {
  -webkit-animation: 0;
  animation: 0;
}

.result-as-of .progress[data-percentage='40'] .progress-right .progress-bar {
  -webkit-animation: loading-4 1.5s linear forwards;
  animation: loading-4 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='40'] .progress-left .progress-bar {
  -webkit-animation: 0;
  animation: 0;
}

.result-as-of .progress[data-percentage='50'] .progress-right .progress-bar {
  -webkit-animation: loading-5 1.5s linear forwards;
  animation: loading-5 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='50'] .progress-left .progress-bar {
  -webkit-animation: 0;
  animation: 0;
}

.result-as-of .progress[data-percentage='60'] .progress-right .progress-bar {
  -webkit-animation: loading-5 1.5s linear forwards;
  animation: loading-5 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='60'] .progress-left .progress-bar {
  -webkit-animation: loading-1 1.5s linear forwards 1.5s;
  animation: loading-1 1.5s linear forwards 1.5s;
}

.result-as-of .progress[data-percentage='70'] .progress-right .progress-bar {
  -webkit-animation: loading-5 1.5s linear forwards;
  animation: loading-5 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='70'] .progress-left .progress-bar {
  -webkit-animation: loading-2 1.5s linear forwards 1.5s;
  animation: loading-2 1.5s linear forwards 1.5s;
}

.result-as-of .progress[data-percentage='80'] .progress-right .progress-bar {
  -webkit-animation: loading-5 1.5s linear forwards;
  animation: loading-5 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='80'] .progress-left .progress-bar {
  -webkit-animation: loading-3 1.5s linear forwards 1.5s;
  animation: loading-3 1.5s linear forwards 1.5s;
}

.result-as-of .progress[data-percentage='90'] .progress-right .progress-bar {
  -webkit-animation: loading-5 1.5s linear forwards;
  animation: loading-5 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='90'] .progress-left .progress-bar {
  -webkit-animation: loading-4 1.5s linear forwards 1.5s;
  animation: loading-4 1.5s linear forwards 1.5s;
}

.result-as-of .progress[data-percentage='100'] .progress-right .progress-bar {
  -webkit-animation: loading-5 1.5s linear forwards;
  animation: loading-5 1.5s linear forwards;
}

.result-as-of .progress[data-percentage='100'] .progress-left .progress-bar {
  -webkit-animation: loading-5 1.5s linear forwards 1.5s;
  animation: loading-5 1.5s linear forwards 1.5s;
}

@-webkit-keyframes loading-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(36deg);
  }
}

@keyframes loading-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(36deg);
  }
}

@-webkit-keyframes loading-2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(72deg);
  }
}

@keyframes loading-2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(72deg);
  }
}

@-webkit-keyframes loading-3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(108deg);
  }
}

@keyframes loading-3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(108deg);
  }
}

@-webkit-keyframes loading-4 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(144deg);
  }
}

@keyframes loading-4 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(144deg);
  }
}

@-webkit-keyframes loading-5 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes loading-5 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.result-as-of .progress {
  margin-bottom: 0px;
}

#circle-timer-svg {
  position: relative;
  margin: 00px;
  width: 40px;
  height: 40px;
  margin-top: -1px;
  margin-right: 12px;
}

.result-as-of #circle-timer-svg #play-img {
  display: none;
  width: 8px;
  height: 8px;
  margin-left: 2px;
  position: absolute;
  top: 12px;
  left: 11px;
}

.result-as-of #circle-timer-svg:hover #play-img {
  display: block;
}

.result-as-of #circle-timer-svg #stop-img {
  display: none;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.result-as-of #circle-timer-svg:hover #stop-img {
  display: none;
}

.result-as-of #circle-timer-svg .additional-wheel-timer-progress {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.result-as-of #circle-timer-svg #per-number {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  color: var(--font-dark-color);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 11px;
  text-align: center;
}

.market-closed-txt > div {
  padding: 0px 8px;
  border-radius: 10px;
  font-size: 11px;
  line-height: 18px;
  display: inline-block;
  margin-bottom: 2px;
}

.market-closed-txt p {
  padding-left: 7px;
}

.SER-accordian {
  box-shadow: unset !important;
  border: 1px solid var(--dark-border-color);
  border-radius: 4px;
  margin: 4px !important;
  min-height: 26px;
}

.SER-accordian.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.SER-accordian.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.SER-accordian.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: var(--light-bg-1);
}

.SER-accordian .mat-expansion-panel-header {
  height: auto !important;
  padding-left: 12px;
  padding-right: 40px;
}

.SER-accordian .mat-expansion-panel-header > span {
  height: 26px;
  justify-content: flex-start;
  align-items: center;
}

.SER-accordian.mat-expanded .mat-expansion-panel-header > span {
  height: 22px;
}

.SER-accordian.mat-expanded .mat-expansion-panel-header {
  padding-top: 3px;
  position: static;
}

.SER-accordian .mat-expansion-panel-header p {
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 14px;
}

.SER-data {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SER-data p:nth-child(1) {
  width: calc(33.33% - 6px);
}

.SER-data p:nth-child(2) {
  width: calc(33.33% - 6px);
}

.SER-data p:nth-child(3) {
  width: calc(33.33% - 6px);
}

.SER-accordian input::-webkit-outer-spin-button,
.SER-accordian input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.SER-accordian input[type='number'] {
  -moz-appearance: textfield;
}

.SER-accordian .expand-btn {
  min-width: 28px;
  line-height: 28px;
  padding: 0;
  border: 1px solid var(--dark-border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -1px;
  top: -1px;
  height: calc(100% + 2px);
}

.SER-accordian .expand-btn:hover {
  background: var(--light-bg-1);
}

.expand-btn .ei-icon {
  height: 16px;
  width: 16px;
  margin-top: -2px;
  margin-left: 1px;
  background: url(assets/img/acco-slider.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.SER-accordian.mat-expanded .mat-expansion-panel-header .expand-btn .ei-icon {
  background: url(assets/img/acco-reset.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.SER-accordian .mat-expansion-panel-body {
  padding-left: 12px;
  padding-right: 40px;
  padding-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.SER-accordian .srtike-value,
.SER-accordian .roi-value {
  width: calc(33.33% - 6px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.SER-accordian .srtike-value .min-input,
.SER-accordian .roi-value .min-input {
  width: calc(50% - 8px);
  margin-right: 4px;
}

.SER-accordian .srtike-value .desh-sign,
.SER-accordian .roi-value .desh-sign {
  height: 2px;
  width: 8px;
  background: var(--dark-bg-1);
  margin-top: 11px;
}

.SER-accordian .srtike-value .max-input,
.SER-accordian .roi-value .max-input {
  width: calc(50% - 8px);
  margin-left: 4px;
}

.SER-accordian .srtike-value .min-input input,
.SER-accordian .srtike-value .max-input input,
.SER-accordian .roi-value .min-input input,
.SER-accordian .roi-value .max-input input {
  width: 100%;
  height: 24px;
  border: 1px solid var(--light-border-color);
  border-radius: 4px;
  padding: 0 6px;
  color: var(--font-dark-color);
  background: var(--white-bg);
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 16px;
  outline: none !important;
  text-align: right;
  display: block;
}

.SER-accordian .srtike-value .min-input input:focus,
.SER-accordian .srtike-value .max-input input:focus,
.SER-accordian .roi-value .min-input input:focus,
.SER-accordian .roi-value .max-input input:focus {
  border-color: rgb(33, 150, 243);
}

.SER-accordian .cap {
  color: var(--light-color);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
  margin-top: 2px;
}

.exchange-search-bar {
  width: calc(33.33% - 6px);
}

.exchange-search-bar .exchange-select {
  height: 22px;
  width: 100%;
  text-align: right;
  background-color: var(--white-bg);
  outline: none;
  border: 0;
  color: var(--font-dark-color);
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  padding: 0 24px 0 8px;
  background-image: url(assets/img/newImg/calc-down.png);
  background-position: calc(100% - 5px), calc(100% - 10px);
  background-size: 16px;
  background-repeat: no-repeat;
}

.exchange-select-outer.mat-form-field {
  width: 70px;
  border-radius: 4px;
  height: 24px;
  border: 1px solid var(--dark-border-color);
  outline-color: var(--primary-color);
  margin-right: 0;
}

.exchange-select-outer.mat-form-field.mat-focused {
  border: 1px solid var(--primary-color);
}

.exchange-select-outer.mat-form-field .exchange-select {
  padding-right: 8px;
  padding: 2.5px 8px;
}

.exchange-select-outer .mat-form-field-wrapper {
  padding-bottom: 0;
}

.exchange-select-outer .exchange-select .mat-select-arrow-wrapper {
  margin: 0;
  opacity: 0;
}

.exchange-select-outer .mat-form-field-infix {
  padding: 0;
  border-top: 0;
  width: unset;
}

.exchange-select-outer .mat-form-field-underline {
  display: none;
}

.exchange-select-outer .mat-form-field-subscript-wrapper {
  display: none;
}

.exchange-select-outer .mat-select-value-text {
  display: block;
  margin-left: 0;
}

.exchange-select-outer .mat-select-value-text span {
  font-size: 10px;
  color: var(--font-dark-color);
  letter-spacing: 0;
  display: block;
  text-align: left;
  margin-left: 0;
  line-height: 10px;
}

.exchange-select-option {
  box-shadow: 0 2px 4px 0 var(--box-shadow-3) !important;
  border-radius: 3px !important;
}

.exchange-select-option .mat-option-text {
  color: var(--font-dark-color-2) !important;
}

.exchange-select-option .mat-active .mat-option-text {
  color: var(--primary-color) !important;
}

.exchange-select-option .mat-active {
  background: var(--light-hover-color) !important;
}

.exchange-select-option .mat-option:hover,
.exchange-select-option .mat-active:hover {
  background: var(--light-hover-color) !important;
}

.exchange-select-option .mat-option {
  line-height: 30px !important;
  height: unset !important;
}

.exchange-search-bar .mat-select-value {
  padding-top: 3px;
}

/***************************************************
    Wheel Conservative Aggressive Header css end
****************************************************/

/****************************
    Cron Page css start
****************************/
app-cron {
  overflow: auto;
}

.cron-padding {
  padding-left: 8px;
  padding-right: 8px;
}

.crons .mat-expansion-panel-header {
  padding: 12px 13px 12px 16px;
  height: auto !important;
  min-height: 48px;
  align-items: flex-start;
}

.crons .mat-expansion-panel-header:hover {
  background: var(--light-hover-color) !important;
}

.crons .mat-content {
  order: 2;
  padding-left: 48px;
  position: relative;
}

.crons .mat-expansion-indicator {
  display: none;
}

.crons .mat-expansion-indicator::after {
  display: none;
}

.crons .mat-content::before {
  position: absolute;
  content: '';
  background: url(assets/img/Plus.svg);
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  margin-right: 24px;
}

.crons [aria-expanded='true'] .mat-content::before {
  background: url(assets/img/Minus.svg);
  background-position: center;
  background-size: cover;
}

.crons .mat-expansion-panel-header.mat-expanded {
  height: auto;
}

.crons .mat-expansion-panel {
  box-shadow: none;
}

.crons.mat-accordion .mat-expansion-panel:first-of-type,
.crons.mat-accordion .mat-expansion-panel:last-of-type,
.crons .mat-expansion-panel {
  border-radius: 0;
}

.crons.mat-accordion .mat-expansion-panel {
  border-bottom: 1px solidvar(--dark-border-color);
}

.crons.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom: 0;
}

.crons .mat-expansion-panel-body {
  border-top: 1px solid var(--dark-border-color);
}

.crons .mat-expansion-panel-spacing {
  margin: 0;
}

.crons .mat-expansion-panel-body {
  padding: 0px 16px;
}

.crons .mat-expansion-panel-header-description {
  margin-right: 0px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.crons .mat-expansion-panel-header-title {
  color: var(--black-color);
  font-size: 17px;
  font-weight: bold;
  white-space: nowrap;
  letter-spacing: 0;
  line-height: 25px;
  width: 20%;
}

.crons .mat-expansion-panel-header-title img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.crons .panle-right-header {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  margin-left: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.crons .panle-right-header:first-child {
  margin-left: 0;
}

.crons .panle-right-header p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.crons .panle-right-header .header-lable {
  margin-right: 8px;
  color: var(--light-color-4);
}

.crons .panle-right-header .header-date {
  color: var(--font-dark-color-3);
}

.Failed,
.Completed,
.Started,
.Not.Started {
  white-space: nowrap;
  position: relative;
  padding-left: 18px;
}

.Failed {
  color: #ef534f;
}

.Completed {
  color: #33a69a;
}

.Started {
  color: #2196f3;
}

.Not.Started {
  color: #9a9da6;
}

.Failed::before,
.Completed::before,
.started::before,
.Not.Started::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
  width: 14px;
}

.Failed::before {
  background: url(assets/img/status-icon/Failed16.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.Completed::before {
  background: url(assets/img/status-icon/Completed16.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.Started::before {
  background: url(assets/img/status-icon/Started16.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.Not.Started::before {
  background: url(assets/img/status-icon/Not-Started16.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.crons .mat-expansion-panel-header-description .Failed,
.crons .mat-expansion-panel-header-description .Completed,
.crons .mat-expansion-panel-header-description .Started,
.crons .mat-expansion-panel-header-description .Not.Started {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 20px;
}

.crons .mat-expansion-panel-header-description .Failed::before,
.crons .mat-expansion-panel-header-description .Completed::before,
.crons .mat-expansion-panel-header-description .Started::before,
.crons .mat-expansion-panel-header-description .Not.Started::before {
  height: 16px;
  width: 16px;
}

.body-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.cron-page .f-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cron-page .f-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.body-header p {
  margin-bottom: 0;
}

.body-header .f-end {
  padding-right: 16px;
}

.cron-page .redC {
  color: var(--red-color);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.cron-page .redC {
  margin-right: 8px !important;
}

.cron-page .body-header a {
  color: var(--primary-color);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
  text-decoration: underline;
}

.cron-page .h-bold {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 8px !important;
}

.cron-page .h-medium {
  color: var(--font-dark-color-3);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.body-header button {
  width: 80px;
  margin-left: 24px;
}

.cronPanelTbl {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  /* height: 350px; */
}

.cronPanelTbl table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.cronPanelTbl th,
.cronPanelTbl td {
  padding: 5px 8px;
  border-bottom: 1px solid var(--dark-border-color);
  background: var(--white-bg);
  font-weight: normal;
  vertical-align: center;
  color: var(--font-dark-color-3);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: left;
}

.cronPanelTbl td {
  vertical-align: middle;
  border-bottom: 1px solid var(--light-border-color-3);
}

.cron-page .logo-with-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cron-page .custome-logo {
  height: 24px;
  width: 24px;
  background-color: #bfa161;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 100%;
  margin-right: 15px;
}

.cron-page .company-with-img .company-name {
  color: var(--font-dark-color-3);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.cronPanelTbl thead th {
  background: var(--white-bg);
  border-top: 1px solid var(--dark-border-color);
  top: 0;
  color: var(--primary-color);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  padding: 8px 8px;
}

.cronPanelTbl th:first-child {
  left: 0;
  z-index: 2;
  background: var(--white-bg);
}

.cronPanelTbl thead th:first-child {
  z-index: 5;
}

.cronPanelTbl thead th:nth-child(1),
.cronPanelTbl tbody td:nth-child(1) {
  width: auto;
}

.cronPanelTbl thead th:nth-child(2),
.cronPanelTbl tbody td:nth-child(2) {
  width: 361px;
}

.cronPanelTbl thead th:nth-child(3),
.cronPanelTbl tbody td:nth-child(3) {
  width: 361px;
}

.cronPanelTbl thead th:nth-child(4),
.cronPanelTbl tbody td:nth-child(4) {
  width: 100px;
}

.cronPanelTbl .greenBorder,
.cronPanelTbl .redBorder {
  width: 80px;
}

.cronPanelTbl tbody tr:last-of-type td {
  border-bottom: 0;
}

.cron-page .complated-icon {
  color: var(--green-color);
  position: relative;
  padding-left: 18px;
}

.cron-page .complated-icon::before {
  position: absolute;
  left: 0;
  content: '';
  height: 14px;
  width: 14px;
  background: url(assets/img/newImg/complated-icon.png);
  background-position: center;
  background-size: cover;
}

.cron-page .failed-icon {
  color: var(--red-color);
  position: relative;
  padding-left: 18px;
}

.cron-page .failed-icon::before {
  position: absolute;
  left: 0;
  content: '';
  height: 14px;
  width: 14px;
  background: url(assets/img/newImg/failed-icon.png);
  background-position: center;
  background-size: cover;
}

.cron-page .mt-24 {
  margin-top: 24px;
}

.cron-page .mb-24 {
  margin-bottom: 24px;
}

.specific-symbol,
.History {
  padding-left: 8px;
  padding-right: 8px;
}

.specific-symbol .header,
.History .header {
  color: var(--font-dark-color-3);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  margin: 0;
  padding: 12px 16px;
  border-bottom: 1px solid var(--dark-border-color);
}

.specific-symbol {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--dark-border-color);
}

.cron-exchange-search-bar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
}

.specific-symbol .tableScroll tbody tr:last-of-type td {
  border-bottom: 1px solid var(--dark-border-color);
}

.cron-page select[data-multi-select-plugin] {
  display: none !important;
}

.multi-select-component {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 32px;
  width: 377px;
  padding-left: 2px;
  padding-right: 27px;
  font-size: 14px;
  line-height: 1.42857143;
  padding-bottom: 0px;
  color: #555;
  background-color: transparent;
  border: 1px solid var(--dark-border-color);
  border-radius: 0px;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.autocomplete-list {
  border-radius: 4px 0px 0px 4px;
}

.multi-select-component .btn-group {
  display: none !important;
}

.multiselect-native-select .multiselect-container {
  width: 100%;
}

.selected-wrapper {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-bg-12);
  white-space: nowrap;
  margin: 3px 2px 3px 2px;
  height: 24px;
  vertical-align: center;
  cursor: default;
}

.selected-wrapper .selected-label {
  color: #212b36;
  font-family: OpenRegular;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 20px;
  max-width: 514px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  vertical-align: top;
}

.selected-wrapper .selected-close {
  display: inline-block;
  text-decoration: none;
  outline: none;
  vertical-align: center;
  margin-right: 4px;
  margin-left: 4px;
  background: url(assets/img/CancelSmall.png);
  background-position: center;
  background-size: cover;
  height: 18px;
  width: 18px;
}

.search-container {
  display: flex;
  flex-direction: row;
}

.search-container .selected-input {
  background: none;
  border: 0;
  height: 30px;
  width: 60px;
  padding: 0;
  margin-bottom: 0px;
  color: #212b36;
  font-family: OpenRegular;
  font-size: 14px;
  letter-spacing: 0.2px;
  padding-left: 4px;
  box-shadow: none;
}

.search-container .selected-input:focus {
  outline: none;
}

.search-container .dropdown-icon {
  display: inline-block;
  padding: 10px 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  border: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: center;
  background-size: 10px;
  background-repeat: no-repeat;
}

.search-container ul {
  position: absolute;
  list-style: none;
  padding: 0;
  z-index: 3;
  margin-top: 37px;
  width: 100%;
  right: 0px;
  background: var(--white-bg);
  border: 1px solid #ccc;
  border-top: none;
  border-bottom: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.search-container ul :focus {
  outline: none;
}

.search-container ul li {
  display: block;
  text-align: left;
  padding: 5px 20px 5px 12px;
  border-bottom: 1px solid #ccc;
  font-size: 13px;
  min-height: 29px;
}

.search-container ul li:first-child {
  border-top: 1px solid #ccc;
  border-radius: 0;
}

.search-container ul li:last-child {
  border-radius: 0;
}

.search-container ul li:hover.not-cursor {
  cursor: default;
}

.search-container ul li:hover {
  color: #333;
  background-color: rgb(251, 242, 152);
  border-color: #adadad;
  cursor: pointer;
}

.autocomplete-list {
  max-height: 130px;
  overflow-y: auto;
}

.cron-exchange-search-bar .exchange-select {
  height: 30px;
  width: 100%;
  text-align: right;
  background-color: var(--white-bg);
  outline: none;
  border: 0;
  color: var(--font-dark-color);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.09;
  border-radius: 0px;
  padding: 0 24px 0 8px;
  background-image: url(assets/img/newImg/calc-down.png);
  background-position: calc(100% - 5px), calc(100% - 10px);
  background-size: 16px;
  background-repeat: no-repeat;
}

.cron-exchange-select-outer.mat-form-field {
  width: 121px;
  border-radius: 0px;
  height: 32px;
  border: 1px solid var(--dark-border-color);
  outline-color: var(--primary-color);
  margin-right: 0;
  border-right: 0;
}

.cron-exchange-select-outer.mat-form-field.mat-focused {
  border: 1px solid var(--primary-color);
}

.cron-exchange-select-outer.mat-form-field .exchange-select {
  padding-right: 8px;
  padding: 2.5px 12px;
}

.cron-exchange-select-outer .mat-form-field-wrapper {
  padding-bottom: 0;
}

.cron-exchange-select-outer .exchange-select .mat-select-arrow-wrapper {
  margin: 0;
  opacity: 0;
}

.cron-exchange-select-outer .mat-form-field-infix {
  padding: 0;
  border-top: 0;
  width: unset;
}

.cron-exchange-select-outer .mat-form-field-underline {
  display: none;
}

.cron-exchange-select-outer .mat-form-field-subscript-wrapper {
  display: none;
}

.cron-exchange-select-outer .mat-select-value-text {
  display: block;
  margin-left: 0;
}

.cron-exchange-select-outer .mat-select-value-text span {
  font-size: 12px;
  color: var(--font-dark-color);
  letter-spacing: 0;
  display: block;
  text-align: left;
  margin-left: 0;
}

.cron-exchange-select-option {
  box-shadow: 0 2px 4px 0 var(--box-shadow-3) !important;
  border-radius: 3px !important;
}

.cron-exchange-select-option .mat-option-text {
  color: var(--font-dark-color-2) !important;
}

.cron-exchange-select-option .mat-active .mat-option-text {
  color: var(--primary-color) !important;
}

.cron-exchange-select-option .mat-active {
  background: var(--light-hover-color) !important;
}

.cron-exchange-select-option .mat-option:hover,
.cron-exchange-select-option .mat-active:hover {
  background: var(--light-hover-color) !important;
}

.cron-exchange-select-option .mat-option {
  line-height: 30px !important;
  height: unset !important;
}

.cron-exchange-search-bar .mat-select-value {
  padding-top: 3px;
}

.multi-select-search {
  position: relative;
  margin-right: 15px;
}

.inner-serach {
  border: 0;
  height: 30px;
  width: 27px;
  outline: none;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  position: absolute;
  right: 1px;
  cursor: pointer;
  top: 1px;
}

.inner-serach img {
  width: 16px;
  height: 16px;
}

.cron-exchange-search-bar .fill-btn {
  width: 77px;
  height: 32px;
}

.cron-page .plr-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.multi-select-search .mat-form-field-infix {
  width: 376px !important;
  border: 1px solid var(--dark-border-color) !important;
}

.multi-select-search .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.multi-select-search .mat-form-field-label-wrapper {
  top: 0;
  padding-top: 0;
}

.multi-select-search .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 50%;
  transform: translateY(-50%) !important;
  padding-left: 8px;
}

.multi-select-search .mat-form-field-infix {
  border-top: 0;
}

.mat-chip-list-wrapper {
  margin: 0 !important;
  flex-wrap: nowrap;
}

.multi-select-search .mat-chip-list-wrapper input.mat-input-element,
.multi-select-search .mat-chip-list-wrapper .mat-standard-chip {
  margin: 2px 2px !important;
}

.multi-select-search .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
}

.multi-select-search .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
}

.multi-select-search .mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 0 !important;
}

.multi-select-search input::-webkit-input-placeholder {
  font-size: 10px !important;
}

.multi-select-search input::-moz-placeholder {
  font-size: 10px !important;
}

.multi-select-search input:-ms-input-placeholder {
  font-size: 10px !important;
}

.multi-select-search input::-ms-input-placeholder {
  font-size: 10px !important;
}

.multi-select-search input::placeholder {
  font-size: 10px !important;
}

.multi-select-search .mat-form-field.mat-focused .mat-form-field-label {
  display: none !important;
  opacity: 0;
}

.example-chip-list .mat-form-field-label {
  color: transparent !important;
  font-size: 13px;
}

.example-chip-list .mat-form-field-label.mat-form-field-empty {
  color: var(--light-color-4) !important;
  font-family: RRegular;
}

.multi-select-search input.mat-chip-input {
  height: 26px !important;
  width: 100% !important;
  flex: 1 0 15px !important;
  padding-left: 8px;
}

.multi-select-search .mat-chip.mat-standard-chip {
  background: var(--light-bg-12) !important;
}

.multi-select-search .mat-standard-chip .mat-chip-remove.mat-icon {
  color: transparent !important;
  background: url(assets/img/Close.svg);
  background-size: cover;
  background-position: center;
}

.multi-select-search .mat-standard-chip {
  min-height: 26px !important;
  border-radius: 0 !important;
}

.multi-select-search .mat-standard-chip.mat-chip-with-trailing-icon {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 8px;
  padding-left: 7px;
  color: #212b36;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.specificTbl {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
}

.specificTbl table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.specificTbl th,
.specificTbl td {
  padding: 5px 8px;
  border-bottom: 1px solid var(--dark-border-color);
  background: var(--white-bg);
  font-weight: normal;
  vertical-align: center;
  color: var(--font-dark-color-3);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: left;
}

.specificTbl td {
  vertical-align: middle;
  border-bottom: 1px solid var(--light-border-color-3);
}

.specificTbl thead th {
  background: var(--white-bg);
  border-top: 1px solid var(--dark-border-color);
  top: 0;
  color: var(--primary-color);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  padding: 8px 8px;
}

.specificTbl th:first-child {
  left: 0;
  z-index: 2;
  background: var(--white-bg);
}

.specificTbl thead th:nth-child(1),
.specificTbl tbody td:nth-child(1) {
  width: auto;
}

.specificTbl thead th:nth-child(2),
.specificTbl tbody td:nth-child(2) {
  width: 200px;
}

.specificTbl thead th:nth-child(3),
.specificTbl tbody td:nth-child(3) {
  width: 200px;
}

.specificTbl thead th:nth-child(4),
.specificTbl tbody td:nth-child(4) {
  width: 100px;
}

.specificTbl tbody tr:last-of-type td {
  border-bottom: 0;
}

.History {
  background: var(--white-bg);
  margin-bottom: 8px;
}

.historyTbl {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
}

.historyTbl table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.historyTbl th,
.historyTbl td {
  border-bottom: 1px solid var(--dark-border-color);
  background: var(--white-bg);
  font-weight: normal;
  vertical-align: center;
  color: var(--font-dark-color-3);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: left;
}

.historyTbl td {
  padding: 10px 8px;
  vertical-align: middle;
  border-bottom: 1px solid var(--light-border-color-3);
}

.historyTbl thead th {
  background: var(--white-bg);
  border-top: 0;
  top: 0;
  color: var(--primary-color);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  padding: 8px 8px;
}

.historyTbl th:first-child {
  left: 0;
  z-index: 2;
  background: var(--white-bg);
}

.historyTbl thead th:nth-child(5),
.historyTbl tbody td:nth-child(5) {
  width: 100px;
}

.historyTbl tbody tr:last-of-type td {
  border-bottom: 0;
}

@media (min-width: 1366px) {
  .crons .mat-expansion-panel-header-title {
    width: auto;
  }
}

@media (max-width: 1365px) {
  .crons .mat-expansion-panel-header-title {
    width: auto;
  }

  .crons .panle-right-header {
    margin-left: 30px;
    width: auto;
  }
}

@media (max-width: 991px) {
  .crons .mat-content {
    flex-wrap: wrap;
  }

  .crons .mat-expansion-panel-header-title {
    width: 100%;
    margin-bottom: 14px;
  }

  .crons .mat-content::before {
    margin-right: 8px;
  }

  .crons .mat-content {
    padding-left: 32px;
  }

  .crons .mat-expansion-panel-header-description {
    margin-left: 0px;
    margin-right: 0px;
  }

  .crons .panle-right-header {
    margin-left: 0px;
    width: 50%;
  }

  .crons .panle-right-header {
    justify-content: flex-start;
  }

  .cron-page .mb-24,
  .cron-page .mt-24 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .cronPanelTbl thead th:nth-child(3),
  .cronPanelTbl tbody td:nth-child(3) {
    width: auto;
  }

  .cron-page .logo-with-name .custome-logo {
    margin-right: 8px;
  }

  .cron-page .logo-with-name .company-name {
    white-space: nowrap;
    width: calc(100% - 32px);
  }
}

@media (max-width: 850px) {
  .body-header {
    flex-wrap: wrap;
    padding-bottom: 16px;
  }

  .cron-page .f-start,
  .cron-page .f-end {
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .cron-page .f-start {
    margin-top: 16px;
  }

  .cron-page .h-bold,
  .cron-page .h-medium {
    margin-top: 16px !important;
  }

  .body-header button {
    margin-left: 0;
    margin-right: 420px;
    margin-top: 16px;
  }

  .tableScroll table {
    table-layout: auto;
    white-space: nowrap;
  }

  .cron-page .tableScroll thead th:nth-child(2),
  .cron-page .tableScroll tbody td:nth-child(2) {
    width: auto;
  }

  .cron-page .tableScroll thead th:nth-child(3),
  .cron-page .tableScroll tbody td:nth-child(3) {
    width: auto;
  }

  .cron-page .tableScroll thead th:nth-child(4),
  .cron-page .tableScroll tbody td:nth-child(4) {
    width: auto;
  }

  .cron-page .tableScroll thead th:nth-child(5),
  .cron-page .tableScroll tbody td:nth-child(5) {
    width: auto;
  }
}

@media (max-width: 767px) {
  .crons .mat-expansion-panel-header-title {
    font-size: 14px;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    margin-right: 0;
  }

  .crons .mat-expansion-panel-header-title {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .crons .panle-right-header p {
    font-size: 12px;
  }

  .crons .mat-expansion-panel-header-description .Failed,
  .crons .mat-expansion-panel-header-description .Completed,
  .crons .mat-expansion-panel-header-description .Started,
  .crons .mat-expansion-panel-header-description .Not.Started {
    font-size: 12px;
    line-height: 14px;
    padding-left: 18px;
  }

  .crons .mat-expansion-panel-header-description .Failed::before,
  .crons .mat-expansion-panel-header-description .Completed::before,
  .crons .mat-expansion-panel-header-description .Started::before,
  .crons .mat-expansion-panel-header-description .Not.Started::before {
    height: 14px;
    width: 14px;
  }

  .specificTbl thead th,
  .historyTbl thead th,
  .cronPanelTbl thead th {
    font-weight: normal;
  }

  .specific-symbol .header,
  .History .header {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
  }

  .cron-page .plr-16 {
    padding-left: 0;
    padding-right: 0;
  }

  .crons .mat-expansion-panel-header {
    padding-left: 8px;
    padding-right: 8px;
  }

  .cron-page .mb-24,
  .cron-page .mt-24 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .cron-exchange-search-bar {
    padding: 12px;
    padding-left: 0;
    padding-right: 0;
  }

  .cron-page .f-start {
    margin-top: 8px;
  }

  .redC,
  .body-header a,
  .cron-page .h-bold,
  .cron-page .h-medium {
    font-size: 12px;
  }

  .cron-page .h-bold,
  .cron-page .h-medium {
    margin-top: 8px !important;
  }

  .body-header button {
    margin-top: 10px;
  }

  .body-header {
    padding-bottom: 12px;
  }

  .body-header .f-end {
    padding-right: 0;
  }
}

@media (max-width: 650px) {
  .crons .panle-right-header {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .multi-select-component {
    width: 300px;
  }
}

@media (max-width: 575px) {
  .child-layout {
    padding-left: 0;
    padding-right: 0;
  }

  .crons .mat-expansion-panel-body {
    padding: 0 6px;
  }

  .body-header button {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .cron-exchange-search-bar {
    flex-wrap: wrap;
  }

  .exchange-first {
    width: 100%;
    border-right: 1px solid var(--dark-border-color);
    border-bottom: 0;
  }

  .multi-select-component {
    width: 100%;
  }

  .multi-select-search {
    width: 100%;
    margin-right: 0;
  }

  .cron-exchange-search-bar .perple-btn {
    margin-top: 8px;
    width: 100%;
  }

  .cron-page .tableScroll thead th:first-child,
  .cron-page .tableScroll tbody td:first-child {
    padding-left: 0;
  }

  .cron-page .tableScroll thead th:last-child,
  .cron-page .tableScroll tbody td:last-child {
    padding-right: 0;
  }

  .cron-exchange-select-outer.mat-form-field {
    width: 100%;
    border-right: 1px solid var(--dark-border-color);
    border-bottom: 0;
  }

  .cron-exchange-search-bar .fill-btn {
    width: 100%;
    margin-top: 8px;
  }

  .edit-user {
    max-width: 90vw !important;
  }

  .edit-user .mat-dialog-title {
    padding: 0 15px 0 15px;
  }

  .edit-user .mat-dialog-content {
    padding: 15px !important;
  }

  .edit-user .mat-dialog-actions {
    padding: 15px 15px !important;
  }

  .multi-select-search mat-form-field {
    width: 100%;
  }

  .multi-select-search .mat-form-field-infix {
    width: 100% !important;
  }

  .multi-select-search .mat-standard-chip.mat-chip-with-trailing-icon {
    font-size: 12px;
  }
}

.edit-user {
  width: 400px !important;
  border-radius: 0;
}

.edit-user .mat-dialog-container {
  border-radius: 0 !important;
  padding: 0 !important;
}

.edit-user .mat-dialog-content {
  margin: 0 !important;
  padding: 24px !important;
}

.edit-user .mat-dialog-actions {
  margin-bottom: 0 !important;
  padding: 16px 24px !important;
  border-top: 1px solid var(--dark-border-color);
}

.edit-user .mat-dialog-title {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: var(--black-color);
  font-family: RMedium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 21px;
  padding: 0 16px 0 24px;
  border-bottom: 1px solid var(--dark-border-color);
}

.edit-user .mat-dialog-actions button {
  height: 32px !important;
  width: 96px !important;
}

.edit-user .mat-typography img {
  margin-bottom: 24px;
}

.edit-user .mat-typography h4 {
  margin-bottom: 16px;
  color: var(--black-color);
  font-family: RMedium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}

.edit-user .mat-typography p {
  margin-bottom: 0;
  color: var(--font-dark-color-3);
  font-family: RRegular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.edit-user .mat-dialog-actions .perple-btn {
  margin-left: 16px !important;
}

.l-inputs {
  margin-bottom: 16px;
}

.cron-page .l-inputs label {
  color: var(--light-color-4);
  font-family: RRegular;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  display: block;
  margin-bottom: 8px;
}

.l-inputs input {
  box-sizing: border-box;
  height: 32px;
  width: 100%;
  outline: none;
  border: 1px solid var(--dark-border-color);
  background-color: transparent;
  padding-left: 12.5px;
  color: var(--font-dark-color-3);
  font-family: RRegular;
  font-size: 12px;
  letter-spacing: 0;
}

.m-radio {
  display: inline-block;
  border-right: 1px solid var(--dark-border-color);
  padding-right: 35px;
}

.m-radio p,
.m-toggle p {
  color: var(--light-color-4) !important;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 16px !important;
  text-align: left !important;
}

.m-radio .custom-radio {
  padding-left: 24px;
  line-height: 16px;
  display: inline-block;
  margin-right: 24px;
}

.m-radio .custom-radio:last-child {
  margin-right: 0;
}

.m-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  margin: 0;
  color: var(--font-dark-color-3);
  font-family: RRgular;
  font-size: 14px;
}

.m-radio .custom-radio .checkmark:after {
  top: 50%;
  left: 50%;
  width: 8.5px;
  height: 8.5px;
  transform: translate(-50%, -50%);
}

.m-toggle {
  padding-left: 35px;
  display: inline-block;
}

.mat-slide-toggle-bar {
  width: 24px !important;
  height: 14px !important;
}

.mat-slide-toggle-thumb-container {
  top: 2px !important;
  left: 2px !important;
}

.mat-slide-toggle-thumb-container,
.mat-slide-toggle-thumb {
  height: 10px !important;
  width: 10px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--primary-color) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--white-bg) !important;
}

.mat-slide-toggle-thumb {
  box-shadow: none !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(10px, 0, 0) !important;
}

/****************************
    Cron Page css end
****************************/

/****************************
    Data window CSS start
****************************/

.data-window {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.data-window .data-window-header {
  background: var(--white-bg);
}

.data-window .no-data-window {
  width: 100%;
  text-align: center;
  color: var(--no-data-msg);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
}

.conservative-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  padding-top: 6px;
}

.cos-name,
.compare-btn,
.rr {
  width: 33.33%;
}

.compare-btn {
  text-align: center;
}

.rr {
  text-align: right;
}

.p-l-14 {
  padding-left: 14px;
}

.p-r-14 {
  padding-right: 14px;
}

.conservative-header p {
  color: var(--font-dark-color);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.compare-btn button {
  padding: 0 6px;
  color: var(--all-theme-white);
  font-size: 11px;
  letter-spacing: 0.4px;
  line-height: 20px;
  width: 65px;
  font-weight: normal;
  text-align: center;
  border: 0;
  border-radius: 50px;
  background: var(--btn-primary-color);
  box-shadow: none;
}

.compare-btn button:hover {
  background: var(--primary-hover-color);
}

.compare-btn .compare-text {
  color: var(--primary-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  font-weight: normal;
}

.compare-btn button img {
  height: 24px;
  width: 24px;
  margin-right: 4px;
  display: inline-block;
}

.position-header.row {
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px;
  padding-bottom: 5px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.position-header {
  position: relative;
}

.position-header .none {
  color: var(--font-dark-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 14px;
  display: inline-block;
}

.position-header .position {
  color: var(--light-color-2);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 14px;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.position-header .short {
  color: var(--red-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 14px;
  display: inline-block;
  position: relative;
  padding-left: 20px;
}

.position-header .long {
  color: var(--green-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 14px;
  display: inline-block;
  position: relative;
  padding-left: 20px;
  flex: 1 0 auto;
}

.position-header .long::before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  background: url(assets/img/newImg/long-up-g.svg);
  background-position: center;
  background-size: cover;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.position-header .short::before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  background: url(assets/img/r-down.svg);
  background-position: center;
  background-size: cover;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.position-header.row > div:first-of-type {
  text-align: left;
}

.position-header.row > div:last-of-type {
  text-align: right;
}

.position-header .long .green-up-arrow {
  vertical-align: middle;
}

.scroll-data {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 15px;
}

.position-data.row {
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  color: var(--black-color);
}

.position-data .row {
  display: flex;
  align-items: center;
  margin-right: 0px;
  margin-left: 0px;
  width: 100%;
}

.position-data .col {
  width: 50%;
}

.position-data .card-title {
  color: var(--light-color);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin: 5px 0 0 0;
}

.position-data .card {
  border: 1px solid var(--dark-border-color);
  background-color: var(--white-bg);
  padding: 8px;
  border-radius: 4px;
  position: relative;
}

.position-data .card.profit-factor .card-title {
  right: -78px;
}

.position-data .card.trade-length .card-title {
  right: -101px;
}

.position-data .m-t-38 {
  margin-top: 36px;
}

.position-data .p-8_5 {
  padding: 8px;
}

.position-data .p-t-0 {
  padding-top: 0px;
}

.position-data .p-t-6 {
  padding-top: 6px;
}

.position-data .d-rtl {
  direction: rtl;
}

.position-data .line-height-20 {
  line-height: 20px !important;
}

.position-data .green {
  background-color: var(--green-light-bg);
}

.position-data .grey {
  background-color: var(--dark-bg-6);
}

.position-data .red {
  background-color: var(--red-light-bg);
}

.position-data .bg-long {
  background-color: var(--green-light-bg);
}

.position-data .bg-short {
  background-color: var(--red-light-bg);
}

.position-data .bg-none {
  background-color: var(--dark-bg-6);
}

.position-data .grey.signal-none .signal-none-txt {
  padding-top: 11px;
}

.position-data .grey.signal-none .signal-txt {
  display: none;
}

.position-data .grey.signal-none .signal-none-txt {
  display: block;
}

.position-data .signal-none-txt {
  display: none;
}

.position-data .grey.position-signal-none .signal-txt {
  display: none;
}

.position-data .grey.position-signal-none .signal-none-txt {
  display: none;
}

.position-data .grey.position-signal-none .share-txt {
  display: none;
}

.position-data .grey.position-signal-none .b-power-txt {
  display: none;
}

.position-data .grey.position-signal-none .position-signal-none-txt {
  display: block;
  padding: 24px 6px;
  width: 100%;
  text-align: center;
}

.position-data .price-range .signal-txt {
  display: block;
}

.position-data .price-range .signal-none-txt {
  display: none;
}

.position-data .price-range .share-txt {
  display: block;
  padding-top: 0 !important;
  height: 48px;
}

.position-data .price-range .b-power-txt {
  display: block;
}

.position-data .price-range .position-signal-none-txt {
  display: none;
}

.position-data .price-range {
  padding: 8px 6px;
  flex-wrap: wrap;
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-height: 100px;

  &.with-trade-btn {
    min-height: 116px;
  }
}

.price-range.change-bs-box {
  justify-content: center;
}

.price-range.change-bs-box .change-font {
  color: var(--font-dark-color);
  width: 100%;
  display: block;
}

.price-range.change-bs-box .c-color {
  width: 100%;
}

.price-range.change-bs-box svg {
  width: 14px;
  height: 14px;
  outline: none;
  margin-top: 4px;
  cursor: pointer;
}

.price-range.change-bs-box .f-11 {
  color: var(--font-dark-color);
}

.price-range.change-bs-box svg path {
  fill: var(--light-svg);
}

.position-data .w-100 {
  width: 100%;
}

.position-data .align-c {
  text-align: center;
}

.position-data .yellow-box {
  background-color: var(--dark-yellow-bg);
  padding: 0px 4px;
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--font-dark-color) !important;
}

.position-data .t-green {
  color: var(--green-color) !important;
}

.position-data .t-red {
  color: var(--red-color) !important;
}

.position-data .t-white {
  color: var(--white-color) !important;
}

.position-data .font-12 {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  color: var(--black-color);
}

.position-data .font-10 {
  font-size: 11px;
  letter-spacing: 0.33px;
  color: var(--light-color);
  line-height: 16px;
}

.position-data .bg-red {
  background-color: #ffd4dd;
}

.position-data .padding-t-b-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.position-data .padding-t-b-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.position-data .light {
  color: var(--light-color);
}

.position-data .p-l-6 {
  padding-left: 6px;
  white-space: nowrap;
}

.position-data .stop-loss-target {
  margin-top: 8px;
  border-bottom: 1px solid var(--dark-border-color);
  padding-bottom: 8px;
}

.position-data .stop-loss-target .tooltip {
  width: 16px;
  height: 16px;
}

.position-data .stop-loss-target img {
  width: 14px;
  height: 14px;
  outline: none;
}

.position-data .risk-reward-small {
  margin-top: 5px;
}

.position-data .buying-power {
  margin-top: 4px;
}

.position-data .total-profit.card {
  padding: 9px 8px;
}

.position-data .total-profit .progress-bar-with-txt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6px;
}

.position-data .total-profit .progress-bar-parent {
  width: unset;
}

.position-data .profit-factor.card {
  padding-bottom: 4px;
}

.position-data .win-profit-factor .col {
  width: 50%;
}

.position-data .win-profit-factor {
  border-bottom: 1px solid var(--dark-border-color);
  padding-bottom: 8px;
}

.position-data .win-profit-factor .circle-dot {
  width: 6px;
  height: 6px;
  background-color: var(--light-bg-6);
  display: inline-block;
  border-radius: 10px;
  vertical-align: 2px;
  margin: 0px 8px;
}

.position-data .profit-factor .win-loss-tbl {
  width: 100%;
  table-layout: fixed;
}

.position-data .profit-factor .win-loss-tbl tr td:nth-child(1) .progress-bar-parent {
  max-width: 81px;
  width: unset;
}

.position-data .profit-factor .win-loss-tbl tr td:nth-child(2) {
  width: 40px;
  text-align: right;
}

.position-data .profit-factor .win-loss-tbl tr td {
  padding: 1px 0px;
}

.position-data .profit-factor .avg-row {
  text-align: right;
  margin-top: 3px;
}

.space-beetwen-row {
  display: flex;
  justify-content: space-around;
}

.bold {
  font-weight: bold;
}

.display-grid {
  display: grid;
}

.font-RB {
  font-weight: 600 !important;
}

.f-10 {
  color: var(--font-dark-color);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
}

.f-11 {
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
}

.f-12 {
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.f-14 {
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 24px;
  color: var(--black-color);
}

.text-center {
  text-align: center;
}

.pt-4 {
  padding-top: 4px;
}

.data-window .buy-sell-call {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stop-loss-target {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 0px;
  padding-right: 0px;
}

.stop-loss-target > div:first-of-type,
.stop-loss-target > div:last-of-type {
  width: calc(50% - 8px);
}

.stop-loss-target .font-10 {
  color: var(--font-dark-color);
}

.stop-loss-target .font-12 {
  line-height: 16px;
}

.stop-loss-target {
  .profit-loss-value {
    margin: 5px 0 5px 0;
  }

  .stop-loss-btn,
  .profit-target-btn {
    background-color: #ebf6fe;
    border-radius: 4px;
    padding: 2px 11px;
    color: var(--primary-color);
    transition:
      color 0.3s ease,
      background-color 0.3s ease;

    &:hover {
      background-color: var(--primary-color);
      color: #fff;
    }
  }
}

.total-profit .font-12 {
  line-height: 16px;
}

.risk-reward-small {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  padding: 3px 0;
}

.risk-reward-small.l-yellow {
  background: var(--dark-yellow-bg);
  border-radius: 4px;
}

.risk-reward-small > div {
  display: flex;
  align-items: center;
  width: 50%;
}

.risk-reward-small > div:first-child {
  justify-content: flex-end;
  padding-right: 11px;
}

.risk-reward-small > div:last-child {
  padding-left: 11px;
  position: relative;
  justify-content: flex-start;
}

.risk-reward-small > div:last-child::before {
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  background: var(--light-bg-6);
  border-radius: 100%;
  left: -3px;
  top: 50%;
  transform: translateY(-50%);
}

.risk-reward-small p {
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  margin-right: 4px;
}

.risk-reward-small span {
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 16px;
  text-align: center;
}

.total-profit .font-12 {
  letter-spacing: 0.4px;
}

.position-data .progress-bar {
  height: 20px;
}

.progress-bar-with-txt .yellow-box {
  padding-top: 3px;
  padding-bottom: 3px;
}

.ptb-3 {
  display: inline-block;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.price-range.green .c-color {
  color: var(--green-color) !important;
}

.price-range.red .c-color {
  color: var(--red-color) !important;
}

.rounded-progress {
  height: 10px !important;
  border-radius: 8px;
}

.rounded-progress.bg-red {
  background: var(--red-bg);
}

.rounded-progress.bg-green {
  background: var(--green-bg);
}

.total-profit .progress-bar-with-txt .txt {
  margin-left: 4px;
  padding: 3px 4px;
}

.full-height-progress .pogress-value {
  bottom: -2px;
  height: 4px;
}

.full-height-progress .pogress-value {
  height: 5px;
  border-radius: 2.5px;
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.full-height-progress.date-progress {
  border-bottom: 0;
  padding-bottom: 0;
}

.full-height-progress.date-progress::after {
  position: absolute;
  content: '';
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
  height: 5px;
  border-radius: 50px;
  background: var(--light-bg-13);
  width: 100%;
}

.full-height-progress .date-value {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 5px;
}

.full-height-progress .date-value-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 5px;
}

.only-none-div {
  background: var(--dark-bg-6);
  height: 100px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: RR; */
  font-size: 14px;
  letter-spacing: 0.1px;
  color: var(--black-color);
}

#oneProgress,
#twoProgress,
#threeProgress,
#fourProgress {
  min-width: 55px;
}

/* for data window high set */
.price-range .buy-sell-call {
  line-height: unset !important;
  height: 48px;
}

.price-range .buy-sell-call .display-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 15px;
}

.price-range.change-bs-box.red .display-grid .f-11 {
  color: var(--red-color);
}

.price-range.change-bs-box.green .display-grid .f-11 {
  color: var(--green-color);
}

/****************************
    Data window CSS end
****************************/

/****************************
    Option CSS Start
****************************/

.risk-reward {
  padding-bottom: 30px;
  width: 100%;
  margin: 0 auto;
  max-width: 910px;
  width: 100%;
  min-width: 760px;
}

.risk-reward .cap {
  text-align: center;
  width: 100%;
  padding: 4px 0px;
  letter-spacing: 0px;
}

.risk-reward .s1 {
  text-align: center;
  width: 100%;
  padding: 4px 0px;
}

.risk-reward .rate {
  text-align: center;
  width: 100%;
  color: var(--font-dark-color);
  font-size: 14px;
}

.risk-reward .rate.red {
  text-align: center;
  width: 100%;
  color: var(--red-color);
  font-size: 14px;
  position: relative;
}

.bid-ask-help {
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.bid-ask-help path {
  fill: var(--red-svg);
}

.risk-reward .a-center {
  text-align: center;
  display: flex;
  justify-content: center;
}

.risk-reward .light {
  color: var(--light-color);
  padding-bottom: 0;
}

.risk-reward-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  padding: 3px 0;
}

.risk-reward-text.l-yellow {
  background: var(--dark-yellow-bg);
  border-radius: 4px;
}

.risk-reward-text > div {
  display: flex;
  align-items: center;
  width: 50%;
}

.risk-reward-text > div:first-child {
  justify-content: center;
  padding-right: 11px;
}

.risk-reward-text > div:last-child {
  padding-left: 11px;
  position: relative;
  justify-content: center;
}

.risk-reward-text p {
  color: var(--font-dark-color);
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  margin-right: 4px;
}

.risk-reward-text span {
  color: var(--light-color);
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.risk-reward-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  padding: 3px 0;
}

.risk-reward-amount.l-yellow {
  background: var(--dark-yellow-bg);
  border-radius: 4px;
}

.risk-reward-amount > div {
  display: flex;
  align-items: center;
  width: 50%;
}

.risk-reward-amount > div:first-child {
  justify-content: center;
  padding-right: 11px;
}

.risk-reward-amount > div:first-child span {
  color: var(--red-color);
}

.risk-reward-amount > div:last-child {
  padding-left: 11px;
  position: relative;
  justify-content: center;
}

.risk-reward-amount > div:last-child span {
  color: var(--green-color);
}

.risk-reward-amount p {
  color: var(--font-dark-color);
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  margin-right: 4px;
}

.risk-reward-amount span {
  color: var(--light-color);
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.risk-reward .p-t-16 {
  padding-top: 3px;
}

.risk-reward .p-b-16 {
  padding-bottom: 6px;
}

.risk-reward .p-0 {
  padding: 0px;
}

.risk-reward .p-b-0 {
  padding-bottom: 0px;
}

.risk-reward .ratio {
  text-align: center;
  width: 100%;
  padding: 4px 0px;
  color: var(--font-dark-color);
  font-size: 12px;
  line-height: 16px;
}

.risk-reward .ratio span {
  color: var(--font-dark-color);
  font-size: 12px;
  line-height: 16px;
  background-color: var(--dark-yellow-bg);
  padding: 4px;
}

.risk-reward .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.risk-reward .row .col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 6px;
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
}

.risk-reward .row .card {
  border: 1px solid var(--dark-border-color);
  border-radius: 4px;
}

.risk-reward .risk-reward-top {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 10px;
  padding-left: 8px;
}

.risk-reward .risk-reward-top .risk-reward-top-left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.risk-reward .risk-reward-top .risk-reward-top-attention {
  margin: 0 auto;
  color: #ff9850;
  border: 0.5px solid #fcc9a5;
  background: rgba(247, 140, 1, 0.05);
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

.risk-reward .risk-reward-top .risk-reward-top-right {
  padding-right: 8px;
}

.risk-reward .risk-reward-top .risk-reward-top-right .mat-flat-button {
  border: 1px solid var(--light-border-color-6);
  border-radius: 4px;
  background: linear-gradient(180deg, var(--white-bg) 0%, var(--light-bg-3) 100%);
  box-shadow: 0 1px 0 0 var(--box-shadow-5);
  line-height: 25px;
}

.risk-reward .risk-reward-top .risk-reward-top-right .mat-flat-button .mat-button-wrapper {
  color: #212b36;
  font-size: 12px;
}

.risk-reward .risk-reward-top .risk-reward-top-left .buy-put-text {
  padding-right: 32px;
  color: var(--font-dark-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.risk-reward .risk-reward-top .risk-reward-top-left .buy-put-text .put {
  display: inline-flex;
  padding: 0 4px;
  border-radius: 2px;
  height: 16px;
  background-color: var(--red-bg);
  color: var(--all-theme-white);
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
}

.risk-reward .risk-reward-top .risk-reward-top-left .buy-put-text .call {
  display: inline-flex;
  padding: 0 4px;
  border-radius: 2px;
  height: 16px;
  background-color: var(--green-bg);
  color: var(--all-theme-white);
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
}

.risk-reward .risk-reward-top .risk-reward-top-left .expiry {
  color: var(--font-dark-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
}

.risk-reward .risk-reward-top .risk-reward-top-left .expiry small {
  color: var(--font-dark-color-3);
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px;
}

.risk-reward .risk-reward-top .risk-reward-top-left .dte {
  color: var(--light-color);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  position: relative;
  padding-left: 20px;
}

.risk-reward .risk-reward-top .risk-reward-top-left .dte::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: var(--light-bg-14);
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.risk-reward .row .col.main-col:nth-of-type(odd) {
  padding-right: 8px;
}

.risk-reward .row .col.main-col:nth-of-type(even) {
  padding-left: 8px;
}

.risk-reward .risk-reward-header {
  background-color: var(--light-bg-1);
  padding: 8px 0px;
  text-align: center;
}

.risk-reward .row .col.half-width {
  .risk-reward-header {
    @media (max-width: 480px) {
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.risk-reward .risk-reward-header span {
  color: var(--font-dark-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.risk-reward .buy-put-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 8px;
}

.risk-reward .buy-put-row .col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 16.5px;
  padding-left: 16.5px;
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  border: 0px;
}

.risk-reward .buy-put-row .col .col {
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 7px;
}

.risk-reward .buy-put-row .col.buy {
  padding-right: 8px;
  padding-left: 8px;
  border-right: 1px solid var(--dark-border-color);
  padding-top: 0px;
}

.risk-reward .buy-put-row .col.put {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 0px;
}

.risk-reward .buy-put-row .col .title-1 {
  width: 100%;
  color: var(--light-color);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: -5px 0px;
  margin-top: -2px;
}

.risk-reward .title .s1 {
  padding: 0px;
}

.risk-reward .title {
  padding-bottom: 5px;
}

.risk-reward .buy-put-row .col .s1 {
  text-align: center;
  padding-top: 2.5px;
}

.risk-reward .buy .price-range {
  background-color: rgba(33, 150, 243, 0.12);
  padding-bottom: 16px;
  border-radius: 4px;
}

.risk-reward .put .price-range {
  background-color: var(--light-bg-1);
  padding-bottom: 16px;
  border-radius: 4px;
}

.risk-reward .price-range .title {
  padding-top: 16px;
  width: 100%;
  padding-bottom: 2px;
}

.risk-reward .price-range .title .cap {
  padding: 0px;
}

.risk-reward .price-range .title .s1 {
  padding: 0px;
}

.risk-reward .put .price-range .title {
  padding-top: 16px;
  width: 100%;
}

.risk-reward .price-range .cap {
  text-align: center;
}

.risk-reward .price-range .s1 {
  text-align: center;
}

.risk-reward .price-range .contract-maxentry .contract .contract-number {
  box-sizing: border-box;
  height: 29px;
  min-width: 29px;
  border: 1px solid var(--border-color-1);
  border-radius: 14px;
  text-align: center;
  padding-top: 4px;
  color: var(--font-dark-color);
  padding: 4px 5px;
}

.risk-reward .bid-ask.row {
  border-bottom: 1px solid var(--dark-border-color);
  padding: 4px 0px;
  padding-bottom: 16px;
}

.risk-reward .risk-reward-ratio.row {
  padding: 4px 0px;
}

.risk-reward .delta-theta.row {
  border-top: 1px solid var(--dark-border-color);
  padding: 4px 0px;
}

.risk-reward .hide-option-greeks {
  padding: 12px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  color: var(--primary-color);
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif;
}

.risk-reward {
  .option-attention-msg {
    margin-top: 6px;
    padding: 0px 8px;

    .option-attention-text {
      margin: 0 auto;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      color: #ff9850;
      border: 0.5px solid #fcc9a5;
      background: rgba(247, 140, 1, 0.05);
      border-radius: 4px;
      padding: 10px;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: flex-start;

      img {
        margin-right: 10px;
      }
    }
  }
}

.no-data.s2 {
  text-align: center;
  margin-top: 14px;
  color: var(--no-data-msg);
}

@media (max-width: 575px) {
  .risk-reward {
    max-width: 100%;
    min-width: 100%;
  }

  .risk-reward .risk-reward-top .risk-reward-top-left .buy-put-text {
    font-size: 14px;
    padding-right: 8px;
    line-height: 24px;
  }

  .risk-reward .risk-reward-top .risk-reward-top-left .buy-put-text .put {
    font-size: 10px;
    line-height: 24px;
  }

  .risk-reward .risk-reward-top .risk-reward-top-left .expiry {
    font-size: 14px;
  }

  .risk-reward .risk-reward-top .risk-reward-top-left .dte {
    font-size: 12px;
  }

  .risk-reward .risk-reward-top {
    margin-bottom: 8px;
  }

  .risk-reward .risk-reward-header span {
    font-weight: normal;
  }

  .risk-reward .buy-put-row .col .s1 {
    font-size: 14px;
  }

  .risk-reward .buy-put-row {
    padding-top: 2.5px;
  }

  .risk-reward .price-range .title,
  .risk-reward .put .price-range .title {
    padding-top: 8px;
  }

  .contract-maxentry.row {
    display: block;
  }

  .risk-reward .buy-put-row .contract.col,
  .risk-reward .buy-put-row .max-entry.col {
    max-width: 100%;
  }

  .risk-reward .buy .row,
  .risk-reward .put .row {
    display: block;
  }

  .risk-reward .buy .col,
  .risk-reward .put .col {
    max-width: 100%;
  }

  .risk-reward .buy-put-row .col.buy,
  .risk-reward .buy-put-row .col.put {
    padding-left: 4px;
    padding-right: 4px;
  }

  .risk-reward-amount {
    display: none;
  }

  .risk-reward-text {
    display: block;
    padding: 1px 4px;
  }

  .risk-reward-text > div:first-child {
    margin-bottom: 3px;
  }

  .risk-reward-text > div:first-child,
  .risk-reward-text > div:last-child {
    justify-content: flex-start;
    padding-left: 0;
  }

  .risk-reward-text > div {
    width: 100%;
  }

  .risk-reward .price-range .max-entry .cap {
    padding-bottom: 0;
  }

  .risk-reward .buy-put-row .max-entry.col .s1 {
    padding-top: 0;
  }

  .risk-reward .buy .price-range,
  .risk-reward .put .price-range {
    padding-bottom: 8px;
  }

  .risk-reward .bid-ask.row {
    padding-top: 0;
    padding-bottom: 12px;
  }

  .risk-reward .buy-put-row .bid-ask .col {
    padding-top: 4px;
  }

  .risk-reward .buy-put-row .bid-ask .col .cap {
    padding-bottom: 0;
  }

  .risk-reward .buy-put-row .bid-ask .ask {
    position: relative;
    padding-top: 22px;
  }

  .risk-reward .buy-put-row .bid-ask .ask .rate {
    position: static;
  }

  .risk-reward .buy-put-row .bid-ask .bid-ask-help {
    position: absolute;
    left: 50%;
    top: 4px;
    transform: translateX(-50%);
  }

  .risk-reward-text {
    margin-top: 8px;
  }

  .risk-reward .risk-reward-ratio.row {
    padding-top: 1px;
  }

  .risk-reward .delta-theta.row {
    padding-top: 1px;
  }
}

/****************************
    Option CSS End
****************************/

.date-popup.modals .mat-dialog-actions .mat-button.fill-btn {
  margin-right: 12px !important;
}

.date-popup.modal .date-today-btn {
  width: 70px !important;
}

.date-popup .date-ok-btn {
  width: 50px !important;
}

.date-popup .date-cancel-btn {
  width: 70px !important;
}

/****************************
    NEW button in header red Start
****************************/

.tradier-integration-new {
  background-color: var(--red-bg);
  color: #fff;
  height: 36px;
  align-items: center;
  padding: 0 15px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-right: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: none;

  &.show {
    display: flex;
  }

  &:hover {
    background-color: var(--red-hover-bg);
  }

  &.fixed {
    position: fixed;
    bottom: 20px;
    left: 10px;
    z-index: 1000;
    display: flex;

    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: -13px;
      top: -10px;
      cursor: pointer;

      img {
        max-width: 100%;
      }
    }
  }

  &.hide {
    -webkit-animation-name: hideTradierIntegrationNewBtn;
    animation-name: hideTradierIntegrationNewBtn;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  &.hidden {
    display: none;
  }
}

.profile-menu-item a.tradier-integration-new {
  &:hover {
    background-color: var(--red-hover-bg);
  }
}

@-webkit-keyframes hideTradierIntegrationNewBtn {
  from {
    transform: translate(0px, 0);
  }

  90% {
    transform: translate(0px, 100px);
  }

  to {
    transform: translate(0px, 100px);
    display: none;
  }
}

@keyframes hideTradierIntegrationNewBtn {
  from {
    transform: translate(0px, 0);
  }

  90% {
    transform: translate(0px, 100px);
  }

  to {
    transform: translate(0px, 100px);
    display: none;
  }
}

/****************************
    NEW button in header red End
****************************/

/****************************
    Black Theme
****************************/
.black-theme .position-table th {
  opacity: 0.7;
}

/****************************
    Black Icon and Images
****************************/
.black-theme {
  .trading-chart .indicator-trade-button .radio-btn input[type='checkbox']:checked + label {
    background-image: url(assets/img/eye-white.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
  }

  .multiSelect .mat-select-arrow {
    background-image: url(assets/img/newImg/drop-down-blc-white.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  .select {
    background-image: url(assets/img/newImg/drop-down-blc-white.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-position: calc(100% - 12px), calc(100% - 16px);
    background-size: 12px;
  }

  .menu-modals::before,
  .help-modals::before {
    background: url(assets/img/newImg/Path-dark.svg);
    background-size: cover;
    background-position: center;
  }

  .wheel-tbl .date-select {
    background-image: url(assets/img/newImg/Arrow-white.svg);
    background-position: calc(100% - 5px), calc(100% - 10px);
    background-size: 16px;
    background-repeat: no-repeat;
  }

  .w-clear {
    background: url(assets/img/newImg/Clear-blur-white.svg);
    background-size: contain;
    background-position: center;
  }

  .w-delete {
    background: url(assets/img/newImg/Delete-blur-white.svg);
    background-size: contain;
    background-position: center;
  }

  .w-clear:hover {
    background: url(assets/img/newImg/Clear-blur-dark.svg);
    background-size: contain;
    background-position: center;
  }

  .w-delete:hover {
    background: url(assets/img/newImg/Delete-dark.svg);
    background-size: contain;
    background-position: center;
  }

  .wheel-tbl .down-arrow {
    background: url(assets/img/newImg/down-arro-tir-white.svg);
    background-position: center;
    background-size: cover;
  }

  .wheel-tbl .up-arrow {
    background: url(assets/img/newImg/up-arrow-tir-white.svg);
    background-position: center;
    background-size: cover;
  }

  .wheel-tbl thead th:last-child .w-add img {
    opacity: 0.9;
  }

  .expand-btn .ei-icon {
    background: url(assets/img/acco-slider-white.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .SER-accordian.mat-expanded .mat-expansion-panel-header .expand-btn .ei-icon {
    background: url(assets/img/acco-reset-white.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .user-search-bar .user-select {
    background-image: url(assets/img/newImg/Arrow-white.svg);
    background-position: calc(100% - 5px), calc(100% - 10px);
    background-size: 16px;
    background-repeat: no-repeat;
  }

  .date-popup .mat-calendar-controls button .mat-calendar-arrow {
    background-image: url(assets/img/newImg/drop-down-blc-white.svg);
    background-position: center;
    background-size: 12px;
    background-repeat: no-repeat;
  }

  .crons .mat-content::before {
    background: url(assets/img/Plus-white.svg);
    background-position: center;
    background-size: cover;
  }

  .crons [aria-expanded='true'] .mat-content::before {
    background: url(assets/img/Minus-white.svg);
    background-position: center;
    background-size: cover;
  }

  .pxo-pagination .pagination-previous a::before {
    background: url(assets/img/Arrowleft-white.svg);
    background-position: center;
    background-size: cover;
  }

  .pxo-pagination .pagination-next a::after {
    background: url(assets/img/ArrowRight-white.svg);
    background-position: center;
    background-size: cover;
  }

  .smiley-menu.mat-menu-panel::before {
    background: url(assets/img/Black-left-arrow.svg);
    background-position: center;
    background-size: cover;
  }

  .Candles-icon {
    background: no-repeat url('assets/img/Candles-icon-light.svg');
  }

  .Bars-icon {
    background: no-repeat url('assets/img/Bars-icon-light.svg');
  }
}

// Scanner result and watchlist hover background issue
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: transparent;
}

@mixin info-box {
  margin: 0 auto;
  border-radius: 5px;
  line-height: 16px;
  font-size: 12px;
  display: flex;
}

.warning-message {
  @include info-box;
  color: #ff9850;
  border: 0.5px solid var(--warning-border-color);
  background: var(--warning-bg-color);
  padding: 7px 10px;
  align-items: center;
}

.info-message {
  @include info-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--info-text-color);
  border: 0.5px solid var(--info-border-color);
  background: var(--info-bg-color);
  padding: 10px;
  align-items: flex-start;

  div {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.item-select.custom-arrow {
  border-radius: 4px;
  padding: 5px 12px;
  background-image: url(assets/img/newImg/Arrow.svg);
  background-position: calc(100% - 5px), calc(100% - 10px);
  background-size: 16px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;

  .mat-select-arrow-wrapper {
    margin: 0;
    opacity: 0;
  }

  .mat-select-value-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .mat-select-trigger {
    width: 100%;
  }
}

.item-select.native-arrow {
  border-radius: 4px;
  padding: 5px 12px;
  height: auto;
  color: inherit;

  .mat-select-trigger {
    display: inline-flex;
    gap: 1px;
    color: inherit;

    .mat-select-value {
      margin-right: 0;
      color: inherit;

      .mat-select-value-text {
        width: auto;
        color: inherit;

        .mat-select-min-line {
          color: inherit;
        }
      }
    }

    .mat-select-arrow-wrapper {
      opacity: 1;
      margin: unset;
      display: inline-flex;
      color: inherit;

      .mat-select-arrow {
        color: inherit;
      }
    }
  }
}

.wheel-filter,
.stock-screener-filter,
.bcs-filter {
  .filters {
    .input-item {
      .flag-select {
        max-width: 102px;

        @media (max-width: 570px) {
          max-width: 100%;
          width: 100%;
        }
      }

      .number-input {
        max-width: 70px;

        @media (max-width: 570px) {
          max-width: calc(50% - 8px);
        }
      }

      .expiration-select {
        max-width: 120px;

        @media (max-width: 570px) {
          max-width: 100%;
          width: 100%;
        }
      }

      .roi-input {
        max-width: 70px;
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-wrapper {
        padding: 0;

        .mat-form-field-subscript-wrapper {
          margin: 0;
          top: 53px;
          font-size: 10px;
          color: var(--filter-hint-color);

          .mat-hint {
            color: inherit;
          }
        }
      }

      .item-select {
        border: 1px solid var(--light-border-color);
        background: var(--white-bg);
        border-radius: 4px;
        padding: 5px 12px;
        background-image: url(assets/img/newImg/Arrow.svg);
        background-position: calc(100% - 5px), calc(100% - 10px);
        background-size: 16px;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 32px;

        .mat-select-arrow-wrapper {
          margin: 0;
          opacity: 0;
        }

        .mat-select-value-text {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }

        .mat-select-trigger {
          .flag-select-trigger {
            position: relative;
            height: 100%;
            width: 100%;
            display: block;

            .mat-select-value {
              overflow: initial;
              position: relative;
            }

            & > span {
              position: absolute;
              border: 1px solid #fff;
              border-radius: 50%;
              height: 18px;
              width: 18px;
              $leftMargin: 10px;
              background: #fff;

              @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                  z-index: $i;

                  @if $i==1 {
                    left: 0;
                  } @else {
                    left: $leftMargin;
                    $leftMargin: $leftMargin + 10;
                  }
                }
              }

              img {
                max-width: 16px;
              }
            }
          }
        }
      }

      .item-input {
        border: 1px solid var(--light-border-color);
        background: var(--white-bg);
        color: var(--input-color);
        border-radius: 4px;
        padding: 5px 12px;
        height: 32px;
        margin: 0;
        font-size: 12px;

        &::-webkit-input-placeholder {
          color: var(--light-color-3);
        }

        &::-moz-placeholder {
          color: var(--light-color-3);
        }

        &:-ms-input-placeholder {
          color: var(--light-color-3);
        }

        &::-ms-input-placeholder {
          color: var(--light-color-3);
        }

        &::placeholder {
          color: var(--light-color-3);
        }
      }

      .mat-form-field-label-wrapper {
        overflow: initial;
      }

      .mat-form-field-label {
        font-size: 12px;
        color: var(--light-color);
        transform: translateY(-1.2em) scale(1) perspective(0);
      }
    }
  }
}

.black-theme {
  .item-select.custom-arrow {
    background-image: url(assets/img/newImg/Arrow-white.svg);

    .mat-select-trigger .flag-select-trigger > span.B,
    .mat-select-trigger .flag-select-trigger > span.Never {
      background: #000;
    }
  }

  .item-select.native-arrow {
    background-image: none;
  }

  .risc-contracts,
  .wheel-filter,
  .bcs-filter {
    .filters {
      .input-item {
        .item-select {
          background-image: url(assets/img/newImg/Arrow-white.svg);

          .mat-select-trigger .flag-select-trigger > span.B,
          .mat-select-trigger .flag-select-trigger > span.Never {
            background: #000;
          }
        }
      }
    }
  }
}

.mat-option.filters-option {
  display: flex;
  flex-direction: row-reverse;

  .mat-option-text {
    display: flex;
    align-content: center;
    align-items: center;
    color: var(--font-dark-color);

    img {
      margin-right: 7px;
    }
  }
}

.mat-select-panel.filter-select-panel {
  max-width: 150px;
  min-width: 150px !important;

  .mat-option.filters-option {
    position: relative;

    &:not(:last-child)::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc(50% - 55px);
      width: 110px;
      height: 1px;
      background: #eef0f7;
    }
  }
}

svg .mat-progress-spinner circle,
.mat-spinner circle {
  stroke: inherit;
}

.white-spinner {
  stroke: white;
}

.blue-spinner {
  stroke: #3f51b5;
}

.black-theme .mat-option {
  color: white;
}

.mat-snack-bar-container {
  position: relative;
  min-width: 327px !important;
  max-width: 327px !important;
  height: 67px;
  background: #ffffff;
  margin: 0 !important;
  box-shadow: 20px -10px 200px rgba(0, 35, 72, 0.4);
  border-radius: 5px 5px 0 0 !important;
  border-bottom: 3px solid #26a69a;
  display: flex !important;
  align-items: center !important;
}

.mat-snack-bar-container {
  &.error {
    border-bottom-color: var(--red-border-color);
  }

  .container {
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;
  }

  .popup-text {
    display: inline-block;
    margin-right: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 11.78px;
    line-height: 15.6px;
    color: #131722;

    p {
      font-family: 'Trebuchet MS';
    }
  }

  .popup-icon {
    color: #26a69a;
    margin: 0 20px 0 4px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .popup-close-icon {
    position: absolute;
    right: 13px;
    color: var(--filter-hint-color);
    cursor: pointer;
  }
}

.CA-icon {
  width: 24px;
  height: 24px;
  background: no-repeat url('./assets/img/exchanges/Canada-icon.svg');
}

.US-icon {
  width: 24px;
  height: 24px;
  background: no-repeat url('./assets/img/exchanges/USA-icon.svg');
}

.CC-icon {
  width: 24px;
  height: 24px;
  background: no-repeat url('./assets/img/exchanges/cryptocurrency-icon.svg');
}

.scanner-redirect-text {
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-color);
}

.oco-spinner {
  position: absolute;
  background: var(--modal-bg);
  width: 90% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.oco-order {
  cursor: pointer;
}

.oco-order:hover {
  background: var(--primary-hover-color);
}

.tradier-account-item {
  .mat-option-text {
    overflow: unset;
  }
}

.oco-form {
  max-width: -webkit-min-content !important;
  max-width: -moz-min-content !important;
  max-width: min-content !important;
}

.chart-bar-radio {
  margin: 0 10px 0 10px;
}

.chart-bar-radio-content {
  display: flex;
  align-items: center;
  margin-left: -10px;
}

.display-sub-label {
  color: var(--font-dark-color);
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.total-profit-text {
  color: var(--light-color);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-right: 10px;
}

.total-background {
  background: none !important;
}

.char-bar-text {
  color: var(--font-dark-color);
  font-size: 14px;
}

.chart-bar-icon {
  width: 28px;
  height: 28px;
}

.Candles-icon {
  background: no-repeat url('assets/img/Candles-icon.svg');
}

.Bars-icon {
  background: no-repeat url('assets/img/Bars-icon.svg');
}

.background-video-backdrop {
  display: none;
}
