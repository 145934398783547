.mat-mdc-slide-toggle,
.mat-mdc-slide-toggle.mat-accent {
  //track-color styles
  --mdc-switch-selected-track-color: var(--primary-color);
  --mdc-switch-selected-hover-track-color: var(--primary-color);
  --mdc-switch-selected-focus-track-color: var(--primary-color);
  --mdc-switch-selected-pressed-track-color: var(--primary-color);
  --mdc-switch-unselected-track-color: var(--slide-toogle-bg);
  --mdc-switch-unselected-hover-track-color: var(--slide-toogle-bg);
  --mdc-switch-unselected-focus-track-color: var(--slide-toogle-bg);
  --mdc-switch-unselected-pressed-track-color: var(--slide-toogle-bg);
  // handle-color styles
  --mdc-switch-selected-handle-color: var(--white-bg);
  --mdc-switch-selected-hover-handle-color: var(--white-bg);
  --mdc-switch-selected-focus-handle-color: var(--white-bg);
  --mdc-switch-selected-pressed-handle-color: var(--white-bg);
  --mdc-switch-unselected-handle-color: var(--white-color);
  --mdc-switch-unselected-hover-handle-color: var(--white-color);
  --mdc-switch-unselected-focus-handle-color: var(--white-color);
  --mdc-switch-unselected-pressed-handle-color: var(--white-color);

  --mdc-switch-state-layer-size: 20px;

  .mdc-switch {
    margin-right: 4px;
    --mat-switch-with-icon-handle-size: 10px;
    --mat-switch-unselected-handle-size: 10px;
    --mat-switch-selected-handle-size: 10px;
    --mat-switch-pressed-handle-size: 10px;
    --mdc-switch-handle-height: 10px;
    --mdc-switch-handle-shape: 5px;
    --mdc-switch-handle-width: 10px;
    --mdc-switch-selected-icon-size: 0;
    --mdc-switch-track-height: 14px;
    --mdc-switch-track-shape: 8px;
    --mdc-switch-track-width: 24px;

    .mdc-switch__handle-track {
      width: calc(calc(100% - 4px) - var(--mdc-switch-handle-width));

      .mdc-switch__handle {
        left: 2px;
        right: 2px;

        .mdc-switch__icon--on,
        .mdc-switch__icon--off {
          display: none;
          opacity: 0;
        }
      }
    }

    .mdc-switch__ripple {
      display: none;
      background-color: transparent;
    }
  }

  .mdc-label {
    color: var(--font-dark-color);
  }

  &.large-slide-toggle {
    --mdc-switch-state-layer-size: 40px;

    .mdc-switch {
      --mat-switch-with-icon-handle-size: 16px;
      --mat-switch-unselected-handle-size: 16px;
      --mat-switch-selected-handle-size: 16px;
      --mat-switch-pressed-handle-size: 16px;
      --mdc-switch-handle-height: 16px;
      --mdc-switch-handle-shape: 50%;
      --mdc-switch-handle-width: 16px;
      --mdc-switch-selected-icon-size: 0;
      --mdc-switch-track-height: 24px;
      --mdc-switch-track-shape: 20px;
      --mdc-switch-track-width: 40px;

      .mdc-switch__handle-track {
        width: calc(calc(100% - 10px) - var(--mdc-switch-handle-width));

        .mdc-switch__handle {
          left: 5px;
          right: 5px;
        }
      }

      .mdc-switch__ripple {
        display: none;
        background-color: transparent;
      }
    }
  }
}

.mat-slide-toggle-content {
  @include font-regular(12px, 130%, normal);
}