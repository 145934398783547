.scroll-style::-webkit-scrollbar-track {
  background: transparent;
  height: 12px;
}

.scroll-style::-webkit-scrollbar,
.scroll-style::-webkit-scrollbar-thumb {
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-clip: content-box;
  border: 4px solid transparent;
}

.scroll-style::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 5px var(--box-shadow-6);
}

.scroll-style::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 0 5px var(--box-shadow-6);
}

.scroll-style.visible-scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 5px var(--box-shadow-6) !important;
}

.scroll-style.show-scroll-on-hover::-webkit-scrollbar-thumb {
  box-shadow: none !important;
}

.scroll-style.show-scroll-on-hover:hover::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 5px var(--box-shadow-6) !important;
}

.scroll-system {
  scrollbar-width: thin;
  scrollbar-color: var(--box-shadow-6) var(--modal-bg);
}

@-moz-document url-prefix() {
  .scroll-style {
    scrollbar-color: var(--box-shadow-6) transparent !important;
    scrollbar-width: thin !important;
  }

  .scroll-style:hover {
    scrollbar-color: var(--box-shadow-6) transparent !important;
    scrollbar-width: thin !important;
  }
}
