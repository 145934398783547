
@import './mixins/fonts';
.pxo-app {
  .lt-dashboard-y-axis-tick {
    color: var(--main-font-color);
    fill: var(--main-font-color);
    @include apply-tl-dashboard-chart-tick-font();
  }

  .lt-dashboard-x-axis-tick {
    color: var(--main-font-color);
    fill: var(--main-font-color);
    @include apply-tl-dashboard-chart-tick-font();
  }
}
