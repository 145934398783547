@import '../../scss/mixins/fonts';
@import '../../scss/mixins/images';
@import '../../scss/mixins/line-clamp';

.pxo-app {
  .profiles-wrapper {
    padding-inline-start: 21px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: var(--white-bg);

    .header-icon-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      padding: 0;
    }
  }

  .profile-menu-item {
    padding-top: 8px;
    width: 288px;
    min-height: 100dvh;
    height: 100dvh;
    flex-shrink: 0;
    position: relative;
    overflow-y: auto;

    .divider {
      margin-inline: 16px;
      background: var(--profile-menu-block-divider-color);
    }

    .profiles {
      margin: 0;
      padding: 8px 8px 8px 0;
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: auto;

      .profile-name-container {
        display: flex;
      }

      .profile-icon {
        margin: 0 8px 0 0;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        @include apply-header-menu-profile-icon-font();
        background-color: var(--btn-primary-color);
        border-radius: 50%;
        overflow: hidden;
        color: var(--white-color);
      }

      .profile-name {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 4px;
        @include apply-header-menu-profile-name-font();
        color: var(--profile-menu-block-item-text-color);

        .last-name,
        .first-name {
          max-width: 135px;
        }
      }
    }

    .profile-menu-block {
      margin-top: 24px;
      position: relative;
      overflow: hidden;
    }

    .profile-menu-block-title {
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      @include apply-header-menu-profile-block-title-font();
      text-transform: uppercase;
      color: var(--profile-menu-block-title-text-color);
      background-color: var(--white-bg);
      position: relative;
      z-index: 2;

      .mat-icon-button {
        border: none !important;

        @include apply-icon-size(20px);
      }
    }

    .profile-menu-block-items {
      display: grid;
      grid-auto-flow: row;
      gap: 0;
    }

    .profile-menu-block-item {
      padding: 8px 0 8px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      @include apply-header-menu-profile-block-item-font();
      color: var(--profile-menu-block-item-text-color);
      border-radius: 4px;
      position: relative;
      z-index: 1;

      &.icon-only {
        padding: 8px !important;

        .mat-icon {
          margin-right: 0;
        }
      }

      &:not(.theme-toggle):hover {
        background-color: var(--profile-menu-block-item-bg-color);
        .mat-icon.strategy-icon {
          color: var(--profile-menu-block-item-bg-color);
        }
      }

      &.profile-settings:hover {
        background-color: transparent;
      }

      .mat-icon {
        margin-right: 8px;
        @include apply-icon-size(18px);
        height: 19px;
        color: var(--profile-menu-block-item-icon-color);
      }

      .mat-icon.strategy-icon {
        color: var(--white-bg);
      }

      &.trading-hub {
        .mat-icon {
          color: #ef5350;
        }
      }

      &.links-organiser {
        margin: 16px 16px 10px 16px;
        border: 1px solid var(--divider-color);

        .mat-icon.pin-icon {
          --pin-icon-active-color: transparent;
          color: var(--primary-color);
          position: relative;
          overflow: visible;

          &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: var(--no-links-section-bg-color);
            border: 1px solid var(--divider-color);
            border-radius: 50%;
            z-index: -1;
          }
        }
      }
    }

    .profile-menu-block.help-block {
      max-height: 24px;
      -webkit-transition: max-height 0.25s ease-in-out;
      -moz-transition: max-height 0.25s ease-in-out;
      -ms-transition: max-height 0.25s ease-in-out;
      -o-transition: max-height 0.25s ease-in-out;
      transition: max-height 0.25s ease-in-out;
      overflow: hidden;
      cursor: pointer;

      .profile-menu-block-title {
        margin: 0;
        padding: 0 16px;

        .mdc-icon-button.close-open-item-btn {
          --mdc-icon-button-state-layer-size: 22px;
          --mdc-icon-button-icon-size: 16px;
        }
      }

      .profile-menu-block-item {
        padding: 6px 0 6px 16px;
        @include apply-header-menu-profile-help-block-item-font();
        color: var(--profile-menu-block-item-text-color);
        transform: translateY(-60px);
        transition:
          opacity 0.25s ease-in-out,
          transform 0.25s ease-in-out;
        appearance: none;
        pointer-events: none;
        opacity: 0;
      }

      &.show {
        max-height: 266px;

        @for $i from 1 through 8 {
          .profile-menu-block-item:nth-child(#{$i}) {
            transform-origin: top center;
            transform: translateY(0);
            transition-delay: ($i * 20ms);
            opacity: 1;
            appearance: auto;
            pointer-events: auto;
          }
        }
      }
    }

    .help-footer {
      margin: 8px 0 20px 16px;
      padding: 0;

      li {
        @include apply-header-menu-profile-help-footer-block-license-font();
        color: var(--profile-menu-block-title-text-color);
      }

      li:last-child {
        margin: 0;
      }
    }

    .profile-menu-block-item.logout {
      margin: 8px 0 10px 0;
      padding-right: 16px;
      justify-content: space-between;

      &:hover {
        background-color: var(--profile-menu-block-item-bg-color);
      }

      .mat-icon {
        margin-right: 0;
      }
    }

    .dark-theme-toggle {
      padding: 0 16px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: var(--profile-menu-block-item-bg-color);
      }
    }

    .divider {
      width: auto;
    }

    .mat-icon.svg-menu-icon {
      margin-right: 8px;
    }
  }
}
