$line-height: 10px;

.range-slider-wrapper {
    &.settings-slider {
        padding: 0 10px;

        .ngx-slider {
            margin: 6px 0 20px;
            height: $line-height;

            .ngx-slider-bar-wrapper {
                padding-top: 0;
                margin-top: 0;
                height: calc(#{$line-height} + 3px);
            }

            .ngx-slider-left-out-selection .ngx-slider-bar {
                background: var(--red-point);
                height: $line-height;
            }

            .ngx-slider-right-out-selection .ngx-slider-bar {
                background: var(--orange-point);
                height: $line-height;
            }

            .ngx-slider-bar {
                height: $line-height;
                position: relative;
                display: inline-block;
                top: -5px;
                border-radius: 0;
                height: 10px;
            }

            .ngx-slider-full-bar .ngx-slider-bar {

                &:after,
                &::before {
                    content: '';
                    width: 20px;
                    height: 100%;
                    position: absolute;
                    transform: scaleY(102%);
                }

                &:after {
                    right: -10px;
                    top: 0;
                    background: var(--orange-point);
                    border-radius: 0px 2px 2px 0px;
                }

                &::before {
                    left: -10px;
                    top: 0;
                    background: var(--red-point);
                    border-radius: 2px 0px 0px 2px;
                }
            }

            @media screen and (-webkit-min-device-pixel-ratio: 1.1979166666666667),
            screen and (min-resolution: 115dpi) {
                .ngx-slider-full-bar .ngx-slider-bar {
                    transform: scaleY(101%);

                    &:after,
                    &::before {
                        top: 0px;
                        transform: scaleY(101%);
                    }
                }

                .ngx-slider-bar {
                    transform: scaleY(101%);
                }
            }

            .ngx-slider-selection {
                background: var(--green-font);
            }

            .ngx-slider-bubble {
                bottom: -23px;
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: var(--main-font-color);
            }

            .ngx-slider-pointer {
                bottom: 0;
                top: -6px;
                left: 5px;
                width: 10px;
                height: 22px;
                background: var(--settings-change-password-input-background-color);
                border: 1px solid var(--settings-show-password-icon-color);
                border-radius: 3px;

                @media screen and (min-width:$mobile-min-575px-width-trigger) {
                    width: 20px;
                }

                &::after {
                    content: none;
                }

                &:focus-visible {
                    outline: none;
                }
            }

            .ngx-slider-floor {
                left: -10px  !important;
                color: var(--main-font-color);
            }

            .ngx-slider-ceil {
                left: calc(100% - 20px) !important;
                padding-left: 0;
                color: var(--main-font-color);
            }
        }
    }

    .searching-slider {
        .ngx-slider {
            margin: 5px 0 11px 0;
        }

        .ngx-slider .ngx-slider-bar {
            height: 10px;
            background: var(--table-border-color);
        }

        .ngx-slider .ngx-slider-pointer,
        .ngx-slider-active {
            width: 20px;
            height: 20px;
            top: -5px;
            background: var(--trading-log-table-bg);
            border: 1px solid var(--monochrome-icon-color);
            border-radius: 3px;

            &:focus {
                outline: none;
            }
        }

        .ngx-slider .ngx-slider-pointer:after,
        .ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
            content: none;
        }

        .ngx-slider .ngx-slider-bubble.ngx-slider-limit,
        .ngx-slider .ngx-slider-bubble {
            bottom: -35px;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: var(--main-font-color);
        }

        .ngx-slider-model-value {
            padding: 1px 0 !important;
        }

        .ngx-slider-floor {
            padding-left: 6px !important;
        }

        .ngx-slider-ceil {
            padding: 1px 0 !important;
        }
    }

}