@import "../mixins/fonts";
@import "../mixins/images";

.mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 24px;
  --mdc-icon-button-icon-size: 16px;
  padding: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--global-mat-btn-border-radius, 4px);

  .mat-icon {
    width: var(--mdc-icon-button-icon-size);
    height: var(--mdc-icon-button-icon-size);
    line-height: var(--mdc-icon-button-icon-size);
  }

  .mat-mdc-button-touch-target {
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
  }

  img {
    --mdc-icon-button-icon-size: 24px;
  }

  .mat-mdc-button-ripple,
  .mat-mdc-button-persistent-ripple,
  .mat-mdc-button-persistent-ripple::before {
    border-radius: var(--global-mat-btn-border-radius, 4px);
  }

  &.mat-primary,
  &.icon-button {
    --mdc-icon-button-state-layer-size: 32px;
    --mdc-icon-button-icon-size: 20px;
    padding: 0;
    background-color: var(--btn-bg-color);

    &:hover {
      background-color: var(--custom-btn-hover-color);
    }
  }

  &.mat-mdc-menu-trigger.drop {
    &[aria-expanded="true"] img {
      transform: rotateX(0.5turn);
      transition: transform 0.3s ease-in-out;
    }

    .mat-mdc-button-ripple,
    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-persistent-ripple::before {
      --global-mat-btn-border-radius: 50%;
    }
  }

  &.rounded-full {
    --global-mat-btn-border-radius: 50%;
  }

  &.extra-small-icon-btn {
    --mdc-icon-button-state-layer-size: 20px;
    --mdc-icon-button-icon-size: 16px;
    padding: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .mat-icon {
      @include apply-icon-size(16px);
    }
  }

  &.mat-secondary {
    .mat-mdc-button-persistent-ripple::before {
      opacity: 0.04;
    }

    &:hover {
      .mat-mdc-button-persistent-ripple::before {
        opacity: 0.048;
      }
    }
  }

  &.component-btn.custom-icon-btn {
    --mdc-icon-button-icon-size: 24px;
    width: 24px;
    height: 24px;
    margin-right: 5px;

    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-persistent-ripple::before {
      --mat-ripple-btn-opacity: 0.02;
      --global-mat-btn-border-radius: 50%;
      border-radius: 50%;
    }

    .mat-icon {
      flex-shrink: 0;
    }

    .mat-ripple.mat-mdc-button-ripple,
    .mat-ripple.mat-mdc-button-ripple::before {
      --global-mat-btn-border-radius: 50%;
      border-radius: 50%;
    }
  }

  &.component-btn.rounded {
    --global-mat-btn-border-radius: 50%;
  }
}

.mdc-button.mat-mdc-button-base {
  --mdc-text-button-container-height: 36px;
  height: var(--mdc-text-button-container-height);
  letter-spacing: normal;
  vertical-align: middle;

  &:not(:disabled) {
    --mdc-text-button-label-text-color: var(--main-font-color);

    &:hover {
      .mat-mdc-button-persistent-ripple::before {
        opacity: 0.04;
      }
    }

    &.mat-unthemed {
      --mdc-text-button-label-text-color: var(--main-font-color);
      --mdc-outlined-button-label-text-color: var(--main-font-color);
    }

    &.mat-primary {
      --mdc-text-button-label-text-color: var(--primary-color);
      --mdc-outlined-button-label-text-color: var(--primary-color);
      --mat-mdc-button-persistent-ripple-color: var(--primary-color);
    }
  }

  &.mdc-button--unelevated.mat-mdc-unelevated-button,
  &.mdc-button--raised.mat-mdc-raised-button {
    &.mat-primary {
      --mdc-protected-button-container-color: var(--primary-color);
      --mdc-unelevated-button-label-text-color: var(--white-color);
      --mdc-filled-button-container-color: var(--primary-color);
      --mat-mdc-button-persistent-ripple-color: var(--primary-color);

      &:hover {
        --mdc-protected-button-container-color: var(--primary-btn-bg-hover);
        --mdc-filled-button-container-color: var(--primary-btn-bg-hover);
      }
    }
  }

  &.mat-mdc-outlined-button {
    --mdc-outlined-button-outline-color: var(--light-border-color-2);

    &.mat-unthemed {
      --mdc-outlined-button-label-text-color: var(--border-btn-color);
    }
  }

  &.mat-secondary {
    .mat-mdc-button-persistent-ripple::before {
      opacity: 0.08;
    }

    &:hover {
      .mat-mdc-button-persistent-ripple::before {
        opacity: 0.088;
      }
    }
  }

  &.custom-action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--custom-action-btn-bg);
    color: var(--custom-action-btn-font-color);

    &:hover {
      background-color: var(--custom-btn-hover-color);
    }
  }
}

/* Raised and Flat Buttons Hover ripple-overlay opacity style */
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  background-color: var(--mat-mdc-button-persistent-ripple-color);
  opacity: var(--mat-ripple-btn-opacity);
}

.mat-icon-button,
.mdc-icon-button,
.mat-stroked-button,
.mdc-button,
.mat-button:not(a) {
  --mdc-icon-button-state-layer-size: 32px;
  --mdc-icon-button-icon-size: 24px;

  &.button {
    color: var(--classic-statick-font-color);
  }

  &.secondary-btn {
    border-color: var(--secondary-btn-border);
  }

  &.mat-raised-button {
    box-shadow: none;
  }

  .label,
  .lable {
    margin-right: 10px;
  }

  &.grouped-btn {
    padding: 0 7px;
  }

  &.btn-large {
    height: $large-size;
    width: $large-size;
    border: none;
    border-radius: $control-border-radius;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 0;

    &:hover {
      background-color: var(--chip-container-field-clear-button-hover-bg-color);
    }
  }

  &.btn-standard {
    --mdc-icon-button-state-layer-size: 32px;
    --mdc-icon-button-icon-size: 20px;
    border: none;
    border-radius: $control-border-radius;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &.btn-middle {
    height: $middle-size;
    width: $middle-size;
    border: none;
    border-radius: $control-border-radius;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &.low-btn {
    height: $standard-size;
  }

  &.label-btn,
  &.lable-btn {
    height: 20px;
    padding: 0 5px !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    .label,
    .lable {
      margin: 0;
    }
  }

  &.default-color {
    background-color: var(--default-btn-background-color);
    color: var(--white-color);
  }

  &.primary-bg {
    background-color: var(--primary-alpha);

    &:hover {
      background-color: var(--chip-container-field-clear-button-hover-bg-color);
    }
  }

  &.danger-bg {
    background: var(--btn-danger-icon-color);

    &:hover {
      background: var(--btn-danger-icon-hover-color);
    }
  }

  &.successful-bg {
    background: var(--btn-success-icon-color);

    &:hover {
      background: var(--btn-success-icon-hover-color);
    }
  }

  &.main-bg {
    --mdc-filled-button-label-text-color: var(--white-color);
    background: var(--primary-btn-bg);

    &:hover {
      background: var(--primary-btn-bg-hover);
    }
  }

  &.secondary-bg {
    &:hover {
      background: var(--secondary-btn-bg-hover);
    }
  }

  &:disabled {
    opacity: 0.7;

    cursor:
      var(--disabled-button-icon) 5 5,
      auto;

    &.main-bg {
      color: var(--secondary-static-font-color);
      background: var(--main-btn-disabled-bg);
    }

    &.primary-bg {
      color: var(--primary-btn-disabled-bg);

      &:hover {
        background-color: var(--primary-alpha);
      }
    }

    &.secondary-bg {
      color: var(--border-btn-color);

      &:hover {
        background-color: transparent;
      }
    }
  }

  .mat-stroked-button {
    line-height: 100%;
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &.mat-button-link .mat-button-wrapper {
    display: inline;
  }

  .right-side-title {
    margin-left: 8px;
  }

  &.rounded {
    border-radius: 50%;
  }
}

.watchlist-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid var(--asset-correlation-table-border-color);
  cursor: pointer;

  &.bright-border {
    border: 1px solid var(--bright-contrast-border-color);
  }

  .star-button {
    --mdc-icon-button-state-layer-size: 32px;
    --mdc-icon-button-icon-size: 20px;
    min-width: auto;
    padding: 5px;
  }
}

.mat-flat-button,
.mdc-button--unelevated {
  &.flat-button {
    border-radius: 50px;
    padding: 5px 10px;
    @include flat-button-font();
    color: var(--active-link);
    transition: 0.2s;
    background-color: var(--lable-primary-background-color);

    &:hover {
      transition: 0.2s;
      background-color: var(--chip-container-field-clear-button-hover-bg-color);
    }

    &.active-button {
      background-color: var(--primary-color);
      color: var(--classic-statick-font-color);

      &:hover {
        background-color: var(--primary-color);
      }

      .amount {
        &.charged {
          color: var(--classic-statick-font-color);
        }
      }
    }

    .mdc-button__label {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .amount {
      @include apply-secondary-header-font();
      color: var(--monochrome-color-hover-color);

      &.charged {
        color: var(--primary-color);
      }
    }
  }
}

.compare-btn .mat-button {
  border-radius: 50px;
}

.mat-button.save-btn .mat-button-wrapper {
  display: unset;
}

.long-position-button.mat-button .mat-button-wrapper {
  justify-content: flex-start;
}

.mat-mdc-tab-header-pagination-before,
.mat-mdc-tab-header-pagination-after {
  padding: 0;
  width: 22px;
  min-width: 22px;
  height: 22px;
  box-shadow: none;
  border-radius: 50%;
  border: 1px solid var(--tabs-border-color);

  &:disabled {
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0 !important;
    pointer-events: none !important;
    cursor: default !important;
    width: 0;
    min-width: 0;
    border: 0;
    transition: 0.1s;
  }
}

.mat-mdc-tab-header-pagination-before .mat-mdc-tab-header-pagination-chevron {
  position: relative;
  left: 1px;
  border-color: var(--mat-chips-close-button-color);
}

.mat-mdc-tab-header-pagination-after .mat-mdc-tab-header-pagination-chevron {
  position: relative;
  right: 1px;
  border-color: var(--mat-chips-close-button-color);
}

// TODO: remove styles below + add class  'lable-btn' to buttons inside traiding log
.actions-btn .action-button.mdc-button {
  --mdc-text-button-container-height: 20px;
  height: 20px;
  width: fit-content;
}

.spinner-btn {
  .white-spinner {
    margin: 9px auto;
    height: 100%;
    transition: 0.2s;
    overflow: hidden;

    svg {
      position: absolute;
    }
  }
}

.trading-panel-container {
  .trading-plan-tabs-content {
    .filter-control {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      padding: 15px 4px;

      .mat-flat-button.flat-button,
      .mdc-button--unelevated {
        padding: 5px 11px 5px 10px;
        min-width: auto;
        font-size: 14px;
        line-height: 21px;

        &:not(.active-button) {
          color: var(--primary-font-color);
        }
      }
    }
  }
}

.mat-button-toggle-appearance-standard {
  background: transparent;

  &.mat-button-toggle-checked {
    --mat-standard-button-toggle-selected-state-background-color: var(--white-bg);
  }
}

.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
  opacity: 0;
}

.price-ticker {
  .price-btn {
    .mat-button-toggle-label-content {
      display: block;
    }
  }
}

.button-toggle-control,
.portfolio-control {
  .mat-button-toggle-label-content {
    padding: 0;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content,
  .mat-button-toggle-label-content {
    line-height: initial;
  }

  .view-switcher {
    margin-left: auto;
    padding: 2px;
    gap: 4px;
    width: 64px;
    height: 26px;
    background: var(--view-switcher-bg-color);
    border: 1px solid var(--divider-color);
    border-radius: 4px;

    .view-switcher-btn {
      width: 28px;
      height: 20px;
      background: transparent;
      box-shadow: none;
      border: none;
      border-radius: 3px;
      line-height: 15px;

      &.mat-button-toggle-checked {
        background: var(--view-switcher-btn-checked-color);
        box-shadow: var(--view-switcher-btn-checked-shadow);
        outline: 1px solid var(--divider-color);

        .view-switcher-icon {
          color: var(--view-switcher-active-icon-color);
        }
      }
    }

    .view-switcher-icon {
      color: var(--view-switcher-default-icon-color);
    }
  }
}

.days-block {
  .mat-button-toggle-label-content {
    padding: 0;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content,
  .mat-button-toggle-label-content {
    line-height: initial;
  }
}

.view-switcher {
  margin-left: auto;
  padding: 2px;
  gap: 4px;
  height: 26px;
  background: var(--view-switcher-bg-color);
  border: 1px solid var(--divider-color);
  border-radius: 4px;

  .view-switcher-btn {
    --mat-standard-button-toggle-label-text-line-height: normal;
    --mat-standard-button-toggle-label-text-size: 14px;
    height: 20px;
    background: transparent;
    box-shadow: none;
    border: none;
    border-radius: 3px;

    &:hover {
      background-color: var(--secondary-btn-bg-hover);
    }

    &.mat-button-toggle-checked {
      background: var(--view-switcher-btn-checked-color);
      box-shadow: var(--view-switcher-btn-checked-shadow);
      outline: 1px solid var(--divider-color);

      .view-switcher-icon,
      .view-switcher-text {
        color: var(--view-switcher-active-icon-color);
      }

      .view-switcher-text,
      .view-switcher-btn-text {
        -webkit-text-stroke-width: 0.3px;
        -webkit-text-stroke-color: var(--view-switcher-active-icon-color);
      }
    }

    .mat-button-toggle-focus-overlay {
      background: none;
    }
  }

  .view-switcher-btn + .view-switcher-btn {
    border-left: none;
  }

  .view-switcher-icon,
  .view-switcher-text {
    color: var(--view-switcher-default-icon-color);
  }

  .view-switcher-text {
    margin-left: 2px;
  }

  .mat-button-toggle-label-content {
    line-height: initial;
  }
}

.view-switcher.video-hub-view-mode {
  padding: 0;
  height: auto;
  background-color: transparent;
  gap: 0;
  border: none;

  mat-button-toggle {
    mat-pseudo-checkbox {
      display: none;
    }
  }

  .view-switcher-btn {
    --mat-standard-button-toggle-shape: 0;
    height: auto;
    max-height: 28px;
    border: 1px solid var(--divider-color);

    &:first-child {
      border-right: none;
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-left: none;
      border-radius: 0 4px 4px 0;
    }

    .mat-button-toggle-button {
      height: 26px;
    }

    .mat-icon.view-mode-btn-icon {
      color: var(--monochrome-icon-color);

      &.cards-view {
        @include apply-icon-size(20px);
      }
    }

    .view-switcher-text {
      -webkit-text-stroke-width: unset;
      -webkit-text-stroke-color: unset;
    }

    &:hover {
      background-color: var(--primary-alpha);

      &:not(.mat-button-toggle-checked) {
        .mat-icon.view-mode-btn-icon {
          color: var(--primary-color);
        }
      }
    }

    &.mat-button-toggle-checked {
      background-color: var(--primary-alpha);
      border: 1px solid var(--primary-color);
      outline: none;

      .view-mode-btn-icon,
      .view-switcher-text {
        color: var(--primary-color);
      }
    }
  }
}

.mat-button.mat-button-link {
  &.link-button {
    line-height: 24px;

    .mat-button-wrapper {
      color: var(--btn-primary-color);
      text-decoration: underline;
    }
  }
}

.heatmap-settings {
  .view-switcher {
    .mat-button-toggle-label-content {
      padding: 4px 8px;
      line-height: initial;
      color: var(--view-switcher-default-icon-color);
    }

    .view-switcher-btn.mat-button-toggle-checked {
      .mat-button-toggle-label-content {
        color: var(--view-switcher-active-icon-color);
      }
    }
  }
}

.my-setting-content.trading-log {
  .view-switcher {
    .mat-button-toggle-label-content {
      padding: 0;
      line-height: initial;
      color: var(--view-switcher-default-icon-color);
    }

    .view-switcher-btn.mat-button-toggle-checked {
      .mat-button-toggle-label-content {
        color: var(--view-switcher-active-icon-color);
      }
    }
  }
}

.view-switcher.overall-performance-switcher {
  .view-switcher-btn {
    padding: 1px 4px !important;
    .mat-button-toggle-label-content {
      line-height: initial;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.heatmap-market-data {
  .selected-market.mdc-button {
    --mdc-text-button-container-height: 32px;
    --mdc-icon-button-icon-size: 16px;

    .mat-icon {
      @include apply-icon-size(16px);
    }
  }
}

.stocks-menu-btn-trigger span {
  margin: 0;
}

.add-tl-trade-container {
  .mat-button-toggle-label-content {
    line-height: 40px;
  }
}

.pxo-datepicker-trigger {
  &.mat-icon-button .mat-button-wrapper,
  &.mat-stroked-button .mat-button-wrapper,
  &.mat-button:not(a) .mat-button-wrapper {
    justify-content: flex-start;
  }
}

.agreement-action-btns .mat-mdc-button-disabled {
  pointer-events: auto !important;
}

.mat-mdc-button-disabled.not-allowed-when-disabled {
  pointer-events: auto !important;
}
