:root {
  --disabled-button-icon: url('/assets/img/Icons/not-allowed.svg');

  // light theme
  --tl-dashboard-chart-extra-wide-img: url('/assets/img/no-search-results/tl-dashboard-charts/no-data-result-extra-long-light.svg');
  --no-data-trading-hub-img: url('/assets/img/no-data-trading-hub/no-data-trading-hub-light.svg');

  body.black-theme {
    --tl-dashboard-chart-extra-wide-img: url('/assets/img/no-search-results/tl-dashboard-charts/no-data-result-extra-long-dark.svg');
    --no-data-trading-hub-img: url('/assets/img/no-data-trading-hub/no-data-trading-hub-dark.svg');
  }
}
