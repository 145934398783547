// need to override style.scss content
.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 3px !important;
}

.feelings-modal-options .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: var(--feelings-modal-option-disabled-color);
}

.chat-feedback-modal {
  .mat-checkbox.feedback-option-checkbox {
    .mat-checkbox-layout {
      .mat-checkbox-label {
        white-space: pre-line;
      }
    }
  }
}
