.plan-tab-content {
  .NgxEditor__Content {
    @extend .scroll-style;
    padding: 6px !important;
    max-height: 316px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;

    p.NgxEditor__Placeholder {
      color: var(--trading-plan-section-content-placeholder-color) !important;
      font-style: italic;
    }
  }

  .NgxEditor__Wrapper {
    min-height: 110px;
    max-height: 428px;
    border-color: var(--active-link-color) !important;
    border-radius: 4px !important;
    position: relative;
  }

  .NgxEditor__MenuBar {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px 8px 8px;
    gap: 0;
  }

  .NgxEditor__Dropdown {
    &:hover {
      background-color: var(--light-bg-1) !important;
    }

    .NgxEditor__Dropdown--Text {
      height: var(--ngx-editor-icon-size, 30px);
      border: 1px solid var(--input-border-color);
    }

    .NgxEditor__Dropdown--Text.NgxEditor__Dropdown--Selected {
      background-color: var(--light-bg-1) !important;
    }

    .NgxEditor__Dropdown--Text:after {
      margin-left: 8px;
    }

    .NgxEditor__Dropdown--DropdownMenu {
      background: var(--drop-down-bg);
      border: 1px solid var(--modal-border);
      box-shadow: 0 3px 6px 0 var(--modal-box-shadow);

      .NgxEditor__Dropdown--Item {
        &:hover {
          background-color: var(--light-bg-1) !important;
        }
      }

      .NgxEditor__Dropdown--Item.NgxEditor__Dropdown--Active {
        background-color: var(--active-background-color);
      }
    }
  }

  .NgxEditor__MenuItem {
    margin: 0 !important;
    position: static;

    &.NgxEditor__MenuItem--IconContainer {
      border: 1px solid var(--input-border-color);

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &:has(.NgxEditor__MenuItem--Active) {
        border-color: var(--active-link-color);

        &:hover {
          background-color: transparent !important;
        }
      }

      .NgxEditor__MenuItem--Icon {
        &:hover {
          --ngx-editor-menu-item-hover-bg-color: var(--light-bg-1)
        }

        &.NgxEditor__MenuItem--Active {
          --ngx-editor-menu-item-active-bg-color: var(--active-background-color);
        }

        &.NgxEditor__MenuItem--Active:hover {
          --ngx-editor-menu-item-hover-bg-color: var(--active-background-color);
        }
      }
    }

    .NgxEditor__Popup {
      top: 98%;
      right: 9px;
      width: 236px;
      background: var(--modal-bg);
      box-shadow: 0 3px 6px 0 var(--modal-box-shadow);
      border: 1px solid var(--modal-border);
      @extend .scroll-style;

      @media screen and (max-width: $mobile-min-425px-width-trigger) {
        width: 100%;
        overflow-x: auto;
      }

      @media screen and (max-width: $mobile-min-500px-width-trigger) {
        left: 0;
        right: initial;
      }

      .NgxEditor__ColorContainer {
        min-width: 220px;
        gap: 4px;
      }
    }

    .NgxEditor__MenuItem--Button {
      display: block;
      padding: 2px 4px;
      border-radius: 4px;
      outline: none;
      border: none;
    }
  }

  .NgxEditor__Seperator {
    display: none;
  }
}
