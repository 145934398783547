@import '../mixins/fonts';

.mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: 18px;

  .mdc-checkbox {
    margin-right: 10px;
    padding: 0;
    flex-basis: var(--mdc-checkbox-state-layer-size);
    width: var(--mdc-checkbox-state-layer-size);
    height: var(--mdc-checkbox-state-layer-size);

    .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      top: 0;
      left: 0;
      background-color: var(--white-bg);
    }

    /*Inner ripple*/
    .mdc-checkbox__ripple {
      border-radius: 4px;
    }

    /*Outer ripple*/
    .mat-ripple.mat-mdc-checkbox-ripple {
      display: none !important;
    }

    .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
      opacity: 0 !important;
      background-color: transparent !important;
    }
  }

  .mdc-label {
    @include font-regular(14px, 18px);
  }
}

.mat-mdc-checkbox .mdc-checkbox__background,
.mat-mdc-checkbox:hover .mdc-checkbox__background,
.mdc-checkbox:hover
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
  ~ .mdc-checkbox__background,
.mdc-checkbox:active .mdc-checkbox__native-control ~ .mdc-checkbox__ripple,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate)
  ~ .mdc-checkbox__background {
  --mdc-checkbox-unselected-icon-color: var(--checkbox-border-color);
  --mdc-checkbox-unselected-hover-icon-color: var(--checkbox-border-color);
  --mdc-checkbox-unselected-focus-icon-color: var(--checkbox-border-color);
  width: calc(var(--mdc-checkbox-state-layer-size));
  height: calc(var(--mdc-checkbox-state-layer-size));
  border: 1px solid var(--checkbox-border-color);
  border-radius: 4px;
}

.mat-mdc-checkbox.large-checkbox {
  --mdc-checkbox-state-layer-size: 22px;

  .mdc-checkbox {
    margin-right: 12px;

    .mat-mdc-checkbox-touch-target {
      width: calc(var(--mdc-checkbox-state-layer-size) * 1.6);
      height: calc(var(--mdc-checkbox-state-layer-size) * 1.6);
    }
  }

  .mdc-label {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: normal;
    color: var(--font-dark-color);
  }
}

.mat-mdc-checkbox.middle-checkbox {
  --mdc-checkbox-state-layer-size: 18px;

  .mdc-checkbox {
    margin-right: 8px;

    .mat-mdc-checkbox-touch-target {
      width: calc(var(--mdc-checkbox-state-layer-size) * 1.6);
      height: calc(var(--mdc-checkbox-state-layer-size) * 1.6);
    }
  }

  .mdc-label {
    @include font-regular(14px, 18px);
  }
}

.mat-mdc-checkbox.small-checkbox {
  --mdc-checkbox-state-layer-size: 14px;

  .mdc-checkbox {
    margin-right: 4px;

    .mat-mdc-checkbox-touch-target {
      width: calc(var(--mdc-checkbox-state-layer-size) * 1.6);
      height: calc(var(--mdc-checkbox-state-layer-size) * 1.6);
    }

    .mdc-checkbox__background {
      border-radius: 3px;
    }
  }

  .mdc-label {
    @include font-regular(11px, 15px);
  }
}

.print-data-options {
  .pxo-checkbox.small-checkbox {
    .mdc-label {
      @include font-regular(14px, 24px, 0.5px, var(--main-font-color));
    }
  }
}

.alert-dialog-container {
  .mat-mdc-checkbox.middle-checkbox .mdc-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-mdc-checkbox-disabled .mdc-checkbox .mdc-checkbox__background {
  background-color: var(--disabled-checkbox-bg-color) !important;
  border: 1px solid var(--disabled-checkbox-border-color);
  .mdc-checkbox__checkmark {
    color: var(--content-color-3);
  }
}
