@import '../values';

.chart {
  display: flex;
  flex-direction: column;

  font-family: $trebuchet-font;
  border: 1px solid var(--chart-border);
  border-radius: $control-border-radius;
  padding: 10px 10px 0 10px;
  color: var(--main-headers-font-color);

  .apexcharts-tooltip {
    color: var(--chart-tooltip-font-color);
    font-family: $trebuchet-font;
  }

  .apexcharts-legend {
    flex-wrap: nowrap !important;
//     inset: unset !important;
 // bottom: 0 !important;
 // left: 50% !important;
 // transform: translateX(-50%);
  }

}