@import '../../scss/mixins/fonts';
@import '../../scss/mixins/images';

.quick-links {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8px 16px;
  height: calc(100% - 8px);

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 0;
    height: 100dvh;
    border-right: 1px solid var(--divider-color);
    z-index: 999;
  }

  app-links-organiser-edit-pane {
    width: 100%;
  }

  .popup-close-btn {
    margin-left: auto;
  }

  .quick-links-container {
    height: fit-content;
    overflow: auto;
    border: 1px solid var(--divider-color);
    border-radius: 2px;

    &.cdk-drop-list-dragging {
      overflow: hidden;

      .quick-links-section {
        border: 0 !important;
      }
    }
  }

  &-heading {
    margin-bottom: 10px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    &-title {
      display: grid;
      grid: auto / 20px auto;
      align-items: center;
      gap: 10px;
      color: var(--main-font-color);

      .pin-icon {
        @include apply-icon-size(20px);
        color: var(--primary-color);
        position: relative;
        overflow: visible;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid var(--divider-color);
          border-radius: 50%;
          background-color: transparent;
          z-index: 1;
        }
      }
    }
  }

  &-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 7px;
    overflow: auto;
    height: fit-content;

    &:nth-of-type(2) {
      border-top: 1px solid var(--divider-color);
    }

    &.cdk-drag-placeholder {
      height: 40px;
    }

    .mat-expansion-panel {
      box-shadow: none;
      border: none;
      height: 100%;
      overflow: hidden;
      display: grid;
      grid: auto 1fr / auto;
      background: transparent;

      &-content {
        overflow: auto;
      }

      .mat-expansion-panel-body {
        padding: 0;
        margin: 0;
        overflow: hidden;
        height: 100%;
      }

      &-header {
        margin: 0;
        padding: 0;

        &-title {
          margin: 0;
          gap: 10px;
          text-transform: uppercase;
          @include font-bold($size: 12px, $l-spacing: -0.01em, $color: var(--sub-header));
        }

        &.cdk-focused:not(:hover) {
          background-color: inherit;
        }

        &:hover:not([aria-disabled='true']) {
          background-color: transparent !important;
        }

        .mat-content {
          margin: 0;
          position: relative;
        }

        .component-btn.close-open-item-btn {
          width: 24px;
          height: 24px;
          margin: 0;

          .mat-mdc-button-persistent-ripple,
          .mat-mdc-button-ripple {
            opacity: 0;
          }
        }

        .close-open-arrow {
          border: 1px solid var(--close-open-btn-font);
          border-radius: 50%;
        }

        .arrow-wrapper {
          width: 24px;
          height: 24px;
          display: block;
          background-color: transparent;

          .close-open-arrow {
            width: 24px;
            height: 24px;
            display: block;
            transition: 0.2s;

            &::after {
              top: 10px;
              left: 6px;
              transform: rotate(45deg);
              background-color: var(--close-open-btn-font);
            }

            &::before {
              top: 10px;
              left: 10px;
              transform: rotate(-45deg);

              background-color: var(--close-open-btn-font);
            }
          }
        }

        &.mat-expanded {
          .close-open-arrow {
            &::after {
              transform: rotate(-45deg);
              transition: 0.3s;
            }

            &::before {
              transform: rotate(45deg);
              transition: 0.3s;
            }
          }
        }
      }
    }

    &-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 5px;
      height: 100%;
      overflow: auto hidden;

      .quick-link-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        min-height: 24px;

        .drag-handler {
          flex-shrink: 0;
          cursor: grab;
          opacity: 0;

          &:active {
            cursor: grabbing;
          }
        }

        .content-wrapper {
          width: -webkit-fill-available;
          display: flex;
          align-items: center;
          font-size: 14px;
          box-sizing: border-box;
          height: 100%;

          .quick-link-actions {
            margin-left: auto;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            gap: 10px;

            .quick-link-action-btn {
              border: none;
              background: none;
              width: 24px;
              height: 24px;
              cursor: pointer;

              .mat-icon {
                @include apply-icon-size(24px);
              }
            }
          }
        }

        .quick-link,
        .quick-link:visited {
          cursor: pointer;
          color: var(--font-dark-color);
        }

        .quick-link-indent {
          white-space: pre-wrap;

          &.margin-left {
            margin-left: 34px;
          }
        }

        .quick-link-actions {
          opacity: 0;
        }

        &:hover {
          .quick-link,
          .quick-link:visited {
            &:not(.empty) {
              color: var(--primary-color);
              text-decoration: underline;
            }

            &.empty {
              color: var(--font-dark-color);
            }
          }

          .drag-handler {
            opacity: 1;
          }

          .quick-link-actions {
            opacity: 1;
          }
        }
      }
    }
  }

  &.MOBILE {
    padding: 16px;
    height: 100dvh;

    &:before {
      display: none;
    }

    .quick-links-heading {
      .quick-links-heading-title {
        .pin-color {
          --pin-icon-active-color: var(--modal-bg);

          &.before {
            display: inline-block;
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .quick-link-wrapper {
      .drag-handler {
        opacity: 1;
      }

      .quick-link,
      .quick-link:visited {
        &:not(.empty) {
          color: var(--primary-color);
          text-decoration: underline;
        }

        &.empty {
          color: var(--font-dark-color);
        }
      }

      .quick-link-actions {
        opacity: 1;
      }
    }
  }
}

.quick-link-wrapper {
  &.cdk-drag-preview {
    display: grid;
    grid: auto / 24px auto;
    gap: 10px;
    padding: 0;
    box-shadow: var(--drag-item-shadow);
    background: var(--drag-item-background);
    overflow: hidden;

    .drag-handler {
      flex-shrink: 0;
      opacity: 0;
    }

    .content-wrapper {
      display: flex;
      align-items: center;
      font-size: 14px;
      box-sizing: border-box;
    }

    .quick-link-indent {
      white-space: pre-wrap;
    }

    .quick-link {
      color: var(--primary-color);
      text-decoration: underline;
    }

    .quick-link-actions {
      display: none;
    }
  }
}

.quick-links-section {
  &.cdk-drag-preview {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 7px;
    height: 40px !important;
    background: var(--white-bg);

    .mat-expansion-panel {
      box-shadow: none;
      border: none;

      .mat-expansion-panel-body {
        padding: 0;
        margin: 0;
      }

      &-header {
        margin: 0;
        padding: 0;

        &-title {
          margin: 0;
          gap: 10px;
        }

        &.cdk-focused:not(:hover) {
          background-color: inherit;
        }

        &:hover:not([aria-disabled='true']) {
          background-color: transparent !important;
        }

        .mat-content {
          margin: 0;
          position: relative;
        }

        .component-btn.close-open-item-btn {
          width: 24px;
          height: 24px;
          margin: 0;
        }

        .close-open-arrow {
          border: 1px solid var(--close-open-btn-font);
          border-radius: 50%;
        }

        .arrow-wrapper {
          width: 24px;
          height: 24px;
          display: block;
          background-color: transparent;

          .close-open-arrow {
            width: 24px;
            height: 24px;
            display: block;
            transition: 0.2s;

            &::after {
              top: 10px;
              left: 6px;
              transform: rotate(45deg);
              background-color: var(--close-open-btn-font);
            }

            &::before {
              top: 10px;
              left: 10px;
              transform: rotate(-45deg);

              background-color: var(--close-open-btn-font);
            }
          }
        }

        &.mat-expanded {
          .close-open-arrow {
            &::after {
              transform: rotate(-45deg);
              transition: 0.3s;
            }

            &::before {
              transform: rotate(45deg);
              transition: 0.3s;
            }
          }
        }
      }
    }

    .mat-expansion-panel-content {
      display: none;
    }
  }
}

.no-links {
  min-height: 430px;
  display: grid;
  place-content: center;
  gap: 30px;

  .no-links-content {
    display: flex;
    flex-direction: column;
  }

  .no-links-image {
    width: 215px;
    height: 150px;
    background: var(--no-links-bg-image) no-repeat center / contain;
  }

  .no-links-description {
    @include apply-no-links-section-description-font();
    text-align: center;
    color: var(--main-font-color);
  }
}
