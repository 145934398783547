.mdc-snackbar {
  --mdc-snackbar-container-color: var(--modal-bg);
  --mdc-snackbar-supporting-text-color: var(--main-font-color);
  --mat-snack-bar-button-color: var(--primary-color);

  .mdc-snackbar__surface {
    padding: 0;
    max-width: var(--mat-snack-bar-max-width, 370px);
    background-color: var(--modal-bg);
    border: 1px solid var(--modal-border);
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    .mdc-snackbar__label {
      padding: 20px 16px;
    }
  }
}
