.pxo-datepicker-wrapper {
  position: absolute;
  left: -1px;
  bottom: -15px;
  width: 100%;
  max-height: 1px;
  overflow: hidden;

  .mat-form-field-flex {
    padding: 0;
    overflow: hidden;
  }

  .mat-form-field-flex {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-120%);
    overflow: hidden;
  }
}

.mat-datepicker-content-container {
  background: var(--modal-bg);
}

.mat-datepicker-content {
  box-shadow: 0 3px 6px 0 var(--modal-box-shadow);
  border: 1px solid var(--modal-border);
}

.mat-calendar {
  height: auto;

  .mat-calendar-header {
    padding-inline: 16px;
    color: var(--main-font-color);
  }

  .mat-calendar-spacer {
    display: none;
  }

  .mat-calendar-controls {
    margin: 5% 0;

    .mat-calendar-period-button {
      margin: auto;
      font-style: normal;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.14px;

      .mat-calendar-arrow {
        display: none;
      }
    }

    .mat-icon-button {
      width: 30px;
      height: 30px;
      display: inline-flex;
      border: 1px solid var(--divider-color);
      border-radius: 5px;
    }

    .mat-calendar-previous-button {
      order: -1;
    }

    .mat-calendar-previous-button::after, .mat-calendar-next-button::after {
      margin: 8px;
      border-color: var(--font-dark-color);
    }
  }

  .mat-calendar-content {
    padding-inline: 16px;

    .mat-calendar-table {
      .mat-calendar-table-header {
        tr th {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.14px;
          text-align: center;
          color: var(--sub-header);
        }

        .mat-calendar-table-header-divider {
          padding: 0;
        }

        .mat-calendar-table-header-divider::after {
          content: none;
        }
      }

      .mat-calendar-body {
        .mat-calendar-body-label {
          opacity: 0;
        }
      }

      .mat-calendar-body-label[colspan="7"] {
        display: none;
      }

      .mat-calendar-body-cell {
        .mat-calendar-body-cell-content {
          border-radius: 50%;
          color: var(--font-dark-color);

          &::before {
            content: none;
            display: none;
          }
        }

        &.mat-calendar-body-range-start {
          .mat-calendar-body-cell-content {
            width: 95%;
            right: 0;
            color: var(--white-color);
            border-radius: 45% 0 0 45%;
          }
        }

        &.mat-calendar-body-range-end {
          .mat-calendar-body-cell-content {
            color: var(--white-color);
            border-radius: 0 45% 45% 0;
          }
        }

        &.mat-calendar-body-active {
          color: var(--primary-color);

          .mat-calendar-body-selected {
            color: var(--white-color);

            &::before {
              content: none;
              display: none;
            }
          }
        }

        .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
          border-color: var(--primary-color);
        }

        &.mat-calendar-body-in-range:not(.mat-calendar-body-range-start):not(.mat-calendar-body-range-end) {
          .mat-calendar-body-cell-content {
            color: var(--primary-color);
          }
        }

        &.mat-calendar-body-in-preview {
          .mat-calendar-body-cell-preview {
            border-color: var(--primary-color);
          }
        }

        &.mat-calendar-body-disabled {
          .mat-calendar-body-cell-content {
            color: var(--font-dark-color-5);
          }
        }
      }
    }
  }
}

.mat-datepicker-actions {
  margin-top: 20px;
  padding-inline: 16px;
}

.cdk-overlay-pane.mat-datepicker-popup {
  right: 10px !important;
  width: fit-content;
}

.w-full {
  .datepicker-wrapper {
    min-width: min-content;
    width: 100%;
  }
}
