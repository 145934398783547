/*
  This file should keep all global colors. in future can replace part of style.css with colors
  You can also link this file to your component's styles using construction:

  @import '../../../../scss/colors';

  (count of '../' depends on the component path )
*/
:root {
  /*Colors without black theme version:*/
  --asset-correlation-sub-header-color: #afafc0;
  --mat-form-field-placeholder-color: #afafc0;
  --chip-container-field-clear-button-hover-bg-color: rgba(29, 137, 228, 0.3);
  --recent-comparison-font-color: #2196f3;
  --asset-correlation-table-color-cell-bg-color: #ffffff; // shouldn't depend on theme
  --asset-correlation-table-color-cell-font-color: #131722; // shouldn't depend on theme
  --green-font: #26a69a;
  --white-color: #ffff;
  --tooltip-font-color: #ffffff;
  --profit-font-color: #26a69a;
  --lose-font-color: #ef5350;
  --wheel-filter-input-invalid-value-color: #ef5350;
  --invalid-marker-color: #ef5350;
  --warning-marker-color: #fabb00;

  --green-dark-color: #1e857b;
  --red-dark-color: #bf4240;

  --login-main-colorful-font-color: #2196f3;
  --login-page-overwrite-black-theme-background-color: #ffffff;
  --login-page-background-color: #dfeefb;
  --login-page-shapes-bg-color: #2196f30d;
  --login-page-font-input-color: #131722;
  --economic-calendar-card-data-bg-color: rgba(33, 150, 243, 0.7);
  --economic-calendar-card-info-bg-color: rgba(33, 150, 243, 0.2);
  --mat-select-label-counter: #2196f3;
  --mat-select-triger-font-color: #ffffff;
  --trading-management-active-tabs-color: #2196f3;
  --input-focused-icon-color: #2196f3;
  --input--font-color: #ffffff;
  --input-placeholder-font-color: #afafc0;
  --custom-removed-btn-font-color: #2196f3;
  --custom-btn-border-color: rgba(33, 150, 243, 0.3);
  --custom-removed-btn-bg-color: transparent;
  --economic-calendar-selected-day-watch-icon-color: #bcbfc6ff;
  --print-modal-submit-button-disabled-color: #e0e3eb;
  --print-icon-bg-hover-color: rgba(29, 137, 228, 0.15);

  --settings-field-title-color: #787b86;
  --settings-change-password-container-color: rgba(29, 137, 228, 0.1);
  --settings-hide-password-icon-color: #2196f3;
  --settings-error-text-color: #ef5350;
  --setting-success-text-color: #26a69a;
  --alert-bg-color: rgba(239, 83, 80, 0.1);
  --alert-box-info-bg-color: rgba(33, 150, 243, 0.1);
  --alert-box-danger-bg-color: rgba(239, 83, 80, 0.1);
  --alert-box-success-bg-color: rgba(38, 166, 154, 0.1);
  --success-bg-color: rgba(38, 166, 154, 0.1);
  --primary-bg-color: rgba(33, 150, 243, 0.1);
  --btn-star-icon-color: #ff9850;
  --btn-star-icon-border-color: #e0e3eb;
  --custom-btn-hover-color: #1d89e433;
  --sub-headers-font-mono-color: #787b86;

  --monochrome-color-hover-color: #afafc0;

  --active-link-color: #2196f3;
  --primary-btn-bg: #2196f3;
  --run-scaner-separate-line: #e5e5e5bd;
  --top-menu-mat-button-ripple: rgb(44, 162, 247, 10%);
  --menu-background-shadow: rgba(0, 0, 0, 0.5);
  --menu-icon-hover-color: #2196f3;
  --icon-arrow-color: #2196f3;
  --btn-danger-icon-font-color: #ef5350;
  --btn-success-icon-font-color: #26a69a;
  --table-sticky-tr-transparent-box-shadow: 9px 0px 0px transparent, inset -5px 0px 0px transparent;
  --main-static-font-color: #131722;
  --secondary-static-font-color: #ffffff;
  --red-point: #ff2723;
  --orange-point: #ff8937;
  --yellow-point: #ffd600;
  --grey-point: #e0e3eb;
  --slide-toogle-bg: #e0e3eb;

  --blue-pressed: #1a78c2;
  --light-gray-bg-0: #e1f2ff;

  --warn-color: #ff8937;
  --primary-color: #2196f3;
  --danger-color: #ef5350;
  --success-color: #26a69a;
  --active-link-color: #2196f3;
  --primary-btn-bg: #2196f3;
  --primary-btn-bg-hover: #2089de;
  --secondary-btn-bg: transparent;
  --main-btn-disabled-bg: #d3eafd;
  --primary-btn-disabled-bg: rgba(33, 150, 243, 0.5);

  --secondary-btn-bg-hover: #e0e3eb;
  --default-btn-bg-hover: #f2f2f2;
  --classic-statick-font-color: #ffffff;
  --tab-font-color: #828282;
  --active-tab-font-color: #2196f3;
  --table-sticky-tr-transparent-box-shadow: 9px 0px 0px transparent, inset -5px 0px 0px transparent;
  --primary-static-color: #2196f3;
  --danger-static-color: #ef5350;
  --success-static-color: #0387b0;
  --light-danger-static-color: #f273a1;
  --light-success-static-color: #359fc0;
  --lable-primary-background-color: rgba(29, 137, 228, 0.1);
  --lable-light-danger-background-color: rgba(242, 115, 161, 0.1);
  --lable-light-success-background-color: rgba(53, 159, 192, 0.1);
  --lable-danger-bg: rgba(239, 83, 80, 0.2);
  --lable-success-bg: rgba(55, 168, 193, 0.1);
  --lable-purple-bg: rgba(206, 173, 253, 0.2);

  --spreadsheet-main-color: #a5bee0ff;
  --spreadsheet-selection-color: rgba(165, 190, 224, 0.2);
  --spreadsheet-active-color: #9eb7d9;
  --trading-log-dashboard-chart-footer-label-color: #787b86;
  --trading-log-no-data-label-color: #787b86;
  --trading-log-dashboard-charts-zero-line-color: #afafc0;
  --trading-log-dashboard-inline-marked-font-color: #2196f3;
  --trading-log-trades-group-header-open-status-color: #9dbf2c;
  --trading-log-trades-group-header-closed-status-color: #787b86;
  --trading-log-datepicker-icon-color: #afafc0;
  --trading-log-add-trade-disabled-btn-color: #787b86;
  --primary-btn-disabled-bg: #d3eafd;
  --chart-tooltip-font-color: #131722;
  --primary-spinner-color: #3f51b5;

  --danger-simple-bar-line: rgba(213, 87, 82, 0.2);
  --success-simple-bar-line: rgba(51, 166, 154, 0.2);

  --prymary-link-color: #2196f3;
  --prymary-link-hover-color: #2089de;
  --prymary-link-visited-color: #37a0f4;
  --prymary-link-bg-color: rgba(33, 150, 243, 0.05);
  --print-body-bg-color: #ffffff;

  --assigned-bar-color: #fabb00;

  --sell-call-bar-color: #f597b9;
  --break-bar-color: #0387b0;
  --buy-call-bar-color: #2cbf36;
  --checkbox-border-color: #757575;

  --trading-hub-input-placeholder-color: #b4c8e7;
  --static-header-font-color: #afafc0;

  --legend-marker-green-color: #26a69a;
  --legend-marker-red-color: #ef5350;
  --legend-marker-blue-color: #0e74dc;
  --legend-marker-orange-color: #ff9800;
  --legend-marker-purple-color: #9c27b0;
  --active-background-color: rgba(33, 150, 243, 0.2);
  --eye-icon-default-color: rgb(180, 200, 231);
  --eye-icon-cost-basis-color: #781dfa;
  --eye-icon-break-even-color: #ef5089;

  --status-open-color: #26a69a;
  --status-partially-filled-color: #7bc0f8;
  --status-canceled-color: #ff8937;
  --status-pending-color: #f273a1;
  --status-rejected-color: #ef5350;
  --status-error-color: #f27573;

  --wheel-trading-log-summary-panel-option-hover-color: rgba(33, 150, 243, 0.05);

  --impersonate-panel-logout-button-background-color: #ff8937;
  --impersonate-panel-logout-button-font-color: #ebf6fe;
  --impersonate-panel-person-icon-color: #ff8937;

  --magic-ball-image-inner-primary-bg-color: #2196f3;
  --magic-ball-image-inner-danger-bg-color: #f32121;
  --magic-ball-image-inner-success-bg--color: #25af33;
  --magic-ball-image-inner-info-bg-color: #254caf;

  --demo-page-action-button-text-color: #1c1c1c;
  --demo-page-action-button-bg-color: #e9bf79;
  --demo-page-action-button-hover-bg-color: #e8bd70;

  --feelings-atp-color: #26a69a;
  --feelings-natp-color: #ef5350;

  --wheel-scanner-filter-changes-indicator-color: #2196f3;
  --wheel-scanner-filter-border-color: #e0e3eb;

  --heatmap-chart-extreme-negative: #eb3e38;
  --heatmap-chart-very-strong-negative: #d73c3f;
  --heatmap-chart-strong-negative: #bf3e44;
  --heatmap-chart-slightly-negative: #8b444e;
  --heatmap-chart-tiny-negative: #4f4554;
  --heatmap-chart--neutral: #414554;
  --heatmap-chart-tiny-positive: #3d4a51;
  --heatmap-chart-slightly-positive: #35764e;
  --heatmap-chart-strong-positive: #2f9e4e;
  --heatmap-chart-very-strong-positive: #2fb854;
  --heatmap-chart-extreme-positive: #30cc5a;

  --heatmap-chart-tiny-negative-tooltip: #6c6162;
  --heatmap-chart--neutral-tooltip: #777b89;
  --heatmap-chart-tiny-positive-tooltip: #5d675b;

  --heatmap-compact-industry-bg: #262931;
  --heatmap-compact-stroke: #e9f4fd;
  --heatmap-chart-tooltip-color: #585757;

  --trading-log-put-call-performance-chart-legend-call-item-bg-color: var(--success-color);
  --trading-log-put-call-performance-legend-call-item-border-color: var(--success-color);
  --trading-log-put-call-performance-chart-legend-put-item-bg-color: var(--danger-color);
  --trading-log-put-call-performance-legend-put-item-border-color: var(--danger-color);
  --trading-log-put-call-performance-chart-legend-all-item-bg-color: var(--primary-color);
  --trading-log-put-call-performance-legend-all-item-border-color: var(--primary-color);

  --weekday-trades-chart-calls-bar-color: #26a69a;
  --weekday-trades-chart-puts-bar-color: #ef5350;
  --weekday-trades-chart-all-bar-color: #2196f3;

  --income-statement-no-data-label-color: #787b86;

  --confirmation-dialog-icon-color: #787b86;

  --my-settings-otp-code-border-color: #bdbfc1;
  --my-settings-otp-code-btn-color: #969696;
  --alerts-modal-text-color: #787b86;

  $tl-custom-icon-colors: (
    1 '#787B86' '#AFD9FB',
    2 '#1A21A3' '#AFAFFB',
    3 '#5807CC' '#CEADFD',
    4 '#620091' '#DDA3F9',
    5 '#0387B0' '#A4D4E2',
    6 '#AFA400' '#F2EDA3',
    7 '#23992B' '#B1DFCB',
    8 '#FF8937' '#FFD4B7',
    9 '#BF4240' '#F9C1C0',
    10 '#D84B1F' '#FFE5CD',
    11 '#014154' '#0387B0',
    12 '#EF5089' '#F9C0D4',
    13 '#1B23D3' '#7B7BF8',
    14 '#2CBF36' '#B3E8B7',
    15 '#785900' '#FBC933',
    16 '#EF5350' '#F9C1C0',
    17 '#722826' '#F27573',
    18 '#02526A' '#A4D4E2',
    19 '#B331F0' '#DDA3F9',
    20 '#787B86' '#D3D3E1',
    21 '#0AB783' '#9BFACC',
    22 '#2964FD' '#9AAAFF',
    23 '#8EBD09' '#E1FEB1',
    24 '#A448FF' '#ECD4FF',
    25 '#0069B5' '#C0E4F8',
    26 '#FF45C0' '#FFDBF7',
    27 '#850E79' '#FBCCFF',
    28 '#026A2C' '#79E9C1',
    29 '#EA105E' '#FFB6D4',
    30 '#252525' '#D0D0D0'
  );

  @each $index, $color-accent, $color-bg in $tl-custom-icon-colors {
    .apply-icon-color-#{$index} {
      --icon-accent-color: #{$color-accent};
      --icon-bg-color: #{$color-bg};
    }
  }

  --stocks-dropdown-static-label-color: #969696;

  --tradier-import-status-complete-bg-color: var(--primary-color);
  --tradier-import-selected-import-group-action-btn-icon-color: #969696;

  --stock-screener-header-color: #131722;

  --import-list-dropzone-bg-color: transparent;
  --import-list-dropzone-bg-hover-color: var(--primary-alpha);
  --import-list-dropzone-border-color: #bdbfc1;
  --import-list-dropzone-border-hover-color: var(--primary-color);

  --lock-icon-top-menu-color: #bdbfc1;

  --rocky-icon-not-hovered-color: #85c5f9;

  --alerts-icons-not-verified-phone: #fcdddc;

  /*Colors with black theme version:*/
  --content-color-1: #1c1c1c;
  --content-color-2: #585757;
  --content-color-3: #969696;
  --border-color: #e8e8e8;
  --background-primary-color: #ffffff;
  --background-secondary-color: #f6f8fd;
  --main-headers-font-color: #131722;
  --chart-positive-value-color: #b5e0db;
  --chart-negative-value-color: #f5c1bf;
  --mat-icon-menu-svg-fill-color: #1b1e29;
  --asset-correlation-trading-days-color: #f5f8fd;
  --mat-chips-close-button-color: #787b86;
  --chip-container-field-border-color: #e0e3eb;
  --chip-container-field-clear-button-bg-color: rgba(29, 137, 228, 0.1);
  --chip-bg-color: rgba(29, 137, 228, 0.1);
  --chips-reset-button-icon-fill-color: #2196f3;
  --asset-correlation-table-cell-green-bg-rgb-color: 108, 196, 188;
  --asset-correlation-table-cell-orange-bg-rgb-color: 255, 200, 135;
  --asset-correlation-table-border-color: #e0e3eb;
  --bright-contrast-border-color: #e0e3eb;
  --table-border-color: #e0e3eb;
  --table-secondary-border-color: #d3d3e1;
  --table-space-between-tr: #eef0f7;
  --grey-point: #e0e3eb;
  --primary-alpha: rgba(29, 137, 228, 0.1);
  --danger-alpha: rgba(239, 83, 80, 0.1);
  --primary-lg-color: #2196f3;
  --br-line: #eef0f6;
  --calendar-icon-color: #e0e3eb;
  --current-icon-color: #afafc0;
  --asset-correlation-legend-left-bg-color: linear-gradient(89.5deg, #6cc4bc 0.35%, #ffffff 87.8%);
  --asset-correlation-legend-right-bg-color: linear-gradient(90.18deg, #ffffff 10.33%, #ffc887 96.56%);
  --asset-correlation-legend-left-icon-color: #6cc4bc;
  --asset-correlation-legend-right-icon-color: #ffc887;

  --highlight-table-color: #2196f3;
  --icon-bg-hover-color: rgba(29, 137, 228, 0.2);
  --mat-form-field-outline-border-color: rgb(0 0 0 / 0.38);
  --mat-form-field-outline-hover-border-color: rgb(0 0 0 / 0.65);
  --trading-management-tabs-color: #131722;
  --trading-management-tabs-border-color: #e0e3eb;
  --tabs-border-color: #e0e3eb;
  --trading-log-table-content-font-color: #131722;
  --trading-log-table-header-font-color: #787b86;
  --table-header-font-color: #787b86;
  --trading-log-header-input-bg: #f5f8fd;
  --trading-log-table-bg: #ffffff;
  --table-disabled-cell-bg: #f5f8fd;
  --table-th-bg: #f5f8fd;
  --table-disabled-cell-content: #d3d3e1;
  --table-cell-placeholder: #afafc0;
  --select-border-color: #e0e3eb;
  --form-separator-border-color: #e0e3eb;
  --selected-bg-color: #ebf6fe98;
  --select-hover-color: #ebf6fe;
  --mat-select-label-font-color: #131722;
  --custom-action-btn-bg: rgba(29, 137, 228, 0.1);
  --custom-action-btn-font-color: #2196f3;
  --custom-action-btn-icon-border-color: rgba(33, 150, 243, 0.3);
  --custom-removed-btn-icon-border-color: rgba(33, 150, 243, 0.2);
  --custom-btn-border-color: rgba(33, 150, 243, 0.3);
  --close-open-btn: #e0e3eb;
  --table-sub-header-bg: #f0f0f0;

  --close-open-btn-font: #787b86;
  --monochrome-icon-color: #d3d3e1;
  --separator-line-color: #d3d3e1;
  --default-separator-line-color: #e0e3eb;
  --settings-section-title-color: #131722;
  --settings-change-password-input-border-color: #e0e3eb;
  --settings-change-password-input-background-color: #ffffff;
  --settings-show-password-icon-color: #d3d3e1;
  --settings-warning-icon-color: #b4c8e7;
  --btn-bg-color: #ebf6fe;

  --chart-border: #d1daf2;
  --select-lable-font-color: #787b86;
  --btn-danger-icon-color: rgba(239, 83, 80, 0.1);
  --btn-danger-icon-hover-color: rgba(239, 83, 80, 0.4);

  --btn-success-icon-color: rgba(38, 166, 154, 0.1);
  --btn-success-icon-hover-color: rgba(38, 166, 154, 0.4);
  --btn-news-icon-color: #2196f3;
  --mat-icon-dashes-menu-svg-fill-color: #2196f3;
  --option-hover-color: rgba(0, 0, 0, 0.04);
  --main-font-color: #131722;
  --top-menu-bg: #f5f8fd;
  --top-menu-decorator: #e0e3eb;
  --top-menu-new-window-icon-bg: #ffffff;
  --top-menu-new-window-icon-border: #9cc8f1;

  --modal-box-shadow: rgba(0, 0, 0, 0.12);
  --modal-border: #e0e3eb;
  --modal-links-bg: #f0f3fa;
  --modal-close-icon-color: #666666;
  --primary-btn-hover: #2089de;
  --header-menu-item-icon-color: #1b1e29;
  --header-menu-icon-color: #a5a8b6;
  --edit-icon-color: #a5a8b6b5;
  --table-row-hover-color: rgba(33, 150, 243, 0.05);
  --table-row-hover-fixed-col-color: #f4fafe;
  --table-row-hover-disabled-td-color: #eaf3fd;
  --icons-example-bg: #fbfbfb;
  --secondary-btn-border: #e0e3eb;
  --main-border-color: #e0e3eb;
  --chart-line-color: #ff8937; // remove
  --options-chart-premium-line-color: #ff8937;
  --options-chart-trades-color: #781dfa;

  --dividends-bar-color: #fabb00;
  --tos-chart-total-bar-color: #2196f3;
  --tos-chart-options-bar-color: #be00ed;
  --tos-chart-stocks-bar-color: #26a69a;
  --tos-chart-avg-line-color: #fabb00;
  --dashboard-custom-btn-border: rgba(33, 150, 243, 0.3);
  --header-font-colors: #787b86;
  --open-positions-block-background-color: #f4faff;
  --trading-log-dashboard-inline-marked-background-color: rgba(29, 137, 228, 0.1);
  --trading-log-dashboard-data-picker-background-color: rgba(33, 150, 243, 0.05);
  --trades-summary-panel-background-color: #f4faff;
  --trading-log-trades-tab-count-background-color: #eef0f6;
  --trading-log-trades-active-tab-count-background-color: rgba(29, 137, 228, 0.1);

  --tr-hover-color: #e8f3fc;
  --block-highlite: #f7f9fc;
  --sub-header: #787b86;
  --card-bg-color: #f5f8fd;
  --table-td-bg: #ffffff;
  --table-sticky-tr-box-shadow: 10px 0px 11px -2px rgba(0, 0, 0, 0.05), inset 0px 0px 0px #ffffff;
  --table-sticky-th-box-shadow: 0 10px 11px -2px rgba(0, 0, 0, 0.05), inset 0px 0px 0px #ffffff;
  --table-sticky-tf-box-shadow: 0px -10px 11px -2px rgba(0, 0, 0, 0.05), inset 0px 0px 0px #ffffff;
  --table-border-color: #eef0f7;
  --input-border-color: #e0e3eb;
  --bar-bg-color: #eef0f7;
  --description-color: #787b86;

  --blur-overlay-bg-color: rgba(255, 255, 255, 0.8);
  --prymary-link-disabled-color: #e0e3eb;
  --custom-btn-disabled-color: #e0e3eb;
  --disclaimer-title-color: #afafc0;

  --nav-tab-font-color: #131722;
  --risk-button-bg-color: rgb(216, 234, 250);

  --bull-call-spread-risk-meet-bg: rgb(239, 252, 212);
  --bull-call-spread-risk-too-high-bg: rgb(255, 226, 205);
  --bull-call-spread-risk-too-low-bg: rgb(244, 237, 254);
  --bull-call-spread-td-border-color: #fdfdfd;
  --bull-call-spread-success-bar-line: #bee4e1;
  --bull-call-spread-success-hint-color: #26a69a;
  --bull-call-spread-risk-button-bg-color: #d6ebfb;
  --bull-call-spread-risk-button-bg-color-hover: #afd4f6;

  --wheel-table-border-color: #e0e3eb;
  --wheel-table-trading-log-summury-panel-border-color: #eef0f7;
  --wheel-search-box-symbol-search-font-color: #131722;
  --wheel-search-box-symbol-search-mobile-font-color: #131722;
  --wheel-search-box-company-name-font-color: #131722;
  --wheel-trading-log-summary-panel-option-border-color: #e0e3eb;
  --trading-log-group-summary-info-header-font-color: #131722;
  --trading-log-group-summary-info-content-font-color: #787b86;
  --refresh-icon-hover-color: #293143;
  --time-and-date-font-color: #131722;
  --expected-move-bg: #f5f8fd;
  --expected-move-header-color: #787b86;
  --expected-move-short-header-color: #787b86;
  --expected-move-hidden-icon-color: rgb(180, 200, 231);
  --expected-move-1-visible-icon-color: rgba(33, 150, 243, 1);
  --expected-move-2-visible-icon-color: rgba(28, 113, 181, 1);

  --economic-calendar-close-icon-color: #666666;
  --earning-day-bg-color: #e3eeff;

  --assigned-bar-backlayer-color: #ffd4b7;
  --trading-hub-zero-progress-color: #ef5350;
  --drag-item-shadow: 0px 30px 100px rgba(1, 41, 84, 0.15);
  --drag-item-background: #ffffff;
  --trading-hub-progress-value-background-color: #ffffff;
  --trading-hub-progress-zero-back-layout-color: #f9c1c0;
  --trading-hub-progress-was-not-opened-layout-color: #f27573;
  --trading-hub-progress-back-layout-color: #ffd4b7;
  --trading-hub-progress-background-completed-color: #2196f3;
  --trading-hub-progress-bar-color: #ff8937;
  --trading-hub-progress-content-icon-color: #ffffff;
  --trading-hub-close-color: #000000;
  --trading-hub-delete-plan-button-icon-color: #afafc0;
  --trading-plan-section-title-color: #131722;
  --trading-plan-section-content-color: #2f323c;
  --trading-plan-section-content-placeholder-color: #afafc0;

  --mat-menu-background-color: #ffffff;
  --mat-menu-item-hover-color: #ebf6fe98;
  --mat-menu-shadow-color: 0px 30px 100px rgba(1, 41, 84, 0.15);
  --mat-menu-item-icon-color: #a5a8b6;

  --primary-font-color: #2196f3;
  --introduction-background-color: #e6eaf2;
  --decorator-line-color: #eef0f7;
  --tab-counter-background-color: #eef0f7;
  --tab-active-counter-background-color: rgba(29, 137, 228, 0.1);
  --default-btn-background-color: #2196f3;
  --purple-lable-font-color: #781dfa;
  --status-filled-color: #787b86;
  --status-expired-color: #fabb00;
  --symbol-font-color: #131722;

  --wheel-chart-legend-bg-color: #ffffff;
  --wheel-chart-legend-border-color: #e0e3eb;
  --wheel-chart-legend-text-color: #131722;
  --wheel-chart-legend-box-shadow-color: rgba(1, 41, 84, 0.15);

  --wheel-scanner-filter-input-item-hover-color: rgba(29, 137, 228, 0.1);

  --trading-panel-connection-item-color: rgba(29, 137, 228, 0.1);
  --trading-panel-account-label-color: #000000;
  --trading-panel-content-color: #131722;
  --trading-panel-connection-item-border-color: #e8e8e8;

  --data-window-pre-market-color: #ff8937;
  --data-window-holiday-color: #969696;
  --data-window-opened-market-color: #26a69a;
  --data-window-closed-market-color: #ef5350;
  --data-window-heatmap-not-found-background-color: linear-gradient(
      0deg,
      rgba(239, 83, 80, 0.1),
      rgba(239, 83, 80, 0.1)
    ),
    #ffffff;

  --impersonate-panel-background-color: #ffd4b7;
  --impersonate-panel-main-label-font-color: #9a5321;
  --impersonate-panel-bookmark-font-color: #363a3a;
  --impersonate-panel-bookmark-shadow: drop-shadow(0 0 2px #787b86);

  --divider-color: #e8e8e8;

  --my-settings-play-sound-controls-divider-color: #e8e8e8;
  --my-settings-play-sound-bar-info-container-icon-color: #969696;
  --my-settings-play-sound-bar-info-container-text-color: #585757;
  // active
  --my-settings-play-sound-bar-bg-color: #e9f4fd;
  --my-settings-play-sound-button-bg-color: #2196f3;
  --my-settings-play-sound-button-inner-triangle-color: #ffffff;
  --my-settings-play-sound-button-border-color: #2196f3;
  --my-settings-play-sound-button-shadow: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08));
  // disabled
  --my-settings-play-sound-bar-inactive-bg-color: #f6f8fd;
  --my-settings-play-sound-button-inactive-bg-color: #ffffff;
  --my-settings-play-sound-button-inactive-inner-triangle-color: #969696;
  --my-settings-play-sound-button-inactive-border-color: #e8e8e8;
  --my-settings-play-sound-button-inactive-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  --my-settings-play-sound-bar-timer-text-color: #969696;

  --smiley-menu-border-color: #e8e8e8;
  --smiley-menu-shadow: drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.1)) drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.04));
  --smiley-menu-item-hover-bg-color: #e4eaf9;
  --smiley-menu-item-title-color: #1c1c1c;
  --smiley-menu-item-progressbar-bg-color: #e8e8e8;
  --smiley-menu-item-hover-progressbar-bg-color: #b9b9b9;
  --smiley-menu-item-progressbar-percentage-bg-color: #656565;
  --smiley-menu-item-progressbar-active-bg-color: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #2196f3;
  --smiley-menu-item-progressbar-active-bg-hover-color: #99d2ff;
  --smiley-menu-item-progressbar-active-percentage-bg-color: #2196f3;
  --smiley-menu-close-btn-color: #000000;
  --symbol-flag-success-bg-color: #66bb6a;
  --symbol-flag-danger-bg-color: #ef5350;
  --symbol-flag-warning-bg-color: #f98600;
  --symbol-flag-never-bg-color: #131722;
  --symbol-flag-never-stroke-color: #ffffff;
  --symbol-flag-none-outer-stroke-color: #e8e8e8;
  --symbol-flag-none-stroke-color: #969696;
  --symbol-flag-none-bg-color: #e8e8e8;
  --never-smile-icon-color: #131722;
  --none-smile-icon-color: #9a9da6;
  --disabled-smile-bg-color: #e7e7e7;

  --smiley-votes-counter-badge-bg-color: #e5e5e6;
  --smiley-votes-counter-selected-bg-color: #585757;
  --smiley-votes-counter-selected-no-icon-bg-color: #fdeeed;
  --smiley-votes-counter-selected-maybe-icon-bg-color: #fff3e6;
  --smiley-votes-counter-selected-yes-icon-bg-color: #e9f6f5;
  --smiley-votes-counter-selected-never-icon-bg-color: #e8e8e8;

  // default symbol flags (in smiley-icons directory) colors - vars should have the same names as in SVG files
  --symbol-flag-outer-stroke-color: transparent;
  --symbol-flag-stroke-color: transparent;
  --symbol-flag-bg-color: transparent;

  --profiles-block-bg-color: #e9f4fd;
  --profiles-block-icon-border-color: #cce7ff;
  --profile-menu-block-divider-color: #e8e8e8;
  --profile-menu-block-title-text-color: #969696;
  --profile-menu-block-item-icon-color: #969696;
  --profile-menu-block-item-text-color: #131722;
  --profile-menu-block-item-bg-color: #f0f3fa;

  --feelings-popup-options-color: #585757;
  --feelings-popup-options-description-color: #969696;
  --feelings-popup-border: #e8e8e8;
  --feelings-popup-box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06),
    0px 24px 38px rgba(0, 0, 0, 0.04);

  --magic-ball-icon-stroke-in-top-menu-color: #413e43;

  --support-access-modal-supported-user-color: rgba(0, 0, 0, 0.4);

  --demo-page-heading-title-color: #1c1c1c;
  --demo-page-text-content-color: #585757;
  --demo-page-vertical-dividers-color: #e8e8e8;
  --demo-page-lock-icon-color: invert(60%) sepia(0%) saturate(1799%) hue-rotate(202deg) brightness(99%) contrast(94%);

  --heatmap-chart-bg-color: #e9f4fd;
  --heatmap-chart-sector-font-color: #1c1c1c;
  --heatmap-chart-child-font-color: #f0f0f0;
  --heatmap-breadcrumbs-hover-bg-color: #e9f4fd;
  --heatmap-date-label-font-color: #969696;
  --heatmap-date-font-color: #1c1c1c;

  --feelings-modal-option-disabled-color: rgba(0, 0, 0, 0.38);

  --chat-bot-example-message-bg-color: #e8f3fc;
  --chat-bot-example-message-bg-hover-color: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    #2196f3;
  --chat-bot-limitations-icon-color: #969696;
  --chat-bot-limitations-title-color: #585757;
  --chat-bot-limitations-list-item-color: #969696;
  --chat-bot-icon-color: #969696;
  --chat-bot-message-bg-color: #f6f8fd;
  --chat-bot-user-message-bg-color: #e8f3fc;
  --chat-bot-message-box-date-font-color: #969696;
  --chat-bot-message-font-color: #585757;
  --chat-bot-user-message-font-color: #1c1c1c;
  --chat-bot-menu-btn-bg-color: transparent;
  --chat-bot-menu-btn-bg-hover-color: #f6f8fd;
  --chat-bot-menu-btn-icon-color: #969696;
  --chat-bot-send-icon-default-color: #969696;
  --chat-bot-pagination-btn-arrow-color: #e8e8e8;
  --chat-bot-pagination-btn-arrow-hover-color: #969696;
  --chat-bot-pagination-state-bg-color: #f6f8fd;
  --chat-bot-pagination-state-border-color: #e8e8e8;
  --chat-bot-error-message-bg-color: linear-gradient(0deg, rgba(239, 83, 80, 0.1), rgba(239, 83, 80, 0.1)), #ffffff;
  --chat-bot-feedback-neutral-color: rgba(150, 150, 150, 1);
  --chat-bot-feedback-hover-neutral-bg-color: #f6f8fd;
  --chat-bot-feedback-positive-color: rgba(33, 150, 243, 1);
  --chat-bot-feedback-positive-bg-color: rgba(33, 150, 243, 0.1);
  --chat-bot-feedback-negative-color: rgba(239, 83, 80, 1);
  --chat-bot-feedback-negative-bg-color: rgba(239, 83, 80, 0.1);

  --oco-order-modal-title-color: #1b1f28;
  --oco-order-modal-subtitle-color: #787b86;
  --oco-order-modal-price-ticker-btn-text-color: #afafc0;
  --oco-order-modal-price-ticker-btn-bg-color: #f5f8fd;
  --oco-order-modal-option-details-locked-input-text-color: #afafc0;
  --oco-order-modal-option-details-locked-input-border-color: #e8e8e8;
  --oco-order-modal-footer-btn-buy-bg-color: var(--success-color);
  --oco-order-modal-footer-btn-buy-bg-hover-color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    var(--success-color);
  --oco-order-modal-footer-btn-sell-bg-color: var(--danger-color);
  --oco-order-modal-footer-btn-sell-bg-hover-color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    var(--danger-color);
  --oco-order-modal-footer-btn-bg-disabled-color: #f0f0f0;
  --oco-order-modal-footer-btn-text-disabled-color: #c5cbce;

  --progressbar-success-color: #26a69a;
  --progressbar-danger-color: #ef5350;
  --progressbar-warning-color: #f98600;
  --progressbar-divider-color: #ffffff;
  --two-way-progressbar-divider-color: #969696;

  --tl-datawindow-shadow-when-content-hides-on-bottom: linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, transparent 100%);

  --portfolio-additional-panel-header-bg-color: #f6f8fd;

  --trading-log-days-in-trade-block-help-icon-color: #969696;
  --trading-log-days-in-trade-block-progress-line-bg-color: #e8e8e8;
  --trading-log-days-in-trade-block-progress-line-color: #585757;
  --trading-log-trades-by-day-btn-group-bg-color: #f6f8fd;
  --trading-log-quoutes-icon-color: #969696;
  --trading-log-quoutes-icon-active-color: #2196f3;
  --trading-log-trades-by-day-btn-group-bg-color: #f6f8fd;

  --view-switcher-bg-color: #f6f8fd;
  --view-switcher-btn-checked-color: #ffffff;
  --view-switcher-btn-checked-shadow: -2 1px 3px rgba(0, 0, 0, 0.1), -2 2px 1px rgba(0, 0, 0, 0.06),
    -2 1px 1px rgba(0, 0, 0, 0.08);
  --view-switcher-default-icon-color: #585757;
  --view-switcher-active-icon-color: #1c1c1c;

  --divider-color: #e8e8e8;

  --tl-sorting-filter-icon-color: #969696;
  --tl-sorting-filter-menu-item-color: rgba(0, 0, 0, 0.87);

  --trading-log-put-call-performance-table-legend-call-item-bg-color: linear-gradient(
      0deg,
      rgba(38, 166, 154, 0.1),
      rgba(38, 166, 154, 0.1)
    ),
    #ffffff;
  --trading-log-put-call-performance-table-legend-put-item-bg-color: linear-gradient(
      0deg,
      rgba(239, 83, 80, 0.1),
      rgba(239, 83, 80, 0.1)
    ),
    #ffffff;
  --trading-log-put-call-performance-table-legend-all-item-bg-color: #e8f3fc;

  --no-links-section-bg-color: #ffffff;

  --earnings-calendar-current-day-bg-color: #e8f3fc;
  --earnings-calendar-progressbar-bg-color: #eef0f7;
  --earnings-calendar-progressbar-percentage-bg-color: #c6dced;
  --earnings-calendar-day-earning-block-bg-color: #f5f8fd;
  --earnings-calendar-day-earning-block-company-placeholder-logo-border-color: #dcefff;

  --tl-dashboard-datepicker-bg-color: #e9f4fd;

  --stocks-dropdown-mat-toggle-bg-color: transparent;
  --stocks-dropdown-mat-toggle-border-color: var(--divider-color);
  --stocks-dropdown-label-color: #787b86;
  --stocks-dropdown-header-color: #1c1c1c;
  --stocks-dropdown-symbol-name-color: #585757;
  --stocks-dropdown-input-bg-color: transparent;
  --stocks-dropdown-input-border-color: var(--divider-color);
  --stocks-dropdown-symbol-container-bg-color: #f6f8fd;
  --stocks-dropdown-symbol-container-hover-bg-color: #e3f0ff;

  --modal-header-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  --modal-footer-box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08);

  --quote-of-day-heading-bg-color: #e9f4fd;
  --quote-of-day-heading-border-color: rgba(#2f323c, 0.8);
  --quote-of-day-block-carousel-active-dot-indicator-color: #2196f3;
  --quote-of-day-block-carousel-inactive-dot-indicator-color: #969696;
  --quote-of-day-block-carousel-button-bg-color: #f6f8fd;
  --quote-of-day-block-carousel-button-bg-disabled-color: #ffffff;
  --quote-of-day-block-carousel-button-border-color: #e8e8e8;
  --quote-of-day-block-carousel-button-border-disabled-color: #e8e8e8;

  --custom-stroked-btn-border-color: #bdbfc1;

  --tradier-import-settings-block-bg-color: #f5f8fd;
  --tradier-import-settings-group-bg-color: #ffffff;
  --tradier-import-selected-import-group-bg-color: #ffffff;
  --tradier-import-selected-import-group-border-color: var(--divider-color);
  --tradier-import-selected-import-group-action-btn-hover-color: rgba(0, 0, 0, 0.1);
  --tradier-import-selected-import-group-symbol-bg-color: #f6f8fd;
  --tradier-import-trades-group-modal-table-in-header-bg-color: #f6f8fd;

  --wtf-trades-strategy-container-original-performance-bg: #e9f4fd;
  --wtf-trades-strategy-container-original-performance-text-color: #1b3345;
  --wtf-trades-strategy-container-original-performance-divider-color: #d3eafd;
  --wtf-trade-report-filtered-trades-bg-color: rgba(229, 229, 230, 0.4);
  --wtf-trade-report-filtered-trades-divider-color: #e7f0ff;
  --wtf-two-step-block-divider-color: #ffffff;

  --wtf-chart-legend-filtered-trades-bg-color: rgba(229, 229, 230, 0.6);
  --wtf-chart-legend-filtered-trades-border-color: #cccccc;

  --yt-link-container-bg-color: #e8e8e8;
  --yt-link-container-border-color: #f6f8fd;
  --dividends-analysis-dividends-bg-color: #e5e5e6;
  --dividends-analysis-dividends-border-color: #969696;
  --dividends-analysis-days-progressbar-bg-color: #e8e8e8;
  --dividends-analysis-days-progressbar-line-color: #969696;
  --dividends-analysis-break-even-warning-bg-color: #fff5e9;

  --bsc-input-label-color: #b7b7b7;

  --import-list-example-table-heading-bg-color: #f6f8fd;
  --import-list-example-optinal-badge-text-color: #585757;
  --import-list-example-optinal-badge-bg-color: #f6f8fd;

  --input-multi-select-dropdown-border-color: #e0e3eb;
  --input-multi-select-dropdown-bg-color: #ffffff;
  --input-multi-select-option-hover-bg-color: #e8f3fc;

  --chat-helper-mode-bg-color: var(--quote-of-day-heading-bg-color);
  --chat-helper-mode-border-color: rgba(33, 150, 243, 0.1);

  --mm-trades-admin-panel-buttons-bg-color: #ffffff;
  --mm-trades-admin-panel-icon-buttons-color: #344054;
  --mm-trades-cell-inactive-color: #a0a0a0;

  --combined-calendars-page-tab-border-color: var(--primary-color);
  --combined-calendars-page-tab-bg-color: #e8f3fc;
  --day-block-bg-color: #ffffff;
  --time-block-bg-color: #f6f8fd;

  --master-calendar-accordion-item-bg-color: #f6f8fd;
  --master-calendar-accordion-item-border-color: #e7ecf8;

  --sol-alert-snackbar-btn-border-color: #e0e3eb;

  --mm-trades-admin-panel-bg-color: #f6f8fd;
  --mm-trades-sequence-column-bg-color: #f6f8fd;
  --mm-trades-top-row-divider-color: #c9c9c9;
  --mm-trades-icon-outline-color: #dddddd;

  --metric-details-expanded-row-bg-color: #f6f8fd;
  --metric-details-notes-icon-color: #afafc0;
  --metric-details-arrow-icon-border-color: #e8e8e8;
  --metric-details-arrow-icon-path-color: #969696;

  --video-hub-player-black-bars-color: #000;
  --video-hub-player-bg-play-again-color: #ffffff1a;
  --video-hub-player-bg-hover-play-again-color: #ffffff33;
  --video-hub-player-bg-placeholder: #000;
  --video-hub-progressbar-bg: #b0b0b0;
  --video-hub-progressbar-color: #ff8937;
  --video-hub-watched-bg: #f6f8fd;
  --video-hub-watched-color: #969696;

  --maintenance-refresh-img-fill-circle: #ebf6fe;
  --maintenance-refresh-img-fill-arrow: #ebf6fe;
  --maintenance-refresh-img-fill-table: #ebeff9;
  --maintenance-refresh-img-fill-block: #ebeff9;
  --maintenance-refresh-img-fill-upper: #ebeff9;
  --maintenance-refresh-img-fill-main: white;
  --maintenance-refresh-img-fill-logo-text: white;
  --maintenance-refresh-img-fill-logo-bg: #898b95;
  --maintenance-refresh-img-fill-logo-second: #2196f3;
  --maintenance-refresh-img-stroke-block: #494c57;
  --maintenance-refresh-img-stroke-arrow: #787b86;
  --maintenance-refresh-img-stroke-table: #eef0f7;

  --homepage-title-font-color: #171a1f;
  --homepage-description-font-color: #363b3e;
  --homepage-btn-color: #131722;
  --homepage-small-card-color: #1c1c1c;
  --homepage-icon-arrow-color: #787b86;
  --homepage-trading-hub-bottom: #00000014;

  --skeleton-loader-bg: #00000014;
  --skeleton-animation-loader-bg: linear-gradient(
    102.7deg,
    rgba(255, 255, 255, 0) 37.3%,
    rgba(255, 255, 255, 0.8) 52.24%,
    rgba(255, 255, 255, 0) 63.5%
  );
  --skeleton-divider-color: #e0e3eb;
  --skeleton-light-gray: #f7f9fc;
  --gradient-start: var(--white-bg);
  --gradient-end: rgba(255, 255, 255, 0);
  --master-calendar-previous-week-selector-bg: #feeeee;
  --master-calendar-previous-week-selector-font-color: #ef5350;
  --master-calendar-current-week-selector-bg: #eaf7f5;
  --master-calendar-current-week-selector-font-color: #26a69a;
  --master-calendar-next-week-selector-bg: #fff5e9;
  --master-calendar-next-week-selector-font-color: #f98600;

  --my-settings-change-phone-title-color: #1b1f28;
  --my-settings-set-phone-number-btn-color: #131722;
  --my-settings-change-phone-number: #1b1f28;
  --my-settings-otp-code-bg-btn-color: #e8e8e8;
  --disable-pannel-background-color: rgba(0, 0, 3, 0.1);
  --my-settings-change-phone-container-bg-color: #fff5e9;
  --my-settings-verify-existing-number-btn-background-color: #ffffff;
  --my-settings-change-phone-container-border-color: #fee7cc;
  --my-settings-verify-phone-border-color: #e0e3eb;
  --my-settings-verified-phone-container-bg-color: #eaf7f5;
  --my-settings-not-verified-phone-number-btn-hover-color: #f2f2f2;

  // disabled checkbox colors

  --disabled-checkbox-bg-color: #eef0f6;
  --disabled-checkbox-border-color: #e0e3eb;

  --trade-zero-information-text-color: #131722;
  --trade-zero-information-bg-color: #f0f0f0;
  --trade-zero-placeholder-text-color: #787b86;

  --submenu-bg-color: #f9fafc;
  --submenu-linear-color-right: linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%);
  --submenu-linear-color-left: linear-gradient(to left, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%);

  body.black-theme {
    --content-color-1: #f0f0f0;
    --content-color-2: #afafc0;
    --content-color-3: #787b86;
    --border-color: #2f323c;
    --background-primary-color: #131722;
    --background-secondary-color: #1b1f28;
    --main-headers-font-color: #ffffff;
    --chart-positive-value-color: #b5e0db;
    --chart-negative-value-color: #f5c1bf;
    --mat-icon-menu-svg-fill-color: #c5cbce;
    --asset-correlation-trading-days-color: #2f323c;
    --mat-chips-close-button-color: #a5a8b6;
    --chip-container-field-border-color: #2f323c;
    --chip-container-field-clear-button-bg-color: rgba(33, 150, 243, 0.3);
    --chip-bg-color: #2f323c;
    --chips-reset-button-icon-fill-color: #ffffff;
    --asset-correlation-table-cell-green-bg-rgb-color: 38, 166, 154;
    --asset-correlation-table-cell-orange-bg-rgb-color: 255, 137, 55;
    --asset-correlation-table-border-color: #2f323c;
    --bright-contrast-border-color: #787b86;
    --table-border-color: #2f323c;
    --table-secondary-border-color: #5a5d6480;
    --table-space-between-tr: #1b1f28;
    --asset-correlation-legend-left-bg-color: linear-gradient(89.68deg, #26a69a 0.22%, #17343a 99.7%);
    --asset-correlation-legend-right-bg-color: linear-gradient(90.34deg, #2b2224 1.99%, #ff8937 96.47%);
    --grey-point: #afafc0;
    --slide-toogle-bg: rgba(255, 255, 255, 0.3);
    // --primary-alpha: #2F323C;
    --primary-lg-color: #ffffff;
    --br-line: #2f323c;
    --calendar-icon-color: rgba(255, 255, 255, 0.4);
    --current-icon-color: #ffffff;
    --highlight-table-color: #ffffff;
    --icon-bg-hover-color: rgba(33, 150, 243, 0.4);
    --mat-form-field-outline-border-color: rgb(255 255 255 / 0.38);
    --mat-form-field-outline-hover-border-color: rgb(255 255 255 / 0.65);
    --trading-management-tabs-color: #ffffff;
    --trading-log-table-content-font-color: #ffffff;
    --trading-log-table-header-font-color: #787b86;
    --table-header-font-color: #787b86;
    --trading-management-tabs-border-color: #2f323c;
    --tabs-border-color: #2f323c;
    --trading-log-table-bg: #131722;
    --table-disabled-cell-bg: #1b1f28;
    --table-th-bg: #1b1f28;
    --table-disabled-cell-content: rgba(255, 255, 255, 0.2);
    --table-cell-placeholder: rgba(255, 255, 255, 0.2);
    --custom-action-btn-bg: rgba(33, 150, 243, 0.3);
    --custom-action-btn-font-color: #ffffff;
    --custom-action-btn-icon-border-color: rgba(255, 255, 255, 0.3);
    --custom-removed-btn-icon-border-color: rgba(33, 150, 243, 0.2);
    --select-border-color: #2f323c;
    --form-separator-border-color: #656872;
    --selected-bg-color: rgba(120, 123, 134, 0.3);
    --select-hover-color: #2f3241;
    --mat-select-label-font-color: #ffffff;
    --trading-log-header-input-bg: rgba(255, 255, 255, 0.05);
    --monochrome-icon-color: #787b86;
    --separator-line-color: #787b86;
    --btn-bg-color: #1d89e466;
    --select-lable-font-color: #afafc0;

    --chart-border: #2f323c;
    --chart-positive-value-color: #b5e0db;
    --settings-section-title-color: #ffffff;
    --settings-change-password-input-border-color: #2f323c;
    --settings-change-password-input-background-color: #131722;
    --settings-show-password-icon-color: #787b86;
    --settings-warning-icon-color: #787b86;
    --btn-bg-color: #1d89e466;
    --btn-news-icon-color: #ffffff;
    --btn-danger-icon-color: rgba(239, 83, 80, 0.2);

    --btn-success-icon-color: rgba(38, 166, 154, 0.2);
    --btn-danger-icon-hover-color: rgba(239, 83, 80, 0.4);
    --close-open-btn: #2f323c;
    --btn-success-icon-hover-color: rgba(38, 166, 154, 0.4);
    --mat-icon-dashes-menu-svg-fill-color: #ffffff;
    --dashboard-custom-btn-border: #5e7790;
    --header-font-colors: #afafc0;
    --option-hover-color: rgba(120, 123, 134, 0.05);
    --table-row-hover-color: #142236;
    --table-row-hover-disabled-td-color: #1c2a3b;
    --table-row-hover-fixed-col-color: #142236;
    --default-separator-line-color: #787b86;
    --main-font-color: #ffffff;
    --top-menu-bg: #131722;
    --top-menu-decorator: #787b86;
    --top-menu-new-window-icon-bg: #2f323c;
    --top-menu-new-window-icon-border: #2196f399;
    --modal-box-shadow: rgba(0, 0, 0, 0.4);
    --modal-border: #434651;
    --modal-links-bg: #2f3241;
    --modal-close-btn-color: #787b86;
    --modal-close-icon-color: #a5a7ab;
    --primary-btn-hover: #36a0f4;
    --header-menu-item-icon-color: #c5cbce;
    --header-menu-icon-color: #ffffff;
    --edit-icon-color: #ffffff8c;
    --secondary-btn-border: #2f323c;
    --secondary-btn-bg-hover: rgba(255, 255, 255, 0.1);
    --main-border-color: #2f323c;
    --tos-chart-total-bar-color: #2196f3;
    --tos-chart-options-bar-color: #be00ed;
    --tos-chart-stocks-bar-color: #26a69a;
    --dividends-bar-color: #fabb00;
    --options-chart-premium-line-color: #ff8937;
    --options-chart-trades-color: #781dfa;
    --open-positions-block-background-color: rgba(27, 31, 40, 1);
    --trading-log-dashboard-inline-marked-background-color: rgba(33, 150, 243, 0.2);
    --trading-log-dashboard-filter-element-background-color: rgba(33, 150, 243, 0.3);
    --trades-summary-panel-background-color: rgba(27, 31, 40, 1);
    --trading-log-trades-tab-count-background-color: rgba(120, 123, 134, 0.3);
    --trading-log-trades-active-tab-count-background-color: rgba(33, 150, 243, 0.2);
    --default-btn-background-color: rgba(33, 150, 243, 0.2);

    --tr-hover-color: #16304c;
    --block-highlite: #142236;
    --sub-header: #afafc0;
    --card-bg-color: rgba(33, 150, 243, 0.2);
    --table-sticky-tr-box-shadow: 10px 0px 11px -2px rgba(0, 0, 0, 0.25), inset 0px 0px 0px #131722;
    --table-sticky-th-box-shadow: 0px 10px 11px -2px rgba(0, 0, 0, 0.25), inset 0px 0px 0px #131722;
    --table-sticky-tf-box-shadow: 0px -10px 11px -2px rgba(0, 0, 0, 0.25), inset 0px 0px 0px #131722;
    --table-border-color: #2f323c;
    --input-border-color: #434651;
    --bar-bg-color: #2f323c;
    --table-td-bg: #131722;
    --description-color: #afafc0;
    --table-sub-header-bg: #1b1f28;

    --blur-overlay-bg-color: rgba(19, 23, 34, 0.8);
    --prymary-link-disabled-color: #2f323c;
    --custom-btn-disabled-color: #2f323c;
    --disclaimer-title-color: #787b86;
    --nav-tab-font-color: #c5cbce;
    --risk-button-bg-color: rgb(19, 39, 66);

    --bull-call-spread-risk-meet-bg: rgb(50, 66, 35);
    --bull-call-spread-risk-too-high-bg: #42281c;
    --bull-call-spread-risk-too-low-bg: rgb(43 40 66);
    --bull-call-spread-td-border-color: #2f323c;
    --bull-call-spread-success-bar-line: #005337;
    --bull-call-spread-success-hint-color: #26a69a;
    --bull-call-spread-risk-button-bg-color: #195992;
    --bull-call-spread-risk-button-bg-color-hover: #194877;

    --wheel-table-border-color: #434651;
    --wheel-table-trading-log-summury-panel-border-color: #2f323c;
    --wheel-search-box-symbol-search-font-color: #f5f8fd;
    --wheel-search-box-symbol-search-mobile-font-color: #ffffff;
    --wheel-search-box-company-name-font-color: #ffffff;
    --wheel-trading-log-summary-panel-option-border-color: #2f323c;
    --trading-log-group-summary-info-content-font-color: #eef0f7;
    --trading-log-group-summary-info-header-font-color: #ebf6fe;
    --refresh-icon-hover-color: rgb(230, 230, 230);
    --time-and-date-font-color: #f5f8fd;
    --expected-move-bg: #2f323c;
    --expected-move-header-color: #ffffff;
    --expected-move-short-header-color: #d3d3e1;

    --wheel-scanner-filter-input-item-hover-color: #1d89e433;

    --economic-calendar-close-icon-color: #a5a7ab;

    --earning-day-bg-color: #2b4778;
    --assigned-bar-backlayer-color: #7a421a;
    --trading-hub-zero-progress-color: #f27573;
    --drag-item-shadow: 0px 30px 100px rgba(1, 41, 84, 0.15);
    --drag-item-background: #131722;
    --trading-hub-progress-value-background-color: #1e222d;
    --trading-hub-progress-zero-back-layout-color: #722826;
    --trading-hub-progress-was-not-opened-layout-color: #f27573;
    --trading-hub-progress-back-layout-color: #7a421a;
    --trading-hub-progress-background-completed-color: #2196f3;
    --trading-hub-progress-bar-color: #ff8937;
    --trading-hub-progress-content-icon-color: #ffffff;
    --trading-hub-close-color: #ffffff;
    --trading-hub-delete-plan-button-icon-color: #ffffff;
    --trading-plan-section-title-color: #ffffff;
    --trading-plan-section-content-color: #ffffff;
    --trading-plan-section-content-placeholder-color: #afafc0;

    --mat-menu-background-color: #2f323c;
    --mat-menu-shadow-color: 0px 30px 100px rgba(1, 41, 84, 0.15);
    --mat-menu-item-hover-color: #1a1b1d;
    --mat-menu-item-icon-color: #a5a8b6;

    --primary-font-color: #4dabf5;
    --introduction-background-color: #1b1f28;
    --decorator-line-color: #787b86;
    --tab-counter-background-color: #2f323c;
    --tab-active-counter-background-color: rgba(29, 137, 228, 0.1);
    --purple-lable-font-color: #ceadfd;
    --status-filled-color: #afafc0;
    --status-expired-color: #fde6a3;
    --symbol-font-color: #e0e3eb;

    --wheel-chart-legend-bg-color: #1b1f28;
    --wheel-chart-legend-border-color: #1b1f28;
    --wheel-chart-legend-text-color: #e0e3eb;
    --wheel-chart-legend-box-shadow-color: #000000;

    --trading-panel-connection-item-color: rgba(33, 150, 243, 0.2);
    --trading-panel-account-label-color: #e0e3eb;
    --trading-panel-content-color: #ffffff;
    --trading-panel-connection-item-border-color: #2f323c;

    --data-window-pre-market-color: #ff8937;
    --data-window-holiday-color: #787b86;
    --data-window-opened-market-color: #26a69a;
    --data-window-closed-market-color: #ef5350;
    --data-window-heatmap-not-found-background-color: linear-gradient(
        0deg,
        rgba(239, 83, 80, 0.1),
        rgba(239, 83, 80, 0.1)
      ),
      #ffffff;

    --impersonate-panel-background-color: #9a5321;
    --impersonate-panel-main-label-font-color: #ffd4b7;

    --feelings-popup-options-color: #afafc0;
    --feelings-popup-options-description-color: #787b86;
    --feelings-popup-border: #2f323c;
    --feelings-popup-box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06),
      0px 24px 38px rgba(0, 0, 0, 0.04);

    --impersonate-panel-bookmark-font-color: #ebf6fe;
    --impersonate-panel-bookmark-shadow: none;

    --divider-color: #2f323c;

    --my-settings-play-sound-controls-divider-color: #2f323c;
    --my-settings-play-sound-bar-info-container-icon-color: #787b86;
    --my-settings-play-sound-bar-info-container-text-color: #afafc0;
    // active
    --my-settings-play-sound-bar-bg-color: linear-gradient(0deg, rgba(33, 150, 243, 0.1), rgba(33, 150, 243, 0.1)),
      #1a1a1a;
    --my-settings-play-sound-button-bg-color: #2196f3;
    --my-settings-play-sound-button-inner-triangle-color: #ffffff;
    --my-settings-play-sound-button-border-color: #2196f3;
    --my-settings-play-sound-button-shadow: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))
      drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08));
    // disabled
    --my-settings-play-sound-bar-inactive-bg-color: #252b36;
    --my-settings-play-sound-button-inactive-bg-color: #131722;
    --my-settings-play-sound-button-inactive-inner-triangle-color: #787b86;
    --my-settings-play-sound-button-inactive-border-color: #2f323c;
    --my-settings-play-sound-button-inactive-shadow: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.06));
    --my-settings-play-sound-bar-timer-text-color: #787b86;

    --magic-ball-tab-icon-border-color: #ebf6fe;

    --profiles-block-bg-color: linear-gradient(0deg, rgba(33, 150, 243, 0.1), rgba(33, 150, 243, 0.1)), #1a1a1a;
    --profiles-block-icon-border-color: rgb(37 65 94 / 80%);
    --profile-menu-block-divider-color: #2f323c;
    --profile-menu-block-title-text-color: #787b86;
    --profile-menu-block-item-icon-color: #969696;
    --profile-menu-block-item-text-color: #f0f0f0;
    --profile-menu-block-item-bg-color: #2f3241;

    --smiley-menu-border-color: #2f323c;
    --smiley-menu-shadow: drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.2)) drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.08));
    --smiley-menu-item-hover-bg-color: #282e3a;
    --smiley-menu-item-title-color: #f0f0f0;
    --smiley-menu-item-progressbar-bg-color: #2f323c;
    --smiley-menu-item-hover-progressbar-bg-color: #656565;
    --smiley-menu-item-progressbar-percentage-bg-color: #afafaf;
    --smiley-menu-item-progressbar-active-bg-color: linear-gradient(0deg, rgba(26, 26, 26, 0.8), rgba(26, 26, 26, 0.8)),
      #2196f3;
    --smiley-menu-item-progressbar-active-bg-hover-color: #557691;
    --smiley-menu-item-progressbar-active-percentage-bg-color: #2196f3;
    --smiley-menu-close-btn-color: #ffffff;
    --symbol-flag-success-bg-color: #66bb6a;
    --symbol-flag-danger-bg-color: #ef5350;
    --symbol-flag-warning-bg-color: #ff8937;
    --symbol-flag-never-bg-color: #ffffff;
    --symbol-flag-never-stroke-color: #131722;
    --symbol-flag-none-outer-stroke-color: #2f323c;
    --symbol-flag-none-stroke-color: #787b86;
    --symbol-flag-none-bg-color: #2f323c;
    --never-smile-icon-color: #ffffff;
    --none-smile-icon-color: #9a9da6;
    --disabled-smile-bg-color: #2f323c;

    --smiley-votes-counter-badge-bg-color: #e5e5e6;
    --smiley-votes-counter-selected-bg-color: #585757;
    --smiley-votes-counter-selected-no-icon-bg-color: #492726;
    --smiley-votes-counter-selected-maybe-icon-bg-color: #3b2f23;
    --smiley-votes-counter-selected-yes-icon-bg-color: #192d2b;
    --smiley-votes-counter-selected-never-icon-bg-color: #4b4848;

    // default symbol flags (in smiley-icons directory) colors - vars should have the same names as in SVG files
    --symbol-flag-outer-stroke-color: transparent;
    --symbol-flag-stroke-color: transparent;
    --symbol-flag-bg-color: transparent;

    --magic-ball-icon-stroke-in-top-menu-color: #ffffff;

    --support-access-modal-supported-user-color: rgba(255, 255, 255, 0.4);

    --demo-page-heading-title-color: #f0f0f0;
    --demo-page-text-content-color: #afafc0;
    --demo-page-vertical-dividers-color: #2f323c;
    --demo-page-lock-icon-color: invert(52%) sepia(15%) saturate(230%) hue-rotate(190deg) brightness(90%) contrast(91%);

    --heatmap-chart-bg-color: #262931;
    --heatmap-chart-sector-font-color: #f0f0f0;
    --heatmap-chart-child-font-color: #f0f0f0;
    --heatmap-breadcrumbs-hover-bg-color: linear-gradient(0deg, rgba(26, 26, 26, 0.8), rgba(26, 26, 26, 0.8)), #2196f3;
    --heatmap-date-label-font-color: #969696;
    --heatmap-date-font-color: #e5e5e6;

    --feelings-modal-option-disabled-color: rgba(203, 203, 203, 0.38);

    --chat-bot-example-message-bg-color: linear-gradient(0deg, rgba(26, 26, 26, 0.8), rgba(26, 26, 26, 0.8)), #2196f3;
    --chat-bot-example-message-bg-hover-color: linear-gradient(0deg, rgba(26, 26, 26, 0.7), rgba(26, 26, 26, 0.7)),
      #2196f3;
    --chat-bot-limitations-icon-color: #afafc0;
    --chat-bot-limitations-title-color: #afafc0;
    --chat-bot-limitations-list-item-color: #787b86;
    --chat-bot-icon-color: #bdbfc1;
    --chat-bot-message-bg-color: #2f323c;
    --chat-bot-user-message-bg-color: linear-gradient(0deg, rgba(26, 26, 26, 0.8), rgba(26, 26, 26, 0.8)), #2196f3;
    --chat-bot-error-message-bg-color: linear-gradient(0deg, rgba(26, 26, 26, 0.8), rgba(26, 26, 26, 0.8)), #ef5350;
    --chat-bot-message-box-date-font-color: #bdbfc1;
    --chat-bot-message-font-color: #f0f0f0;
    --chat-bot-user-message-font-color: #f0f0f0;
    --chat-bot-menu-btn-bg-color: #1b1f28;
    --chat-bot-menu-btn-icon-color: #c5cbce;
    --chat-bot-send-icon-default-color: #afafc0;
    --chat-bot-pagination-btn-arrow-color: #696a6a;
    --chat-bot-pagination-btn-arrow-hover-color: #787b86;
    --chat-bot-pagination-state-bg-color: #2f323c;
    --chat-bot-pagination-state-border-color: #2f323c;
    --chat-bot-feedback-neutral-color: rgba(120, 123, 134, 1);
    --chat-bot-feedback-hover-neutral-bg-color: #2f323c;
    --chat-bot-feedback-positive-color: rgba(33, 150, 243, 1);
    --chat-bot-feedback-positive-bg-color: rgba(33, 150, 243, 0.1);
    --chat-bot-feedback-negative-color: rgba(239, 83, 80, 1);
    --chat-bot-feedback-negative-bg-color: rgba(239, 83, 80, 0.1);

    --oco-order-modal-title-color: #f0f0f0;
    --oco-order-modal-subtitle-color: #afafc0;
    --oco-order-modal-price-ticker-btn-text-color: #787b86;
    --oco-order-modal-price-ticker-btn-bg-color: #2f323c;
    --oco-order-modal-option-details-locked-input-text-color: #afafc0;
    --oco-order-modal-option-details-locked-input-border-color: #292e38;
    --oco-order-modal-footer-btn-buy-bg-color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      var(--success-color);
    --oco-order-modal-footer-btn-buy-bg-hover-color: var(--success-color);
    --oco-order-modal-footer-btn-sell-bg-color: var(--danger-color);
    --oco-order-modal-footer-btn-sell-bg-hover-color: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      var(--danger-color);
    --oco-order-modal-footer-btn-bg-disabled-color: #2f323c;
    --oco-order-modal-footer-btn-text-disabled-color: #787b86;

    --progressbar-success-color: #26a69a;
    --progressbar-danger-color: #ef5350;
    --progressbar-warning-color: #ff8937;
    --progressbar-divider-color: #131722;
    --two-way-progressbar-divider-color: #787b86;

    --tl-datawindow-shadow-when-content-hides-on-bottom: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.25) 0%,
      transparent 100%
    );
    --trading-log-days-in-trade-block-help-icon-color: #787b86;
    --trading-log-days-in-trade-block-progress-line-bg-color: #2f323c;
    --trading-log-days-in-trade-block-progress-line-color: #afafc0;
    --trading-log-quoutes-icon-color: #969696;
    --trading-log-quoutes-icon-active-color: #2196f3;
    --trading-log-days-in-trade-block-help-icon-color: #787b86;
    --trading-log-days-in-trade-block-progress-line-bg-color: #2f323c;
    --trading-log-days-in-trade-block-progress-line-color: #afafc0;

    --portfolio-additional-panel-header-bg-color: #1b1f28;

    --view-switcher-bg-color: #1b1f28;
    --view-switcher-btn-checked-color: #0e1118;
    --view-switcher-btn-checked-shadow: -2px 1px 3px rgba(0, 0, 0, 0.1), -2px 2px 1px rgba(0, 0, 0, 0.06),
      -2px 1px 1px rgba(0, 0, 0, 0.08);
    --view-switcher-default-icon-color: #afafc0;
    --view-switcher-active-icon-color: #f0f0f0;

    --trading-log-put-call-performance-table-legend-call-item-bg-color: linear-gradient(
        0deg,
        rgba(26, 26, 26, 0.8),
        rgba(26, 26, 26, 0.8)
      ),
      var(--success-color);
    --trading-log-put-call-performance-table-legend-put-item-bg-color: linear-gradient(
        0deg,
        rgba(26, 26, 26, 0.8),
        rgba(26, 26, 26, 0.8)
      ),
      var(--danger-color);
    --trading-log-put-call-performance-table-legend-all-item-bg-color: linear-gradient(
        0deg,
        rgba(26, 26, 26, 0.8),
        rgba(26, 26, 26, 0.8)
      ),
      var(--primary-color);

    --tl-sorting-filter-icon-color: #787b86;
    --tl-sorting-filter-menu-item-color: #ffffff;

    --no-links-section-bg-color: #1b1f28;

    --earnings-calendar-current-day-bg-color: linear-gradient(0deg, rgba(26, 26, 26, 0.8), rgba(26, 26, 26, 0.8)),
      #2196f3;
    --earnings-calendar-progressbar-bg-color: #2f323c;
    --earnings-calendar-progressbar-percentage-bg-color: #324557;
    --earnings-calendar-day-earning-block-bg-color: #1b1f28;
    --earnings-calendar-day-earning-block-company-placeholder-logo-border-color: #1b3345;

    --tl-dashboard-datepicker-bg-color: rgba(33, 150, 243, 0.3);

    --stocks-dropdown-mat-toggle-bg-color: #2f323c;
    --stocks-dropdown-mat-toggle-border-color: #2f323c;
    --stocks-dropdown-label-color: #e5e5e6;
    --stocks-dropdown-header-color: #ffffff;
    --stocks-dropdown-symbol-name-color: #bdbfc1;
    --stocks-dropdown-input-bg-color: #2f323c;
    --stocks-dropdown-input-border-color: transparent;
    --stocks-dropdown-symbol-container-bg-color: #2f323c;
    --stocks-dropdown-symbol-container-hover-bg-color: #2f323c;

    --modal-header-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    --modal-footer-box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08);

    --quote-of-day-heading-bg-color: linear-gradient(0deg, rgba(33, 150, 243, 0.1), rgba(33, 150, 243, 0.1)), #1a1a1a;
    --quote-of-day-heading-border-color: rgba(34, 148, 242, 0.1);
    --quote-of-day-block-carousel-active-dot-indicator-color: #2196f3;
    --quote-of-day-block-carousel-inactive-dot-indicator-color: #787b86;
    --quote-of-day-block-carousel-button-bg-color: #f6f8fd;
    --quote-of-day-block-carousel-button-bg-disabled-color: #131722;
    --quote-of-day-block-carousel-button-border-color: #e8e8e8;
    --quote-of-day-block-carousel-button-border-disabled-color: #2f323c;

    --tradier-import-settings-block-bg-color: #1b1f28;
    --tradier-import-settings-group-bg-color: #2f323c;
    --tradier-import-selected-import-group-bg-color: #2f323c;
    --tradier-import-selected-import-group-border-color: #565667;
    --tradier-import-selected-import-group-action-btn-hover-color: rgba(255, 255, 255, 0.1);
    --tradier-import-selected-import-group-symbol-bg-color: #2f323c;
    --tradier-import-trades-group-modal-table-in-header-bg-color: #1b1f28;

    --wtf-trades-strategy-container-original-performance-bg: #1b3345;
    --wtf-trades-strategy-container-original-performance-text-color: #d3eafd;
    --wtf-trades-strategy-container-original-performance-bg-color: #1b2630;
    --wtf-trades-strategy-container-original-performance-divider-color: #1a1a1a;
    --wtf-trade-report-filtered-trades-bg-color: rgba(39, 40, 44, 0.4);
    --wtf-trade-report-filtered-trades-divider-color: #282828;
    --wtf-chart-legend-filtered-trades-bg-color: rgba(39, 40, 44, 0.6);
    --wtf-chart-legend-filtered-trades-border-color: #353535;
    --wtf-two-step-block-divider-color: #131722;

    --yt-link-container-bg-color: #2f323c;
    --yt-link-container-border-color: #1b1f28;
    --dividends-analysis-dividends-bg-color: #2f323c;
    --dividends-analysis-dividends-border-color: #787b86;
    --dividends-analysis-dividendss-bg-color: #2f323c;
    --dividends-analysis-dividendss-border-color: #787b86;
    --dividends-analysis-days-progressbar-bg-color: #2f323c;
    --dividends-analysis-days-progressbar-line-color: #787b86;
    --dividends-analysis-break-even-warning-bg-color: #3d2f1f;

    --stock-screener-header-color: #ffffff;

    --bsc-input-label-color: #676767;

    --import-list-dropzone-bg-color: #1b1f28;
    --import-list-dropzone-bg-hover-color: var(--primary-alpha);
    --import-list-dropzone-border-color: #2f323c;
    --import-list-dropzone-border-hover-color: var(--primary-color);
    --import-list-example-table-heading-bg-color: #2f323c;

    --import-list-example-optinal-badge-text-color: #AFAFCO;
    --import-list-example-optinal-badge-bg-color: #252b36;

    --input-multi-select-dropdown-border-color: #1b1f28;
    --input-multi-select-dropdown-bg-color: #1b1f28;
    --input-multi-select-option-hover-bg-color: #16304c;

    --chat-helper-mode-bg-color: var(--quote-of-day-heading-bg-color);
    --chat-helper-mode-border-color: rgba(33, 150, 243, 0.1);

    --mm-trades-admin-panel-buttons-bg-color: #242833;
    --mm-trades-admin-panel-icon-buttons-color: #eef0f6;
    --mm-trades-cell-inactive-color: #a0a0a0;

    --sol-alert-snackbar-btn-border-color: rgba(120, 123, 134, 0.25);

    --mm-trades-admin-panel-bg-color: #1b1f28;
    --mm-trades-sequence-column-bg-color: #2f323c;
    --mm-trades-top-row-divider-color: #737373;
    --mm-trades-icon-outline-color: #5d5d5d;

    --combined-calendars-page-tab-border-color: #4dabf5;
    --combined-calendars-page-tab-bg-color: rgba(33, 150, 243, 0.1);
    --day-block-bg-color: #1b1f28;
    --time-block-bg-color: #1b1f28;

    --master-calendar-accordion-item-bg-color: #1b1f28;
    --master-calendar-accordion-item-border-color: #2f323c;

    --metric-details-expanded-row-bg-color: #1b1f28;
    --metric-details-notes-icon-color: #afafc0;
    --metric-details-arrow-icon-border-color: #2f323c;
    --metric-details-arrow-icon-path-color: #ffffff;

    --my-settings-change-phone-title-color: #f0f0f0;
    --my-settings-set-phone-number-btn-color: #eef0f6;
    --my-settings-change-phone-number: #f0f0f0;
    --my-settings-otp-code-bg-btn-color: #2f323c;
    --video-hub-player-bg-play-again-color: #ffffff1a;
    --video-hub-player-bg-hover-play-again-color: #ffffff33;
    --video-hub-player-bg-placeholder: #000;
    --video-hub-progressbar-bg: #b0b0b0;
    --video-hub-progressbar-color: #ff8937;
    --video-hub-watched-bg: #2f323c;
    --video-hub-watched-color: #afafc0;

    --maintenance-refresh-img-fill-circle: #16304c;
    --maintenance-refresh-img-fill-arrow: #19446e;
    --maintenance-refresh-img-fill-table: #494c57;
    --maintenance-refresh-img-fill-block: #787b86;
    --maintenance-refresh-img-fill-upper: #2a2d37;
    --maintenance-refresh-img-fill-main: #1b1f28;
    --maintenance-refresh-img-fill-logo-text: #2f323c;
    --maintenance-refresh-img-fill-logo-bg: white;
    --maintenance-refresh-img-fill-logo-second: #2196f3;
    --maintenance-refresh-img-stroke-block: #494c57;
    --maintenance-refresh-img-stroke-arrow: #2f323c;
    --maintenance-refresh-img-stroke-table: #2f323c;

    --homepage-title-font-color: #eef0f6;
    --homepage-description-font-color: #e0e3eb;
    --homepage-btn-color: #ffffff;
    --homepage-small-card-color: #ffffff;
    --homepage-icon-arrow-color: #eef0f6;
    --homepage-trading-hub-bottom: #ffffff14;

    --submenu-bg-color: #131722;

    --skeleton-loader-bg: #262933;
    --skeleton-animation-loader-bg: linear-gradient(
      102.7deg,
      rgba(67, 71, 86, 0) 37.3%,
      rgba(67, 71, 86, 0.5) 52.24%,
      rgba(67, 71, 86, 0) 63.5%
    );
    --skeleton-divider-color: #525252;
    --skeleton-light-gray: #363636;
    --gradient-start: var(--white-bg);
    --gradient-end: rgba(255, 255, 255, 0);
    --master-calendar-previous-week-selector-bg: linear-gradient(0deg, rgba(239, 83, 80, 0.1), rgba(239, 83, 80, 0.1)),
      #1a1a1a;
    --master-calendar-previous-week-selector-font-color: #ef5350;
    --master-calendar-current-week-selector-bg: linear-gradient(0deg, rgba(38, 166, 154, 0.1), rgba(38, 166, 154, 0.1)),
      #1a1a1a;
    --master-calendar-current-week-selector-font-color: #51b8ae;
    --master-calendar-next-week-selector-bg: linear-gradient(0deg, rgba(255, 137, 55, 0.1), rgba(255, 137, 55, 0.1)),
      #1a1a1a;
    --master-calendar-next-week-selector-font-color: #fa9e33;
    --my-settings-change-phone-container-bg-color: linear-gradient(
        0deg,
        rgba(255, 137, 55, 0.1) 0%,
        rgba(255, 137, 55, 0.1) 100%
      ),
      #1a1a1a;
    --my-settings-verify-phone-text-color: #f0f0f0;
    --my-settings-verify-existing-number-btn-background-color: transparent;
    --my-settings-change-phone-container-border-color: #483020;
    --my-settings-verify-phone-border-color: #c5cbce;
    --my-settings-verified-phone-container-bg-color: linear-gradient(
        0deg,
        rgba(38, 166, 154, 0.1) 0%,
        rgba(38, 166, 154, 0.1) 100%
      ),
      #1a1a1a;
    --my-settings-not-verified-phone-number-btn-hover-color: #262931;

    // disabled checkbox colors

    --disabled-checkbox-bg-color: #1b1f28;
    --disabled-checkbox-border-color: #2f323c;

    --trade-zero-information-text-color: #eef0f6;
    --trade-zero-information-bg-color: #2f3241;
    --trade-zero-placeholder-text-color: #afafc0;

    --submenu-linear-color-right: linear-gradient(to right, rgba(19, 23, 34, 0.2) 0%, rgba(19, 23, 34, 1) 100%);
    --submenu-linear-color-left: linear-gradient(to left, rgba(19, 23, 34, 0.2) 0%, rgba(19, 23, 34, 1) 100%);
  }
}
