@import '../values';
@import '../mixins/images';

.quote-of-day {
  .carousel {
    height: 330px !important;
  }

  .carousel-slide {
    height: 280px !important;
    background-size: contain;
    border-radius: 2px;
    overflow: hidden;
  }

  .carousel-controls {
    width: 150%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: $mobile-min-500px-width-trigger) {
      display: none;
    }

    & > button {
      width: 32px;
      height: 32px;
      background-color: var(--quote-of-day-block-carousel-button-bg-color);
      border: 1px solid var(--quote-of-day-block-carousel-button-border-color);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
      border-radius: 50%;

      &:first-of-type {
        left: 0;
      }

      &:last-of-type {
        right: 0;
      }

      &:disabled {
        background-color: var(--quote-of-day-block-carousel-button-bg-disabled-color);
        border-color: var(--quote-of-day-block-carousel-button-border-disabled-color);

        .mat-icon {
          opacity: 1;
        }
      }
    }

    .mat-icon {
      @include apply-icon-size(12px);
      color: var(--quote-of-day-block-carousel-inactive-dot-indicator-color);
    }
  }

  .carousel-indicators > button {
    margin: 5px;
    width: 8px;
    height: 8px;
    background-color: var(--quote-of-day-block-carousel-inactive-dot-indicator-color);
    box-shadow: none;
  }

  .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
    background-color: var(--quote-of-day-block-carousel-active-dot-indicator-color);
  }
}
