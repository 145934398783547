@import '../mixins/fonts';

.mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
  --mdc-radio-unselected-pressed-icon-color: var(--mdc-radio-unselected-icon-color);
}

.mat-mdc-radio-button {
  &.mat-primary {
    --mat-radio-ripple-color: var(--primary-color);
  }

  .mdc-radio {
    --mdc-radio-state-layer-size: 32px;
    padding: calc((var(--mdc-radio-state-layer-size) - 16px) / 2);
    width: 16px;
    height: 16px;

    .mat-mdc-radio-touch-target {
      width: calc(16px * 2);
      height: calc(16px * 2);
    }

    .mdc-radio__native-control {
      --mdc-radio-state-layer-size: 32px;
    }

    .mdc-radio__native-control:focus + .mdc-radio__background::before {
      opacity: 0;
    }

    .mdc-radio__background {
      width: 16px;
      height: 16px;

      &:before {
        top: calc(-1 * (var(--mdc-radio-state-layer-size) - 16px) / 2);
        left: calc(-1 * (var(--mdc-radio-state-layer-size) - 16px) / 2);
      }

      .mdc-radio__inner-circle {
        border-width: 8px;
      }

      .mdc-radio__outer-circle {
        --mdc-radio-unselected-icon-color: var(--light-border-color-2);
        --mdc-radio-unselected-hover-icon-color: var(--light-border-color-2);
        border-width: 1px;
        background: var(--white-bg);
      }
    }

    .mat-ripple .mat-ripple-element {
      display: none !important;
    }
  }

  .mdc-label {
    font-size: 12px;
    line-height: 24px;
    color: var(--font-dark-color);
    cursor: pointer;

    .label-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }
}

.my-setting__wrapper {
  .date-radio-button {
    .mdc-radio,
    .mdc-radio__native-control {
      --mdc-radio-state-layer-size: 16px;
    }

    .mdc-radio__background {
      &:before {
        --mdc-radio-state-layer-size: 32px;
      }
    }

    .mdc-label {
      padding-left: 8px;
    }
  }
}

.pxo-app {
  .feelings-modal {
    .mat-radio-container {
      width: 20px;
      height: 20px;
    }

    .mat-radio-label-content {
      @include apply-feelings-modal-options-font();
      color: inherit;
    }
  }
}

.chat-feedback-modal {
  .radio-group {
    .mat-radio-button.radio-button {
      .mat-radio-label {
        .mat-radio-label-content {
          @include apply-chatbot-tab-example-question-font();
          line-height: 20px !important;
          white-space: pre-line;
        }
      }
    }
  }

  .mat-mdc-radio-button {
    .mdc-label {
      font-size: 14px;
    }
  }
}

.tradier-imports .importing-rule {
  .option-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--main-font-color);
  }

  .option-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.1px;
    color: var(--font-dark-color);
  }
}

.trades-group-modal {
  .trading-group-content {
    .mdc-label {
      padding-block: 12px;
      display: flex;
      align-items: flex-start;
      width: 100% !important;
      border-top: 1px solid var(--divider-color);
    }

    .mat-radio-label-content {
      flex: 1 1 100%;
    }
  }
}