@import './../values';

// OVERRIDE DEFAULT LIBRARY STYLES
.skeleton-loader {
  width: 100% !important;
  height: 100% !important;
  border-radius: 15px !important;
  margin: 0 !important;
  display: block !important;
  cursor: default !important;
  background: var(--skeleton-loader-bg) no-repeat !important;

  &::before {
    width: 100% !important;
    background-size: 100% 100% !important;
    left: -100% !important;
    background-image: var(--skeleton-animation-loader-bg) !important;
    animation-duration: 3s !important;
  }

  &.circle {
    border-radius: 50% !important;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.full-size {
  width: 100%;
  height: 100%;
}

.mask-gradient-horizontal {
  mask-image: linear-gradient(to right, var(--gradient-start) 0%, var(--gradient-end) 100%);
}

.mask-gradient-vertical {
  mask-image: linear-gradient(to bottom, var(--gradient-start) 0%, var(--gradient-end) 100%);
}

.skeleton-border-bottom {
  border-bottom: 1px solid var(--skeleton-divider-color);
}

.skeleton-loader-item {
  width: 100%;
  height: 6px;
}

.chart-skeleton {
  display: block;
  @extend .full-size;
  overflow: hidden;

  .wrapper {
    display: grid;
    grid: 1fr auto / 1fr auto;
    gap: 10px;
    @extend .full-size;

    .left-chart-side {
      display: grid;
      grid: 1fr 40px / auto;
      align-items: center;
      gap: 10px;
      grid-row: 1 / 3;

      .chart-skeleton {
        @extend .mask-gradient-vertical;
      }

      .chart-down-skeleton {
        display: flex;
        gap: 10px;
        height: 20px;
        @extend .mask-gradient-horizontal;
      }
    }

    .right-chart-side {
      height: 100%;
      overflow: hidden;
      @extend .mask-gradient-vertical;

      ngx-skeleton-loader {
        display: grid;
        grid-auto-rows: 20px;
        gap: 30px;

        .skeleton-loader {
          min-width: 120px;
          max-width: 150px;
        }
      }
    }
  }
}

.data-window-skeleton {
  @extend .full-size;
  @extend .flex-column;

  .header {
    display: grid;
    grid-template-columns: 24px 1fr auto;
    gap: 5px;
    padding: 10px 10px 20px;
    @extend .skeleton-border-bottom;

    .circle {
      width: 100%;
      height: 24px;
    }

    .lines {
      margin-top: 3px;
      @extend .mask-gradient-vertical;

      ngx-skeleton-loader {
        display: grid;
        grid-auto-rows: 11px;
        gap: 7px;

        .skeleton-loader:nth-child(1) {
          width: 90% !important;
        }
        .skeleton-loader:nth-child(2) {
          width: 80% !important;
        }
      }
    }

    .actions {
      @extend .flex-center;

      ngx-skeleton-loader {
        display: grid;
        height: 32px;
        grid-template-columns: repeat(3, 32px);
        gap: 4px;

        .skeleton-loader {
          border-radius: 8px !important;
        }
      }
    }
  }

  .second-line {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    padding: 10px;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
    }

    &::before {
      height: 100%;
      top: 0;
      @extend .mask-gradient-horizontal;
    }

    &::after {
      height: 1px;
      bottom: 0;
      @extend .skeleton-border-bottom;
    }

    .item {
      @extend .flex-column;
      justify-content: space-between;
      gap: 4px;

      ngx-skeleton-loader {
        &:first-child {
          height: 19px;
        }
        &:nth-child(2) {
          height: 8px;
        }
      }
    }
  }

  .data-section {
    display: grid;
    grid-template-columns: 1fr 2fr;
    @extend .mask-gradient-vertical;
    @extend .full-size;

    .left-section,
    .right-section {
      @extend .full-size;
      @extend .flex-column;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      gap: 10px;
      overflow: hidden;

      .item {
        @extend .full-size;
        @extend .flex-column;
        gap: 5px;

        ngx-skeleton-loader {
          @extend .skeleton-loader-item;
        }
      }
    }

    .left-section {
      background: var(--skeleton-light-gray);
    }

    .right-section .item {
      align-items: center;
    }
  }
}

.filters-skeleton {
  display: block;
  @extend .full-size;

  .wrapper {
    @extend .full-size;
    overflow: hidden;

    .header {
      padding: 10px 15px;
      @extend .skeleton-border-bottom;

      ngx-skeleton-loader {
        display: grid;
        width: 100%;
        height: 10px;
        gap: 30px;
        grid-template-columns: repeat(4, 1fr) 2fr;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      @extend .mask-gradient-vertical;
      @extend .full-size;

      .item {
        display: grid;
        grid-template-columns: repeat(4, 1fr) 2fr;
        gap: 30px;
        padding: 10px 15px;
        @extend .skeleton-border-bottom;

        &-wrapper {
          @extend .flex-center;
          width: 100%;
          height: 20px;

          ngx-skeleton-loader {
            @extend .full-size;

            &.circle {
              width: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-991px-width-trigger) {
  .strategy-wrapper {
    grid: 1fr / auto !important;
  }

  .chart-skeleton {
    display: none;
  }
}
