// these styles are important for cdk-textarea-autosize
// they were taken from the angular material source code: "./node_modules/@angular/cdk/text-field-prebuilt.css"
// in case of update version find this file again and copy the styles for "textarea" and "cdk-textarea-autosize" tag/classes
// issue: https://github.com/angular/components/issues/24568

textarea.cdk-textarea-autosize {
  resize : none
}

textarea.cdk-textarea-autosize-measuring {
  padding : 2px 0 !important;
  box-sizing : content-box !important;
  height : auto !important;
  overflow : hidden !important
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding : 2px 0 !important;
  box-sizing : content-box !important;
  height : 0 !important
}
