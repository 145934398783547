@media (max-width: 1365px) {
  .login-section {
    width: 1174px;
  }

  .login-box {
    padding: 50px 50px;
  }
}

@media (min-width: 1200px) {
  .s-1199 {
    display: none !important;
  }

  .wheel-section .left-section {
    max-width: calc(100% - 444px);
  }

  .wheel-section .right-section {
    min-width: 440px;
  }
}

@media (max-width: 1199px) {
  .h-1199 {
    display: none !important;
  }

  .navbar .logo,
  .a-known-issue,
  .print-help-group,
  .print-help-group {
    display: none !important;
  }

  .first-column {
    width: 43%;
  }

  .second-column {
    width: 57%;
  }

  .login-section {
    width: 900px;
  }

  .login-box {
    padding: 50px 45px;
  }

  .login-box {
    width: 100%;
  }

  .side-bar {
    width: 210px;
  }

  .left-side {
    left: -210px;
    transition: 0.3s all;
    z-index: 101;
  }

  .side-content {
    width: calc(100% - 210px);
  }

  .full-width {
    width: 100%;
    transition: 0.3s width;
  }

  .help-ul ul li:last-child {
    margin-bottom: 10px;
  }

  .position-compare .mat-dialog-container {
    width: 85vw;
  }

  .print-data-group {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .powerx-main .left-side {
    width: calc(100% - 460px);
  }

  .powerx-main .right-side {
    width: 460px;
  }

  .wheel-section .left-section {
    max-width: calc(100% - 384px);
  }

  .wheel-section .right-section {
    min-width: 380px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) {
  .s-991 {
    display: none !important;
  }

  .s-less-991 {
    display: none !important;
  }
}

// if layout disturb so below code placed into 992 view
.pxo-main-div {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

app-wheel {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;
}

.wheel-section {
  height: calc(100% - 115px) !important;
}

// if layout disturb so above code placed into 992 view

@media (max-width: 991px) {
  app-powerx {
    overflow: auto;
  }

  .s-less-991 {
    display: block !important;
  }

  .h-less-991 {
    display: none !important;
  }

  .powerx-main {
    flex-wrap: wrap;
  }

  .powerx-page {
    height: 100% !important;
    overflow-y: visible !important;
  }

  .powerx-main .left-side,
  .powerx-main .right-side {
    width: 100%;
  }

  .powerx-main .left-side {
    order: 2;
    border-left: 0;
    border-right: 0;
    height: 500px;
  }

  .powerx-main .right-side {
    order: 1;
    height: auto;
  }

  app-chart-menu {
    height: calc(100% - 48px);
  }

  .ws-tabbing .mat-tab-labels {
    height: 38px;
  }

  .ws-tabbing .mat-tab-label {
    margin-top: 1px;
    height: 37px;
  }

  .ws-tabbing .mat-tab-label {
    padding-right: 16px !important;
  }

  .ws-tabbing .drop:not(.show-for-mobile) {
    display: none;
  }

  .scanner-table,
  .watchlist-table {
    height: 172px;
  }

  .search-section {
    border-bottom: 0;
  }

  .chart-trading-option,
  .chart-wheel {
    display: block;
  }

  .left-side-button {
    width: 100%;
    height: auto;
    border: 0 !important;
    border-bottom: 1px solid var(--dark-border-color) !important;
    height: 41px;
  }

  .left-side-button .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    -webkit-writing-mode: unset;
    -ms-writing-mode: unset;
    writing-mode: unset;
    transform: unset;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 40px;
    padding-left: 22px;
    padding-right: 22px;
  }

  .left-side-button .mat-button-toggle-group {
    flex-direction: row;
    width: auto;
    margin-top: 0;
    height: 41px;
  }

  .left-side-button .mat-button-toggle-button {
    border-right: 0;
    border-bottom: 1px solid var(--dark-border-color);
  }

  .left-side-button .mat-button-toggle-checked {
    border-bottom: 0;
    border-right: 1px solid var(--dark-border-color);
    border-top: 1px solid var(--dark-border-color) !important;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
  }

  .left-side-button .mat-button-toggle-checked .mat-button-toggle-button {
    border-bottom-color: var(--border-white);
  }

  .left-side-button .mat-button-toggle:first-child {
    border-top: unset;
  }

  .left-side-button .mat-button-toggle-checked .mat-button-toggle-label-content {
    line-height: 38px;
  }

  .left-side-button .mat-button-toggle:first-child {
    border-left: 0;
  }

  .right-side-content {
    width: 100%;
    height: 100dvh;
  }

  .app-tv-chart-container {
    height: 500px !important;
  }

  .chart-back-layout {
    height: 500px;
    overflow: visible;
  }

  .data-window {
    width: 480px;
    margin: 0 auto;
  }

  // .scroll-data {
  //   overflow-y: visible;
  // }

  // .wheel-section{
  //   height: calc(100vh - 53px) !important;
  // }
  .all-section {
    display: block !important;
  }

  .wheel-section .left-section {
    border-left: 0;
  }

  .chart-wheel .left-side-button .mat-button-toggle-group {
    margin-top: 0;
  }

  .wheel-section .right-side-content {
    height: calc(100% - 41px);
    overflow-y: hidden;
  }

  .cons-agg {
    height: 210px;
  }

  .wheel-scanner-part {
    height: 100%;
  }

  // .consAndAgg,
  // .premiums-chart-tabbing{
  //   height: 50%;
  // }
  .wheel-tbl thead th:first-child,
  .wheel-tbl th:nth-child(1) {
    border-left: 1px solid var(--dark-border-color) !important;
  }

  .consAndAgg {
    // height: 206px;
  }

  .premiums-chart-tabbing {
    height: auto;
  }

  .premiums-chart-tabbing {
    border-top: 4px solid var(--light-border-color-3) !important;
  }

  .premiums-section {
    height: calc(100% - 41px);
  }

  .consAndAgg .mat-tab-group.ws-tabbing .mat-tab-label-active {
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    border-bottom: 2px solid var(--primary-color) !important;
  }

  app-wheel-scanner {
    position: relative;
    display: block;
    height: 100%;
  }

  .consAndAgg #circle-timer-svg {
    height: 25px !important;
    width: 25px !important;
  }

  .consAndAgg .result-as-of .progress-detail-txt {
    font-size: 10px !important;
    line-height: 14px !important;
    // padding-left: 6px;
  }

  #circle-timer-svg {
    margin-right: 6px;
  }

  .consAndAgg .result-as-of #circle-timer-svg #per-number {
    font-size: 9px !important;
    line-height: 9px !important;
  }

  .search-tooltip {
    display: none !important;
  }

  .first-column {
    width: 100%;
  }

  .second-column {
    display: none;
  }

  .login-section {
    width: 540px;
  }

  .login-page::before {
    height: 226px;
    width: 213px;
    background: url(assets/img/newImg/login-top.svg);
    background-position: center;
    background-size: cover;
  }

  .login-page::after {
    height: 238px;
    width: 205px;
    background: url(assets/img/newImg/login-bottom.svg);
    background-position: center;
    background-size: 100% 100%;
  }

  .position-compare .mat-dialog-container {
    width: calc(100% - 50px);
  }

  .top-alert-text {
    display: flex;
    flex-direction: column;
    margin-left: 13px;
  }

  .top-alert {
    .alert-btns {
      margin-bottom: 1px;
    }

    .alert-text {
      font-size: 12px;
      line-height: 14.4px;
      margin-top: 0;
    }

    .alert-text-bold {
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      margin: 0 0 2px 0;
    }

    .notify-icon {
      margin-left: -4px;
      margin-bottom: 2px;
    }

    .close-alert {
      right: 14px;
      top: 14px;
    }

    .refresh-btn {
      margin-right: 35px;
      padding: 0 0 1px 3px !important;
    }

    .ver-info-btn {
      padding-top: 2px !important;
    }

    .refresh-btn-text {
      margin-right: 3px;
    }
  }
}

@media (min-width: 768px) {
  .h-less-767 {
    display: block !important;
  }

  .s-less-767 {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .help-menu-item a {
    font-weight: normal;
  }

  .h-less-767 {
    display: none !important;
  }

  .s-less-767 {
    display: block !important;
  }

  .navbar .mat-button-toggle-group .mat-button-toggle-button {
    width: auto;
    padding: 5px 0;
  }

  .navbar .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 12px !important;
  }

  .navbar .mat-button-toggle-group .mat-button-toggle-button .cap {
    margin-top: 0px;
  }

  .navbar .mat-button-toggle-group .mat-button-toggle-button .s1 {
    font-size: 14px;
    line-height: 18px;
  }

  // .navbar .mat-button-toggle-group{
  //   margin-top: 0;
  // }
  .account-size {
    padding: 3px 8px;
  }

  .account-size .icon-btn {
    display: none;
  }

  .ws-tabbing .mat-tab-label {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .ws-tabbing .drop:not(.show-for-mobile) {
    display: none;
  }

  .per-legend span > span {
    display: none;
  }

  .scanner-setting .mat-dialog-container {
    width: 535px;
  }

  .run-scanner-for .mat-form-field,
  .signal-type .o-radio {
    width: 368px;
  }

  .w-267 {
    width: 224px;
  }

  .scanner-exchanges {
    .exchanges-checkbox {
      width: 386px;
    }
  }

  .add-watchlist {
    top: 0px;
    right: 140px;
  }

  .report-con-tbl .date-progress-bar {
    display: none;
  }

  .report-con-tbl .start-end-date-name,
  .report-con-tbl .start-end-date-value {
    display: block;
    width: auto;
    text-align: center;
  }

  .report-con-tbl .start-end-date-value p {
    width: auto;
  }

  .report-con-tbl th {
    padding: 6px 8px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.33px;
    text-align: center;
  }

  .report-con-tbl th.text-right,
  .report-con-tbl td.text-right {
    text-align: center !important;
  }

  .report-con-tbl td {
    font-size: 12px;
    letter-spacing: 0.33px;
    line-height: 16px;
    padding: 6px 8px;
    text-align: center;
  }

  .profit-center {
    width: auto;
    margin-right: auto;
  }

  .profit-bar {
    align-items: flex-end;
  }

  .report-con-tbl .fonts-14px {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.33px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.text-right {
      display: table-cell;
    }
  }

  .user-list .header,
  .holidays-list .header,
  .trading-log-details .header,
  .banner-details .header {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
  }

  .holidays-list .semi-header,
  .trading-log-details .semi-header,
  .banner-details .semi-header {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .user-search-bar {
    padding: 10px 0;
  }

  .userListTbl th,
  .holidaysListTbl th,
  .bannerDetailsTbl th {
    font-weight: normal;
  }

  .licence-agreement-section {
    width: 100vw;
    height: 100vh;
    border-radius: 16px;
    background: var(--white-bg);
    box-shadow: 0 10px 35px 0 rgb(0 0 0 / 8%);
    min-height: auto;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }

  /* .user-agreement .terms-conditions-detail {
      height: calc(100vh - 320px);
  } */
  .user-agreement .terms-conditions-detail {
    height: auto;
  }

  .agreement-action-btns {
    position: static;
  }

  .add-watchlist {
    display: none;
  }

  .app-tv-chart-container,
  .chart-new-header {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .app-tv-chart-container {
    border: 1px solid var(--dark-border-color);
    border-top: 0;
  }

  .chart-back-layout {
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
  }

  .chart-new-header {
    padding-left: 0;
  }
}

@media (max-width: 580px) {
  .top-alert-text {
    margin-left: 15px;
  }

  .top-alert {
    flex-direction: column;
    padding: 9px 0 0 0;
    align-items: flex-start;

    .alert-text-bold {
      font-size: 12px;
      line-height: 14px;
      margin: 2px 0 0 0;
    }

    .alert-text {
      width: 230px;
      font-size: 10px;
      line-height: 130%;
      margin-top: 1px;
    }

    .ver-info-btn {
      width: 220px;
      height: 35px;
      padding-bottom: 2px !important;
    }

    .alert-btns {
      margin: 11px 0 10px 10px;
      display: flex;
      justify-content: flex-start;

      .fill-btn {
        font-size: 14px !important;
      }
    }

    .notify-icon {
      margin-left: 12px;
      margin-top: 3px;
    }

    .refresh-btn {
      margin: 0;
      height: 35px;
      padding-top: 2px !important;
    }

    .close-alert {
      right: 14px;
      top: 14px;
    }
  }
}

@media (min-width: 576px) {
  .navbar .run-scanner {
    display: block !important;
  }

  .right-side .run-scanner {
    display: none !important;
  }

  .collapse-btn {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .scanner-setting .mat-dialog-container {
    max-width: 90vw !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .scanner-setting .mat-dialog-content {
    padding: 15px !important;
  }

  .navbar .run-scanner {
    display: none !important;
  }
  .add-watchlist img {
    height: 20px;
    width: 20px;
  }

  .account-size {
    padding: 2px 8px;
    margin-left: 0;
  }

  .navbar .mat-button-toggle-group {
    margin-top: 3px;
  }

  .header-menu-icon {
    height: 24px;
    width: 24px;
  }

  .ws-tabbing .mat-tab-label {
    padding-left: 12px !important;
    padding-right: 12px !important;
    min-width: unset;
  }

  .ws-tabbing .mat-tab-label-content {
    font-size: 12px;
  }

  .nav-start {
    margin-right: unset;
    margin-left: auto;
  }

  .nav-end {
    display: none;
  }

  .left-side-button .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    font-weight: normal;
  }

  .left-side-button .mat-button-toggle-checked .mat-button-toggle-label-content {
    font-weight: bold;
  }

  .left-side-button .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    font-size: 12px;
    padding-left: 18px;
    padding-right: 18px;
  }

  .chart-new-header {
    flex-wrap: wrap;
    padding-top: 9px;
    padding-left: 0px;
    padding-bottom: 12px;
  }

  .trading-chart .indicator-trade-button {
    padding-bottom: 0 !important;
    margin-top: 10px;
  }

  .trading-chart .indicator-trade-button .next-erning {
    font-size: 10px;
  }

  .company-name .s2 {
    line-height: 16px;
    margin-right: 0;
  }

  .long-position .mat-button {
    line-height: 24px !important;
    width: 130px !important;
  }

  .trading-headers {
    padding-left: 0;
  }

  .trading-headers .blue-line-toggle .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 32px !important;
  }

  .blue-line-toggle .target-toggle-button .toggle-button-red .mat-button-toggle-appearance-standard {
    font-size: 12px !important;
  }

  .right-view-toggle .cap {
    font-size: 11px;
    width: 155px;
    line-height: 12px;
  }

  .wheel-tbl .heading-text > div::after {
    height: 16px;
    width: 16px;
  }

  .wheel-tbl th:nth-child(1) {
    min-width: 189px;
  }

  .wheel-tbl .heading-text,
  .wheel-tbl th {
    font-size: 12px;
  }

  .wheel-tbl tbody th .help {
    height: 12px;
    width: 12px;
    margin-right: 6px;
  }

  .wheel-tbl tbody th ul {
    padding-left: 0;
  }

  .wheel-tbl tbody th ul li {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 10px;
  }

  .per-legend span {
    line-height: 10px;
  }

  .chart-wheel .left-side-button {
    display: flex;
    justify-content: space-between;
  }

  .modals .mat-dialog-title,
  .symbol-search .modal-heading {
    font-size: 16px;
    padding: 10px 12px;
  }

  .modals .mat-dialog-actions {
    padding: 18px !important;
  }

  .modals .small-btn {
    width: 80px !important;
  }

  .modals .large-btn {
    width: 120px !important;
  }

  .fill-btn,
  .border-btn {
    font-size: 13px !important;
    height: 32px;
  }

  .modals .mat-dialog-actions.mr-16 .mat-button {
    margin-right: 12px !important;
  }

  .position-sizing .mat-dialog-container,
  .position-sizing.buying-power .mat-dialog-container,
  .edit-announcement .mat-dialog-container,
  .max-popup .mat-dialog-container {
    width: 300px;
  }

  .add-symbol-to-watchlist .mat-dialog-container {
    width: 340px;
  }

  .position-sizing .mat-dialog-content,
  .add-symbol-to-watchlist .mat-dialog-content,
  .edit-announcement .mat-dialog-content,
  .max-popup .mat-dialog-content,
  .date-popup .mat-dialog-content {
    padding: 16px 18px 8px 18px !important;
  }

  .max-popup p {
    font-size: 12px;
  }

  .modal-search-btn {
    height: 32px;
  }

  .symbol-search .modal-search input {
    height: 34px;
    font-size: 14px;
  }

  .scanner-setting .mat-dialog-container {
    width: 350px;
  }

  .signal-type .o-radio {
    width: 217px;
  }

  .signal-type {
    align-items: flex-start;
  }

  .signal-type .mat-radio-button {
    margin-right: 18px;
  }

  .scanner-setting .per-input .input-box,
  .scanner-setting .dollar-input .input-box,
  .scanner-setting .icon-input .input-box,
  .scanner-setting .only-input .input-box {
    width: 160px;
  }

  .w-267 {
    width: 100%;
  }

  .scanner-exchanges {
    align-items: flex-start;

    .exchanges-checkbox {
      width: 215px;
    }
  }

  .mat-checkbox:first-child {
    margin-right: 28px;
  }

  .mat-checkbox:nth-child(2) {
    margin-right: 0;
  }

  .navbar .mat-button-toggle-group .mat-button-toggle-button {
    padding: 4px 0;
  }

  .navbar .mat-button-toggle-group .mat-button-toggle-button .s1 {
    font-size: 11px;
    line-height: 19px;
  }

  .navbar .mat-button-toggle-group .mat-button-toggle-button .cap {
    margin-top: -3px;
    font-size: 10px;
  }

  .report-con-tbl th {
    padding: 4px 8px;
    // font-size: 10px;
    // line-height: 16px;
  }

  .report-con-tbl td {
    // font-size: 10px;
    // line-height: 14px;
    padding: 4px 6px;
  }

  .user-list,
  .holidays-list,
  .trading-log-details,
  .banner-details {
    padding-left: 8px;
    padding-right: 8px;
  }

  .user-select-outer.mat-form-field {
    margin-right: 12px;
  }

  .user-select-outer.mat-form-field .user-select {
    padding-left: 8px;
  }

  .user-select-outer.mat-form-field {
    width: 200px;
  }

  .search-boxs input {
    padding-left: 8px;
    padding-right: 7px;
  }

  .bannerDetailsTbl thead th:nth-child(2),
  .bannerDetailsTbl tbody td:nth-child(2) {
    width: 160px;
  }

  .bannerDetailsTbl thead th:last-child,
  .bannerDetailsTbl tbody td:last-child {
    width: 45px;
  }

  .bannerDetailsTbl .mat-radio-label-content {
    padding-left: 2px;
  }

  .bannerDetailsTbl .mat-radio-button:first-child {
    margin-right: 8px;
  }

  .search-section {
    flex-wrap: wrap;
  }

  .long-position .mat-button {
    width: 160px !important;
  }

  .position-dropdown.mat-menu-panel {
    min-width: 160px !important;
  }

  .position-dropdown .mat-menu-item {
    height: 28px !important;
    font-size: 10px !important;
  }

  .search-section .company-name {
    order: 3;
    margin-top: 2px;
    width: 100%;
  }

  .search-section .add-to-watchlist-btn {
    padding: 4px 4px !important;
  }

  .search-section .add-to-watchlist-btn img {
    height: 16px;
    width: 16px;
  }

  .login-section {
    height: 450px;
    display: table;
    width: 350px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .error-msg {
    width: 380px;
  }

  .login-page input.mat-input-element {
    margin-top: 17px;
  }

  .login-box {
    padding: 30px 35px;
  }

  .user-agreement .terms-conditions-detail .h2 {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  // .wheel-section{
  //   height: calc(100vh - 48px) !important;
  // }
  .input-btn-header input {
    width: 97px;
  }

  .w-delete {
    margin-left: 9px;
    margin-right: 10px;
  }

  .ws-tabbing .mat-tab-labels {
    height: 32px;
  }

  .ws-tabbing .mat-tab-label {
    height: 31px;
  }

  .left-side-button .mat-button-toggle-group {
    height: 32px;
  }

  .left-side-button .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 31px;
  }

  .left-side-button .mat-button-toggle-checked .mat-button-toggle-label-content {
    line-height: 29px;
  }

  .left-side-button {
    height: 32px;
  }

  .wheel-section .right-side-content {
    height: calc(100% - 32px);
  }

  .consAndAgg .result-as-of {
    padding: 5px 8px !important;
    padding-bottom: 2px;
    // height: 32px;
  }

  .SER-accordian .mat-expansion-panel-header,
  .SER-accordian .mat-expansion-panel-body {
    padding-left: 6px;
    padding-right: 33px;
  }

  .search-first-tbl h2,
  .search-second-tbl h2 {
    font-size: 14px;
    line-height: 37px;
    padding-left: 18px;
  }

  .symbol-search {
    width: 100% !important;
    max-width: 100% !important;
    overflow: auto;
  }

  .symbol-search .mat-dialog-container {
    width: 85vw;
    margin: 50px auto;
  }

  .symbol-search .mat-dialog-content {
    height: auto;
    max-height: initial;
    min-height: 350px;
  }

  // .scanner-table .no-data-msg,
  // .watchlist-table .no-data-msg,
  // .conservative-table .no-data-msg,
  // .aggresive-table .no-data-msg,
  // .data-window .no-data-window,
  // .no-data.s2{
  //   font-size: 12px;
  //   line-height: 16px;
  // }

  .cons-agg {
    height: 204px;
  }

  .SER-accordian .srtike-value .min-input input,
  .SER-accordian .srtike-value .max-input input,
  .SER-accordian .roi-value .min-input input,
  .SER-accordian .roi-value .max-input input {
    padding: 0 4px;
  }

  .risk-reward .option-attention-msg .option-attention-text {
    padding: 4px 6px;
  }
}

@media (max-width: 500px) {
  .login-section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .data-window {
    width: 100%;
  }

  .conservative-header p {
    font-size: 12px;
  }

  .position-data .font-10 {
    font-size: 10px;
  }

  .position-data .stop-loss-target {
    margin-top: 3px;
  }

  .position-data .stop-loss-target svg {
    height: 12px;
    width: 12px;
  }

  .risk-reward-small span {
    font-size: 10px;
  }
}

@media (max-width: 455px) {
  .user-agreement .main-agreement-heading {
    font-size: 22px !important;
  }

  .top-alert {
    align-items: flex-start;
  }

  .oco-target {
    padding: 0 5px 0 5px;
  }

  .oco-profit {
    width: 65px;
  }

  .oco-loss {
    width: 47px !important;
  }
}

@media (max-width: 400px) {
  .scanner-setting .mat-dialog-container {
    max-width: 95vw !important;
  }

  .trading-chart .indicator-trade-button .next-erning {
    font-size: 10px;
  }

  .login-section {
    height: 460px;
    min-height: 460px;
    display: table;
    width: 300px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .login-box {
    padding: 30px 25px;
  }
}

@media (min-height: 700px) and (max-width: 991px) {
  /* app-powerx{
    display: block;
    height: calc(100vh - 53px);
  }
  .powerx-main {
    height: 100% !important;
  }
  .powerx-main .right-side{
    height: 30%;
  }
  .powerx-main .left-side{
    height: 70%;
  } */
}

@media (max-width: 374px) {
  .left-side-button .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding-left: 12px;
    padding-right: 12px;
  }

  .report-con-tbl th,
  .report-con-tbl td {
    padding: 4px 5px;
  }

  .account-size {
    padding: 3px 5px;
  }

  .top-alert {
    padding-right: 0 !important;

    .alert-btns {
      width: 100%;
      justify-content: center;
      margin-left: 0;
    }
  }
}

@media (max-width: 360px) {
  .user-agreement .main-agreement-heading {
    font-size: 20px;
  }
}

@media screen and (max-height: 700px) and (min-width: 768px) {
  .licence-agreement-section {
    height: calc(100vh - 68px);
    overflow: auto;
    max-height: unset;
    min-height: unset;
  }

  .user-agreement .terms-conditions-detail {
    height: calc(100vh - 406px);
  }
}

@media screen and (max-height: 1366px) and (min-width: 1024px) {
  .licence-agreement-section {
    height: calc(100vh - 68px);
    overflow: auto;
    max-height: unset;
    min-height: unset;
  }

  .user-agreement .terms-conditions-detail {
    height: calc(100vh - 406px);
  }
}

@media screen and (max-width: 991px) and (min-height: 940px) {
  .powerx-main .left-side {
    min-height: calc(100% - 221px);
  }

  .app-tv-chart-container {
    height: 62vh !important;
  }

  .premiums-chart-tabbing {
    height: calc(100% - 247px);
  }

  .chart-back-layout {
    height: 100%;
  }

  .right-side-content {
    height: calc(100% - 41px);
  }

  app-trading-chart,
  app-trading-chart-v0 {
    display: block;
    height: 100%;
  }
}

@media print {
  @page {
    size: letter;
  }

  .data-for-print {
    visibility: visible !important;
    width: 890px;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .page-while-print {
    display: none !important;
  }

  .cdk-overlay-container {
    display: none !important;
  }

  .print-border {
    padding: 2px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
  }

  .data-window {
    margin: 50px auto auto auto;
    transform: scale(1.1);
    max-width: 780px;
    width: 100%;
  }

  .risk-reward {
    max-width: 910px !important;
    width: 100% !important;
    min-width: 760px !important;

    .hide-option-greeks {
      display: none;
    }

    .buy .row,
    .put .row,
    .contract-maxentry.row,
    .risk-reward-amount {
      display: flex !important;
    }

    .buy-put-row .bid-ask .ask {
      padding-top: 0;
    }

    .risk-reward .buy-put-row .bid-ask .ask {
      padding-top: 2.5px;
      font-size: 16px;
    }
  }

  .risk-reward-text {
    display: flex;
    margin-top: 12px;
    padding: 3px 0;
  }

  .search-section {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;

    & .company-name {
      order: 0;
    }
  }

  .risk-reward-text > div:first-child,
  .risk-reward-text > div:last-child {
    justify-content: center;
  }

  .cdk-overlay-dark-backdrop {
    background: none;
  }
}
