.from-group {
  margin-bottom: 40px;
  h6 {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 700;
    margin: 0 0 5px;
  }
  .label-title {
    font-size: 12px;
    display: block;
    line-height: 1.3;
    margin-bottom: 2px;
  }
  .label-sub-title {
    font-size: 10px;
    line-height: 1.3;
    margin-bottom: 5px;
  }
  &-text {
    padding: 8px;
    font-size: 14px;
    line-height: 28px;
    background: #F5F8FD;
    color: #131722;
    border-radius: 5px;
    display: flex;
    .black-theme & {
      color: #fff !important;
      background: rgba(255, 255, 255, 0.2);
    }
    > div {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}
.from-group-column {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  > div {
    padding: 0 10px;
    flex: 1 0 0%;
    width: 50%;
  }
}

@media(max-width: 767px) {
  .from-group {
    margin-bottom: 20px;
  }
}