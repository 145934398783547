.extended-data-window{
  .expected-move-container {
    position: relative;
    margin-top: 5px;
    margin-bottom: -5px;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% - 20px);
      margin: 0 10px;
      border-top: 1px solid var(--light-border-color-7);
    }

    // it's done intentionally to avoid changes inside expected-move component
    // now it depending on "hidden" class inside expected-move component
    &:has(.expected-wrapper.hidden) {
      margin-top: 0;
      margin-bottom: 0;

      &:before {
        border-top: 1px solid transparent;
      }
    }
  }

  .expected-move-placeholder {
    padding: 4px 0 0;
    border-top: 1px solid var(--light-border-color-7);
  }
}
