.mat-expansion-panel {
  --mat-expansion-container-background-color: var(--white-bg);

  &:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }
}

.mat-accordion {
  &.trading-log-panels {
    .mat-expansion-panel {
      --mat-expansion-container-background-color: var(--white-bg);
      margin: 0 0 15px 10px;
      border: 1px solid var(--table-border-color);
      box-shadow: 0 5px 20px rgba(2, 69, 141, 0.1);
      border-radius: 5px;
    }

    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
      background: inherit;
    }

    .mat-expansion-panel-header {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content !important;
      padding: 10px 10px;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }

    @media screen and (min-width: $tablet-min-width-trigger) {
      .mat-expansion-panel {
        margin: 0 0 15px 10px;
      }
    }
  }
}

.portfolio {
  .portfolio-accordion,
  .portfolio-accordion-item {
    .mat-content.mat-content-hide-toggle {
      margin: 0;
    }

    .mat-expansion-panel {
      --mat-expansion-container-background-color: var(--white-bg);
      box-shadow: none;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }

    .mat-expansion-panel-header {
      padding: 4px 12px;
    }
  }
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: inherit;
  }
}

.calendar-container.master-calendar {
  .mat-accordion {
    .accordion-item {
      .mat-expansion-indicator {
        rotate: 45deg;
      }

      .mat-expansion-panel-body {
        padding: 4px;
      }
    }
  }
}

.profile-menu-item .profile-menu-block {
  .mat-expansion-panel {
    --mat-expansion-container-background-color: var(--white-bg);
    box-shadow: none;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel-header {
   height: 20px;
  }
}
