table.hover-styles {

    tbody tr td,
    thead tr td {
        background: transparent;
    }

    tr:hover td {
        background: var(--table-row-hover-color);
    }
}

.classic-hover-styles {

    tr td,
    tr td {
        background: transparent;
    }

    tr:hover td {
        background-color: var(--light-bg-1);
    }
}

.sticky-table {
    .stycky-cell {
        box-shadow: var(--table-sticky-tr-box-shadow)
    }
}

.trading-panel-content {
    .table-container.table-main-style {
        &.shadow-top {
              table thead tr {
                  th {
                      position: sticky;
                      top: 0;
                      left: 0;
                      z-index: 3;
                      box-shadow: var(--table-sticky-th-box-shadow);

                      &:first-child {
                        z-index: 5;
                      }
                  }
            }
        }

        &.shadow-bottom::after {
            display: inline-block;
            content: "";
            position: absolute;
            bottom: 0;
            width: calc(100% - 10px);
            height: 20px;
            pointer-events: none;
            background: var(--tl-datawindow-shadow-when-content-hides-on-bottom);
            background-repeat: no-repeat;
            z-index: 5;
        }
    }

    .table-main-style {
        width: 100%;
        overflow: auto;

        table {
            min-width: 100%;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            box-shadow: none;

            .th-conteiner {
                display: inline-flex;
                align-items: center;
                min-width: 62px;
                width: 62px;

                .name {
                    line-height: 150%;
                    white-space: nowrap;
                    cursor: pointer;
                }

                .arrow-container {
                    margin-left: 4px;
                    cursor: pointer;
                }
            }

            thead th {
              padding-top: 8px;
            }

            th,
            td {
                width: 75px;
                min-width: 75px;
                background-color: var(--trading-log-table-bg);
                white-space: nowrap;

                &.mat-column-avgFillPrice {
                    .th-conteiner {
                        width: 125px;
                        min-width: 125px;

                        @media screen and (max-width: $tablet-min-width-trigger) {
                            width: 100px;
                            min-width: 100px;
                        }
                    }
                }

                &.mat-column-profit {
                    .th-conteiner {
                        width: 58px;
                        min-width: 58px;
                    }
                }

                &.mat-column-lastPrice {
                    width: 105px;
                    min-width: 105px;
                }

                &.mat-column-placingTime {
                    max-width: 135px;
                    width: 9%;
                }

                &.mat-column-controlMenu {
                    padding-block: 0;
                    min-width: 34px !important;
                    width: 34px !important;
                    max-width: 34px !important;

                    @media screen and (max-width: $mobile-min-575px-width-trigger) {
                      padding-right: 5px !important;
                    }

                    .handle-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        .mat-button-base {
                            width: 24px;
                            height: 24px;
                            border-radius: 4px;
                            cursor: pointer;
                        }
                    }
                }
            }

            tr {
                height: 30px;

                td {
                    white-space: nowrap;
                    text-align: inherit;
                    vertical-align: middle;
                }

                &.mat-header-cell:last-of-type {
                    padding-right: 10px;
                }
            }

            th.mat-header-cell:last-of-type,
            td.mat-cell:last-of-type,
            td.mat-footer-cell:last-of-type {
                padding-right: 10px;
            }

            .symbol-content {
              display: flex;
              align-items: center;
              gap: 4px;

              &.sub-symbol-row {
                  margin-left: 40px;
              }

              .table-small-symbol {
                color: var(--symbol-font-color);
              }

              .table-description {
                color: var(--description-color);
              }

              .table-small-symbol ~ .table-description {
                line-height: 14px;
              }

              app-label {
                  margin-left: 6px;
              }
            }
        }

        &.table-sticky-style {
            table {
                thead tr {
                    position: initial;
                }

                .mat-column-symbol {
                    box-shadow: var(--table-sticky-tr-box-shadow);
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0;
                    z-index: 4;
                }
            }
        }
    }
}
