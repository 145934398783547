@mixin divider($type: horizontal, $space: 0) {
  @if $type == 'horizontal' {
    margin-block: $space;
    width: 100%;
    height: 1px;
  } @else {
    margin-inline: $space;
    width: 1px;
    height: 100%;
  }
  display: inline-block;
  background-color: var(--divider-color);
}
