%metric-details-modal-trigger-style {
  padding: 3px;
  display: inline-block;

  &:not(.no-transactions) {
    text-decoration: underline;

    &:hover {
      text-underline-offset: 2px;
      color: var(--primary-color);
      background-color: var(--custom-action-btn-bg);
      border-radius: 1px;
      cursor: pointer;
    }
  }

  &.no-transactions {
    pointer-events: none;
  }
}
